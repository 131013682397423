<template>
  <form @submit.prevent="handleSubmitForm">
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50 md-layout">
        <div class="md-layout-item md-size-25">
          <md-avatar class="md-large">
            <my-s3-image :imagePath="form.CustomerLogo"></my-s3-image>
          </md-avatar>
        </div>
        <div class="md-layout-item">
          <md-field>
            <md-input :value="form.CustomerName" :disabled="true" />
          </md-field>
        </div>
      </div>
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-datepicker v-model="form.Date" :md-model-type="String" md-immediately>
          <label>Date</label>
        </md-datepicker>
      </div>
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <label for="jobType">JobType</label>
          <md-select v-model="form.JobType" name="jobType" id="jobType" @md-selected="handleJobTypeChanged">
            <md-option :value="opt" v-for="opt in jobTypeAllOptions" :key="opt">{{opt}}</md-option>
          </md-select>
        </md-field>
      </div>
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <label for="jobSubtype">Job Subtype</label>
          <md-select v-model="form.JobSubtype" name="jobSubtype" id="jobSubtype" @md-selected="handleJobTypeChanged">
            <md-option :value="opt" v-for="opt in currentSubJobTypes" :key="opt">{{opt}}</md-option>
          </md-select>
        </md-field>
      </div>
      
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <label for="origin">Origin</label>
          <md-select v-model="form.Origin" name="origin" id="origin">
            <md-option :value="opt.Name" v-for="opt in currentOrigin" :key="opt.Name">{{opt.Label}}</md-option>
          </md-select>
        </md-field>
      </div>      
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <label for="destination">Destination</label>
          <md-select v-model="form.Destination" name="destination" id="destination">
            <md-option :value="opt.Name" v-for="opt in currentDestination" :key="opt.Name">{{opt.Label}}</md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <md-input :value="form.DriverName" :disabled="true" />
        </md-field>
      </div>
      
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <md-input :value="form.LicensePlate" :disabled="true" />
        </md-field>
      </div>
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <md-input :value="form.TruckType" :disabled="true" />
        </md-field>                         
      </div>
    </div> 

    <div class="md-layout md-gutter">
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <label for="serviceRatePlan">Service Rate Plan</label>
          <md-select v-model="form.ServiceRatePlan" name="serviceRatePlan" id="serviceRatePlan">
            <md-option :value="opt.SK" v-for="opt in currentRatePlans" :key="opt.SK">{{opt.Name}}</md-option>
          </md-select>
        </md-field>
      </div>
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <label>Service Price Amount</label>
          <md-input :value="form.ServicePriceAmount" type="number" />
        </md-field>
      </div>
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <label for="้hireRatePlan">Hire Rate Plan</label>
          <md-select v-model="form.HireRatePlan" name="้hireRatePlan" id="้hireRatePlan">
            <md-option :value="opt.SK" v-for="opt in currentRatePlans" :key="opt.SK">{{opt.Name}}</md-option>
          </md-select>
        </md-field>
      </div>
      <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <label>Hire Cost Amount</label>
          <md-input :value="form.HireCostAmount" type="number" />
        </md-field>
      </div>
    </div>

    <div class="md-layout md-gutter">
      <md-button type="submit" class="md-primary" @click="handleSubmitForm">Create Order</md-button>
    </div>

  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import VueScreenSize from 'vue-screen-size'
import moment from 'moment'
import { API } from 'aws-amplify'
import MyS3Image from '../components/MyS3Image'
import * as uuid from 'uuid'

export default {
  name: 'NewOrderFromDiary',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image},
  data () {
    return {
      isLoading: false,
      diary: {},
      errors: {},
      form: {
        BatchID: null,
        CustomerID: null,
        CustomerName: null,
        CustomerLogo: null,
        Date: null,
        JobType: null,
        JobSubtype: null,
        StartDateTime: null,
        CreatedBy: null,
        CreatedAt: null,
        UpdatedAt: null,
        DriverName: null,
        DriverID: null,
        DriverPhoneNumber: null,
        Driver: null,
        LicensePlate: null,
        TruckType: null,
        Origin: null,
        Destination: null,
        CustomField: null,
        Remark: null,
        ServiceRatePlan: null,
        ServicePriceAmount: null,
        HireRatePlan: null,
        HireCostAmount: null
      },
      jobTypeOptions: [],
      jobTypeCustomerOptions: [],
      priceOptions: [],
      truckTypeOptions: [],
      containerTypeOptions: [],
      customerFavoriteLocations: [],
      userItems: [],
      orders: [],
      trip: {},
      selectedOrder: [],
      step: 1,
      orderValString: '',
      orderString: '',
      tripString: '',
      customerJobTypes: [],
      fieldOptionLookup: {}
    }
  },
  methods: {
    fetchFavJobTypes: async function (customerID) {
      const json = await API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`)
      this.customerJobTypes = json
    },
    fetchCustomerLookup: async function (name, customerID, jobType, jobSubType, lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      if (!this.fieldOptionLookup[`${name}/${customerID}/${jobType}/${jobSubType}`]) {
        this.fieldOptionLookup[`${name}/${customerID}/${jobType}/${jobSubType}`] = []
      } else if (!lastEvaluatedKey) {
        return;
      }
      API.get('ezietruckapi', `/api/lookups/OptionLookup/${encodeURIComponent(name)}/${encodeURIComponent(customerID)}/${encodeURIComponent(jobType)}/${encodeURIComponent(jobSubType)}${q}`).then((json) => {
        this.fieldOptionLookup[`${name}/${customerID}/${jobType}/${jobSubType}`] = [
          ...self.fieldOptionLookup[`${name}/${customerID}/${jobType}/${jobSubType}`],
          ...json['Items']
        ]
        this.fieldOptionLookup = Object.assign({}, this.fieldOptionLookup)
        if (json.LastEvaluatedKey) {
          self.fetchCustomerLookup(name, customerID, jobType, jobSubType, json.LastEvaluatedKey)
        }
      })
    },
    fetchRatePlans: async function (customerID, jobType, jobSubType, lastEvaluatedKey) {
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      const json = await API.get('ezietruckapi', `/api/ratePlans/${encodeURIComponent(customerID)}/${encodeURIComponent(jobType)}/${encodeURIComponent(jobSubType)}` + q)
      if (json['Items']) {
        this.fieldOptionLookup[`ratePlans/${customerID}/${jobType}/${jobSubType}`] = [
          ...this.fieldOptionLookup[`ratePlans/${customerID}/${jobType}/${jobSubType}`],
          ...json['Items']
        ]
      }
      if (json.LastEvaluatedKey) {
        await this.fetchRatePlans(customerID, jobType, jobSubType, json.LastEvaluatedKey)
      }
    },
    fetchOptions: function () {
      let self = this
      API.get('ezietruckapi', `/api/options/JobType`).then((json) => {
        self.jobTypeOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/TruckType`).then((json) => {
        self.truckTypeOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/ContainerType`).then((json) => {
        self.containerTypeOptions = json.map((j) => j.Name)
      })
    },
    fetchUser: function () {
      let self = this
      API.get('ezietruckapi', '/api/users').then((json) => {
        self.userItems = json['Users']
      })
    },
    fetchDiary: function () {
      let self = this
      API.get('ezietruckapi', `/api/vehicles/diary/${encodeURIComponent(this.$route.params.pk)}/${encodeURIComponent(this.$route.params.sk)}`).then((json) => {
        if (json['Item']) {
          self.diary = json['Item']
          self.fetchFavJobTypes(json['Item']['CustomerID'])
          if (!self.fieldOptionLookup[`Origin/${json['Item']['CustomerID']}/---/---`]) {
            self.fetchCustomerLookup('Origin', json['Item']['CustomerID'], '---', '---')
          }          
          if (!self.fieldOptionLookup[`Origin/${json['Item']['CustomerID']}/${json['Item']['JobType'] || '---'}/${json['Item']['JobSubtype'] || '---'}`]) {
            self.fetchCustomerLookup('Origin', json['Item']['CustomerID'], json['Item']['JobType'] || '---', json['Item']['JobSubtype'] || '---')
          }

          if (!self.fieldOptionLookup[`Destination/${json['Item']['CustomerID']}/---/---`]) {
            self.fetchCustomerLookup('Destination', json['Item']['CustomerID'], '---', '---')
          }          
          if (!self.fieldOptionLookup[`Destination/${json['Item']['CustomerID']}/${json['Item']['JobType'] || '---'}/${json['Item']['JobSubtype'] || '---'}`]) {
            self.fetchCustomerLookup('Destination', json['Item']['CustomerID'], json['Item']['JobType'] || '---', json['Item']['JobSubtype'] || '---')
          }

          if (!self.fieldOptionLookup[`ratePlans/${json['Item']['CustomerID']}/---/---`]) {
            self.fieldOptionLookup[`ratePlans/${json['Item']['CustomerID']}/---/---`] = []
            self.fetchRatePlans(json['Item']['CustomerID'], '---', '---')
          }          
          if (!self.fieldOptionLookup[`ratePlans/${json['Item']['CustomerID']}/${json['Item']['JobType'] || '---'}/${json['Item']['JobSubtype'] || '---'}`]) {
            self.fieldOptionLookup[`ratePlans/${json['Item']['CustomerID']}/${json['Item']['JobType'] || '---'}/${json['Item']['JobSubtype'] || '---'}`] = []
            self.fetchRatePlans(json['Item']['CustomerID'], json['Item']['JobType'] || '---', json['Item']['JobSubtype'] || '---')
          }
          
          self.form = Object.assign({}, self.form, {
            BatchID: null,
            CustomerID: json['Item']['CustomerID'],
            CustomerName: json['Item']['Customer']['CustomerName'],
            CustomerLogo: json['Item']['Customer']['CustomerLogo'],
            Date: moment(json['Item']['StartDateTime']).format('YYYY-MM-DD'),
            JobType: json['Item']['JobType'],
            JobSubtype: json['Item']['JobSubtype'] || '',
            StartDateTime: json['Item']['StartDateTime'],
            DriverName: json['Item']['Driver']['DriverName'],
            DriverID: json['Item']['DriverID'],
            DriverPhoneNumber: json['Item']['Driver']['DriverPhoneNumber'],
            Driver: json['Item']['Driver'],
            LicensePlate: `${(json['Item']['Vehicle'] || {})['VehicleLicenseNumber'] || ''} ${(json['Item']['Vehicle'] || {})['VehicleLicenseProvince'] || ''}`,
            TruckType: (json['Item']['Vehicle'] || {})['VehicleTruckType']
          })
        }
      })
    },
    handleJobTypeChanged: function () {        
      if (!this.fieldOptionLookup[`Origin/${this.form['CustomerID']}/${this.form['JobType'] || '---'}/${this.form['JobSubtype'] || '---'}`]) {
        this.fetchCustomerLookup('Origin', this.form['CustomerID'], this.form['JobType'] || '---', this.form['JobSubtype'] || '---')
      }    
      if (!this.fieldOptionLookup[`Destination/${this.form['CustomerID']}/${this.form['JobType'] || '---'}/${this.form['JobSubtype'] || '---'}`]) {
        this.fetchCustomerLookup('Destination', this.form['CustomerID'], this.form['JobType'] || '---', this.form['JobSubtype'] || '---')
      }    
      if (!this.fieldOptionLookup[`ratePlans/${this.form['CustomerID']}/${this.form['JobType'] || '---'}/${this.form['JobSubtype'] || '---'}`]) {
        this.fetchRatePlans(this.form['CustomerID'], this.form['JobType'] || '---', this.form['JobSubtype'] || '---')
      }
    },
    handleSubmitForm: function () {
      let u = uuid.v4()
      console.log(Object.assign({}, this.form, {
              PK: 'Order',
              SK: `5:Confirmed#${u}`,
              BatchID: u,
              StartDateTime: d.Date +'T00:00:00+07:00',
              UpdatedAt: new Date().toISOString(),
              CreatedAt: new Date().toISOString(),
              CreatedBy: this.user.signInUserSession.idToken.payload.email
            }))
    }
  },
  computed: {
    ...mapGetters(['user']),
    jobTypeAllOptions: function () {
      return [...new Set([...this.jobTypeOptions, ...this.customerJobTypes.map((c) => c.Name)])];
    },
    userOptions: function () {
      return this.userItems.map((u) => {
        let idx = u.Attributes.findIndex((a) => a.Name === 'email')
        if (idx > -1) {
          return u.Attributes[idx].Value
        }
      })
    },
    sortedOrders: function () {
      return this.orders.sort((a,b) => a.PK.localeCompare(b.PK))
    },
    currentSubJobTypes: function () {
      if (this.form.JobType) {
        const idx = this.customerJobTypes.findIndex((j) => j.Name === this.form.JobType)
        return idx > -1 ? this.customerJobTypes[idx].SubJobType : []
      }
    },
    currentOrigin: function () {
      return [
        ...this.fieldOptionLookup[`Origin/${this.form.CustomerID}/---/---`] || [],
        ...this.fieldOptionLookup[`Origin/${this.form.CustomerID}/${this.form.JobType || '---'}/${this.form.JobType || '---'}`] || [],
      ]
    },
    currentDestination: function () {
      return [
        ...this.fieldOptionLookup[`Destination/${this.form.CustomerID}/---/---`] || [],
        ...this.fieldOptionLookup[`Destination/${this.form.CustomerID}/${this.form.JobType || '---'}/${this.form.JobType || '---'}`] || [],
      ]
    },
    currentRatePlans: function () {
      return [
        ...this.fieldOptionLookup[`ratePlans/---/---/---`] || [],
        ...this.fieldOptionLookup[`ratePlans/${this.form.CustomerID}/---/---`] || [],
        ...this.fieldOptionLookup[`ratePlans/${this.form.CustomerID}/${this.form.JobType || '---'}/${this.form.JobType || '---'}`] || [],
      ]
    }
  },
  created () {
    this.fetchOptions()
    this.fetchUser()
    this.fetchDiary()
    this.fieldOptionLookup['ratePlans/---/---/---'] = []
    this.fetchRatePlans('---', '---', '---')
  }
}
</script>