<template>
  <div>
    <div class="md-layout">
      <div style="padding: 4px" class="md-layout-item md-large-size-85 md-xlarge-size-85 md-medium-size-85 md-small-size-100 md-xsmall-size-100 md-content">
        <md-card v-for="(detail, orderID) in sortedDiaries" :key="orderID" style="margin: 4px">
          <md-card-header>
            <div class="md-layout">
              <div class="md-layout-item">
                <div class="md-title">{{ sortedDiaries[orderID].Driver.DriverName }}</div>
              </div>
              <div class="md-layout-item">
                <md-card-actions>
                  <md-button :class="{'md-primary': activeDiary.SK === sortedDiaries[orderID].SK, 'md-raised': activeDiary.SK === sortedDiaries[orderID].SK}" @click="handleDiaryClick(sortedDiaries[orderID])">
                    <span v-show="activeDiary.SK !== sortedDiaries[orderID].SK">Select</span>
                    <span v-show="activeDiary.SK === sortedDiaries[orderID].SK">Selected</span>
                  </md-button>
                  <md-button @click="handleConfirmDiary(sortedDiaries[orderID])" :disabled="!activeOrder.PK">Confirm</md-button>
                </md-card-actions>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <md-tabs>
              <md-tab :id="d.CreatedAt" :md-label="$dateTimeFormater(d.CreatedAt)" exact v-for="d in detail.Diaries" :key="d.SK">
                <DiaryDetail :diary="d"></DiaryDetail>
              </md-tab>
            </md-tabs>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-large-size-15 md-xlarge-size-15 md-medium-size-15 md-small-hide md-xsmall-hide md-elevation-1">
        <div :style="{'position': 'fixed', 'width': $vssWidth*0.15 + 'px', 'max-height': $vssHeight-190 + 'px', 'overflow': 'auto'}">
          <md-datepicker :value="formData.StartDate" :md-model-type="String" md-immediately @input="handleFetchOrder">
            <label>Start Date</label>
          </md-datepicker>
          <md-list class="md-triple-line" >
            <md-list-item v-for="order in resultOrders" :key="order.OrderID" @click="handleOrderClick(order)" :class="{'activeOrder': order.OrderID === activeOrder.OrderID}">
              <md-icon class="md-primary" v-if="order.OrderID === activeOrder.OrderID">check_box</md-icon>
              <md-icon v-if="order.OrderID !== activeOrder.OrderID">check_box_outline_blank</md-icon>
              <div class="md-list-item-text">
                <span>{{order.OrderID}}</span>
                <span>{{order.JobType}} - {{order.JobSubtype}} </span>
                <span>{{order.Origin}} -> {{order.Destination}} </span>
                <p v-if="order.DriverName">{{order.DriverName}}</p>
                <p v-if="!order.DriverName && approveForm.searchSelected.length > 0">{{approveForm.searchSelected[0].Driver.DriverName}}</p>
              </div>
              <md-button class="md-icon-button md-list-action">
                <md-icon>delete</md-icon>
              </md-button>
            </md-list-item>
          </md-list>
          <div v-show="activeDiary.SK">
            <p v-show="resultOrders.length === 0">
              No match order please select other date or 
              <router-link :to="'/NewOrderFromDiary/' + encodeURIComponent(activeDiary.PK) + '/' + encodeURIComponent(activeDiary.SK)">create new order</router-link>
            </p>
          </div>
          <div v-show="!activeDiary.SK">
            <p>
              Please select diary
            </p>
          </div>
        </div>
      </div>
    </div>
    <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackBarMsg}}</span>
    </md-snackbar>
  </div>
</template>
<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import MyS3Image from '../components/MyS3Image'
import DiaryDetail from './DiaryDetail'

export default {
  name: 'DiaryMatcher',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image, DiaryDetail},
  data () {
    return {
      diaryItems: [],
      orderItems: [],
      activeOrder: {},
      activeDiary: {},
      formData: {
        StartDate: this.$route.params.date
      },
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
    }
  },
  methods: {
    fetchDiary: function (lastEvaluatedKey) {
      let self = this
      let q = '?CustomerID='+this.$route.params.customerID+'&filter=' + this.$route.params.filter
      if (lastEvaluatedKey) {
        q += `&ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', `/api/vehicles/diary/${this.$route.params.date}${q}`).then((json) => {
        if (json['Items']) {
          self.diaryItems = [...self.diaryItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchDiary(json.LastEvaluatedKey)
        }
      })
    },
    fetchOrder: function (driverID) {
      let self = this
      let q = '?filter=unmatch&driverID='+encodeURIComponent(driverID)
      API.get('ezietruckapi', `/api/newOrders/${this.$route.params.customerID}/${this.formData.StartDate}${q}`).then((json) => {
        if (json['Items']) {
          self.orderItems = [...json['Items']]
        }
      })
    },
    handleOrderClick: function (order) {
      if (this.activeOrder.OrderID === order.OrderID) {
        this.activeOrder = {}
      } else {
        this.activeOrder = order
      }
    },
    handleDiaryClick: function (diary) {
      if (this.activeDiary.SK === diary.SK) {
        this.activeDiary = {}
        this.orderItems = []
      } else {
        this.activeDiary = diary
        this.orderItems = []
        this.fetchOrder(diary.Driver.DriverID)
      }
    },
    handleConfirmDiary: function (diary) {
      let vm = this
      if (this.activeDiary.SK && this.activeOrder.SK) {
        API.post('ezietruckapi', `/api/newOrders/${encodeURIComponent(this.activeOrder.SK)}/diaries/each`, {body: {
          MatchedDiaries: {
            SuggestDiaries: diary.Diaries,
            SearchDiaries: [],
            DiaryReason: '-'
          }
        }}).then(() => {
          this.activeDiary = {}
          this.orderItems = []
          vm.showSnackbar = true
          vm.snackBarMsg = 'Order matched successfully.'
          const sk = diary.Diaries.map((i) => i.SK)
          this.diaryItems = [
            ...this.diaryItems.filter((d) => !sk.includes(d.SK))
          ]
        })
      }
    },
    handleFetchOrder: function (event) {
      this.formData.StartDate = event
      if (this.activeDiary.SK) {
        this.fetchOrder(this.activeDiary.Driver.DriverID)
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    resultOrders () {
      let vm = this
      return this.orderItems.map((o) => Object.assign({}, o, {
        score: vm.activeDiary.PK && vm.activeDiary.OrderID === o.OrderID ? 1 : 0
      })).sort((a,b) => b.score - a.score)
    },
    sortedDiaries () {
      const diaries = {}
      for (const d of this.diaryItems.sort((a,b) => (b.CreatedAt || '').localeCompare(a.CreatedAt || ''))) {
        if (d.OrderID) {
          if (!diaries[d.OrderID]) {
            diaries[d.OrderID] = {
              PK: '-',
              SK: d.OrderID,
              Driver: d.Driver,
              Diaries: []
            }
          }
          diaries[d.OrderID].Diaries.push(d)
        } else {
          diaries[`_${(Math.random() + 1).toString(36).substring(2)}`] = {
            PK: d.PK,
            SK: d.SK,
            Driver: d.Driver,
            Diaries: [d]
          }
        }
      }
      return diaries
    }
  },
  created () {
    this.fetchDiary()
  }
}
</script>
<style scoped>
.activeOrder {
  border: 2px solid red;
}
</style>