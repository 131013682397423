<template>
  <div>
    <form @submit.prevent="">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <div class="md-layout">
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.CustomerID']}">
                <label>Customer ID</label>
                <md-input v-model="formData.CustomerID" @blur="checkCustomer($event.target.value)"></md-input>
                <span class="md-error" v-if="errors['instance.CustomerID']">{{errors['instance.CustomerID']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100" v-show="formData.CustomerID">
              <div style="display: flex;">
                <md-avatar class="md-large" v-show="formData.CustomerLogo">
                  <my-s3-image :imagePath="formData.CustomerLogo"></my-s3-image>
                </md-avatar>
                <md-field :class="{'md-invalid': errors['instance.CustomerLogo']}">
                  <md-file v-model="imageSelect" accept="image/*" @md-change="handleImageSelect" placeholder="Customer Logo" />
                  <span class="md-error" v-if="errors['instance.CustomerLogo']">Invalid field input</span>
                </md-field>
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-field :class="{'md-invalid': errors['instance.CustomerName']}">
            <label>Customer Name</label>
            <md-input v-model="formData.CustomerName"></md-input>
            <span class="md-error" v-if="errors['instance.CustomerName']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <div class="md-layout">
            <div :class="['md-layout-item', 'md-large-size-100', 'md-xlarge-size-100', 'md-medium-size-100', 'md-small-size-100', 'md-xsmall-size-100']">
              <md-field :class="{'md-invalid': errors['instance.CustomerAddress.Text']}">
                <label>Address</label>
                <md-input v-model="formData.CustomerAddress.Text"></md-input>
                <span class="md-error" v-if="errors['instance.CustomerAddress.Text']">Invalid field input</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
              <md-field :class="{'md-invalid': errors['instance.CustomerAddress.GPS.Lat']}">
                <label>Lat</label>
                <md-input v-model.number="formData.CustomerAddress.GPS.Lat"></md-input>
                <span class="md-error" v-if="errors['instance.CustomerAddress.GPS.Lat']">Invalid field input</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
              <md-field :class="{'md-invalid': errors['instance.CustomerAddress.GPS.Lat']}">
                <label>Lon</label>
                <md-input v-model.number="formData.CustomerAddress.GPS.Lon"></md-input>
                <span class="md-error" v-if="errors['instance.CustomerAddress.GPS.Lon']">Invalid field input</span>
              </md-field>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <div class="md-layout">
            <div :class="['md-layout-item', 'md-large-size-50', 'md-xlarge-size-50', 'md-medium-size-50', 'md-small-size-50', 'md-xsmall-size-50']">
              <md-field :class="{'md-invalid': errors['instance.CustomerTaxID']}">
                <label>Tax ID</label>
                <md-input v-model="formData.CustomerTaxID"></md-input>
                <span class="md-error" v-if="errors['instance.CustomerTaxID']">Invalid field input</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
              <md-datepicker :value="formData.CreatedDate" :md-model-type="String" :class="{'md-invalid': errors['instance.CreatedDate']}" md-immediately>
                <label>Created date</label>
                <span class="md-error" v-if="errors['instance.CreatedDate']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
              <md-field :class="{'md-invalid': errors['instance.CustomerBusinessType']}">
                <label>Business Type</label>
                <md-input v-model="formData.CustomerBusinessType"></md-input>
                <span class="md-error" v-if="errors['instance.CustomerBusinessType']">Invalid field input</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
              <md-field :class="{'md-invalid': errors['instance.CustomerCreditDays']}">
                <label>Credit Days</label>
                <md-input v-model.number="formData.CustomerCreditDays"></md-input>
                <span class="md-error" v-if="errors['instance.CustomerCreditDays']">Invalid field input</span>
              </md-field>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <h3>Contacts</h3>
          <div class="md-layout" v-for="(contact, idx) in formData.Contacts" :key="idx">
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.Contacts.' + idx + '.Name']}">
                <label>Name</label>
                <md-input v-model="formData.Contacts[idx].Name"></md-input>
                <span class="md-error" v-if="errors['instance.Contacts.' + idx + '.Name']">Invalid field input</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.Contacts.' + idx + '.Position']}">
                <label>Position</label>
                <md-input v-model="formData.Contacts[idx].Position"></md-input>
                <span class="md-error" v-if="errors['instance.Contacts.' + idx + '.Position']">Invalid field input</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.Contacts.' + idx + '.Email']}">
                <label>Email</label>
                <md-input v-model="formData.Contacts[idx].Email"></md-input>
                <span class="md-error" v-if="errors['instance.Contacts.' + idx + '.Email']">Invalid field input</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.Contacts.' + idx + '.Phone']}">
                <label>Phone</label>
                <md-input v-model="formData.Contacts[idx].Phone"></md-input>
                <span class="md-error" v-if="errors['instance.Contacts.' + idx + '.Phone']">Invalid field input</span>
              </md-field>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <div class="md-layout">
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
              <h3>Job Type</h3>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
              <md-button class="md-raised" @click="handleAddMoreJobType">Add more Job type</md-button>
            </div>
          </div>
          <div class="md-layout" v-for="(jobType, idx) in formData.JobTypes" :key="idx">
            <div class="md-layout-item md-large-size-33 md-xlarge-size-33 md-medium-size-33 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.JobTypes.' + idx + '.Name']}">
                <label>Name</label>
                <md-input v-model="formData.JobTypes[idx].Name"></md-input>
                <span class="md-error" v-if="errors['instance.JobTypes.' + idx + '.Name']">Invalid field input</span>
                <md-button class="md-icon-button" v-show="formData.JobTypes[idx].Name" @click="handleDeleteJobType(formData.JobTypes[idx].Name)">
                  <md-icon>delete</md-icon>
                </md-button>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-33 md-xlarge-size-33 md-medium-size-33 md-small-size-100 md-xsmall-size-100">
              <md-chips v-model="formData.JobTypes[idx].SubJobType" md-placeholder="Add SubJobType..."></md-chips>
            </div>
            <div class="md-layout-item md-large-size-33 md-xlarge-size-33 md-medium-size-33 md-small-size-100 md-xsmall-size-100">
              <md-chips v-model="formData.JobTypes[idx].Drivers" md-placeholder="Add driver phone numbers..."></md-chips>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-button @click="handleCreateCustomer" class="md-raised md-primary" v-show="!hideSubmit">
            <span v-show="!$route.params.customerID">Create an Customer</span>
            <span v-show="!!$route.params.customerID">Update an Customer</span>
          </md-button>
          <md-button class="md-raised" @click="$router.go(-1)">Back</md-button>
        </div>
      </div>
      <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
        <span>{{snackBarMsg}}</span>
      </md-snackbar>
      </form>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'
import {validate} from 'jsonschema'
import schema from '../schema'
import MyS3Image from '../components/MyS3Image'

export default {
  name: 'CustomerForm',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image},
  data () {
    return {
      driverItems: [],
      businessTypeOptions: [],
      hideSubmit: false,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      errors: {},
      imageSelect: null,
      formData: {
        CustomerID: null,
        CustomerLogo: null,
        CustomerName: null,
        CustomerAddress: {
          Text: null,
          GPS: {
            Lat: null, Lon: null
          }
        },
        CustomerBusinessType: null,
        CustomerCreditDays: null,
        Contacts: [
          {Name: null, Position: null, Phone: null, Email: null}
        ],
        JobTypes: [
          {Name: null, Drivers: [], SubJobType: []}
        ],
        CustomerTaxID: null,
        CreatedDate: moment().format('YYYY-MM-DD')
      },
      OriginalJobTypes: [],
    }
  },
  methods: {
    fetchFavJobTypes: function () {
      if (this.$route.params.customerID) {
        let vm = this
        API.get('ezietruckapi', `/api/customers/${this.$route.params.customerID}/favorite-job-types`).then((json) => {
          vm.formData.JobTypes = [...json.map((j) => ({Name: j.Name, Drivers: j.Drivers || [], SubJobType: j.SubJobType || []})), {Name: null, Drivers: [], SubJobType: []}]
          vm.OriginalJobTypes = [...json]
          vm.formData = Object.assign({}, vm.formData)
        })
      }
    },
    fetchData: function () {
      if (this.$route.params.customerID) {
        API.get('ezietruckapi', `/api/customers/${this.$route.params.customerID}`).then((json) => {
          delete json.PK
          delete json.SK
          delete json.Listed
          delete json.LastOrderID
          if (!json.Contacts || json.Contacts.length === 0) {
            json.Contacts = [
              {Name: null, Position: null, Phone: null, Email: null}
            ]
          } else {
            for (let c of json.Contacts) {
              if (!c.Position) {
                c.Position = null
              }
              if (!c.Phone) {
                c.Phone = null
              }
              if (!c.Email) {
                c.Email = null
              }
            }
          }

          if (!json.CustomerAddress.GPS) {
            json.CustomerAddress.GPS = {Lat: null, Lon: null}
          }
          if (!json.CreatedDate) {
            json.CreatedDate = moment().format('YYYY-MM-DD')
          }
          if (!this.formData.JobTypes) {
            json.JobTypes = [{Name: null, Drivers: [], SubJobType: []}]
          } else {
            json.JobTypes = [...this.formData.JobTypes]
          }
          this.formData = Object.assign({}, json)
        })
      }
    },
    formValidate: function () {
      let p = validate(this.submitForm, schema.CustomerSchema)
      this.errors = {}
      for (let err of p.errors) {
        this.errors[err.property] = err.message
      }
      this.checkCustomer(this.submitForm.CustomerID)
      this.errors = Object.assign({}, this.errors)
    },
    handleImageSelect: function (event) {
      for (let file of event) {
        this.$Amplify.Storage.put(
          `Cust_Profile_Images/${this.formData.CustomerID}.`+ file.name, file, { level: 'public' }
        ).then((res) => {
          this.formData.CustomerLogo = res.key
        }).catch(console.error)
      }
    },
    handleCreateCustomer: function () {
      this.formValidate()
      if (Object.keys(this.errors).length == 0) {
        this.hideSubmit = true
        let promises = [API.post('ezietruckapi', '/api/customers', {body: this.submitForm}), ...this.changedJobType]
        for (let f of this.formData.JobTypes) {
          if (f.Name) {
            promises.push(
              API.post('ezietruckapi', `/api/customers/${this.$route.params.customerID}/favorite-job-types`, {body: f})
            )
          }
        }
        
        Promise.all(promises).then(() => {
          this.showSnackbar = true
          this.snackBarMsg = 'Successfully saved'
          this.hideSubmit = false
          this.$router.push('/Customers')
        })
      } else {
        this.showSnackbar = true
        this.snackBarMsg = 'Invalid form input'
        this.hideSubmit = false
      }
    },
    checkCustomer: async function (value) {
      if (value && !this.$route.params.customerID) {
        await API.get('ezietruckapi', `/api/customers/${value}`).then((json) => {
          if (json.PK) {
            this.errors['instance.CustomerID'] = 'CustomerID already exist'
            this.errors = Object.assign({}, this.errors)
          }
        })
      }
    },
    handleAddMoreJobType: function () {
      this.formData.JobTypes = [
        ...this.formData.JobTypes,
        {Name: null, Drivers: [], SubJobType: []}
      ]
      this.formData = Object.assign({}, this.formData)
    },
    handleDeleteJobType: function(name) {
      const idx = this.formData.JobTypes.findIndex((j) => j.Name === name)
      if (idx > -1) {
        this.formData.JobTypes = [...this.formData.JobTypes.slice(0, idx), ...this.formData.JobTypes.slice(idx + 1)]
        this.formData = Object.assign({}, this.formData)
      }
    },
  },
  computed: {
    ...mapGetters(['user']),
    changedJobType () {
      let deleteJobTypes = []
      let putJobTypes = []
      const names = this.formData.JobTypes.filter((j) => j.Name).map((j) => j.Name)
      for (let jobType of this.OriginalJobTypes) {
        if (!names.includes(jobType.Name)) {
          deleteJobTypes.push(API.del('ezietruckapi', `/api/customers/${this.$route.params.customerID}/favorite-job-types/${jobType.Name}`))
        }
      }
      for (let jobType of this.formData.JobTypes.filter((j) => j.Name)) {
        putJobTypes.push(API.post('ezietruckapi', `/api/customers/${this.$route.params.customerID}/favorite-job-types`, {body: jobType}))
      }
      return [
        ...deleteJobTypes,
        ...putJobTypes
      ]
    },
    submitForm () {
      let form = Object.assign({}, this.formData)
      if (form.CustomerAddress.GPS.Lat == null || form.CustomerAddress.GPS.Lon == null) {
        form.CustomerAddress = Object.assign({}, {Text: form.CustomerAddress.Text})
      }
      let contacts = []
      for (let contact of form.Contacts) {
        if (contact.Name) {
          let c = {Name: contact.Name}
          if (contact.Position) {
            c.Position = contact.Position
          }
          if (contact.Phone) {
            c.Phone = contact.Phone
          }
          if (contact.Email) {
            c.Email = contact.Email
          }
          contacts.push(c)
        }
      }
      delete form.Contacts
      form.Contacts = contacts
      delete form.JobTypes
      return form
    },
    driverOption () {
      return this.driverItems.filter((d) => d.SK.startsWith('#METADATA#'))
    }
  },
  created () {
    this.fetchData()
    this.fetchFavJobTypes()
  }
}
</script>
