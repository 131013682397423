<template>
  <md-table class="md-primary" md-card>
    <md-table-toolbar>
      <div class="md-toolbar-section-start">
        <h3 class="md-subtitle" style="color: white">{{dateTimeFormatter(month)}} ({{$currencyFormater(customerGroup.sum)}})</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button class="md-icon-button" @click="isShow=!isShow">
          <md-icon v-show="isShow">expand_less</md-icon>
          <md-icon v-show="!isShow">expand_more</md-icon>
        </md-button>
      </div>
    </md-table-toolbar>
    <md-table-row v-show="isShow">
      <md-table-head>Customer</md-table-head>
      <md-table-head>Total</md-table-head>
    </md-table-row>
    <md-table-row v-show="isShow" v-for="(sum, cusID) in customerGroup.invoices" :key="cusID">
      <md-table-cell>
        <md-avatar><amplify-s3-image :imagePath="(customers[cusID] || {}).CustomerLogo" class="md-avatar md-large"></amplify-s3-image></md-avatar>
        <router-link :to="{'name': 'NoInvoiceDetail', params: {'month': month.split('-')[1], 'billDate': month, 'customerID': cusID}}">{{customers[cusID].CustomerName}}</router-link>
      </md-table-cell>
      <md-table-cell>{{$currencyFormater(sum)}}</md-table-cell>
    </md-table-row>
  </md-table>
</template>

<script>
import moment from 'moment'

export default {
  name: 'NoInvoiceListTable',
  props: {
    month: String,
    customerGroup: Object,
    customers: Object
  },
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY")
      } else {
        return ''
      }
    }
  }
}
</script>