var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('md-toolbar',[_c('div',{staticClass:"md-toolbar-row"},[_c('div',{staticClass:"md-toolbar-section-start"},[_c('form',{staticClass:"md-toolbar-row md-layout",on:{"submit":function($event){$event.preventDefault();return _vm.handleSearchOrder.apply(null, arguments)}}},[_c('div',{staticClass:"md-layout-item  md-size-20"},[_c('md-datepicker',{class:{'md-invalid': _vm.errors['Date']},attrs:{"md-model-type":String,"md-immediately":""},model:{value:(_vm.form.StartDate),callback:function ($$v) {_vm.$set(_vm.form, "StartDate", $$v)},expression:"form.StartDate"}},[_c('label',[_vm._v("Start Date")]),(_vm.errors['Date'])?_c('span',{staticClass:"md-error"},[_vm._v("Invalid field input")]):_vm._e()])],1),_c('div',{staticClass:"md-layout-item  md-size-20"},[_c('md-datepicker',{class:{'md-invalid': _vm.errors['Date']},attrs:{"md-model-type":String,"md-immediately":""},model:{value:(_vm.form.EndDate),callback:function ($$v) {_vm.$set(_vm.form, "EndDate", $$v)},expression:"form.EndDate"}},[_c('label',[_vm._v("End Date")]),(_vm.errors['Date'])?_c('span',{staticClass:"md-error"},[_vm._v("Invalid field input")]):_vm._e()])],1),_c('div',{staticClass:"md-layout-item"},[_c('md-autocomplete',{class:{'md-invalid': _vm.errors['instance.Customer']},attrs:{"value":_vm.form.Customer.CustomerName,"md-options":_vm.customers,"disabled":!!_vm.$route.params.orderID},on:{"input":_vm.handleCustomerSelect},scopedSlots:_vm._u([{key:"md-autocomplete-item",fn:function(ref){
var item = ref.item;
var term = ref.term;
return [_c('md-highlight-text',{attrs:{"md-term":term}},[_vm._v(_vm._s(item.CustomerName))])]}},{key:"md-autocomplete-empty",fn:function(ref){
var term = ref.term;
return [_vm._v(" No customer matching \""+_vm._s(term)+"\" were found. ")]}}])},[_c('label',[_vm._v("Customer")]),(_vm.errors['instance.Customer'])?_c('span',{staticClass:"md-error"},[_vm._v("Invalid field input")]):_vm._e()])],1),_c('div',{staticClass:"md-layout-item"},[_c('md-autocomplete',{class:{'md-invalid': _vm.errors['instance.JobType']},attrs:{"md-options":_vm.jobTypeAllOptions},scopedSlots:_vm._u([{key:"md-autocomplete-empty",fn:function(ref){
var term = ref.term;
return [_c('a',{on:{"click":function($event){return _vm.handleSaveCustomerJobType(term)}}},[_vm._v("Create a new")]),_vm._v(" Job Type! ")]}}]),model:{value:(_vm.form.JobType),callback:function ($$v) {_vm.$set(_vm.form, "JobType", $$v)},expression:"form.JobType"}},[_c('label',[_vm._v("Job Type")]),(_vm.errors['instance.JobType'])?_c('span',{staticClass:"md-error"},[_vm._v("Invalid field input")]):_vm._e()])],1),_c('div',{staticClass:"md-layout-item"},[_c('md-button',{staticClass:"md-raised md-primary",attrs:{"type":"submit"}},[_c('span',[_vm._v("Search")])])],1)])]),_c('div',{staticClass:"md-toolbar-section-end"},[_c('md-button',{staticClass:"md-raised",on:{"click":function($event){return _vm.nextStep(_vm.step + 1)}}},[_c('span',[_vm._v("Next")])])],1)])]),_c('div',{staticClass:"md-layout-item md-content"},[_c('md-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 1),expression:"step === 1"}],on:{"md-selected":_vm.onSelect},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{attrs:{"md-selectable":"multiple","md-auto-select":""}},[_c('md-table-cell',{attrs:{"md-label":"Order ID"}},[_vm._v(_vm._s(item.OrderID))]),_c('md-table-cell',{attrs:{"md-label":"Customer Name"}},[_vm._v(_vm._s(item.CustomerName))]),_c('md-table-cell',{attrs:{"md-label":"Job Type"}},[_vm._v(_vm._s(item.JobType))]),_c('md-table-cell',{attrs:{"md-label":"Route"}},[_vm._v(_vm._s(item.From.Description)+" - "+_vm._s(item.To.Description))]),_c('md-table-cell',{attrs:{"md-label":"Start Date"}},[_vm._v(_vm._s(_vm.dateTimeFormat(item.StartDateTime)))]),_c('md-table-cell',{attrs:{"md-label":"Remark"}},[_vm._v(_vm._s(item.Remark))]),_c('md-table-cell',{attrs:{"md-label":"Driver"}},[(_vm.trip[item.PK])?_c('span',[_vm._v(_vm._s(_vm.trip[item.PK].DriverName))]):_vm._e()]),_c('md-table-cell',{attrs:{"md-label":"Customer Paid / Driver Paid"}},[_vm._v(" "+_vm._s(_vm.$currencyFormater(item.CustomerPaidAmount))+" / "),(_vm.trip[item.PK])?_c('span',[_vm._v(" "+_vm._s(_vm.$currencyFormater(_vm.trip[item.PK].DailyPickerCost + _vm.trip[item.PK].DeliveryCost + _vm.trip[item.PK].FuelAllowance + _vm.trip[item.PK].OtherCost + _vm.trip[item.PK].SalaryPerTrip))+" ")]):_vm._e()])],1)}}]),model:{value:(_vm.sortedOrders),callback:function ($$v) {_vm.sortedOrders=$$v},expression:"sortedOrders"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 2),expression:"step === 2"}]},[_c('md-field',[_c('label',[_vm._v("Select Order")]),_c('md-textarea',{attrs:{"value":_vm.orderValString,"readonly":""}})],1),_c('md-field',[_c('label',[_vm._v("Edit Order")]),_c('md-textarea',{model:{value:(_vm.orderString),callback:function ($$v) {_vm.orderString=$$v},expression:"orderString"}})],1),_c('md-field',[_c('label',[_vm._v("Edit Driver")]),_c('md-textarea',{model:{value:(_vm.tripString),callback:function ($$v) {_vm.tripString=$$v},expression:"tripString"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }