<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-section-start">
        <h3 class="md-title">Announce</h3>
      </div>
      <div class="md-toolbar-section-end">
      <md-button @click="$router.push('/JobAnnounceAttendance')" class="md-raised md-primary">
        <span>Attendance List</span>
      </md-button>
      <md-button @click="$router.push('/JobAnnounceForm')" class="md-raised md-primary">
        <span>New Announcement</span>
      </md-button>
      </div>
    </md-toolbar>
    <md-table v-model="announceItems" class="md-primary">
      <md-table-row slot="md-table-row" slot-scope="{ item }" >
        <md-table-cell md-label="Title">
          <router-link :to="'/JobAnnounce/'+encodeURIComponent(item.SK.replace('#METADATA#',''))">
            {{item['Title']}}
          </router-link>
        </md-table-cell>
        <md-table-cell md-label="Description"><pre>{{item['Description']}}</pre></md-table-cell>
        <md-table-cell md-label="Conditions">
          <dl>
            <dt>Vehicle Truck Type:</dt>
            <dd>{{item['Conditions']['Truck']['VehicleTruckType'].join(',')}}</dd>
            <dt>Vehicle Container Type:</dt>
            <dd>{{item['Conditions']['Truck']['VehicleContainerType'].join(',')}}</dd>
            <dt>Driver Team:</dt>
            <dd>{{item['Conditions']['Driver']['DriverTeam'].join(',')}}</dd>
            <dt>Driver Tags:</dt>
            <dd>{{item['Conditions']['Driver']['DriverTags'].join(',')}}</dd>
          </dl>
        </md-table-cell>
        <md-table-cell md-label="CreatedBy">{{item['CreatedBy']}}</md-table-cell>
        <md-table-cell md-label="CreatedAt">{{datetimeFormater(item['CreatedAt'])}}</md-table-cell>
        <md-table-cell md-label="Operation">
          <md-button class="md-raised" @click="handleCloseAnnounce(encodeURIComponent(item.SK.replace('#METADATA#','')))" >Close</md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'

export default {
  name: 'JobAnnounce',
  data () {
    return {
      announceItems: [],
      loading: false,
      truckTypeOptions: [],
      containerTypeOptions: [],
      driverTeamOptions: [],
      driverTagOptions: [],
      formData: {
        Title: null,
        Description: null,
        Conditions: {
          Truck: {
            VehicleTruckType: [],
            VehicleContainerType: []
          },
          Driver: {
            DriverTeam: [],
            DriverTags: []
          }
        },
        StartDateTime: null,
        EndDateTime: null
      },
      errors: {},
      showNewDialog: false
    }
  },
  methods: {
    fetchData: function (lastEvaluatedKey) {
      let q = ''
      let self = this
      if (lastEvaluatedKey) {
        q += `?lastEvaluatedKey=${encodeURIComponent(lastEvaluatedKey)}`
      } else {
        this.updateItems = []
      }
      API.get('ezietruckapi', `/api/announces${q}`).then((json) => {
        this.announceItems = [...self.announceItems, ...json.Items].sort((a,b) => b.CreatedAt.localeCompare(a.CreatedAt))
        if (json.LastEvaluatedKey) {
          self.fetchData(json.LastEvaluatedKey)
        }
      })
    },
    fetchOptions: function () {
      let self = this
      API.get('ezietruckapi', `/api/options/TruckType`).then((json) => {
        self.truckTypeOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/ContainerType`).then((json) => {
        self.containerTypeOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/DriverTeam`).then((json) => {
        self.driverTeamOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/Tag`).then((json) => {
        self.driverTagOptions = json.map((j) => j.Name)
      })
    },
    datetimeFormater: function (str) {
      let m = moment(str)
      m.local() 
      return m.format('DD MMM YYYY HH:mm:ss')
    },
    handleCloseAnnounce: function (announceID) {
      API.del('ezietruckapi', `/api/announces/${announceID}`).then(() => {
        // this.showSnackbar = true
        // this.snackBarMsg = 'Successfully saved'
        // this.hideSubmit = false
        this.$router.push('/JobAnnounce')
      }).catch((e) => {
        this.showSnackbar = true
        this.snackBarMsg = e.response.data.message || e.message
        this.hideSubmit = false
      })
    },
  },
  computed: {
    ...mapGetters(['user'])
  },
  created () {
    this.fetchOptions()
    this.fetchData()
  }
}
</script>
