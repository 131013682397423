<template>
  <GoogleMapLoader
    :mapConfig="mapConfig"
    apiKey="AIzaSyAGQeljdWK0A-z_4Hmj9eNrufJMh0mC8S4"
  >
  <template slot-scope="{ google, map }">
    <GoogleMapTruckMarker
          v-for="marker in markers"
          :key="marker.id"
          :marker="marker"
          :google="google"
          :map="map"
        />
    </template>
  </GoogleMapLoader>
</template>

<script>
import GoogleMapLoader from "./GoogleMapLoader";
import GoogleMapTruckMarker from "./GoogleMapTruckMarker";
import { mapSettings } from "@/constants/mapSettings";


export default {
  props: {
    truckLatLng: {
      type: Object,
      required: false
    }
  },
  components: {
    GoogleMapLoader, GoogleMapTruckMarker
  },
  data() {
    return {}
  },
  computed: {
    mapConfig() {
      if (this.truckLatLng) {
        return Object.assign({}, mapSettings, {
          center: this.truckLatLng,
          zoom: 15
        })
      }
      return {
        ...mapSettings,
        zoom: 15
      };
    },
    markers () {
      if (this.truckLatLng) {
        return [
          {
            id: "truck",
            position: this.truckLatLng
          }
        ]
      } else {
        return []
      }
    }
  }
};
</script>
