<template>
  <div class="container">
    <div class="md-layout" md-elevation="2">
      <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
        <md-autocomplete @input="handleSelectcustomer" :value="currentCustomer.CustomerName" :md-options="customers">
          <label>Customer</label>
          <template slot="md-autocomplete-item" slot-scope="{ item, term }">
            <md-avatar>
              <amplify-s3-image :imagePath="item.CustomerLogo" class="md-avatar md-large"></amplify-s3-image>
            </md-avatar>
            <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
          </template>

          <template slot="md-autocomplete-empty" slot-scope="{ term }">
            No customer matching "{{ term }}" were found.
          </template>
        </md-autocomplete>
      </div>
      <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
        <md-field>
          <label for="movie">Group by</label>
          <md-select v-model="groupBy" name="groupBy" id="groupBy">
            <md-option value="JobType">Job Type</md-option>
            <md-option value="Driver.DriverName">Driver</md-option>
            <md-option value="From.Description">From</md-option>
            <md-option value="To.Description">To</md-option>
            <md-option value="TruckType">Truck Type</md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <md-table md-card>
      <md-table-row>
        <md-table-head>From</md-table-head>
        <md-table-head>To</md-table-head>
        <md-table-head>Job</md-table-head>
        <md-table-head>Truck</md-table-head>
        <md-table-head>Container</md-table-head>
        <md-table-head>Driver</md-table-head>
        <md-table-head>Operation</md-table-head>
      </md-table-row>
      <md-table-row v-for="item in groupedByOrder" :key="item.FavoriteOrderName">
        <md-table-cell :class="{'md-active': item.PK === '-'}">
          {{ item.OrderTemplate.From.Description }}
          <span v-show="item.PK === '-'" class="md-subheading">{{item.SK}}</span>
        </md-table-cell>
        <md-table-cell>{{ item.OrderTemplate.To.Description }}</md-table-cell>
        <md-table-cell>{{ item.OrderTemplate.JobType }}</md-table-cell>
        <md-table-cell>{{ item.OrderTemplate.TruckType }}</md-table-cell>
        <md-table-cell>{{ item.OrderTemplate.ContainerType }}</md-table-cell>
        <md-table-cell>
          <span v-if="item.OrderTemplate.Trip">{{ item.OrderTemplate.Trip.Driver.DriverName }}</span>
          <span v-if="!item.OrderTemplate.Trip"><md-icon>warning</md-icon> <span>Unassign Driver</span></span>
        </md-table-cell>
        <md-table-cell>
          <md-button class="md-icon-button" v-show="item.OrderTemplate.Customer.CustomerID" :to="`/NewOrderFromFav/${item.OrderTemplate.Customer.CustomerID}/${item.FavoriteOrderName}`"><md-icon>launch</md-icon></md-button>
          <md-button class="md-icon-button" v-show="item.OrderTemplate.Customer.CustomerID" @click="handleDeleteFev(item.FavoriteOrderName)"><md-icon>delete</md-icon></md-button></md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import VueScreenSize from 'vue-screen-size'

export default {
  name: 'FavoriteOrder',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data () {
    return {
      customerItems: [],
      currentCustomer: {},
      favoriteOrders: [],
      groupBy: 'Driver.DriverName',
      searchCustomerText: null
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchFavoriteOrder: function() {
      if (this.$route.params.CustomerID) {
        API.get('ezietruckapi', `/api/customers/${this.$route.params.CustomerID}/favorite-orders`).then((json) => {
          this.favoriteOrders = json.map((j) => {
            let r = {
              'Driver.DriverName': j.OrderTemplate.Trip ? j.OrderTemplate.Trip.Driver.DriverName : '',
              'From.Description': j.OrderTemplate.From.Description,
              'To.Description': j.OrderTemplate.To.Description
            }
            j.OrderTemplate = Object.assign({}, j.OrderTemplate, r)
            return j
          })
        })
      }
    },
    handleSelectcustomer: function (customer) {
      if (typeof customer === 'object') {
        this.$router.push('/FavoriteOrder/'+customer.CustomerID)
        this.fetchFavoriteOrder()
      }
    },
    handleDeleteFev: function (favName) {
      API.del('ezietruckapi', `/api/customers/${this.$route.params.CustomerID}/favorite-orders/${favName}`).then(() => {
        this.fetchFavoriteOrder()
      })
    }
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    groupedByOrder: function () {
      let orders = {}
      let out = []
      for (let k of this.favoriteOrders) {
        if (orders[k.OrderTemplate[this.groupBy]]) {
          orders[k.OrderTemplate[this.groupBy]].push(k)
        } else {
          orders[k.OrderTemplate[this.groupBy]] = [
          {
            PK: '-',
            SK: k.OrderTemplate[this.groupBy],
            OrderTemplate: {
              Customer: {CustomerID: null},
              From: {Description: ''},
              To: {Description: ''},
              JobType: '',
              TruckType: '',
              ContainerType: '',
              Trip: {Driver: {DriverName: ''}}
            }
          },
          k]
        }
      }
      for (let g of Object.keys(orders).sort((a, b) => -1 * (a.localeCompare(b)))) {
        out = [...out, orders[g][0], ...orders[g].slice(1).sort((a, b) => -1 * (a.PK.localeCompare(b.PK)))]
      }
      return out
    },
    filteredCustomers: function () {
      if (this.searchCustomerText) {
        return this.customers.filter((c) => c.CustomerName.includes(this.searchCustomerText))
      } else {
        return this.customers
      }
    }
  },
  created () {
    this.fetchCustomer()
    this.fetchFavoriteOrder()
  }
}
</script>
