<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-section-start">
        <h3 class="md-title">Announce</h3>
      </div>
      <div class="md-toolbar-section-end">
      <form @submit.prevent="handleSearchAttendance" class="md-toolbar-row md-layout" >
        <div class="md-layout-item">
          <md-datepicker v-model="formData.StartDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
            <label>Start Date</label>
            <span class="md-error" v-if="errors['Date']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item">
          <md-datepicker v-model="formData.EndDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
            <label>End Date</label>
            <span class="md-error" v-if="errors['Date']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item">
          <md-button :disabled="!!LastEvaluatedKey" type="submit" class="md-raised md-primary">
            <span>Search</span>
          </md-button>
          <md-button :disabled="!!LastEvaluatedKey" @click="handleDownloadAllCSV">
            Download All
          </md-button>
        </div>
      </form>
      </div>
    </md-toolbar>
    <md-table v-model="announceItems" class="md-primary">
      <md-table-row slot="md-table-row" slot-scope="{ item }" >
        <md-table-cell md-label="Driver">
          <router-link :to="'/Drivers/'+encodeURIComponent(item.PK.replace('DRIVER#',''))">
          {{item['DriverName']}}
          </router-link>
        </md-table-cell>
        <md-table-cell md-label="Phone Number">{{item['DriverPhoneNumber']}}</md-table-cell>
        <md-table-cell md-label="Announce ID">{{item['SK'].split('#')[2]}}</md-table-cell>
        <md-table-cell md-label="Title">
          <router-link :to="'/JobAnnounce/'+encodeURIComponent(item.SK.replace('#METADATA#',''))">
            {{item['Title']}}
          </router-link>
        </md-table-cell>
        <md-table-cell md-label="Description"><pre>{{item['Description']}}</pre></md-table-cell>
        <md-table-cell md-label="Start / End Date Time">
          {{item['StartDateTime']}} - {{item['EndDateTime']}}
        </md-table-cell>
        <md-table-cell md-label="AttendedAt">{{datetimeFormater(item['AttendedAt'])}}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'

export default {
  name: 'JobAnnounce',
  data () {
    return {
      announceItems: [],
      loading: false,
      truckTypeOptions: [],
      containerTypeOptions: [],
      driverTeamOptions: [],
      driverTagOptions: [],
      formData: {
        StartDate: moment().format('YYYY-MM-DD'),
        EndDate: moment().format('YYYY-MM-DD'),
      },
      errors: {},
      showNewDialog: false,
      LastEvaluatedKey: null
    }
  },
  methods: {
    fetchData: function (lastEvaluatedKey) {
      let q = ''
      let self = this
      if (lastEvaluatedKey) {
        q += `?lastEvaluatedKey=${encodeURIComponent(lastEvaluatedKey)}`
      } else {
        this.updateItems = []
      }
      API.post('ezietruckapi', `/api/announces/attendances/${this.formData.StartDate}T/${this.formData.EndDate}U${q}`).then((json) => {
        this.announceItems = [...self.announceItems, ...json.Items].sort((a,b) => b.AttendedAt.localeCompare(a.AttendedAt))
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKey = json.LastEvaluatedKey
          self.fetchData(json.LastEvaluatedKey)
        } else {
          self.LastEvaluatedKey = false
        }
      })
    },
    datetimeFormater: function (str) {
      let m = moment(str)
      m.local() 
      return m.format('DD MMM YYYY HH:mm:ss')
    },
    handleCloseAnnounce: function (announceID) {
      API.del('ezietruckapi', `/api/announces/${announceID}`).then(() => {
        // this.showSnackbar = true
        // this.snackBarMsg = 'Successfully saved'
        // this.hideSubmit = false
        this.$router.push('/JobAnnounce')
      }).catch((e) => {
        this.showSnackbar = true
        this.snackBarMsg = e.response.data.message || e.message
        this.hideSubmit = false
      })
    },
    handleDownloadAllCSV: function () {
      let csv= 'EndDate Time,DriverTags,Conditions.Driver.DriverTeam,Conditions.Driver.DriverTags,Conditions.Truck.VehicleTruckType,Conditions.Truck.VehicleContainerType,VehicleContainerType,VehicleTruckType,HeroImage,Description,DriverName,LineUserID,Title,DriverPhoneNumber,StartDateTime,SK,CreatedBy,PK,AttendedAt\n';
      for (let t of this.announceItems) {
        csv += `${this.datetimeFormater(t.CreatedAt)},${t.DriverTags.join('|')},${t.Conditions.Driver.DriverTeam.join('|')},${t.Conditions.Driver.DriverTags.join('|')},${t.Conditions.Truck.VehicleTruckType.join('|')},${t.Conditions.Truck.VehicleContainerType.join('|')},${t.VehicleContainerType},${t.VehicleTruckType},${t.HeroImage},${t.Description.replace(/(\n|,)/g, " ")},${t.DriverName},${t.LineUserID},${t.Title},${t.DriverPhoneNumber},${t.StartDateTime},${t.SK},${t.CreatedBy},${t.PK},${t.AttendedAt}\n`
      }
      let hiddenElement = document.createElement('a')
      const blob = new Blob(["\ufeff", csv], {type : 'text/csv;charset=utf-8'})
      hiddenElement.href = URL.createObjectURL(blob)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'attendance.csv'
      hiddenElement.click()
    },
    handleSearchAttendance: function () {
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  created () {
    this.fetchData()
  }
}
</script>
