<template>
  <div>
    <md-toolbar md-elevation="0" class="md-dense">
      <div class="md-toolbar-section-start">
        <md-menu md-direction="bottom-start" v-show="editorTableErrors.messages.length > 0">
          <md-button md-menu-trigger  class="md-icon-button">
            <md-icon>error</md-icon>
          </md-button>
          <md-menu-content>
            <md-menu-item v-for="e in editorTableErrors.messages" :key="e">{{ e }}</md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>
      <div class="md-toolbar-section-end">
        <md-button class="md-icon-button" @click="fetchData()">
          <md-icon>refresh</md-icon>
        </md-button>
        <md-button class="md-icon-button" @click="handleSave()" :disabled="editorTableErrors.messages.length > 0">
          <md-icon>save</md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <md-content>
      <canvas-datagrid :data.prop="editorData" :schema.prop="editorSchema" :style="editorStyle" ref="table"
      :width="$vssWidth" :height="$vssHeight"></canvas-datagrid>
    </md-content>
    <div class="md-layout">
      <div class="md-layout-item  md-size-10">
        <md-field>
          <label>More rows</label>
          <md-input v-model.number="moreRowForm" type="number"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item  md-size-15 md-alignment-center-left">
        <md-button class="md-button" @click="addRow()">
          Add
        </md-button>
      </div>
    </div>
    {{ putItems }} {{ deleteItems }}
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'

export default {
  name: 'CustomFieldList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data () {
    return {
      customFields: [],
      optionLookups: [],
      newField: {
        Name: null,
        Label: null,
        DataType: null,
        OptionLookup: null
      },
      editorData: [],
      editorStyle: {},
      moreRowForm: 1,
    }
  },
  methods: {
    fetchData: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.customFields = []
        this.editorData = []
      }
      API.get('ezietruckapi', '/api/customFields'+q).then((json) => {
        if (json['Items']) {
          self.customFields = [...self.customFields, ...json['Items']]
          self.editorData = [...self.editorData, ...json['Items'].map((i) => Object.assign({}, i, {
            isNew: false,
            isEdited: false,
          }))]
        }
        if (json.LastEvaluatedKey) {
          self.fetchData(json.LastEvaluatedKey)
        } else {
          self.$refs.table.draw()
        }
      })
    },
    fetchOptionLookup: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/lookups/'+encodeURIComponent('OptionLookup')+q).then((json) => {
        if (json['Items']) {
          self.optionLookups = [...self.optionLookups, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchOptionLookup(json.LastEvaluatedKey)
        }
      })
    },
    handleAddNew: function () {
      let body = {
        Name: this.newField.Name,
        Label: this.newField.Label,
        DataType: this.newField.DataType
      }
      if (this.newField.OptionLookup) {
        body['OptionLookup'] = this.newField.OptionLookup
      }
      API.post('ezietruckapi', '/api/customFields', {body: body}).then(() => {
        this.newField = {
          Name: null,
          Label: null,
          DataType: null,
          OptionLookup: null
        }
        this.fetchData()
      })
    },
    handleDelete: function (name) {
      API.del('ezietruckapi', `/api/customFields/${encodeURIComponent(name)}`).then(() => {
        this.fetchData()
      })
    },
    handleDeletes: function (names) {
      this.editorData = [
        ...this.editorData.filter((e) => !names.includes(e.Name))
      ]
      this.$refs.table.draw();
    },
    handleSave: function () {
      if (this.editorTableErrors.messages.length === 0) {
        if (this.putItems.length > 0) {
          API.put('ezietruckapi', '/api/customFields', {body: {
            PutItems: this.putItems.map((obj) => 
              Object.keys(obj)
                .filter((k) => !!obj[k])
                .reduce((a, k) => ({ ...a, [k]: obj[k] }), {})
            ),
            DeleteItems: this.deleteItems
          }}).then(() => {
            this.fetchData()
          })
        }
      }
    },
    addRow: function () {
      for (let i = 0; i < this.moreRowForm; i++) {
        this.editorData.push({
          PK: '',
          SK: '',
          Name: '',
          Label: '',
          DataType: '',
          Option: '',
          OptionLookup: '',
          isNew: false,
          isEdited: false,
        })
      }
      this.editorData = [...this.editorData]
      this.$refs.table.draw();
    }
  },
  computed: {
    optionLookupOpts () {
      return []
    },
    editorSchema () {
      return [
        {name: 'PK', type: 'string', hidden: true},
        {name: 'SK', type: 'string', hidden: true},
        {name: 'Name', type: 'string'},
        {name: 'Label', type: 'string'},
        {name: 'DataType', type: 'string', enum: [
          'Text',
          'Number',
          'DateTime',
          'Date',
          'Time',
          'Image',
          'MultiImage',
          'Select',
        ]},
        {name: 'Option', type: 'string'},
        {name: 'OptionLookup', type: 'string', enum: Array.from(new Set(this.optionLookups.map((o) => o.SK.split('#')[0])))},
      ]
    },
    editorTableErrors () {
      let out = []
      let idx = []
      let pksk = []
      const names = this.editorData.map((d) => d.Name)
      let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
      const dupNames = findDuplicates(names)
      for (let i = 0; i < this.editorData.length; i++) {
        if (dupNames.includes(this.editorData[i].Name)) {
          out.push(`At ${i + 1}: Duplicate name is not allow.`)
          idx.push(i)
          pksk.push(this.editorData[i].PK+this.editorData[i].SK)
        }
        if (typeof this.editorData[i].DataType !== 'undefined') {
          if (this.editorSchema[4].enum && !this.editorSchema[4].enum.includes(this.editorData[i].DataType)) {
            out.push(`At ${i + 1}: DataType has invalid selected value.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
        }
        if (this.editorData[i].DataType === 'Select') {
          if (!this.editorData[i].Option && !this.editorData[i].OptionLookup) {
            out.push(`At ${i + 1}: Option or OptionLookup need to be selected.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          } else {
            if (this.editorSchema[5].enum && !this.editorSchema[5].enum.includes(this.editorData[i].OptionLookup)) {
              out.push(`At ${i + 1}: OptionLookup has invalid selected value.`)
              idx.push(i)
              pksk.push(this.editorData[i].PK+this.editorData[i].SK)
            }
          }
        }
      }
      return {
        idx: idx,
        messages: out,
        pksk: pksk,
      }
    },
    putItems () {
      const vm = this
      return this.editorData.filter((d) =>
        !vm.editorTableErrors.pksk.includes(d.PK+d.SK) && (d.isEdited || d.isNew)
      ).map((d) => Object.assign({}, d, {
        PK: 'CustomField',
        SK: `#ALL#${d.Name}`
      }))
    },
    deleteItems () {
      let out = []
      const pksk = this.customFields.map((f) => f.PK+f.SK)
      for (let d of this.editorData.filter((f)=> f.PK && f.SK)) {
        if (!pksk.includes(d.PK+d.SK)) {
          out.push(d)
        } else if (d.SK.split('#')[2] !== d.Name) {
          out.push(d)
        }
      }
      return out
    },
    ...mapGetters(['user'])
  },
  watch: {
    curentOption: function () {
      this.fetchData()
    }
  },
  created () {
    this.fetchOptionLookup()
    this.fetchData()
  },
  mounted () {
    const grid = this.$refs.table
    const vm = this
    const validateEditCellData = function (e) {
      if (e.cell.data.PK && e.cell.data.SK) {
        e.cell.data.isEdited = true;
      } else {
        e.cell.data.isNew = true;
      }
      const names = vm.editorData.map((d) => d.Name)
      if (e.cell.header.enum && !e.cell.header.enum.includes(e.newValue)) {
        alert('Invalid selected value')
        e.preventDefault();
      }
      if (e.cell.header.name === 'Name' && names.includes(e.newValue)) {
        alert('Duplicate name is not allow.')
        e.preventDefault();
      }
    }
    grid.attributes.showFilter = false;
    grid.addEventListener('contextmenu', function (e) {
      e.items.splice(0, e.items.length);
      e.items.push({
          title: 'Delete Rows',
          click: function (ev) {
            vm.handleDeletes(grid.selectedRows.map((i) => i.Name))
          }
      });
    });

    grid.addEventListener('rendercell', function (e) {
      if (e.cell.data) {
        if (vm.editorTableErrors.idx.includes(e.cell.rowIndex)) {
          e.ctx.fillStyle = '#F1948A';
        } else if (e.cell.data.isEdited) {
          e.ctx.fillStyle = '#F9E79F';
        } else if (e.cell.data.isNew) {
          e.ctx.fillStyle = '#AEEDCF';
        }
      }
    });
    grid.addEventListener('beforeendedit', function (e) {
      if (e.newValue !== e.oldValue) {
        validateEditCellData(e)
      }
    });
    // grid.addEventListener('afterpaste', function (e) {
    //   const rowIdxs = Array.from(new Set(e.cells.map((c) => c[2])))
    //   for (let i of rowIdxs) {
    //     if (vm.editorData[i] && vm.editorData[i].PK !== ''&& vm.editorData[i].SK !== '') {
    //       vm.editorData[i].isEdited = true
    //     }
    //   }
    //   vm.editorData = [...vm.editorData]
    // })
  }
}
</script>
