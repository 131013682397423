<template>
  <div>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Create Invoice</md-dialog-title>
      <md-dialog-content>
        <md-toolbar class="md-dense">
          <span class="md-subtitle">Total: {{form.OrderKeys.length}} Orders [{{orderKeyRange[0].format('DD MMM YYYY')}} - {{orderKeyRange[1].format('DD MMM YYYY')}}]</span>
        </md-toolbar>
        <form novalidate class="md-layout md-margin" @submit.prevent="validateForm">
          <div class="md-layout-item">
            <md-field :class="{'md-invalid': errors['instance.InvoiceNumber']}">
              <label>Invoice Number</label>
              <md-input v-model="form.InvoiceNumber"/>
              <span class="md-error" v-if="errors['instance.InvoiceNumber']">{{errors['instance.InvoiceNumber']}}</span>
            </md-field>
            <md-field :class="{'md-invalid': errors['instance.JobType']}">
              <label>Job Type</label>
              <md-input v-model="form.JobType"/>
              <span class="md-error" v-if="errors['instance.JobType']">{{errors['instance.JobType']}}</span>
            </md-field>
            <md-field :class="{'md-invalid': errors['instance.CustomerBillDate']}">
              <label>Customer Bill Date</label>
              <md-input :value="form.CustomerBillDate" disabled="disabled"/>
              <span class="md-error" v-if="errors['instance.CustomerBillDate']">Invalid field input</span>
            </md-field>
            <md-field :class="{'md-invalid': errors['instance.InvoiceOriginalTotalAmount']}">
              <label>Original Total Amount</label>
              <md-input :value="$currencyFormater(form.InvoiceOriginalTotalAmount)" disabled="disabled"/>
              <span class="md-error" v-if="errors['instance.InvoiceOriginalTotalAmount']">Invalid field input</span>
            </md-field>
            <md-field :class="{'md-invalid': errors['instance.InvoiceOriginalTotalAmount']}">
              <label>Total Cost Amount</label>
              <md-input :value="$currencyFormater(form.TotalTripCostAmount)" disabled="disabled"/>
              <span class="md-error" v-if="errors['instance.TotalTripCostAmount']">Invalid field input</span>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field :class="{'md-invalid': errors['instance.CustomerName']}">
              <label>Customer</label>
              <md-input :value="form.CustomerName" disabled="disabled"/>
              <span class="md-error" v-if="errors['instance.CustomerName']">Invalid field input</span>
            </md-field>
            <md-datepicker :value="form.CustomerPaidDueDate" :md-model-type="String" :class="{'md-invalid': errors['instance.CustomerPaidDueDate']}" md-immediately v-model="form.CustomerPaidDueDate">
              <label>Customer Paid Due Date</label>
              <span class="md-error" v-if="errors['instance.CustomerPaidDueDate']">Invalid field input</span>
            </md-datepicker>
            <md-field :class="{'md-invalid': errors['instance.InvoiceFinalTotalAmount']}">
              <label>Final Total Amount</label>
              <md-input v-model.number="form.InvoiceFinalTotalAmount"/>
              <span class="md-error" v-if="errors['instance.InvoiceFinalTotalAmount']">Invalid field input</span>
            </md-field>
            <md-field>
              <label>Margin</label>
              <md-input :value="$currencyFormater((form.InvoiceFinalTotalAmount*1000 - form.TotalTripCostAmount*1000)/1000)" disabled="disabled"/>
            </md-field>
          </div>
        </form>
        <md-toolbar class="md-dense md-primary" v-show="form.InvoiceOriginalTotalAmount - form.InvoiceFinalTotalAmount != 0">
          <span class="md-subtitle">Amount Diff (Original - Final): {{$currencyFormater((form.InvoiceOriginalTotalAmount*1000 - form.InvoiceFinalTotalAmount*1000)/1000)}}</span>
        </md-toolbar>
      </md-dialog-content>
      <md-card-actions>
        <md-button type="submit" class="md-primary" :disabled="sending" @click="validateForm">Submit</md-button>
      </md-card-actions>
    </md-dialog>
    <md-dialog :md-active.sync="isOpenOrderDialog">
      <md-dialog-title>Orders</md-dialog-title>
      <md-dialog-content>
        <md-table class="md-primary" md-card v-model="shownOrders" :md-selected-value.sync="selectedOrders">
          <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" >
            <md-table-cell md-label="Order ID"><router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link></md-table-cell>
            <md-table-cell md-label="Start at">{{ dateTimeFormatter(item.StartDateTime) }}</md-table-cell>
            <md-table-cell md-label="Truck Type">{{item.TruckType}} - {{item.ContainerType}}</md-table-cell>
            <md-table-cell md-label="From">{{ item.From.Description }}</md-table-cell>
            <md-table-cell md-label="To">{{ item.To.Description }}</md-table-cell>
            <md-table-cell md-label="Created By">{{ item.CreatedBy }}</md-table-cell>
            <md-table-cell md-label="Total">{{ $currencyFormater(item.CustomerPaidAmount) }}</md-table-cell>
          </md-table-row>
        </md-table>
      </md-dialog-content>
    </md-dialog>
    <md-table class="md-primary" md-card v-model="jobTypeOrderTable"  @md-selected="onSelect($event)">
      <md-table-toolbar>
          <div class="md-toolbar-section-start">
            No Invoice
          </div>
          <div class="md-toolbar-section-end">
            <md-button @click="handleCreateInvoiceDialog">
              New Invoice
            </md-button>
          </div>
      </md-table-toolbar>
      <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Group">{{ item.group }}</md-table-cell>
          <md-table-cell md-label="Orders">
            <md-button @click="showOrders(item.orders)">
            View Orders (Selected: {{countSelectedGroup[item.group]}} Total: {{item.orders.length}})
          </md-button>
          </md-table-cell>
          <md-table-cell md-label="Total">{{ $currencyFormater(item.sum) }}</md-table-cell>
        </md-table-row>
    </md-table>
  </div>
</template>

<script>
import moment from 'moment'
import { API } from 'aws-amplify'
import {validate} from 'jsonschema'
import schema from '../schema'
import { mapGetters } from 'vuex'

export default {
  name: 'NoInvoiceDetail',
  data () {
    return {
      orderItems: [],
      jobTypeOptions: [],
      unIVMonths: {},
      selectedOrders: [],
      showDialog: false,
      sending: false,
      newJobType: null,
      loading: true,
      errors: {},
      customerItems: [],
      tripItems: [],
      form: {
        InvoiceNumber: null,
        OrderKeys: [],
        CustomerID: null,
        CustomerName: null,
        CustomerLogo: null,
        CustomerBillDate: null,
        CustomerPaidDueDate: null,
        InvoiceOriginalTotalAmount: null,
        InvoiceFinalTotalAmount: null,
        TotalTripCostAmount: 0,
        JobType: null
      },
      groupBy: 'JobType',
      selected: null,
      shownOrders: [],
      isOpenOrderDialog: false
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchOrders: function (startIndex) {
      let keys = this.unIVMonths['Items'].slice(startIndex, startIndex + 100).map((u) => {
        let orderId = u.SK.split('#')[3]
        return {PK: `ORDER#${orderId}`, SK: `#METADATA#${orderId}`}
      })
      let vm = this
      console.log(keys)
      if (keys.length > 0) {
        API.post('ezietruckapi', `/api/customers/${this.$route.params.customerID}/morders`, {body: {Orders: keys}}).then((json) => {
          if (json) {
            console.log(json.length)
            vm.orderItems = [...vm.orderItems, ...json]
          }
          if (startIndex + 100 < vm.unIVMonths['Items'].length) {
            vm.fetchOrders(startIndex + 100)
          } else {
            vm.loading = false
          }
        })
        let tripKeys = this.unIVMonths['Items'].slice(startIndex, startIndex + 100).map((u) => {
          let orderId = u.SK.split('#')[3]
          return {PK: `ORDER#${orderId}`, SK: `TRIP#${orderId}.1`}
        })
        API.post('ezietruckapi', `/api/customers/${keys[0].PK.slice(6,9)}/morders`, {body: {Orders: tripKeys}}).then((json) => {
          if (json) {
            vm.tripItems = [...vm.tripItems, ...json]
          }
        })
      }
    },
    fetchData: function () {
      this.fetchCustomer()
      API.get('ezietruckapi', `/api/unIVMonths/orders/${this.$route.params.month}/${this.$route.params.billDate}/${this.$route.params.customerID}`).then((json) => {
        this.unIVMonths = json
        this.loading = true
        this.fetchOrders(0)
      })
      API.get('ezietruckapi', `/api/options/JobType`).then((json) => {
        this.jobTypeOptions = json.map((j) => j.Name)
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY HH:MM")
      } else {
        return ''
      }
    },
    onSelect: function (item) {
      if (item) {
        this.selected = item.group 
        this.selectedOrders = item.orders
      } else {
        this.selected = null
        this.selectedOrders = []
      }
    },
    onOrderSelect: function (item) {
      if (item) {
        this.selectedOrders = item
      } else {
        this.selected = null
        this.selectedOrders = []
      }
    },
    validateForm: function () {
      let p = validate(this.form, schema.InvoiceFormSchema)
      let self = this
      this.errors = {}
      for (let err of p.errors) {
        this.errors[err.property] = err.message
      }
      if (p.errors.length === 0) {
        this.sending = true
        API.get('ezietruckapi', `/api/customerInvoices/${this.form.InvoiceNumber}`).then((data) => {
          if(data && data.PK) {
            self.errors['instance.InvoiceNumber'] = 'InvoiceNumber already exists'
            self.sending = false
          } else {
            API.post('ezietruckapi', '/api/customerInvoices', {body: this.form}).then(() => {
              for (let o of self.form.OrderKeys) {
                let idx = self.orderItems.findIndex((u) => u.PK === o.PK)
                if (idx > -1) {
                  self.orderItems = [...self.orderItems.slice(0, idx), ...self.orderItems.slice(idx + 1)]
                }
              }
              self.selectedOrders = []
              self.form = {
                InvoiceNumber: null,
                OrderKeys: [],
                CustomerID: null,
                CustomerName: null,
                CustomerLogo: null,
                CustomerBillDate: null,
                CustomerPaidDueDate: null,
                InvoiceOriginalTotalAmount: null,
                InvoiceFinalTotalAmount: null,
                TotalTripCostAmount: 0
              }
              self.showDialog = false
              self.sending = false
            }).catch((e) => {
              self.errors['instance.InvoiceNumber'] = JSON.stringify(e)
              self.sending = false
            })
          }
        }).catch((e) => {
          self.errors['instance.InvoiceNumber'] = JSON.stringify(e)
          self.sending = false
        })
      }
      return false
    },
    handleCreateInvoiceDialog: function () {
      let sum = this.selectedOrders.reduce((accumulator, currentValue) => (accumulator*1000 + currentValue.CustomerPaidAmount*1000)/1000, 0)
      let costSum = 0
      for (let order of this.selectedOrders) {
        let idx = this.tripItems.findIndex((t) => t.PK === order.PK)
        if (idx > -1) {
          costSum = (costSum*1000 + this.tripItems[idx].DeliveryCost*1000 + this.tripItems[idx].DailyPickerCost*1000 + this.tripItems[idx].OtherCost*1000 + this.tripItems[idx].SalaryPerTrip*1000)/1000
        }
      }
      this.form = {
        InvoiceNumber: null,
        OrderKeys: [...this.selectedOrders.map((o) => { return {PK: o.PK,SK: o.SK }})],
        JobType: this.selectedOrders.length > 0 ? this.selectedOrders[0].JobType : '-',
        CustomerID:  this.selectedOrders[0].CustomerID,
        CustomerName: this.selectedOrders[0].CustomerName || this.selectedOrders[0].CustomerID,
        CustomerLogo: this.customers[this.selectedOrders[0].CustomerID].CustomerLogo || this.selectedOrders[0].CustomerID,
        InvoiceOriginalTotalAmount: sum,
        InvoiceFinalTotalAmount: sum,
        CustomerBillDate: this.selectedOrders[0].CustomerBillDate,
        CustomerPaidDueDate: moment(this.selectedOrders[0].CustomerBillDate).add(this.customers[this.selectedOrders[0].CustomerID].CustomerCreditDays || 30,'days').format('YYYY-MM-DD'),
        TotalTripCostAmount: costSum,
        FirstStartDate: this.orderKeyRange[0].format('YYYY-MM-DD'),
        LastStartDate: this.orderKeyRange[1].format('YYYY-MM-DD'),
        CreatedDate: moment().format('YYYY-MM-DD'),
        CreatedAt: (new Date()).toISOString(),
        CreatedBy: this.user.signInUserSession.idToken.payload.email
      }
      API.get('ezietruckapi', `/api/customerInvoices/IV${moment(this.selectedOrders[0].CustomerBillDate).format('YYYYMM')}`).then((json) => {
        if (json.Items.length > 0) {
          let lastIV = json.Items.sort((a, b) => b.InvoiceNumber.localeCompare(a.InvoiceNumber))[0].InvoiceNumber
          let ivNumber = lastIV.slice(0,8)
          let s = `${parseInt(lastIV.slice(8)) + 1}`
          while (s.length < 3) s = "0" + s
          ivNumber += s
          this.form.InvoiceNumber = ivNumber
        } else {
          this.form.InvoiceNumber = `IV${moment(this.selectedOrders[0].CustomerBillDate).format('YYYYMM')}001`
        }
        this.form = Object.assign({} , this.form)
        this.showDialog = true
      }).catch(console.error)
    },
    saveJobType: function () {
      API.post('ezietruckapi', `/api/unIVMonths/updateOrders`, {body: this.selectedOrders.map((o) => {
        return {
          Key: {PK: o.PK, SK: o.SK},
          UpdateExpression: "SET JobType = :jt",
          ExpressionAttributeValues: {
            ':jt': this.newJobType
          }
        }
      })}).then(() => {
        this.selectedOrders = []
        this.orderItems = []
        this.fetchData()
      }).catch(console.error)
    },
    showOrders: function (orders) {
      this.shownOrders = orders
      this.isOpenOrderDialog = true
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    customers: function () {
      let out = {}
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out[o.CustomerID] = o
        }
      }
      return out
    },
    groupedByOrder: function () {
      let orders = {}
      if (!this.loading) {
        for (let o of this.orderItems) {
          if (orders[o[this.groupBy]]) {
            orders[o[this.groupBy]].orders.push(o)
            orders[o[this.groupBy]].sum = (orders[o[this.groupBy]].sum * 1000 + o.CustomerPaidAmount * 1000)/1000
          } else {
            orders[o[this.groupBy]] = {orders: [o], sum: o.CustomerPaidAmount}
          }
        }
        for (let k in orders) {
          orders[k].orders.sort((a, b) => a.StartDateTime.localeCompare(b.StartDateTime))
        }
      }
      return orders
    },
    orderKeyRange: function () {
      let orders = [...this.selectedOrders].sort((a, b) => a.StartDateTime.localeCompare(b.StartDateTime))
      if (orders.length > 0) {
        return [moment(orders[0].StartDateTime), moment(orders[orders.length - 1].StartDateTime)]
      } else {
        return [moment(), moment()]
      }
    },
    jobTypeOrderTable: function () {
      let out = []
      for (let g in this.groupedByOrder) {
        out.push({
          group: g,
          orders: this.groupedByOrder[g].orders,
          sum: this.groupedByOrder[g].sum
        })
      }
      return out
    },
    countSelectedGroup: function () {
      let count = {}
      for (let o of this.selectedOrders) {
        if (count[o.JobType]) {
          count[o.JobType] += 1
        } else {
          count[o.JobType] = 1
        }
      }
      return count
    }
  },
  created () {
    this.fetchData()
  }
}
</script>