<template>
  <GoogleMapLoader
    :mapConfig="mapConfig"
    apiKey="AIzaSyAGQeljdWK0A-z_4Hmj9eNrufJMh0mC8S4"
  >
  <template slot-scope="{ google, map }">
    <GoogleMapDropMarker
          v-for="(marker, i) in markers"
          :label="(i + 1).toString()"
          :key="marker.SK"
          :marker="marker"
          :google="google"
          :map="map"
        />
    </template>
  </GoogleMapLoader>
</template>

<script>
import GoogleMapLoader from "./GoogleMapLoader";
import GoogleMapDropMarker from "./GoogleMapDropMarker";
import { mapSettings } from "@/constants/mapSettings";


export default {
  props: {
    markers: {
      type: Array,
      required: true
    }
  },
  components: {
    GoogleMapLoader, GoogleMapDropMarker
  },
  data() {
    return {}
  },
  computed: {
    mapConfig() {
      if (this.markers.length > 0) {
        return Object.assign({}, mapSettings, {
          center: this.markers[this.markers.length -1].LatLng,
          zoom: 15
        })
      }
      return {
        ...mapSettings,
        zoom: 15
      };
    },
  }
};
</script>
