<template>
  <div>
      <form novalidate class="md-layout md-margin" @submit.prevent="validateForm">
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.InvoiceNumber']}">
            <label>Invoice Number</label>
            <md-input v-model="form.InvoiceNumber"/>
            <span class="md-error" v-if="errors['instance.InvoiceNumber']">{{errors['instance.InvoiceNumber']}}</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.CustomerName']}">
            <label>Customer</label>
            <md-input :value="form.CustomerName" disabled="disabled"/>
            <span class="md-error" v-if="errors['instance.CustomerName']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.CustomerBillDate']}">
            <label>Customer Bill Date</label>
            <md-input :value="form.CustomerBillDate" disabled="disabled"/>
            <span class="md-error" v-if="errors['instance.CustomerBillDate']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-datepicker :value="form.CustomerPaidDueDate" :md-model-type="String" :class="{'md-invalid': errors['instance.CustomerPaidDueDate']}" md-immediately v-model="form.CustomerPaidDueDate">
            <label>Customer Paid Due Date</label>
            <span class="md-error" v-if="errors['instance.CustomerPaidDueDate']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.InvoiceOriginalTotalAmount']}">
            <label>Original Total Amount</label>
            <md-input :value="$currencyFormater(form.InvoiceOriginalTotalAmount)" disabled="disabled"/>
            <span class="md-error" v-if="errors['instance.InvoiceOriginalTotalAmount']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field>
            <label>Adjust Customer Paid</label>
            <md-input :value="$currencyFormater(adjustCustPaidSum)" disabled="disabled"/>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.TotalTripCostAmount']}">
            <label>Original Delivery Cost</label>
            <md-input :value="$currencyFormater(form.TotalTripCostAmount)" disabled="disabled"/>
            <span class="md-error" v-if="errors['instance.TotalTripCostAmount']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field>
            <label>Adjust Delivery Cost</label>
            <md-input :value="$currencyFormater(adjustDeliverCostSum)" disabled="disabled"/>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.RcceiptActualReceiveAmount']}">
            <label>Actual Receive</label>
            <md-input v-model.number="form.RcceiptActualReceiveAmount"/>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.RcceiptTaxAmount']}">
            <label>Withholding TAX Amount</label>
            <md-input v-model.number="form.RcceiptTaxAmount"/>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.RcceiptOtherFeeAmount']}">
            <label>Other Fee Amount</label>
            <md-input v-model.number="form.RcceiptOtherFeeAmount"/>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.InvoiceFinalTotalAmount']}">
            <label>Final Total Amount</label>
            <md-input v-model.number="form.InvoiceFinalTotalAmount"/>
            <span class="md-error" v-if="errors['instance.InvoiceFinalTotalAmount']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field>
            <label>(Original - (Actual + Withholding))</label>
            <md-input :value="$currencyFormater((form.InvoiceOriginalTotalAmount*1000 - ((form.RcceiptActualReceiveAmount || 0)*1000 + (form.RcceiptTaxAmount || 0)*1000))/1000)" disabled="disabled" />
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <div style="display: flex;">
            <md-field :class="{'md-invalid': errors['instance.ReceiptNumber']}">
              <label>Receipt Number</label>
              <md-input v-model="form.ReceiptNumber"/>
            </md-field>
            <md-button @click="fetchNewReceiptNumber" class="md-icon-button">
              <md-icon>autorenew</md-icon>
            </md-button>
          </div>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.InvoiceRemark']}">
            <label>Invoice Remark</label>
            <md-input v-model="form.InvoiceRemark"/>
            <span class="md-error" v-if="errors['instance.InvoiceRemark']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-field :class="{'md-invalid': errors['instance.ReceiptRemark']}">
            <label>Receipt Remark</label>
            <md-input v-model="form.ReceiptRemark"/>
            <span class="md-error" v-if="errors['instance.ReceiptRemark']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-25">
          <md-button type="submit" class="md-raised md-primary" :disabled="sending">Save</md-button>
          <md-button :class="{'md-raised': showAdjust}" @click="handleShowAdjustAmountForm">Adjust Amount</md-button>
        </div>
      </form>
      <form novalidate class="md-layout md-margin" @submit.prevent="validateForm" v-show="showAdjust">
        <div class="md-layout-item md-large-size-15 md-xlarge-size-15 md-medium-size-15 md-small-size-15 md-xsmall-size-15">
          <md-field :class="{'md-invalid': errors['adjustForm.AdjustField']}">
            <label>Adjust field</label>
            <md-select v-model="adjustForm.AdjustField" @md-selected="handleAdjustFieldChange">
              <md-option value="CustomerPaidAmount">Customer Paid Amount</md-option>
              <md-option value="DeliveryCost">Deliver Cost</md-option>
            </md-select>
            <span class="md-error" v-if="errors['adjustForm.AdjustField']">{{errors['adjustForm.AdjustField']}}</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-15 md-xlarge-size-15 md-medium-size-15 md-small-size-15 md-xsmall-size-15">
          <md-field :class="{'md-invalid': errors['instance.CurrentAmount']}">
            <label>Current Amount</label>
            <md-input :value="adjustForm.CurrentAmount" readonly="readonly" />
            <span class="md-error" v-if="errors['adjustForm.CurrentAmount']">{{errors['adjustForm.CurrentAmount']}}</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-15 md-xlarge-size-15 md-medium-size-15 md-small-size-15 md-xsmall-size-15">
          <md-field :class="{'md-invalid': errors['adjustForm.TargetAmount']}">
            <label>Target Amount</label>
            <md-input v-model="adjustForm.TargetAmount" />
            <span class="md-error" v-if="errors['adjustForm.TargetAmount']">{{errors['adjustForm.TargetAmount']}}</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-15 md-xlarge-size-15 md-medium-size-15 md-small-size-15 md-xsmall-size-15">
          <md-field :class="{'md-invalid': errors['adjustForm.CostDiff']}">
            <label>Cost Diff</label>
            <md-input :value="(adjustForm.CurrentAmount*1000 - adjustForm.TargetAmount*1000)/1000" readonly="readonly" />
            <span class="md-error" v-if="errors['adjustForm.CostDiff']">{{errors['adjustForm.CostDiff']}}</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-15 md-xlarge-size-15 md-medium-size-15 md-small-size-15 md-xsmall-size-15">
          <md-field :class="{'md-invalid': errors['adjustForm.CostDistribution']}">
            <label>Cost distribution</label>
            <md-select v-model="adjustForm.CostDistribution" @md-selected="handleAdjustFieldChange">
              <md-option value="all-percent">All trips with percentage</md-option>
              <md-option value="selected-percent">Selected trips with percentage</md-option>
            </md-select>
            <span class="md-error" v-if="errors['adjustForm.CostDistribution']">{{errors['adjustForm.CostDistribution']}}</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-15 md-xlarge-size-15 md-medium-size-15 md-small-size-15 md-xsmall-size-15">
          <md-button @click="handleVerifyAdjustment">Verify Adjustment</md-button>
        </div>
      </form>
      <progress max="100" style="width: 100%" v-show="loading">60%</progress>
      <div class="viewport" v-if="sortedOrderItem.length > 0">
        <md-toolbar :md-elevation="1">
          <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
              <span class="md-title">Orders [ {{dateTimeFormatter(sortedOrderItem[0].StartDateTime)}} - {{dateTimeFormatter(sortedOrderItem[sortedOrderItem.length - 1].StartDateTime)}} ]</span>
            </div>
            <div class="md-toolbar-section-end">
              <span class="md-title">
                Trips: {{sortedOrderItem.length}}
                Cost: {{$currencyFormater(costSum)}}
                Invoice: {{$currencyFormater(orderSum)}}
                Margin: {{$currencyFormater((orderSum*1000 - costSum*1000)/1000)}}
                <span v-show="form.InvoiceOriginalTotalAmount != form.InvoiceFinalTotalAmount">
                  ( {{$currencyFormater((form.InvoiceOriginalTotalAmount*1000 - form.InvoiceFinalTotalAmount*1000 + (form.CustomerPaidAdjustAmount || 0)*1000)/1000)}} )
                </span>
              </span>
            </div>
          </div>
        </md-toolbar>
        <md-table  v-model="sortedOrderItem" md-fixed-header @md-selected="onSelect">
          <md-table-toolbar slot="md-table-alternate-header" slot-scope="{}">
            <div class="md-toolbar-section-end">
              <md-button @click="handleDeleteOrder">
                Delete
              </md-button>
            </div>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select>
            <md-table-cell md-label="Order ID"><router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link></md-table-cell>
            <md-table-cell md-label="Job Type">{{item.JobType}}</md-table-cell>
            <md-table-cell md-label="Start at">{{ dateTimeFormatter(item.StartDateTime) }}</md-table-cell>
            <md-table-cell md-label="Truck Type">{{item.TruckType}} - {{item.ContainerType}}</md-table-cell>
            <md-table-cell md-label="From">{{ item.From.Description }}</md-table-cell>
            <md-table-cell md-label="To">{{ item.To.Description }}</md-table-cell>
            <md-table-cell md-label="Created By">{{ item.CreatedBy }}</md-table-cell>
            <md-table-cell md-label="Original Total" md-numeric>{{ $currencyFormater(item.CustomerPaidAmount) }}</md-table-cell>
            <md-table-cell md-label="Adjust Amount" md-numeric>{{ $currencyFormater(item.AdjustAmount || 0) }}</md-table-cell>
            <md-table-cell md-label="Final Total" md-numeric>{{ $currencyFormater(item.CustomerPaidAmount + (item.AdjustAmount || 0)) }}</md-table-cell>
            <md-table-cell md-label="Original Cost" md-numeric>{{ $currencyFormater(item.OriginalTotalCost) }}</md-table-cell>
            <md-table-cell md-label="Adjust Cost" md-numeric>{{ $currencyFormater(item.AdjustCost || 0)}}</md-table-cell>
            <md-table-cell md-label="Final Cost" md-numeric>{{ $currencyFormater(item.FinalTotalCost + (item.AdjustCost || 0)) }}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
        <span>{{snackBarMsg}}</span>
      </md-snackbar>
      <md-dialog :md-active.sync="showDialog">
        <md-dialog-title>Adjust Detail</md-dialog-title>
        <md-dialog-content>
          <md-field>
            <label>Adjust Records</label>
            <md-textarea v-model="adjustForm.Records" md-autogrow></md-textarea>
          </md-field>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-primary" @click="showDialog = false">Close</md-button>
          <md-button class="md-primary" @click="handleAdjustAmount">Adjust</md-button>
        </md-dialog-actions>
      </md-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { API } from 'aws-amplify'
import {validate} from 'jsonschema'
import schema from '../schema'
import { mapGetters } from 'vuex'

export default {
  name: 'InvoiceDetail',
  data () {
    return {
      orderItems: [],
      tripItems: [],
      invoice: {},
      errors: {},
      sending: false,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      newReceipt: false,
      showAdjust: false,
      showDialog: false,
      loading: false,
      form: {
        InvoiceNumber: null,
        OrderKeys: [],
        CustomerID: null,
        CustomerName: null,
        CustomerLogo: null,
        CustomerBillDate: null,
        CustomerPaidDueDate: null,
        InvoiceOriginalTotalAmount: 0,
        InvoiceFinalTotalAmount: 0,
        ReceiptNumber: null,
        RcceiptActualReceiveAmount: 0,
        RcceiptTaxAmount: 0,
        RcceiptOtherFeeAmount: 0,
        TotalTripCostAmount: 0,
        FirstStartDate: null,
        LastStartDate: null,
        CreatedDate: null,
        RCVerifyBy: null,
        RCVerifyAt: null,
        CreatedAt: null,
        CreatedBy: null,
        InvoiceRemark: null,
        ReceiptRemark: null,
        DeliverCostAdjustAmount: 0,
        CustomerPaidAdjustAmount: 0
      },
      adjustForm: {
        AdjustField: 'CustomerPaidAmount',
        CurrentAmount: null,
        TargetAmount: 0,
        CostDistribution: 'all-percent',
        SelectedOrders: [],
        Records: ""
      },
      selected: []
    }
  },
  methods: {
    onSelect (items) {
      this.adjustForm.SelectedOrders = items
      this.handleAdjustFieldChange()
    },
    fetchOrders: function (startIndex) {
      let keys = this.form['OrderKeys'].slice(startIndex, startIndex + 100)
      let tripKeys = keys.map((k) => { return {PK: k.PK,SK: `TRIP#${k.PK.split('#')[1]}.1`} })
      if (startIndex === 0) {
        this.loading = true
      }
      let vm = this
      if (keys.length > 0) {
        Promise.all([
          API.post('ezietruckapi', `/api/customers/${keys[0].PK.slice(6,9)}/morders`, {body: {Orders: keys}}),
          API.post('ezietruckapi', `/api/customers/${keys[0].PK.slice(6,9)}/morders`, {body: {Orders: tripKeys}})
        ]).then(res => {
          if (res[0]) {
            vm.orderItems = [...vm.orderItems, ...res[0]]
          }
          if (res[1]) {
            vm.tripItems = [...vm.tripItems, ...res[1]]
          }
          if (startIndex + 100 < vm.form['OrderKeys'].length) {
            this.fetchOrders(startIndex + 100)
          } else {
            this.loading = false
          }
        })
      }
    },
    fetchData: function () {
      API.get('ezietruckapi', `/api/customerInvoices/${this.$route.params.invoiceID}`).then((json) => {
        let up = {}
        if (!json.CreatedAt) {
          up['CreatedAt'] = (new Date()).toISOString()
        } 
        if (!json.CreatedBy) {
          up['CreatedBy'] = this.user.signInUserSession.idToken.payload.email
        }
        this.form = Object.assign({}, this.form, json, up)
        this.fetchOrders(0)
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY")
      } else {
        return ''
      }
    },
    validateForm: function () {
      let output = Object.assign({}, this.form)
      delete output.CustomerPaidAdjustAmount
      delete output.DeliverCostAdjustAmount
      if (output.ReceiptNumber) {
        output.RCVerifyAt = (new Date()).toISOString()
        output.RCVerifyBy = this.user.signInUserSession.idToken.payload.email
      } else {
        delete output.ReceiptNumber
        delete output.RCVerifyAt
        delete output.RCVerifyBy
      }
      if (!output.InvoiceRemark) {
        delete output.InvoiceRemark
      }
      if (!output.ReceiptRemark) {
        delete output.ReceiptRemark
      }
      let p = validate(output, schema.InvoiceFormSchema)
      let self = this
      this.errors = {}
      for (let err of p.errors) {
        this.errors[err.property] = err.message
      }
      if (p.errors.length === 0) {
        this.sending = true
        API.post('ezietruckapi', `/api/customerInvoices`, {body: output}).then(() => {
          self.showSnackbar = true
          self.snackBarMsg = 'Successfully saved'
          self.sending = false
        }).catch((e) => {
          self.sending = false
          self.showSnackbar = true
          self.snackBarMsg = JSON.stringify(e)
        })
      }
      return false
    },
    fetchNewReceiptNumber: function () {
      let pdNumber = `RC${moment().format('YYYYMM')}`
      let self = this
      API.get('ezietruckapi', `/api/options/LastReceiptNumber`).then((json) => {
        if (json.length > 0 && json[0].Name.slice(2, 8) === moment().format('YYYYMM')) {
          let s = `${parseInt(json[0].Name.slice(8)) + 1}`
          while (s.length < 3) s = "0" + s
          pdNumber += s
        } else {
          pdNumber += '001'
        }
        self.form.ReceiptNumber = pdNumber
      })
    },
    handleShowAdjustAmountForm: function () {
      this.showAdjust = !this.showAdjust
      if (this.showAdjust) {
        this.adjustForm = {
          AdjustField: 'CustomerPaidAmount',
          CurrentAmount: this.orderItems.reduce((accumulator, currentValue) => (accumulator*1000 + currentValue.CustomerPaidAmount*1000)/1000, 0),
          TargetAmount: 0,
          CostDistribution: 'all-percent',
          SelectedOrders: [],
          Records: ""
        }
      }  else {
        this.adjustForm = {
          AdjustField: 'CustomerPaidAmount',
          CurrentAmount: 0,
          TargetAmount: 0,
          CostDistribution: 'all-percent',
          SelectedOrders: [],
          Records: ""
        }
      }
    },
    handleAdjustFieldChange: function () {
      if (this.adjustForm.AdjustField === 'CustomerPaidAmount') {
        if (this.adjustForm.CostDistribution === 'all-percent') {
          this.adjustForm = Object.assign({}, this.adjustForm, {CurrentAmount: this.orderItems.reduce((accumulator, currentValue) => (accumulator*1000 + currentValue.CustomerPaidAmount*1000)/1000, 0)})
        } else if (this.adjustForm.CostDistribution === 'selected-percent') {
          let sum = 0
          for (let o of this.adjustForm.SelectedOrders) {
            sum = (sum*1000 + o.CustomerPaidAmount*1000)/1000
          }
          this.adjustForm = Object.assign({}, this.adjustForm, {CurrentAmount: sum})
        }
      } else if (this.adjustForm.AdjustField === 'DeliveryCost') {
        if (this.adjustForm.CostDistribution === 'all-percent') {
          this.adjustForm = Object.assign({}, this.adjustForm, {CurrentAmount: this.tripItems.reduce((accumulator, currentValue) => (accumulator*1000 + currentValue.DeliveryCost*1000 + currentValue.DailyPickerCost*1000 + currentValue.OtherCost*1000 + currentValue.SalaryPerTrip*1000 + currentValue.FuelAllowance*1000)/1000, 0)})
        } else if (this.adjustForm.CostDistribution === 'selected-percent') {
          let sum = 0
          for (let o of this.adjustForm.SelectedOrders) {
            let idx = this.tripItems.findIndex((t) => t.OrderID === o.OrderID)
            if (idx > -1) {
              sum = (sum*1000 + this.tripItems[idx].DeliveryCost*1000 + this.tripItems[idx].DailyPickerCost*1000 + this.tripItems[idx].OtherCost*1000 + this.tripItems[idx].SalaryPerTrip*1000 + this.tripItems[idx].FuelAllowance*1000)/1000
            }
          }
          this.adjustForm = Object.assign({}, this.adjustForm, {CurrentAmount: sum})
        }
      }
    },
    handleVerifyAdjustment: function () {
      this.showDialog = true
      let includedItem = []
      if (this.adjustForm.AdjustField === 'CustomerPaidAmount') {
        if (this.adjustForm.CostDistribution === 'all-percent') {
          includedItem = this.orderItems.map((o) => [o.OrderID, o.CustomerPaidAmount])
        } else if (this.adjustForm.CostDistribution === 'selected-percent') {
          includedItem = this.adjustForm.SelectedOrders.map((o) => [o.OrderID, o.CustomerPaidAmount])
        }
      } else if (this.adjustForm.AdjustField === 'DeliveryCost') {
        if (this.adjustForm.CostDistribution === 'all-percent') {
          includedItem = this.orderItems.map((o) => {
            let idx = this.tripItems.findIndex((t) => t.OrderID === o.OrderID)
            if (idx > -1) {
              return [this.tripItems[idx].TripID, (this.tripItems[idx].DeliveryCost*1000 + this.tripItems[idx].DailyPickerCost*1000 + this.tripItems[idx].OtherCost*1000 + this.tripItems[idx].SalaryPerTrip*1000 + this.tripItems[idx].FuelAllowance*1000)/1000]
            } else {
              return [null, 0]
            }
          })
        } else if (this.adjustForm.CostDistribution === 'selected-percent') {
          includedItem = this.adjustForm.SelectedOrders.map((o) => {
            let idx = this.tripItems.findIndex((t) => t.OrderID === o.OrderID)
            if (idx > -1) {
              return [this.tripItems[idx].TripID, (this.tripItems[idx].DeliveryCost*1000 + this.tripItems[idx].DailyPickerCost*1000 + this.tripItems[idx].OtherCost*1000 + this.tripItems[idx].SalaryPerTrip*1000 + this.tripItems[idx].FuelAllowance*1000)/1000]
            } else {
              return [null, 0]
            }
          })
        }
      }
      let adjustSum = 0
      let percent = this.adjustForm.TargetAmount/this.adjustForm.CurrentAmount
      for (let i = 0; i < includedItem.length - 1; i ++) {
        let diff = (parseInt(includedItem[i][1] * percent)*1000 - includedItem[i][1]*1000)/1000
        adjustSum = (adjustSum*1000 + diff*1000)/1000
        includedItem[i][1] = diff
      }
      includedItem[includedItem.length - 1][1] = (this.adjustForm.TargetAmount*1000 - (this.adjustForm.CurrentAmount*1000 + adjustSum*1000))/1000
      this.adjustForm.Records = includedItem.map((i) => i.join(',')).join('\n')
    },
    handleAdjustAmount: function () {
      let self = this
      API.post('ezietruckapi', `/api/customerInvoices/${this.$route.params.invoiceID}/adjust`, {body: this.adjustForm}).then((res) => {
          self.showSnackbar = true
          self.snackBarMsg = 'Successfully saved'
          self.sending = false
          self.adjustForm.Records = res.Records
          self.orderItems = []
          self.tripItems = []
          self.fetchOrders(0)
        }).catch((e) => {
          self.sending = false
          self.showSnackbar = true
          self.snackBarMsg = JSON.stringify(e)
        })
    },
    handleDeleteOrder: function () {
      let sum = 0
      let self = this
      let body = {DeleteOrderKeys: [], InvoiceOriginalTotalAmount: 0}
      for (let order of this.adjustForm.SelectedOrders) {
        let idx = this.orderItems.findIndex((t) => t.OrderID === order.PK.split('#')[1])
        if (idx > -1) {
          body.DeleteOrderKeys.push(order)
          sum += this.orderItems[idx].CustomerPaidAmount + (this.orderItems[idx].AdjustAmount || 0)
        }
      }
      body.InvoiceOriginalTotalAmount = this.form.InvoiceOriginalTotalAmount - sum
      API.patch('ezietruckapi', `/api/customerInvoices/${this.$route.params.invoiceID}/orders`, {body: body}).then(() => {
        self.orderItems = []
        self.fetchData()
      })
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    customers: function () {
      let out = {}
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out[o.CustomerID] = o
        }
      }
      return out
    },
    sortedOrderItem: function () {
      if (this.loading) {
        return []
      } else {
        return [...this.orderItems.map((o) => {
          let idx = this.tripItems.findIndex((t) => t.OrderID === o.OrderID)
          if (idx > -1) {
            return Object.assign({}, o, {
              OriginalTotalCost: (this.tripItems[idx].DeliveryCost*1000 + this.tripItems[idx].DailyPickerCost*1000 + this.tripItems[idx].OtherCost*1000 + this.tripItems[idx].SalaryPerTrip*1000 + this.tripItems[idx].FuelAllowance*1000)/1000,
              AdjustCost: (this.tripItems[idx].AdjustAmount || 0),
              FinalTotalCost: (this.tripItems[idx].DeliveryCost*1000 + this.tripItems[idx].DailyPickerCost*1000 + this.tripItems[idx].OtherCost*1000 + this.tripItems[idx].SalaryPerTrip*1000 + this.tripItems[idx].FuelAllowance*1000 + (this.tripItems[idx].AdjustAmount || 0)*1000)/1000
            })
          } else {
            return Object.assign({}, o, {
              OriginalTotalCost: 0,
              AdjustCost: 0,
              FinalTotalCost: 0
            })
          }
        })].sort((a, b) => a.StartDateTime.localeCompare(b.StartDateTime))
      }
    },
    orderSum: function () {
      return this.orderItems.reduce((accumulator, currentValue) => (accumulator*1000 + currentValue.CustomerPaidAmount*1000 + (currentValue.AdjustAmount || 0)*1000)/1000, 0)
    },
    costSum: function () {
      return this.tripItems.reduce((accumulator, currentValue) => (accumulator*1000 + currentValue.DeliveryCost*1000 + currentValue.DailyPickerCost*1000 + currentValue.OtherCost*1000 + currentValue.SalaryPerTrip*1000 + currentValue.FuelAllowance*1000 + (currentValue.AdjustAmount || 0)*1000)/1000, 0)
    },
    adjustCustPaidSum: function () {
      return this.orderItems.reduce((accumulator, currentValue) => (accumulator*1000 + (currentValue.AdjustAmount || 0)*1000)/1000, 0)
    },
    adjustDeliverCostSum: function () {
      return this.tripItems.reduce((accumulator, currentValue) => (accumulator*1000 + (currentValue.AdjustAmount || 0)*1000)/1000, 0)
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

