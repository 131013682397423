<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Option</label>
                <md-select v-model="curentOption">
                  <md-option value="TruckType" v-show="user.signInUserSession.idToken.payload['cognito:groups'].includes('superuser')">Truck Type</md-option>
                  <md-option value="ContainerType" v-show="user.signInUserSession.idToken.payload['cognito:groups'].includes('superuser')">Container Type</md-option>
                  <md-option value="JobType" v-show="user.signInUserSession.idToken.payload['cognito:groups'].includes('superuser')">Job Type</md-option>
                  <md-option value="Bank" v-show="user.signInUserSession.idToken.payload['cognito:groups'].includes('superuser')">Bank</md-option>
                  <md-option value="Link" v-show="user.signInUserSession.idToken.payload['cognito:groups'].includes('superuser')">Link to external</md-option>
                  <md-option value="Config" v-show="user.signInUserSession.idToken.payload['cognito:groups'].includes('superuser')">Configurations</md-option>
                  <md-option value="DriverTeam" v-show="user.signInUserSession.idToken.payload['cognito:groups'].includes('superuser')">Driver Team</md-option>
                  <md-option value="Owner" v-show="user.signInUserSession.idToken.payload['cognito:groups'].includes('superuser')">Owner</md-option>
                  <md-option value="Tag">Driver Tags</md-option>
                  <md-option value="DriverDeliveryZone">Driver Delivery Zone</md-option>
                  <md-option value="CustomerLoginCode">Customer Login Code</md-option>
                  <md-option value="CustomerJobType">Customer Job Type</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </div>
      </div>
    </md-toolbar>
    <md-list>
      <md-list-item v-for="option in options" :key="option.SK">
        <span class="md-list-item-text">{{option.Name}}</span>
        <md-button class="md-icon-button md-list-action" @click="handleDelete(option)">
          <md-icon class="md-primary">delete</md-icon>
        </md-button>
      </md-list-item>
      <md-list-item>
        <md-field class="md-layout-item">
          <label>New</label>
          <md-input v-model="newName"></md-input>
        </md-field>
        <md-button class="md-icon-button md-list-action" @click="handleAddNew">
          <md-icon class="md-primary">add</md-icon>
        </md-button>
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'

export default {
  name: 'OptionList',
  data () {
    return {
      curentOption: 'CustomerLoginCode',
      options: [],
      newName: null
    }
  },
  methods: {
    fetchData: function () {
      let self = this
      API.get('ezietruckapi', '/api/options/' + this.curentOption).then((json) => {
        self.options = json
      })
    },
    handleAddNew: function () {
      API.post('ezietruckapi', '/api/options/' + this.curentOption, {body: {Name: this.newName}}).then(() => {
        this.newName = null
        this.fetchData()
      })
    },
    handleDelete: function (option) {
      API.del('ezietruckapi', `/api/options/${this.curentOption}/${encodeURIComponent(option.Name)}`).then(() => {
        this.fetchData()
      })
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    curentOption: function () {
      this.fetchData()
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
