<template>
  <div>
    <md-dialog :md-active.sync="showMapDialog">
      <md-dialog-title>Location</md-dialog-title>
      <LastLocationMap :truck-lat-lng="mapOption.markerLatLng" :style="{'height': ($vssHeight - 20) + 'px', 'width': ($vssWidth *0.3) + 'px'}"/>
    </md-dialog>
    <md-dialog :md-active.sync="showPhotoDialog">
      <md-dialog-title>Photo</md-dialog-title>
      <div style="overflow: auto">
        <md-content v-for="photo in currentPhoto" :key="photo">
          <my-s3-image :imagePath="photo"></my-s3-image>
        </md-content>
      </div>
    </md-dialog>
    <md-toolbar>
      <form @submit.prevent="handleSearchDiary" class="md-toolbar-row md-layout" >
        <div class="md-layout-item">
          <md-datepicker v-model="formData.StartDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
            <label>Start Date</label>
            <span class="md-error" v-if="errors['Date']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item">
          <md-datepicker v-model="formData.EndDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
            <label>End Date</label>
            <span class="md-error" v-if="errors['Date']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item">
          <md-autocomplete @input="handleCustomerSelect" :value="formData.CustomerID" :md-options="customerItems" :class="{'md-invalid': errors['instance.CustomerID']}">
            <label>Customer</label>
            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
              <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
            </template>
            <span class="md-error" v-if="errors['instance.CustomerID']">Invalid field input</span>
          </md-autocomplete>
        </div>
        <div class="md-layout-item">
          <md-autocomplete @input="handleDriverSelect" :value="formData.DriverName" :md-options="drivers" :class="{'md-invalid': errors['instance.DriverName']}">
            <label>Driver</label>
            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
              <md-highlight-text :md-term="term">{{ item.DriverName }}</md-highlight-text>
            </template>
            <span class="md-error" v-if="errors['instance.DriverName']">Invalid field input</span>
          </md-autocomplete>
        </div>
        <div class="md-layout-item">
          <md-button :disabled="!!LastEvaluatedKey" type="submit" class="md-raised md-primary">
            <span>Search</span>
          </md-button>
          <md-button :disabled="!!LastEvaluatedKey" @click="handleDownloadAllCSV">
            Download All
          </md-button>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="groupBy">Group By</label>
            <md-select v-model="groupBy" name="groupBy" id="groupBy">
              <md-option value="Driver">Driver</md-option>
              <md-option value="CustomerID">CustomerID</md-option>
            </md-select>
          </md-field>
        </div>
      </form>
    </md-toolbar>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <div v-for="group in groups" :key="group.Name">
      <md-toolbar class="md-accent" md-elevation="1">
        <div class="md-toolbar-section-start">
          <h3 class="md-subtitle" style="color: white">{{group.Name}}</h3>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-icon-button" :disabled="!!LastEvaluatedKey" @click="handleDownloadCSV(group.Name)">
            <md-icon>get_app</md-icon>
          </md-button>
          <md-button class="md-icon-button" @click="handleCalculateDistance(group.Name)">
            <md-icon>navigation</md-icon>
          </md-button>
        </div>
      </md-toolbar>
      <md-table  v-for="(ttitem, t) in group.TripTrackItems" :key="t" v-model="ttitem.Items"  @md-selected="onSelect($event)" class="md-primary">
        <md-table-toolbar>
          <div class="md-toolbar-section-start">{{t}}
          </div>
          <div class="md-toolbar-section-end" v-if="ttitem.Distance">
            Total Distance: {{ttitem.Distance.toFixed(2)}}
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }" >
          <md-table-cell md-label="Created At">
            <p v-show="!item.CheckIn && !item.StartDateTime" style="color: red">
              Created At:<br />
              {{datetimeFormater(item.CreatedAt)}}
            </p>
            <p v-if="item.CheckIn && item.CheckIn.split('#').length > 2">
              Check In:<br />
              {{datetimeFormater(item.CheckIn.split('#')[1])}}
            </p>
            <p v-show="item.StartDateTime" style="color: red">
              Start DateTime:<br />
              {{datetimeFormater(item.StartDateTime)}}
            </p>
          </md-table-cell>
          <md-table-cell md-label="Driver">
            <router-link :to="'/Drivers/' + item.Driver.DriverID">{{ item.Driver.DriverName }} ({{ item.Driver.DriverPhoneNumber }})</router-link>
            <img src="../assets/LINE_Brand_icon.png" v-show="item.LineUserID" width="16" />
            <p>
              {{ item.Vehicle ? item.Vehicle.VehicleLicenseNumber : "" }} {{ item.Vehicle ? item.Vehicle.VehicleLicenseProvince : "" }} -
              {{ item.Vehicle ? item.Vehicle.VehicleTruckType : "" }} {{ item.Vehicle ? item.Vehicle.VehicleContainerType : "" }}
            </p>
          </md-table-cell>
          <md-table-cell md-label="Customer">{{ item.CustomerID }}</md-table-cell>
          <md-table-cell md-label="JobType">{{ item.JobType }}</md-table-cell>
          <md-table-cell md-label="OrderID">
            <router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link>
          </md-table-cell>
          <md-table-cell md-label="Stop for">{{ item.StopFor }}</md-table-cell>
          <md-table-cell md-label="Location"><a @click="mapFocusAt(item.Location)">{{ item.Area }}</a></md-table-cell>
          <md-table-cell md-label="Photo">
            <md-button class="md-icon-button" @click="openPhotoDialog(item.Photo)">
              <md-icon>image</md-icon>
            </md-button>
            <p v-if="item.ImageMeta">
              {{datetimeFormater(item.ImageMeta.DateTimeOriginal)}}
            </p>
            <br />
            <md-button class="md-icon-button" @click="openPhotoDialog(item.PhotoAlbum || [])" v-show="(item.PhotoAlbum || []).length > 0">
              <md-icon>image</md-icon>
            </md-button>
          </md-table-cell>
          <md-table-cell md-label="Note">{{ item.Note }}</md-table-cell>
          <md-table-cell md-label="Pick Up / Drop Off">{{ item.PickUpItemNo }} / {{ item.DropOffItemNo }}</md-table-cell>
          <md-table-cell md-label="Distance (km)">
            <md-field>
              <md-input v-model.number="item.Distance" ></md-input>
            </md-field>
          </md-table-cell>
          <router-link :to="'/DiaryDetail/' + encodeURIComponent(item.PK) + '/' + encodeURIComponent(item.SK)" target="_blank">
            Detail
          </router-link>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import LastLocationMap from '../components/LastLocationMap'
import CustomField from '../components/CustomField'

import moment from 'moment'
import MyS3Image from '../components/MyS3Image'
import { latLng } from "leaflet"

export default {
  name: 'DiaryList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image, LastLocationMap, CustomField},
  data () {
    const sdate = this.$route.params.date || moment().format('YYYY-MM-DD')
    const edate = this.$route.params.date || moment().format('YYYY-MM-DD')
    return {
      diaryItems: [],
      newDriverItems: [],
      driverItems: [],
      customerItems: [],
      formData: {
        StartDate: sdate,
        EndDate: edate,
        DriverID: null,
        DriverName: null,
        DriverPhoneNumber: null,
        CustomerID: null,
      },
      loading: false,
      errors: {},
      LastEvaluatedKey: null,
      groupBy: 'Driver',
      showMapDialog: false,
      showPhotoDialog: false,
      currentPhoto: null,
      mapOption: {
        zoom: 13,
        center: latLng(13.75318, 100.53173),
        url: 'https://ms.longdo.com/mmmap/tile.php?zoom={z}&x={x}&y={y}&key=c146605c52087ac63385d5db545fb84c&proj=epsg3857&HD=1',
        attribution:
          '© Longdo Map',
        markerLatLng: null,
        options: {
          zoomSnap: 0.5
        },
        showMap: true
      }
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items'].filter((o) => o.SK.slice(0, 10) === '#METADATA#')]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.driverItems = []
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          self.driverItems = [...self.driverItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKey = json.LastEvaluatedKey
          self.fetchDriver(json.LastEvaluatedKey)
        } else {
          self.LastEvaluatedKey = null
        }
      })
    },
    fetchedDiary: function (selectedDiaryDate, driverPhoneNumber, customerID, lastEvaluatedKey) {
      let q = []
      let vm = this
      this.loading = true
      if (lastEvaluatedKey && lastEvaluatedKey.SK) {
        q.push(`ExclusiveStartKey=${ encodeURIComponent(JSON.stringify(lastEvaluatedKey)) }`)
      }
      if (driverPhoneNumber) {
        q.push(`DriverPhoneNumber=${driverPhoneNumber}`)
      }
      if (customerID) {
        q.push(`CustomerID=${customerID}`)
      }
      API.get('ezietruckapi', `/api/vehicles/diary/${selectedDiaryDate}${q.length > 0 ? '?' + q.join('&') : ''}`).then((json) => {
        vm.diaryItems = [...vm.diaryItems, ...(json.Items || [])]
        if (json.LastEvaluatedKey) {
          vm.fetchedDiary(selectedDiaryDate, driverPhoneNumber, customerID, json.LastEvaluatedKey)
        } else {
          vm.loading = false
        }
      })
    },
    handleSearchDiary: function () {
      let i = moment(this.formData.StartDate)
      this.diaryItems = []
      while (i <= moment(this.formData.EndDate)) {
        this.fetchedDiary(
          i.format('YYYY-MM-DD'),
          this.formData.DriverID ? this.formData.DriverPhoneNumber || '-' : (this.$route.query.DriverPhoneNumber || null),
          this.formData.CustomerID, null)
        i.add(1, 'days')
      }
    },
    datetimeFormater: function (str) {
      let m = moment(str)
      m.local() 
      return m.format('DD MMM YYYY HH:mm:ss')
    },
    mapFocusAt: function (gps) {
      this.showMapDialog = true
      this.mapOption = Object.assign({}, this.mapOption, {
        center: latLng(gps.Lat, gps.Lon),
        markerLatLng: latLng(gps.Lat, gps.Lon),
      })
    },
    openPhotoDialog: function (photo) {
      this.showPhotoDialog = true
      this.currentPhoto = photo
    },
    handleDriverSelect: function (value) {
      if (typeof value === 'object' && value.DriverID) {
        this.formData.DriverID = value.DriverID
        this.formData.DriverName = value.DriverName
        this.formData.DriverPhoneNumber = value.DriverPhoneNumber || null
        this.formData = Object.assign({}, this.formData)
      } else {
        if (!value) {
          this.formData.DriverID = ''
          this.formData.DriverName = '',
          this.formData.DriverPhoneNumber = null
        }
      }
    },
    handleCustomerSelect: function (value) {
      if (typeof value === 'object' && value.CustomerID) {
        this.formData.CustomerID = value.CustomerID
        this.formData = Object.assign({}, this.formData)
      } else {
        if (!value) {
          this.formData.CustomerID = ''
        }
      }
    },
    handleDownloadCSV: function (groupName) {
      let csvHeader = 'Created At,Driver,Vehicle,Customer,JobType,OrderID,Stop for,Address,Lat,Lon,Snapshot,Album,Note,Pick Up,Drop Off,SK,Origin SK,Distance,TripTrackID,FinishOrder,Detail URL,';
      let csv = ''
      let customHeaders = []
      let customValues = []
      let hidx = -1
      let idx = this.groups.findIndex((g) => g.Name === groupName)
      if (idx > -1) {
        for (let t in this.groups[idx].TripTrackItems) {
          this.groups[idx].TripTrackItems[t].Items.forEach((item) => {
            hidx = -1
            customValues = customHeaders.map((h) => '')
            for (let fk in item.CustomField) {
              hidx = customHeaders.findIndex((e) => e === fk)
              if (hidx < 0) {
                customHeaders = [...customHeaders, fk]
                customValues = [...customValues, item.CustomField[fk]]
              } else {
                customValues[hidx] = item.CustomField[fk]
              }
            }
            csv += `${this.datetimeFormater(item.CreatedAt)},${ item.Driver.DriverName } (${ item.Driver.DriverPhoneNumber }),${item.Vehicle ? item.Vehicle.VehicleLicenseNumber : ""} ${item.Vehicle ? item.Vehicle.VehicleLicenseProvince : ""} - ${item.Vehicle ? item.Vehicle.VehicleTruckType : ""} ${item.Vehicle ? item.Vehicle.VehicleContainerType : ""}, ${item.CustomerID}, ${item.JobType ? item.JobType.replace(/(\n|,)/g, " ") : ""}, ${item.OrderID || ""},${item.StopFor},${item.Area.replace(/(\n|,)/g, " ")},${item.Location.Lat},${item.Location.Lon},${item.Photo.join(' ')}, ${(item.PhotoAlbum || []).join(' ')},${item.Note ? item.Note.replace(/(\n|,)/g, " ") : ""},${item.PickUpItemNo},${item.DropOffItemNo},${item.SK},${item.OriginKey ? item.OriginKey.SK : ''},${item.Distance},${item.TripTrackID || '-'},${item.FinishOrder},${'https://ezie-truck.eziecorp.com/#/DiaryDetail/' + encodeURIComponent(item.PK) + '/' + encodeURIComponent(item.SK)},${customValues.join(',')}\n`
          })
        }
        csvHeader += customHeaders.join(',') + '\n'
        let hiddenElement = document.createElement('a')
        const blob = new Blob(["\ufeff", csvHeader + csv], {type : 'text/csv;charset=utf-8'})
        hiddenElement.href = URL.createObjectURL(blob)
        hiddenElement.target = '_blank'
        hiddenElement.download = 'diary.csv'
        hiddenElement.click()
      }
    },
    handleDownloadAllCSV: function () {
      let csvHeader = 'Created At,Driver,Vehicle,Customer,JobType,OrderID,Stop for,Address,Lat,Lon,Snapshot,Album,Note,Pick Up,Drop Off,SK,Origin SK,Distance,TripTrackID,FinishOrder,Detail URL,';
      let csv = ''
      let customHeaders = []
      let customValues = []
      let hidx = -1
      for (let group of this.groups) {
        for (let t in group.TripTrackItems) {
          group.TripTrackItems[t].Items.forEach((item) => {
            customValues = customHeaders.map((h) => '')
            for (let fk in item.CustomField) {
              hidx = customHeaders.findIndex((e) => e === fk)
              if (hidx < 0) {
                customHeaders = [...customHeaders, fk]
                customValues = [...customValues, item.CustomField[fk]]
              } else {
                customValues[hidx] = item.CustomField[fk]
              }
            }
            csv += `${this.datetimeFormater(item.CreatedAt)},${ item.Driver.DriverName } (${ item.Driver.DriverPhoneNumber }),${item.Vehicle ? item.Vehicle.VehicleLicenseNumber : ""} ${item.Vehicle ? item.Vehicle.VehicleLicenseProvince : ""} - ${item.Vehicle ? item.Vehicle.VehicleTruckType : ""} ${item.Vehicle ? item.Vehicle.VehicleContainerType : ""}, ${item.CustomerID}, ${item.JobType ? item.JobType.replace(/(\n|,)/g, " ") : ""}, ${item.OrderID || ""},${item.StopFor},${item.Area.replace(/(\n|,)/g, " ")},${item.Location.Lat},${item.Location.Lon},${item.Photo.join(' ')}, ${(item.PhotoAlbum || []).join(' ')},${item.Note ? item.Note.replace(/(\n|,)/g, " ") : ""},${item.PickUpItemNo},${item.DropOffItemNo},${item.SK},${item.OriginKey ? item.OriginKey.SK : ''},${item.Distance},${item.TripTrackID || '-'},${item.FinishOrder},${'https://ezie-truck.eziecorp.com/#/DiaryDetail/' + encodeURIComponent(item.PK) + '/' + encodeURIComponent(item.SK)},${customValues.join(',')}\n`
          })
        }
      }
      csvHeader += customHeaders.join(',') + '\n'
      let hiddenElement = document.createElement('a')
      const blob = new Blob(["\ufeff", csvHeader + csv], {type : 'text/csv;charset=utf-8'})
      hiddenElement.href = URL.createObjectURL(blob)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'diary.csv'
      hiddenElement.click()
    },
    handleCalculateDistance: function (groupName) {
      let idx = this.groups.findIndex((g) => g.Name === groupName)
      if (idx > -1) {
        let req = {Keys: [], Distances: [], Origins: [], Destinations: []}
        this.groups[idx].Items.forEach((item) => {
          req.Keys.push({PK: item.PK, SK: item.SK});
          req.Distances.push(item.Distance);
          req.Origins.push(`${item.Location.Lat},${item.Location.Lon}`);
          req.Destinations.push(`${item.Location.Lat},${item.Location.Lon}`);
        })
        API.post('ezietruckapi', '/api/vehicles/distances', {body: req}).then(() => {
          this.handleSearchDiary()
        }).catch(console.error)
      }
    },
    isPhoto: function(v) {
      if (typeof v === 'string') {
        return v.startsWith('Driver_Diary_Images/')
      } else {
        return false
      }
    },
    isArray: function(v) {
      return Array.isArray(v)
    }
  },
  computed: {
    ...mapGetters(['user']),
    groups: function () {
      let out = []
      if (!this.loading) {
        let idx = -1
        let gName = ''
        for (let diary of this.diaryItems) {
          if (this.groupBy === 'Driver') {
            idx = out.findIndex((o) => o.Name === diary.Driver.DriverName)
            gName = diary.Driver.DriverName
          } else if (this.groupBy === 'CustomerID') {
            gName = diary.CustomerID
            idx = out.findIndex((o) => o.Name === diary.CustomerID)
          }
          if (idx < 0) {
            out.push({
              Name: gName,
              TripTrackItems: {
                [diary.TripTrackID || "-"]: {
                  Items: [diary],
                  Distance: diary.Distance
                }
              }
            })
          } else {
            if (!out[idx].TripTrackItems[diary.TripTrackID || "-"]) {
              out[idx].TripTrackItems[diary.TripTrackID || "-"] = {
                Items: [],
                Distance: 0
              }
            }
            out[idx].TripTrackItems[diary.TripTrackID || "-"].Items.push(diary)
            out[idx].TripTrackItems[diary.TripTrackID || "-"].Distance += diary.Distance
          }            
        }
        for (let o of out) {
          for (let t in o.TripTrackItems) {
            o.TripTrackItems[t].Items = o.TripTrackItems[t].Items.sort((a,b) => a.CreatedAt.localeCompare(b.CreatedAt))
          }
        }
      }
      return out
    },
    drivers () {
      let out = []
      for (let o of this.driverItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out.sort((a,b) => a.DriverName.localeCompare(b.DriverName))
    }
  },
  created () {
    this.fetchCustomer()
    this.fetchDriver()
    this.handleSearchDiary()
  }
}
</script>
<style lang="css" scoped>
  .md-layout-item {
    height: 40px;
  }
</style>