const schema = {
  OrderFormSchema: {
    'type': 'object',
    'properties': {
      'PK': {'type': 'string'},
      'SK': {'type': 'string'},
      'OrderID': {'type': 'string'},
      'Date': {'type': 'string'},
      'Customer': {
        'type': 'object',
        'properties': {
          'CustomerID': {'type': 'string'},
          'CustomerName': {'type': 'string'},
          'CustomerLogo': {'type': 'string'},
        },
        'required': ['CustomerID', 'CustomerName']
      },
      'ProductType': {'type': 'string'},
      'JobType': {'type': 'string'},
      'TruckType': {'type': 'string'},
      'ContainerType': {'type': 'string'},
      'LoadPerTruck': {'type': 'integer'},
      'PickerPerTruck': {'type': 'integer'},
      'From': {
        'type': 'object',
        'properties': {
          'Description': {'type': 'string'},
          'GPS': {
            'type': 'object',
            'properties': {
              'Lat': {'type': 'number'},
              'Lon': {'type': 'number'}
            }
          }
        },
        'required': ['Description'],
        'additionalProperties': false
      },
      'To': {
        'type': 'object',
        'properties': {
          'Description': {'type': 'string'},
          'GPS': {
            'type': 'object',
            'properties': {
              'Lat': {'type': 'number'},
              'Lon': {'type': 'number'}
            }
          }
        },
        'required': ['Description'],
        'additionalProperties': false
      },
      'StartDate': {'type': 'string'},
      'StartTime': {'type': 'string'},
      'EndDate': {'type': 'string'},
      'EndTime': {'type': 'string'},
      'CustomerPaidAmount': {'type': 'number'},
      'CustomerBillDate': {'type': 'string'},
      'Remark': {'type': 'string'},
      'CreatedBy': {'type': 'string'},
      'CreatedAt': {'type': 'string'},
      'OrderSheetRef': {'type': 'string'},
      'OrderStatus': {'type': 'string'},
      'TruckRequest': {'type': 'number'},
      'StopNumber': {'type': 'number'},
      'TripRequest': {'type': 'number'},
      'Trip': {
        'type': 'object',
        'properties': {
          'PK': {'type': 'string'},
          'SK': {'type': 'string'},
          'Driver': {
            'type': 'object',
            'properties': {
              'DriverName': {'type': 'string'},
              'DriverID': {'type': 'string'},
              'DriverPhoto': {'type': 'string'},
            },
            'CreatedAt': {'type': 'string'},
            'required': ['DriverName', 'DriverID'],
          },
          'TripID': {'type': 'string'},
          'OrderID': {'type': 'string'},
          'DeliveryCost': {'type': 'number'},
          'DailyPickerCost': {'type': 'number'},
          'OtherCost': {'type': 'number'},
          'SalaryPerTrip': {'type': 'number'},
          'FuelAllowance': {'type': 'number'},
          'DriverPaidDueDate': {'type': 'string'},
          'InfromStopNumber': {'type': 'number'},
          'InformDistance': {'type': 'number'},
          'PickUpActual': {'type': 'number'},
          'UnloadActual': {'type': 'number'},
          'DriverComment': {'type': 'string'},
          'LicensePlate': {'type': 'string'},
          'TruckType': {'type': 'string'},
          'ContainerType': {'type': 'string'},
          'TripStatus': {'type': 'string'},
          'TruckSupporter': {'type': 'string'},
          'OrderSheetRef': {'type': 'string'},
          'AssignSheetRef': {'type': 'string'},
          'CreatedBy': {'type': 'string'},
          'CreatedAt': {'type': 'string'}
         },
         "required": ["PK", "SK", "Driver", "OrderID", "TripID", "TripStatus", "DeliveryCost",
           'LicensePlate', "SalaryPerTrip", 'TruckType', 'ContainerType', "DriverPaidDueDate"],
        'additionalProperties': false
      },
      'FavoriteOrderName': {
        'type': 'string',
        "pattern": /[a-zA-Z0-9-]{1,63}/
      }
    },
    "additionalProperties": false,
    "required": ["Customer", "JobType", "TruckType", "ContainerType", "LoadPerTruck", "CustomerBillDate",
    "PickerPerTruck", "From", "To", "StartDate", "StartTime", "EndDate", "EndTime"]
  },
  OrderDBSchema: {
    'type': 'object',
    'properties': {
      'PK': {'type': 'string'},
      'SK': {'type': 'string'},
      'OrderID': {'type': 'string'},
      'CustomerID': {'type': 'string'},
      'CustomerName': {'type': 'string'},
      'CustomerLogo': {'type': 'string'},
      'Date': {'type': 'string'},
      'JobType': {'type': 'string'},
      'ProductType': {'type': 'string'},
      'TruckType': {'type': 'string'},
      'ContainerType': {'type': 'string'},
      'LoadPerTruck': {'type': 'integer'},
      'TruckRequest': {'type': 'integer'},
      'StopNumber': {'type': 'integer'},
      'TripRequest': {'type': 'integer'},
      'PickerPerTruck': {'type': 'integer'},
      'From': {'type': 'object', 'properties': {
        'Description': {'type': 'string'},
        'GPS': {'type': 'object', 'properties': {
          'Lat': {'type': 'number'},
          'Lon': {'type': 'number'}
        }, "required": ["Lat", "Lon"], "additionalProperties": false},
        "additionalProperties": false
      }},
      'To': {'type': 'object', 'properties': {
        'Description': {'type': 'string'},
        'GPS': {'type': 'object', 'properties': {
          'Lat': {'type': 'number'},
          'Lon': {'type': 'number'}
        }, "required": ["Lat", "Lon"], "additionalProperties": false},
        "additionalProperties": false
      }},
      'StartDateTime': {'type': 'string'},
      'EndDateTime': {'type': 'string'},
      'CustomerPaidAmount': {'type': 'number'},
      'CustomerBillDate': {'type': 'string'},
      'CustomerPaidDueDate': {'type': 'string'},
      'OrderStatus': {'type': 'string'},
      'OrderSheetRef': {'type': 'string'},
      'CreatedBy': {'type': 'string'},
      'CreatedAt': {'type': 'string'},
      'UpdatedAt': {'type': 'string'},
      'Remark': {'type': 'string'}
    },
    "additionalProperties": false,
    "required": ["PK", "SK", "OrderID", "CustomerID", "CustomerName", "Date", "JobType", "ProductType",
    "TruckType", "ContainerType", "LoadPerTruck", "TruckRequest", "StopNumber", "TripRequest",
    "PickerPerTruck", "From", "To", "StartDateTime", "EndDateTime", "OrderStatus", "CreatedBy",
    "CustomerPaidAmount", "CustomerBillDate", 'OrderSheetRef', 'CreatedAt']
  },
  TripDBSchema: {
    'type': 'object',
    'properties': {
      'PK': {'type': 'string'},
      'SK': {'type': 'string'},
      'OrderID': {'type': 'string'},
      'TripID': {'type': 'string'},
      'DriverName': {'type': 'string'},
      'DriverID': {'type': 'string'},
      'DriverPhoto': {'type': 'string'},
      'LicensePlate': {'type': 'string'},
      'DeliveryCost': {'type': 'number'},
      'DailyPickerCost': {'type': 'number'},
      'OtherCost': {'type': 'number'},
      'SalaryPerTrip': {'type': 'number'},
      'FuelAllowance': {'type': 'number'},
      'DriverPaidDueDate': {'type': 'string'},
      'InfromStopNumber': {'type': 'number'},
      'InformDistance': {'type': 'number'},
      'PickUpActual': {'type': 'number'},
      'UnloadActual': {'type': 'number'},
      'TripStatus': {'type': 'string'},
      'DriverComment': {'type': 'string'},
      'TruckType': {'type': 'string'},
      'ContainerType': {'type': 'string'},
      'TruckSupporter': {'type': 'string'},
      'OrderSheetRef': {'type': 'string'},
      'AssignSheetRef': {'type': 'string'},
      'CreatedBy': {'type': 'string'},
      'CreatedAt': {'type': 'string'},
      'UpdatedAt': {'type': 'string'}
    },
    "additionalProperties": false,
    "required": ["PK", "SK", "OrderID", "TripID", "DriverName", "DriverID", "LicensePlate", "TripStatus", "DeliveryCost",
      "SalaryPerTrip", 'TruckType', 'ContainerType', "DriverPaidDueDate", "OrderSheetRef", "AssignSheetRef", "CreatedBy", "CreatedAt"]
  },
  PutOrderStatusSchema: {
    'type': 'object',
    'properties': {
      'OrderStatus': {'type': 'string'}
    },
    'additionalProperties': false,
    "required": ['OrderStatus']
  },
  ConfirmOrderSchema: {
    'type': 'object',
    'properties': {
      'ApprovedBy': {'type': 'string'}
    },
    'additionalProperties': false,
    "required": ['ApprovedBy']
  },
  FavoriteOrderSchema: {
    'type': 'object',
    'properties': {
      'PK': {'type': 'string'},
      'SK': {'type': 'string'},
      'OrderID': {'type': 'string'},
      'FavoriteOrderName':  {'type': 'string'},
      'Date': {'type': 'string'},
      'Customer': {
        'type': 'object',
        'properties': {
          'CustomerID': {'type': 'string'},
          'CustomerName': {'type': 'string'},
          'CustomerLogo': {'type': 'string'}
        },
        'required': ['CustomerID', 'CustomerName']
      },
      'ProductType': {'type': 'string'},
      'JobType': {'type': 'string'},
      'TruckType': {'type': 'string'},
      'ContainerType': {'type': 'string'},
      'LoadPerTruck': {'type': 'integer'},
      'PickerPerTruck': {'type': 'integer'},
      'From': {
        'type': 'object',
        'properties': {
          'Description': {'type': 'string'},
          'GPS': {
            'type': 'object',
            'properties': {
              'Lat': {'type': 'number'},
              'Lon': {'type': 'number'}
            }
          }
        },
        'required': ['Description'],
        'additionalProperties': false
      },
      'To': {
        'type': 'object',
        'properties': {
          'Description': {'type': 'string'},
          'GPS': {
            'type': 'object',
            'properties': {
              'Lat': {'type': 'number'},
              'Lon': {'type': 'number'}
            }
          }
        },
        'required': ['Description'],
        'additionalProperties': false
      },
      'StartDate': {'type': 'string'},
      'StartTime': {'type': 'string'},
      'EndDate': {'type': 'string'},
      'EndTime': {'type': 'string'},
      'CustomerPaidAmount': {'type': 'number'},
      'CustomerBillDate': {'type': 'string'},
      'Remark': {'type': 'string'},
      'CreatedBy': {'type': 'string'},
      'OrderStatus': {'type': 'string'},
      'TruckRequest': {'type': 'number'},
      'StopNumber': {'type': 'number'},
      'TripRequest': {'type': 'number'},
      'Trip': {
        'type': 'object',
        'properties': {
          'PK': {'type': 'string'},
          'SK': {'type': 'string'},
          'Driver': {
            'type': 'object',
            'properties': {
              'DriverName': {'type': 'string'},
              'DriverID': {'type': 'string'},
              'DriverPhoto': {'type': 'string'}
            },
            'required': ['DriverName', 'DriverID'],
          },
          'TripID': {'type': 'string'},
          'OrderID': {'type': 'string'},
          'DeliveryCost': {'type': 'number'},
          'DailyPickerCost': {'type': 'number'},
          'OtherCost': {'type': 'number'},
          'SalaryPerTrip': {'type': 'number'},
          'FuelAllowance': {'type': 'number'},
          'DriverPaidDueDate': {'type': 'string'},
          'InfromStopNumber': {'type': 'number'},
          'InformDistance': {'type': 'number'},
          'PickUpActual': {'type': 'number'},
          'UnloadActual': {'type': 'number'},
          'TripStatus': {'type': 'string'},
          'LicensePlate': {'type': 'string'},
          'TruckType': {'type': 'string'},
          'ContainerType': {'type': 'string'},
          'DriverComment': {'type': 'string'},
          'TruckSupporter': {'type': 'string'}
         },
         "required": ["PK", "SK", "Driver", "OrderID", "TripID", "TripStatus", "DeliveryCost",
           "SalaryPerTrip", 'LicensePlate', 'TruckType', 'ContainerType'],
        'additionalProperties': false
      }
    },
    "additionalProperties": false,
    "required": ["Customer", "JobType", "TruckType", "ContainerType", "LoadPerTruck",
    "PickerPerTruck", "From", "To", "StartDate", "StartTime", "EndDate", "EndTime",
    "CustomerBillDate", "FavoriteOrderName"]
  },
  LocationSchema: {
    'type': 'object',
    'properties': {
      'Description': {'type': 'string'},
      'GPS': {
        'type': 'object',
        'properties': {
          'Lat': {'type': 'number'},
          'Lon': {'type': 'number'}
        },
        'required': ['Lat', 'Lon'],
        'additionalProperties': false
      },
    'required': ['Description'],
    'additionalProperties': false
    }
  },
  OptionSchema: {
    'type': 'object',
    'properties': {
      'Name': {'type': 'string'}
    },
    'required': ['Name']
  },
  DriverPaidFormSchema: {
    'type': 'object',
    'properties': {
      'PK': {'type': 'string'},
      'SK': {'type': 'string'},
      'PDNumber': {'type': 'string'},
      'DriverID': {'type': 'string'},
      'DriverName': {'type': 'string'},
      'DriverPhoneNumber': {'type': 'string', "pattern": /[0-9]{10}/},
      'DriverGroup': {'type': 'string'},
      'DriverTeam': {'type': 'string'},
      'OrderCreatedBy': {'type': 'string'},
      'OrderStartDate': {'type': 'string'},
      'OrderEndDate': {'type': 'string'},
      'CustomerID': {'type': 'string'},
      'CustomerName': {'type': 'string'},
      'TripCostAmount': {'type': 'number'},
      'PickerCostAmount': {'type': 'number'},
      'TollCostAmount': {'type': 'number'},
      'BrokerCostAmount': {'type': 'number'},
      'FuelCostAmount': {'type': 'number'},
      'SalaryCostAmount': {'type': 'number'},
      'OtherCostAmount': {'type': 'number'},
      'OtherCostDescription': {'type': 'string'},
      'TotalCostAmount': {'type': 'number'},
      'WithholdingTaxAmount': {'type': 'number'},
      'DepositAmount': {'type': 'number'},
      'LossClaimAmount': {'type': 'number'},
      'InsurranceAmount': {'type': 'number'},
      'OtherDeductAmount': {'type': 'number'},
      'OtherDeductDescription': {'type': 'string'},
      'TotalDeductAmount': {'type': 'number'},
      'TotalPaidAmount': {'type': 'number', 'minimum': 1},
      'PaidSlipPhoto': {'type': 'string'},
      'PaymentVoucher': {'type': 'string'},
      'PaymentBankName': {'type': 'string'},
      'PaymentBankAccountName': {'type': 'string'},
      'PaymentBankAccountNumber': {'type': 'string'},
      'PaymentBankBranchCode': {'type': 'string'},
      'PaidBy': {'type': 'string'},
      'PaidRemark': {'type': 'string'},
      'CreatedDate': {'type': 'string'},
      'CreatedAt': {'type': 'string'},
      'CreatedBy': {'type': 'string'},
      'NoDiaryPD': {'type': 'number'},
      'Diaries': {
        'type': 'array',
        'items': {'type': 'object'}
      },
      'PaymentRequests': {
        'type': 'array',
        'items': {
          'type': 'object',
          'properties': {
            'PK': {'type': 'string'},
            'SK': {'type': 'string'}
          }
        }
      },
      'PaymentCycles': {
        'type': 'array',
        'items': {'type': 'string'}
      },
      'PVMonth': {'type': 'string'}
    },
    'required': ['PK', 'SK', 'PDNumber', 'PaymentBankAccountName', 'OrderCreatedBy', 'OrderStartDate', 'OrderEndDate',
      'TripCostAmount', 'PickerCostAmount', 'TollCostAmount', 'BrokerCostAmount', 'FuelCostAmount', 'SalaryCostAmount',
      'OtherCostAmount', 'TotalCostAmount', 'WithholdingTaxAmount', 'DepositAmount', 'LossClaimAmount', 'InsurranceAmount',
      'OtherDeductAmount', 'TotalDeductAmount', 'TotalPaidAmount', 'PaidSlipPhoto', 'CreatedDate', 'CreatedAt', 'CreatedBy'
    ],
    'additionalProperties': false
  },
  PaymentVoucherFormSchema: {
    'type': 'object',
    'properties': {
      'PK': {'type': 'string'},
      'SK': {'type': 'string'},
      'PaymentVoucher': {'type': 'string'},
      'DriverPaidKeys': {
        'type': 'array',
        'items': {
          'type': 'object',
          'properties': {
            'PK': {'type': 'string'},
            'SK': {'type': 'string'}
          },
          'required': ['PK', 'SK'],
          'additionalProperties': false
        }
      },
      'CreatedAt': {'type': 'string'},
      'CreatedBy': {'type': 'string'}
    },
    'additionalProperties': false,
    'required': ['PK', 'SK', 'PaymentVoucher', 'DriverPaidKeys']
  },
  InvoiceFormSchema: {
    'type': 'object',
    'properties': {
      'PK': {'type': 'string'},
      'SK': {'type': 'string'},
      'InvoiceNumber': {'type': 'string'},
      'OrderKeys': {
        'type': 'array',
        'items': {
          'type': 'object',
          'properties': {
            'PK': {'type': 'string'},
            'SK': {'type': 'string'}
          },
          'required': ['PK', 'SK'],
          'additionalProperties': false
        }
      },
      'CustomerID': {'type': 'string'},
      'CustomerName': {'type': 'string'},
      'CustomerLogo': {'type': 'string'},
      'CustomerBillDate': {'type': 'string'},
      'CustomerPaidDueDate': {'type': 'string'},
      'InvoiceOriginalTotalAmount': {'type': 'number'},
      'InvoiceFinalTotalAmount': {'type': 'number'},
      'ReceiptNumber': {'type': 'string'},
      'RcceiptActualReceiveAmount': {'type': 'number'},
      'RcceiptTaxAmount': {'type': 'number'},
      'RcceiptOtherFeeAmount': {'type': 'number'},
      'TotalTripCostAmount': {'type': 'number'},
      'FirstStartDate': {'type': 'string'},
      'LastStartDate': {'type': 'string'},
      'CreatedDate': {'type': 'string'},
      'RCVerifyBy': {'type': 'string'},
      'RCVerifyAt': {'type': 'string'},
      'CreatedAt': {'type': 'string'},
      'CreatedBy': {'type': 'string'},
      'InvoiceRemark': {'type': 'string'},
      'ReceiptRemark': {'type': 'string'},
      'RCMonth': {'type': 'string'},
      'JobType': {'type': 'string'}
    },
    'required': ['InvoiceNumber', 'OrderKeys', 'CustomerPaidDueDate', 'CustomerID', 'CustomerName', 'InvoiceOriginalTotalAmount', 'CustomerBillDate',
      'InvoiceFinalTotalAmount', 'TotalTripCostAmount', 'FirstStartDate', 'LastStartDate', 'CreatedDate', 'CreatedAt', 'CreatedBy'],
    'additionalProperties': false
  },
  CustomerSchema: {
    'type': 'object',
    'properties': {
      'CustomerID': {'type': 'string', "pattern": "^[A-Z0-9]{3}$"},
      'CustomerLogo': {'type': 'string'},
      'CustomerName': {'type': 'string'},
      'CustomerAddress': {
        'type': 'object',
        'properties': {
          'Text': {'type': 'string'},
          'GPS': {
            'type': 'object',
            'properties': {
              'Lat': {'type': 'number'},
              'Lon': {'type': 'number'}
            },
            'required': ['Lat', 'Lon'],
            'additionalProperties': false
          }
        },
        'required': ['Text'],
        'additionalProperties': false
      },
      'CustomerBusinessType': {'type': 'string'},
      'CustomerCreditDays': {'type': 'number'},
      'Contacts': {
        'type': 'array',
        'items': {
          'type': 'object',
          'properties': {
            'Name': {'type': 'string'},
            'Position': {'type': 'string'},
            'Phone': {'type': 'string'},
            'Email': {'type': 'string'}
          },
          'required': ['Name'],
          'additionalProperties': false
        }
      },
      'CustomerTaxID': {'type': 'string'},
      'CreatedDate': {'type': 'string'},
      'ShowInDriverDiary': {'type': 'string'}
    },
    'required': ['CustomerID', 'CustomerName', 'CustomerLogo', 'CustomerAddress', 'CustomerBusinessType', 'CustomerCreditDays', 'CreatedDate'],
    'additionalProperties': false
  },
  NewDriverFormSchema: {
    'type': 'object',
    'properties': {
      'DriverID': {'type': 'string'},
      'DriverCitizenID': {'type': 'string'},
      'DriverName': {'type': 'string'},
      'DriverPhoto': {'type': 'string'},
      'DriverBankName': {'type': 'string'},
      'DriverBankAccountName': {'type': 'string'},
      'DriverBankAccountNumber': {'type': 'string'},
      'DriverBankBranchCode': {'type': 'string'},
      'DriverBookBankPhoto': {'type': 'string'},
      'DriverOwnerName': {'type': 'string'},
      'DriverTeam': {'type': 'string'},
      'DriverPhoneNumber': {'type': 'string', "pattern": /[0-9]{10}/},
      'Vehicles': {
        'type': 'array',
        'items': {
          'type': 'object',
          'properties': {
            'VehicleLicenseNumber': {'type': 'string'},
            'VehicleLicenseProvince': {'type': 'string'},
            'VehicleStatus': {'type': 'string'},
            'VehicleGroup': {'type': 'string'},
            'VehicleTruckType': {'type': 'string'},
            'VehicleContainerType': {'type': 'string'},
            'VehicleLoadCapacity': {'type': 'number'},
            'VehicleBrand': {'type': 'string'},
            'VehiclePurchasedYear': {'type': 'number'},
            'VehicleInsurranceAmount': {'type': 'number'},
            'VehicleFuelChargePerKM': {'type': 'number'},
            'VehiclePhoto': {
              'type': 'array',
              'items': {
                'type': 'object',
                'properties': {
                  'Caption': {'type': 'string'},
                  'Path': {'type': 'string'}
                },
                'required': ['Path'],
                'additionalProperties': false
              }
            }
          },
          'required': ['VehicleLicenseNumber', 'VehicleLicenseProvince', 'VehicleTruckType', 'VehicleContainerType'],
          'additionalProperties': false
        }
      }
    },
    'required': ['DriverID', 'DriverCitizenID', 'DriverName', 'DriverPhoneNumber', 'DriverPhoto', 'Vehicles', 'DriverBankName', 'DriverBankAccountNumber'],
    'additionalProperties': false
  },
  DriverFormSchema: {
    'type': 'object',
    'properties': {
      'PK': {'type': 'string'},
      'SK': {'type': 'string'},
      'DriverID': {'type': 'string'},
      'DriverCitizenID': {'type': 'string'},
      'DriverName': {'type': 'string'},
      'DriverAddress': {'type': 'string'},
      'DriverLicenseID': {'type': 'string'},
      'DriverLicenseExpire': {'type': 'string'},
      'DriverLicensePhoto': {'type': 'string'},
      'DriverPhoto': {'type': 'string'},
      'DriverPhoneNumber': {'type': 'string', "pattern": /[0-9]{10}/},
      'DriverEmail': {'type': 'string'},
      'DriverDepositAmount': {'type': 'number'},
      'DriverBankName': {'type': 'string'},
      'DriverBankAccountName': {'type': 'string'},
      'DriverBankAccountNumber': {'type': 'string'},
      'DriverBankBranchCode': {'type': 'string'},
      'DriverBookBankPhoto': {'type': 'string'},
      'DriverOwnerName': {'type': 'string'},
      'DriverTeam': {'type': 'string'},
      'DriverOwnerAddress': {'type': 'string'},
      'DriverOwnerPhoneNumber': {'type': 'string', "pattern": /[0-9]{10}/},
      'DriverOwnerLogo': {'type': 'string'},
      'DriverMonthlySalary': {'type': 'number'},
      'DriverGroup': {'type': 'string'},
      'DriverTags': {'type': 'array', 'items': {'type': 'string'}},
      'LineUserID': {'type': 'string'},
      'RichMenuID': {'type': 'string'},
      'LineMenuStatus': {'type': 'string'},
      'BeginDate': {'type': 'string'},
      'RecruitedBy': {'type': 'string'},
      'Status': {'type': 'string'},
      'ApprovedBy': {'type': 'string'},
      'NoDiaryPD': {'type': 'number'},
      'LastLocation': {'type': 'object'},
      'LastGPSUpdatedAt': {'type': 'string'},
      'Vehicles': {
        'type': 'array',
        'items': {
          'type': 'object',
          'properties': {
            'PK': {'type': 'string'},
            'SK': {'type': 'string'},
            'VehicleLicenseNumber': {'type': 'string'},
            'VehicleLicenseProvince': {'type': 'string'},
            'VehicleStatus': {'type': 'string'},
            'VehicleGroup': {'type': 'string'},
            'VehicleTruckType': {'type': 'string'},
            'VehicleContainerType': {'type': 'string'},
            'VehicleLoadCapacity': {'type': 'number'},
            'VehicleBrand': {'type': 'string'},
            'VehiclePurchasedYear': {'type': 'number'},
            'VehicleInsurranceAmount': {'type': 'number'},
            'VehicleFuelChargePerKM': {'type': 'number'},
            'VehiclePhoto': {
              'type': 'array',
              'items': {
                'type': 'object',
                'properties': {
                  'Caption': {'type': 'string'},
                  'Path': {'type': 'string'}
                },
                'required': ['Path'],
                'additionalProperties': false
              }
            }
          },
          'required': ['PK', 'SK', 'VehicleLicenseNumber', 'VehicleLicenseProvince', 'VehicleTruckType', 'VehicleContainerType'],
          'additionalProperties': false
        }
      }
    },
    'required': ['PK', 'SK', 'DriverID', 'DriverName', 'DriverCitizenID'],
    'additionalProperties': false
  },
  DiaryFormSchema: {
    'type': 'object',
    'properties': {
      'PhoneNumber':  {'type': 'string', "pattern": /[0-9]{10}/},
      'Driver': {'type': 'object'},
      'Vehicle': {'type': 'object'},
      'Photo': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'PhotoAlbum': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'Area': {'type': 'string'},
      'OrderID': {'type': 'string'},
      'FinishOrder': {'type': 'boolean'},
      'Location': {
        'type': 'object',
        'properties': {
          'Lat': {'type': 'number'},
          'Lon': {'type': 'number'}
        },
        'required': ['Lat', 'Lon'],
        'additionalProperties': false
      },
      'Note': {'type': 'string'},
      'StopFor': {'type': 'string'},
      'Customer': {
        'type': 'object',
        'properties': {
          'CustomerID': {'type': 'string'},
          'CustomerName': {'type': 'string'}
        },
        'required': ['CustomerID', 'CustomerName'],
      },
      'JobType': {'type': 'string'},
      'PickUpItemNo': {'type': 'number'},
      'DropOffItemNo': {'type': 'number'}
    },
    'required': ['PhoneNumber', 'Photo', 'Location', 'StopFor'],
  },
  DriverLocationUpdateSchema: {
    'type': 'object',
    'properties': {
      'GPS': {
        'type': 'object',
        'properties': {
          'Lat': {'type': 'number'},
          'Lon': {'type': 'number'}
        },
        'required': ['Lat', 'Lon'],
        'additionalProperties': false
      },
      'Address': {'type': 'string'},
      'Platform': {'type': 'string'}
    }
  },
  ExcelSchema: {
    'type': 'object',
    'properties': {
      'PK': {'type': 'string'},
      'SK': {'type': 'string'},
      'UploadPath': {'type': 'string'},
      'ResultPath': {'type': 'string'},
      'ExcelStatus': {'type': 'string'},
      'CreatedBy': {'type': 'string'},
      'CreatedAt': {'type': 'string'},
      'UpdatedAt': {'type': 'string'}
    },
    'required': ['PK', 'SK', 'UploadPath', 'CreatedBy', 'CreatedAt'],
    'additionalProperties': false
  },
  ExcelExportSchema: {
    'type': 'object',
    'properties': {
      'ORDER#': {
        'type': 'object',
        'properties': {
          's': {'type': 'string', "pattern": /\d{4}-\d{2}-\d{2}/},
          'e': {'type': 'string', "pattern": /\d{4}-\d{2}-\d{2}/}
        },
        'additionalProperties': false
      },
      'PAIDDRIVER#': {
        'type': 'object',
        'properties': {
          'i': {'type': 'array', 'items': {'type': 'string', 'pattern': /PAIDDRIVER#PD\d{6}/}}
        },
        'additionalProperties': false
      },
      'CUSTOMERINVOICE#': {
        'type': 'object',
        'properties': {
          'i': {'type': 'array', 'items': {'type': 'string', 'pattern': /CUSTOMERINVOICE#IV\d{6}/}}
        },
        'additionalProperties': false
      },
      'DIARY#': {
        'type': 'object',
        'properties': {
          'i': {'type': 'array', 'items': {'type': 'string', 'pattern': /DIARY#\d{4}-\d{2}-\d{2}/}}
        },
        'additionalProperties': false
      },
      'CUSTOMER#': {
        'type': 'object',
        'properties': {},
        'additionalProperties': false
      },
      'DRIVER#': {
        'type': 'object',
        'properties': {},
        'additionalProperties': false
      },
      'CreatedBy': {'type': 'string'}
    },
    'required': ['CreatedBy'],
    'additionalProperties': false
  },
  DiaryPatch: {
    'type': 'array',
    'items': {
      'type': 'object',
      'properties': {
        'PK': {'type': 'string'},
        'SK': {'type': 'string'},
        'OrderID': {'type': 'string'}
      },
      'required': ['PK', 'SK', 'OrderID'],
      'additionalProperties': false
    }
  },
  PaymentRequest: {
    'type': 'object',
    'properties': {
      'PK': {'type': 'string', 'pattern': /PaymentRequest#.+/},
      'SK': {'type': 'string', 'pattern': /#METADATA#.+/},
      'OrderOwner': {'type': 'string'},
      'Remark': {'type': 'string'},
      'Cycle': {'type': 'string', 'pattern': /\d{4}-\d{2}-\d+/},
      'PDNumber': {'type': 'string'},
      'Trips': {
        'type': 'array',
        'items': {
          'type': 'object',
          'properties': {
            'OrderDate': {'type': 'string'},
            'Driver': {'type': 'object'},
            'CustomerID': {'type': 'string'},
            'CustomerName': {'type': 'string'},
            'From': {
              'type': 'object',
              'properties': {
                'Description': {'type': 'string'}
              },
              'required': ['Description'],
              'additionalProperties': false
            },
            'To': {
              'type': 'object',
              'properties': {
                'Description': {'type': 'string'}
              },
              'required': ['Description'],
              'additionalProperties': false
            },
            'DeliveryCost': {'type': 'number'},
            'FuelCost': {'type': 'number'},
            'InformDistance': {'type': 'number'},
            'TollParkCost': {'type': 'number'},
            'InformDeliverNumbers': {'type': 'number'},
            'IncentiveCost': {'type': 'number'},
            'SalaryPerTrip': {'type': 'number'},
            'PickerPerTrip': {'type': 'number'},
            'DiaryRecords': {
              'type': 'array',
              'items': {
                'type': 'object',
                'properties': {
                  'PK': {'type': 'string', 'pattern': /DIARY#.+/},
                  'SK': {'type': 'string', 'pattern': /RECORD#.+/},
                },
              'required': ['PK', 'SK'],
              "minItems": 1,
              }
            },
            'TollParkPhoto': {
              'type': 'array',
              'items': {'type': 'string'},
              "maxItems": 5,
            },
            'IncentivePhoto': {
              'type': 'array',
              'items': {'type': 'string'},
              "maxItems": 5,
            },
            'OwnerChecked': {'type': 'boolean'},
            'OrderOwner': {'type': 'string'},
            'CreatedAt': {'type': 'string'},
            'CreatedBy': {'type': 'object'}
          },
          'required': ['OrderDate', 'CustomerID', 'CustomerName', 'From', 'To', 'DeliveryCost', 'FuelCost', 'InformDistance', 'TollParkCost',
            'InformDeliverNumbers', 'IncentiveCost', 'SalaryPerTrip', 'PickerPerTrip', 'DiaryRecords', 'TollParkPhoto', 'IncentivePhoto']
        }
      },
      'CreatedAt': {'type': 'string'},
      'CreatedBy': {'type': 'object'}
    },
    'required': ['OrderOwner', 'Cycle', 'Trips', 'CreatedAt', 'CreatedBy']
  },
  CycleFieldSchema: {
    'type': 'object'
  }
}

module.exports = schema;

