<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-section-start">
        <h3 class="md-title">Export File</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-field>
          <md-file accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @md-change="handleExcelSelect" placeholder="Update File" v-if="!uploading" />
        </md-field>
      </div>
    </md-toolbar>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-20">
        <md-checkbox v-model="exportForm.Order">Order</md-checkbox>
        <md-autocomplete  @input="handleCustomerSelect" :value="exportForm.CustomerID" :md-options="customers">
          <label>Customer</label>
          <template slot="md-autocomplete-item" slot-scope="{ item, term }">
            <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
          </template>

          <template slot="md-autocomplete-empty" slot-scope="{ term }">
            No customer matching "{{ term }}" were found.
          </template>
        </md-autocomplete>
        <md-datepicker v-model="exportForm.OrderStartDate" :md-model-type="String" md-immediately>
          <label>Order Start Date</label>
        </md-datepicker>
        <md-datepicker v-model="exportForm.OrderEndDate" :md-model-type="String" md-immediately>
          <label>Order End Date</label>
        </md-datepicker>
      </div>
      <div class="md-layout-item md-size-20">
        <md-checkbox v-model="exportForm.Payment">Payment</md-checkbox>
        <md-chips v-model="exportForm.PaymentPKs" md-placeholder="PD Prefix"></md-chips>
      </div>
      <div class="md-layout-item md-size-20">
        <md-checkbox v-model="exportForm.Invoice">Invoice</md-checkbox>
        <md-chips v-model="exportForm.InvoicePKs" md-placeholder="IV Prefix"></md-chips>
      </div>
      <div class="md-layout-item md-size-20">
        <md-checkbox v-model="exportForm.Diary">Diary</md-checkbox>
        <md-chips v-model="exportForm.DiaryPKs" md-placeholder="Date"></md-chips>
      </div>
      <div class="md-layout-item md-size-20">
        <div>
          <md-checkbox v-model="exportForm.Customer">Customer</md-checkbox>
        </div>
        <md-divider />
        <div>
          <md-checkbox v-model="exportForm.Driver">Driver</md-checkbox>
        </div>
        <md-divider />
        <div>
          <md-button class="md-dense md-raised md-primary" @click="handleExport">Export</md-button>
          <md-button class="md-dense md-raised" @click="fetchData">Fetch Data</md-button>
        </div>
      </div>
    </div>
    <md-divider />
    <md-table v-model="filteredUpdateItems" md-fixed-header>
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="UploadPath" md-sort-by="UploadPath">
          <a @click="handleDownloadFile(item.UploadPath)">{{ getFileName(item.UploadPath || '') }}</a>
        </md-table-cell>
        <md-table-cell md-label="ResultPath" md-sort-by="ResultPath">
          <a @click="handleDownloadFile(item.ResultPath)">{{ getFileName(item.ResultPath || '') }}</a>
        </md-table-cell>
        <md-table-cell md-label="CreatedBy" md-sort-by="CreatedBy">{{ item.CreatedBy.split('@')[0] }}</md-table-cell>
        <md-table-cell md-label="CreatedAt" md-sort-by="CreatedAt">{{ dateTimeFormatter(item.CreatedAt) }}</md-table-cell>
        <md-table-cell md-label="UpdateedAt" md-sort-by="UpdateedAt">{{ item.UpdatedAt ? dateTimeFormatter(item.UpdatedAt) : '' }}</md-table-cell>
        <md-table-cell md-label="Delete"><md-button @click="handleDeleteExport(item.PK, item.SK)">Delete</md-button></md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'

export default {
  name: 'BatchUpdate',
  data () {
    return {
      updateItems: [],
      customerItems: [],
      uploading: false,
      exportForm: {
        Order: false,
        CustomerID: null,
        OrderStartDate: moment().format('YYYY-MM-DD'),
        OrderEndDate: moment().format('YYYY-MM-DD'),
        Payment: false,
        PaymentPKs: ['PD'+ moment().format('YYYYMM')],
        Invoice: false,
        InvoicePKs: ['IV'+ moment().format('YYYYMM')],
        Customer: false,
        Driver: false,
        Diary: false,
        DiaryPKs: [moment().format('YYYY-MM-DD')]
      },
      formData: {
        'PK': null,
        'SK': null,
        'UploadPath': null,
        'ExcelStatus': null,
        'CreatedBy': null,
        'CreatedAt': null
      }
    }
  },
  methods: {
    fetchData: function (lastEvaluatedKey) {
      let q = ''
      let self = this
      if (lastEvaluatedKey && lastEvaluatedKey.PK && lastEvaluatedKey.SK) {
        q += `?PK=${encodeURIComponent(lastEvaluatedKey.PK)}&SK=${encodeURIComponent(lastEvaluatedKey.SK)}`
      } else {
        this.updateItems = []
      }
      API.get('ezietruckapi', `/api/excels${q}`).then((json) => {
        this.updateItems = [...self.updateItems, ...json.Items].sort((a,b) => b.CreatedAt.localeCompare(a.CreatedAt))
        if (json.LastEvaluatedKey) {
          self.fetchData(json.LastEvaluatedKey)
        }
      })
    },
    handleDownloadFile: function (fileName) {
      API.post('ezietruckapi', `/diary/signedGetURL`, {body: {Filename: fileName}}).then((json) => {
        let downloadElement = document.createElement('a')
        downloadElement.href = json.imageURL
        downloadElement.target = '_blank'
        downloadElement.download = fileName.split('/')[3]
        downloadElement.click()
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY HH:mm")
      } else {
        return ''
      }
    },
    handleDeleteExport: function(pk, sk) {
      let vm = this
      API.del('ezietruckapi', `/api/excels`, {body: {PK: pk, SK: sk}}).then(() => {
        let idx = vm.updateItems.findIndex((u) => u.PK === pk)
        if (idx > -1) {
          vm.updateItems = [...vm.updateItems.slice(0, idx), ...vm.updateItems.slice(idx+1)]
        }
      })
    },
    handleExcelSelect: function (event) {
      let vm = this
      vm.uploading = true
      for (let file of event) {
        let uploadPath = `Excel_Files/Uploads/${moment().format('YYYY-MM-DD')}/${moment().format()}.`+ file.name
        this.$Amplify.Storage.put(uploadPath, file, { level: 'public' }).then(() => {
          API.post('ezietruckapi', `/api/excels`, {body: {
            'PK': `EXCEL#${uploadPath}`,
            'SK': `#METADATA#${uploadPath}`,
            'UploadPath': uploadPath,
            'CreatedBy': vm.user.signInUserSession.idToken.payload.email,
            'CreatedAt': (new Date()).toISOString()
          }}).then(() => {
            vm.uploading = false;
            vm.updateItems = [{
              'PK': `EXCEL#${uploadPath}`,
              'SK': `#METADATA#${uploadPath}`,
              'UploadPath': uploadPath,
              'CreatedBy': vm.user.signInUserSession.idToken.payload.email,
              'CreatedAt': (new Date()).toISOString()
            }, ...vm.updateItems]
          })
        }).catch(console.error)
      }
    },
    handleExport: function () {
      let vm = this
      API.post('ezietruckapi', `/api/excels/export`, {body: this.submitForm}).then(() => {
        vm.updateItems = []
        vm.exportForm = {
          Order: false,
          CustomerID: null,
          OrderStartDate: moment().format('YYYY-MM-DD'),
          OrderEndDate: moment().format('YYYY-MM-DD'),
          Payment: false,
          PaymentPKs: ['PD'+ moment().format('YYYYMM')],
          Invoice: false,
          InvoicePKs: ['IV'+ moment().format('YYYYMM')],
          Customer: false,
          Driver: false,
          Diary: false,
          DiaryPKs: [moment().format('YYYY-MM-DD')]
        }
        vm.fetchData()
      })
    },
    getFileName: function (path) {
      let part = path.split('/')
      if (part.length > 0) {
        return part[part.length - 1]
      } else {
        return ''
      }
    },
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    handleCustomerSelect (value) {
      if (typeof value === 'object') {
        this.exportForm = Object.assign({}, this.exportForm, {
          CustomerID: ((value || {}).CustomerID || null)
        })
      } else {
        if (!value) {
          this.exportForm = Object.assign({}, this.exportForm, {
            CustomerID: null
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    submitForm: function () {
      let form = {'CreatedBy': this.user.signInUserSession.idToken.payload.email}
      if (this.exportForm.Order) {
        form['ORDER#'] = {
          's': this.exportForm.OrderStartDate,
          'e': this.exportForm.OrderEndDate,
          ...(this.exportForm.CustomerID ? {'CustomerID': this.exportForm.CustomerID} : {})
        }
      }
      if (this.exportForm.Payment) {
        form['PAIDDRIVER#'] = {'i': this.exportForm.PaymentPKs.map((p) => 'PAIDDRIVER#' + p)}
      }
      if (this.exportForm.Invoice) {
        form['CUSTOMERINVOICE#'] = {'i': this.exportForm.InvoicePKs.map((i) => 'CUSTOMERINVOICE#' + i)}
      }
      if (this.exportForm.Customer) {
        form['CUSTOMER#'] = {}
      }
      if (this.exportForm.Driver) {
        form['DRIVER#'] = {}
      }
      if (this.exportForm.Diary) {
        form['DIARY#'] = {'i': this.exportForm.DiaryPKs.map((i) => 'DIARY#' + i )}
      }
      return form
    },
    filteredUpdateItems: function () {
      return this.updateItems.slice(0,100)
    },
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
  },
  created () {
    this.fetchData()
    this.fetchCustomer()
  }
}
</script>
