<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-section-start">
        Incomplete Field
      </div>
      <div class="md-toolbar-section-end">
        <md-field>
          <label for="movies">Fields</label>
          <md-select v-model="selectedFields" multiple>
            <md-option v-for="opt in fieldOption" :value="opt" :key="opt">{{opt}}</md-option>
          </md-select>
        </md-field>
      </div>
    </md-toolbar>
    <md-table>
      <md-table-row>
        <md-table-head>ID</md-table-head>
        <md-table-head>Name</md-table-head>
        <md-table-head v-for="field in selectedFields" :key="field">{{field}}</md-table-head>
      </md-table-row>
      <md-table-row v-for="driver in drivers" :key="driver.SK">
        <md-table-cell>
          <router-link :to="'/Drivers/' + driver.DriverID">{{driver.DriverID}}</router-link>
        </md-table-cell>
        <md-table-cell>
          {{driver.DriverName}}
          <img src="../assets/LINE_Brand_icon.png" v-show="driver.LineUserID" width="16" />
        </md-table-cell>
        <md-table-cell v-for="field in selectedFields" :key="field">
          <md-icon v-show="driver.IncompleteFields.includes(field)">cancel</md-icon>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
  </div>
</template>

<script>
import moment from 'moment'
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
// import MyS3Image from '../components/MyS3Image'
import LastLocationMap from '../components/LastLocationMap'
import { getFirestore, getDocs, collection } from "firebase/firestore";

export default {
  name: 'DriverList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {LastLocationMap},
  data () {
    return {
      driverItems: [],
      newDriverItems: [],
      blockDrivers: [],
      LastEvaluatedKey: null,
      showDialog: false,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      interval: null,
      currentDriver: null,
      loading: false,
      mapOption: {
        markerLatLng: null,
      },
      selectedFields: ['DriverName','DriverAddress','DriverLicenseID','DriverLicenseExpire',
        'DriverLicensePhoto','DriverPhoto','DriverPhoneNumber','DriverEmail',
        'DriverDepositAmount','DriverBankName','DriverBankAccountName',
        'DriverBankAccountNumber','DriverBankBranchCode','DriverBookBankPhoto'],
      fieldOption: ['DriverName','DriverAddress','DriverLicenseID','DriverLicenseExpire',
        'DriverLicensePhoto','DriverPhoto','DriverPhoneNumber','DriverEmail',
        'DriverDepositAmount','DriverBankName','DriverBankAccountName',
        'DriverBankAccountNumber','DriverBankBranchCode','DriverBookBankPhoto']
    }
  },
  methods: {
    fetchDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.driverItems = []
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          self.driverItems = [...self.driverItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKey = json.LastEvaluatedKey
          self.fetchDriver(json.LastEvaluatedKey)
        } else {
          self.loading = false
          self.LastEvaluatedKey = null
        }
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("lll")
      } else {
        return ''
      }
    },
  },
  computed: {
    ...mapGetters(['user']),
    drivers: function () {
      let vm = this
      return this.driverItems.filter((d) => d.SK.startsWith('#METADATA#')).map((d) => Object.assign({}, d, {
        IncompleteFields: [...vm.selectedFields.filter((f) => !Object.keys(d).includes(f))]
      }))
    },
  },
  created () {
    this.loading = true
    this.fetchDriver()
    const db = getFirestore();
    getDocs(collection(db, "locations")).then((snapshot) => {
      snapshot.forEach((change) => {
        if (change.type === "modified") {
          let newLocationData = change.doc.data()
          let idx = this.driverItems.findIndex((d) => d.SK.startsWith('#METADATA#') && d.DriverID === newLocationData.SK.split('#')[1])
          if (idx > -1) {
            this.driverItems = [
              ...this.driverItems.slice(0, idx),
              Object.assign({}, this.driverItems[idx], {
                LastLocation: {
                  Address: newLocationData.Address,
                  GPS: newLocationData.GPS
                },
                LastGPSUpdatedAt: (new Date(newLocationData.UpdatedAt.seconds * 1000)).toISOString()
              }),
              ...this.driverItems.slice(idx + 1)
            ]
          }
        }
      });
    });
  }
}
</script>
<style lang="scss" scoped>
  .badge {
    width: 19px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
    font-family: 'Roboto Mono', monospace;
  }
</style>