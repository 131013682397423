<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Look up Table</label>
                <md-select v-model="formData.CurrentLookup" @md-selected="handleCustomerChanged">
                  <md-option v-for="opt in lookupTables" :key="(opt.PK+opt.SK)" :value="(opt.PK+opt.SK)">{{opt.Label}}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label>CustomerID</label>
                <md-select v-model="formData.CustomerID" @md-selected="handleCustomerChanged">
                  <md-option value="---">---</md-option>
                  <md-option v-for="customer in customers" :key="customer.SK" :value="customer.CustomerID">
                    {{customer.CustomerID}} / {{customer.CustomerName}}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label>Job Type</label>
                <md-select v-model="formData.JobType" @click="fetchData()">
                  <md-option value="---">---</md-option>
                  <md-option v-for="jobType in customerJobTypes" :key="jobType.Name" :value="jobType.Name">{{jobType.Name}}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label>Job Subtype</label>
                <md-select v-model="formData.JobSubtype" @click="fetchData()">
                  <md-option value="---">---</md-option>
                  <md-option v-for="subJobType in currentSubJobTypes" :key="subJobType" :value="subJobType">{{ subJobType }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </div>
        <div class="md-toolbar-section-end">
          <md-menu md-direction="bottom-start" v-show="editorTableErrors.messages.length > 0">
            <md-button md-menu-trigger  class="md-icon-button">
              <md-icon>error</md-icon>
            </md-button>
            <md-menu-content>
              <md-menu-item v-for="e in editorTableErrors.messages" :key="e">{{ e }}</md-menu-item>
            </md-menu-content>
          </md-menu>
          <md-button class="md-icon-button" @click="fetchData()">
            <md-icon>refresh</md-icon>
          </md-button>
          <md-button class="md-icon-button" @click="handleSave()" :disabled="editorTableErrors.messages.length > 0">
            <md-icon>save</md-icon>
          </md-button>
        </div>
      </div>
    </md-toolbar>
    <canvas-datagrid :data.prop="editorData" :schema.prop="editorSchema" :style="editorStyle" ref="table"
      :width.prop="$vssWidth" :height.prop="$vssHeight"></canvas-datagrid>
    {{ putItems }} {{ deleteItems }}
    <div class="md-layout">
      <div class="md-layout-item  md-size-10">
        <md-field>
          <label>More rows</label>
          <md-input v-model.number="moreRowForm" type="number"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item  md-size-15 md-alignment-center-left">
        <md-button class="md-button" @click="addRow()">
          Add
        </md-button>
      </div>
    </div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>New Lookup Table</md-dialog-title>
      <md-dialog-content>
        <md-field>
          <label>Type</label>
          <md-select v-model="newLookupTableForm.PK">
            <md-option value="OptionLookup">OptionLookup</md-option>
            <md-option value="PriceLookup">PriceLookup</md-option>
            <md-option value="MapperLookup">MapperLookup</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>Name</label>
          <md-input v-model="newLookupTableForm.SK"></md-input>
        </md-field>
        <md-field>
          <label>Label</label>
          <md-input v-model="newLookupTableForm.Label"></md-input>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">Close</md-button>
        <md-button class="md-primary" @click="handleAddLookupTable">Save</md-button>
      </md-dialog-actions>
    </md-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API, input } from 'aws-amplify'
import VueScreenSize from 'vue-screen-size'

export default {
  name: 'LookupList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data () {
    return {
      customerItems: [],
      customerJobTypes: [],
      customFields: [],
      lookupTables: [],
      optionLookups: [],
      fieldOptionLookup: {},
      driverItems: [],
      priceLookups: [],
      mapperLookups: [],
      formData: {
        CurrentLookup: null,
        CustomerID: '---',
        JobType: '---',
        JobSubtype: '---'
      },
      showDialog: false,
      newLookupTableForm: {
        PK: null,
        SK: null,
        Label: null,
      },
      truckTypeOptions: [],
      containerTypeOptions: [],
      originalData: [],
      editorData: [],
      editorStyle: {},
      moreRowForm: 1,
    }
  },
  methods: {
    fetchLookupTable: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.lookupTables = []
      }
      API.get('ezietruckapi', '/api/lookups' + q).then((json) => {
        if (json['Items']) {
          self.lookupTables = [...self.lookupTables, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchLookupTable(json.LastEvaluatedKey)
        }
      })
    },
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items'].filter((i) => i.SK.startsWith('#METADATA#'))]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchFavJobTypes: function (customerID) {
      let vm = this
      API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`).then((json) => {
        vm.customerJobTypes = json
      })
    },
    fetchCustomField: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.customFields = []
      }
      API.get('ezietruckapi', '/api/customFields'+q).then((json) => {
        if (json['Items']) {
          self.customFields = [...self.customFields, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomField(json.LastEvaluatedKey)
        }
      })
    },
    fetchEnum: function (name) {
      let self = this
      const idx = this.customFields.findIndex((c) => c.Name === name)
      if (idx > -1) {
        if (this.customFields[idx].DataType === 'Select') {
          if (this.customFields[idx].Option) {
            this.fieldOptionLookup[name] = this.customFields[idx].Option
            this.fieldOptionLookup = Object.assign({}, this.fieldOptionLookup)
          } else if (this.customFields[idx].OptionLookup) {
            this.fetchOptionLookup(this.customFields[idx].OptionLookup, '---', '---', '---')
            this.fetchOptionLookup(this.customFields[idx].OptionLookup, this.formData.CustomerID, '---', '---')
            this.fetchOptionLookup(this.customFields[idx].OptionLookup, this.formData.CustomerID, this.formData.JobType, '---')
            this.fetchOptionLookup(this.customFields[idx].OptionLookup, this.formData.CustomerID, this.formData.JobType, this.formData.JobSubtype)
          }
        }
      }
    },
    fetchData: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      let idx = this.lookupTables.findIndex((l) => l.PK+l.SK === this.formData.CurrentLookup)
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.optionLookups = []
        this.priceLookups = []
        this.mapperLookups = []
        this.editorData = []
        this.originalData = []
        this.fieldOptionLookup = {}
      }
      if (idx > -1) {
        for (let o of self.lookupTables[idx].TableSchema.filter((t) => !!t.enum)) {
          if (o.name === 'TruckType') {
            this.fieldOptionLookup['TruckType'] = this.truckTypeOptions.map((t) => [t.Name, t.Label])
          } else if (o.name === 'ContainerType') {
            this.fieldOptionLookup['ContainerType'] = this.containerTypeOptions.map((t) => [t.Name, t.Label])
          } else if (o.name === 'Driver.DriverID') {
            this.fieldOptionLookup['Driver.DriverID'] = this.driverItems.map((t) => [t.DriverID, t.DriverName]).sort((a,b) => a[1].localeCompare(b[1]))
          } else {
            this.fetchEnum(o.name)
          }
        }
        API.get('ezietruckapi', `/api/lookups/${this.lookupTables[idx].PK}/${this.lookupTables[idx].SK}/${this.formData.CustomerID}/${this.formData.JobType}/${this.formData.JobSubtype}${q}`).then((json) => {
          if (self.lookupTables[idx].PK === 'OptionLookup') {
            self.optionLookups = [...self.optionLookups, ...json['Items']]
          } else if (self.lookupTables[idx].PK === 'PriceLookup') {
            self.priceLookups = [...self.priceLookups, ...json['Items']]
          } else if (self.lookupTables[idx].PK === 'MapperLookup') {
            self.mapperLookups = [...self.mapperLookups, ...json['Items']]
          }
          if (self.lookupTables[idx].PK === 'MapperLookup') {
            this.editorData = [...this.editorData, ...json['Items'].map((i) => {
              let o = []
              let oo = {}
              for (let n = 0; n < i.Ranges.length; n++) {
                oo = {
                  'Ranges.Min': i.Ranges[n][0],
                  'Ranges.Max': i.Ranges[n][1],
                  ServiceValues: i.ServiceValues[n],
                  HireValues: i.HireValues[n],
                  isNew: false,
                  isEdited: false,
                }
                for (let k in i) {
                  if (!['Ranges.Min', 'Ranges.Max', 'ServiceValues', 'HireValues', 'isNew', 'isEdited'].includes(k)) {
                    oo[k] = i[k]
                  }
                }
                o.push(Object.assign({}, oo))
              }
              return o
            }).flat()]
            this.originalData = [...this.originalData, ...json['Items']]
          } else {
            this.editorData = [...this.editorData, ...json['Items'].map((i) => Object.assign({}, i, {
              isNew: false,
              isEdited: false,
            }))]
            this.originalData = [...this.originalData, ...json['Items']]
          }
          if (json.LastEvaluatedKey) {
            self.fetchData(json.LastEvaluatedKey)
          }
        })
      }
    },
    fetchOptionLookup: function (name, customerID, jobType, jobSubType, lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', `/api/lookups/OptionLookup/${name}/${customerID}/${jobType}/${jobSubType}${q}`).then((json) => {
        if (!self.fieldOptionLookup[name]) {
          self.fieldOptionLookup[name] = []
        }
        this.fieldOptionLookup[name] = [
          ...self.fieldOptionLookup[name],
          ...json['Items'].filter((i) => !self.fieldOptionLookup[name].map((l) => l[0]).includes(i.Name)).map((l) => [l.Name, l.Label])
        ]
        this.fieldOptionLookup = Object.assign({}, this.fieldOptionLookup)
        if (json.LastEvaluatedKey) {
          self.fetchOptionLookup(name, customerID, jobType, jobSubType, json.LastEvaluatedKey)
        }
      })
    },
    fetchDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.driverItems = []
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          self.driverItems = [...self.driverItems, ...json['Items'].filter((i) => i.SK.startsWith('#METADATA#')).map((i) => ({
            DriverID: i.DriverID,
            DriverName: i.DriverName
          }))]
        }
        if (json.LastEvaluatedKey) {
          self.fetchDriver(json.LastEvaluatedKey)
        }
      })
    },
    handleAddLookupTable: function () {
      API.post('ezietruckapi', `/api/lookups`, {body: this.newLookupTableForm}).then(() => {
        this.newLookupTableForm = {
          PK: null,
          SK: null,
          Label: null,
        }
        this.fetchLookupTable()
        this.showDialog = false
      })
    },
    fetchOptions: function () {
      let self = this
      API.get('ezietruckapi', `/api/options/TruckType`).then((json) => {
        self.truckTypeOptions = json.map((j) => Object.assign({}, j, {
          Label: j.Name
        }))
      })
      API.get('ezietruckapi', `/api/options/ContainerType`).then((json) => {
        self.containerTypeOptions = json.map((j) => Object.assign({}, j, {
          Label: j.Name
        }))
      })
    },
    getDriverName: function (driverID) {
      if (this.hasDriverID) {
        let idx = this.driverItems.findIndex((d) => d.DriverID === driverID)
        if (idx > -1) {
          return this.driverItems[idx].DriverName
        }
      }
      return driverID
    },
    handleSave: function () {
      if (this.editorTableErrors.messages.length === 0) {
        if (this.putItems.length > 0 || this.deleteItems.length > 0) {
          API.put('ezietruckapi', '/api/lookups', {body: {
            PutItems: this.putItems.map((obj) => 
              Object.keys(obj)
                .filter((k) => !!obj[k])
                .reduce((a, k) => ({ ...a, [k]: obj[k] }), {})
            ),
            DeleteItems: this.deleteItems
          }}).then(() => {
            this.fetchData()
          })
        }
      }
    },
    addRow: function () {
      let o = {isNew: true, isEdited: false}
      for (let s of this.editorSchema) {
        if (s.type === 'string') {
          o[s.name] = ''
        } else {
          o[s.name] = null
        }
      }
      for (let i = 0; i < this.moreRowForm; i++) {
        this.editorData.push(Object.assign({}, o))
      }
      this.editorData = [...this.editorData]
      this.$refs.table.draw();
    },
    handleCustomerChanged: function (customerID) {
      this.customerJobTypes = []
      this.editorData = []
      this.fetchFavJobTypes(customerID)
    },
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    currentSubJobTypes: function () {
      if (this.formData.JobType) {
        const idx = this.customerJobTypes.findIndex((j) => j.Name === this.formData.JobType)
        return idx > -1 ? this.customerJobTypes[idx].SubJobType : []
      }
      return []
    },
    formNames: function () {
      if ((this.formData.CurrentLookup || '').includes('PriceLookup')) {
        let idx = this.lookupTables.findIndex((l) => l.PK === 'PriceLookup' && l.SK === this.formData.CurrentLookup.replace('PriceLookup', ''))
        return idx > -1 ? this.lookupTables[idx].Names.filter((n) => n !== 'Driver.DriverID') || [] : []
      } else {
        return []
      }
    },
    hasDriverID: function () {
      if ((this.formData.CurrentLookup || '').includes('PriceLookup')) {
        let idx = this.lookupTables.findIndex((l) => l.PK === 'PriceLookup' && l.SK === this.formData.CurrentLookup.replace('PriceLookup', ''))
        return idx > -1 ? this.lookupTables[idx].Names.includes('Driver.DriverID') : false
      } else {
        return false
      }
    },
    editorSchema () {
      const vm = this
      let out = []
      let idx = this.lookupTables.findIndex((i) => i.PK+i.SK === vm.formData.CurrentLookup)
      if (idx > -1) {
        for (let schema of this.lookupTables[idx].TableSchema) {
          if (schema.enum) {
            out.push(Object.assign({}, schema, {
              enum: vm.fieldOptionLookup[schema.name] || []
            }))
          } else {
            out.push(schema)
          }
        }
      }
      return out
    },
    editorTableEdits () {
      let pksk = []
      let idx = -1
      for (let e of this.items) {
        idx = this.originalData.findIndex((o) => o.SK === e.SK && o.PK === e.PK)
        if (idx > -1 && Object.values(e).join() !== Object.values(this.originalData[idx])) {
          pksk.push(e.PK+e.SK)
        }
      }
      return pksk
    },
    editorTableNews () {
      return this.items.map((i) => i.PK+i.SK).filter(x => !this.originalData.map((o)=> o.PK + o.SK).includes(x))
    },
    editorTableErrors () {
      let out = []
      let idx = []
      let pksk = []
      let sdx = -1
      let edx = -1
      const names = this.editorData.map((d) => d.Name)
      let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
      const dupNames = findDuplicates(names)
      for (let i = 0; i < this.editorData.length; i++) {
        if (this.formData.CurrentLookup.includes('OptionLookup') && dupNames.includes(this.editorData[i].Name)) {
          out.push(`At ${i + 1}: Duplicate name is not allow.`)
          idx.push(i)
          pksk.push(this.editorData[i].PK+this.editorData[i].SK)
        }
        for (let k in this.editorData[i]) {
          sdx = this.editorSchema.findIndex((s) => s.name === k)
          if (sdx > -1 && this.editorSchema[sdx].enum && !this.editorSchema[sdx].enum.map((e) => typeof e === 'object' ? e[0] : e).includes(this.editorData[i][k])) {
            out.push(`At ${i + 1}: ${k} has invalid selected value.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
          if (k === 'Driver.PK') {
            edx = sdx = this.editorSchema.findIndex((s) => s.name === 'Driver.DriverID')
            if (edx > -1 && this.editorSchema[edx].enum && this.editorSchema[edx].enum.findIndex((e) => e[0] === this.editorData[i][k].split('#')[1]) < 0) {
              out.push(`At ${i + 1}: ${k} has invalid Driver PK.`)
              idx.push(i)
              pksk.push(this.editorData[i].PK+this.editorData[i].SK)
            }
          }
          if(sdx > -1 && this.editorSchema[sdx].type === 'number' && typeof this.editorData[i][k] === 'string' && isNaN(+(this.editorData[i][k].replace(',', '')))) {
            out.push(`At ${i + 1}: ${k} has invalid number.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
          if(sdx > -1 && this.editorSchema[sdx].type === 'date' && this.editorData[i][k] && isNaN(Date.parse(this.editorData[i][k]))) {
            out.push(`At ${i + 1}: ${k} has invalid date.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
          if (!['PK', 'SK', 'isEdited', 'isNew'].includes(k) && (this.editorData[i][k]  === null || this.editorData[i][k]  === '')) {
            out.push(`At ${i + 1}: ${k} is required`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
        }
      }
      return {
        idx: idx,
        messages: out,
        pksk: pksk,
      }
    },
    items () {
      const vm = this
      let idx = this.lookupTables.findIndex((i) => i.PK+i.SK === vm.formData.CurrentLookup)
      if (idx > -1 && (this.formData.CurrentLookup || '').includes('MapperLookup')) {
        const sk = this.lookupTables[idx].SK
        let mapper = {}
        for (let d of this.editorData.filter((d) => !vm.editorTableErrors.pksk.includes(d.PK+d.SK))) {
          const name = vm.lookupTables[idx].Names ? vm.lookupTables[idx].Names.map((n) => d[n]).join('') : d.Name
          if (!mapper[name]) {
            mapper[name] = {
              PK: 'MapperLookup',
              SK: `${sk}#${vm.formData.CustomerID}#${vm.formData.JobType}#${vm.formData.JobSubtype}#${name}`,
              Name: name,
              Ranges: [],
              ServiceValues: [],
              HireValues: [],
              isEdited: d.isEdited,
              isNew: d.isNew
            }
            for (let n of vm.lookupTables[idx].Names || []) {
              mapper[name][n] = d[n]
            }
          }
          mapper[name].Ranges.push([
            typeof d['Ranges.Min'] === 'string' ? +d['Ranges.Min'].replace(',', '') : d['Ranges.Min'],
            typeof d['Ranges.Max'] === 'string' ? +d['Ranges.Max'].replace(',', '') : d['Ranges.Max'],
          ])
          mapper[name].ServiceValues.push(typeof d['ServiceValues'] === 'string' ? +d['ServiceValues'].replace(',', '') : d['ServiceValues'])
          mapper[name].HireValues.push(typeof d['HireValues'] === 'string' ? +d['HireValues'].replace(',', '') : d['HireValues'])
        }
        return Object.values(mapper)
      } else if (idx > -1) {
        const sk = this.lookupTables[idx].SK
        return this.editorData.filter((d) => !vm.editorTableErrors.pksk.includes(d.PK+d.SK)).map((d) => {
          const name = vm.lookupTables[idx].Names ? vm.lookupTables[idx].Names.map((n) => d[n]).join('') : d.Name
          let upd = {}
          if (this.formData.CurrentLookup.includes('OptionLookup')) {
            upd['PK'] = 'OptionLookup'
            upd['SK'] = `${sk}#${vm.formData.CustomerID}#${vm.formData.JobType}#${vm.formData.JobSubtype}#${name}`
          } else if (this.formData.CurrentLookup.includes('PriceLookup')) {
            upd['PK'] = 'PriceLookup'
            upd['Name'] = name
            upd['SK'] = `${sk}#${vm.formData.CustomerID}#${vm.formData.JobType}#${vm.formData.JobSubtype}#${name}`
          }
          for (let s of vm.editorSchema) {
            if (s.type === 'number' && typeof d[s.name] === 'string') {
              upd[s.name] = +d[s.name].replace(',', '')
            }
          }
          return Object.assign({}, d, upd)
        })
      } else {
        return []
      }
    },
    putItems () {
      let vm = this
      return this.items.filter((i) => vm.editorTableEdits.includes(i.PK+i.SK) || vm.editorTableNews.includes(i.PK+i.SK))
    },
    deleteItems () {
      const pksk = this.items.map((f) => f.PK+f.SK)
      return this.originalData.filter((o) => !pksk.includes(o.PK+o.SK))
    },
  },
  created () {
    this.fetchLookupTable()
    this.fetchCustomField()
    this.fetchCustomer()
    this.fetchDriver()
    this.fetchOptions()
  },
  mounted () {
    const grid = this.$refs.table
    const vm = this
    const validateEditCellData = function (e) {
      if (e.cell.data.PK && e.cell.data.SK) {
        e.cell.data.isEdited = true;
      } else {
        e.cell.data.isNew = true;
      }
      const names = vm.editorData.map((d) => d.Name)
      const options = []
      for (let o of e.cell.header.enum || []) {
        if (typeof o === 'object') {
          options.push(o[0])
        } else if (typeof o === 'string') {
          options.push(o)
        }
      }
      if (e.cell.header.enum && !options.includes(e.newValue)) {
        alert('Invalid selected value')
        e.preventDefault();
      }
      if (e.cell.header.name === 'Name' && names.includes(e.newValue)) {
        alert('Duplicate name is not allow.')
        e.preventDefault();
      }
      if (e.cell.header.name === 'Driver.PK') {
        const idx = vm.editorSchema.findIndex((s) => s.name === 'Driver.DriverID')
        const driverID = e.newValue.split('#')[1]
        if (idx > -1 && vm.editorSchema[idx].enum.findIndex((num) => num[0] === driverID) > -1) {
          e.cell.data['Driver.DriverID'] = driverID
        } else {
          alert('Invalid Driver PK.')
          e.preventDefault();
        }
      }
      if (vm.formData.CurrentLookup.includes('MapperLookup')) {
        for (let j = 0; j < vm.editorData.length; j++) {
          if (vm.editorData[j].SK === e.cell.data.SK) {
            vm.editorData[j].isEdited = true
          }
        }
      }
    }
    grid.attributes.showFilter = false;
    grid.formatters.number =function (e) {
      return typeof e.cell.value === 'number' ? vm.$currencyFormater(e.cell.value) : e.cell.value;
    };
    grid.formatters.date = function (e) {
      return e.cell.value !== undefined ? e.cell.value : '';
    };
    grid.formatters.string = function (e) {
      if (e.cell.header.enum) {
        let idx = e.cell.header.enum.findIndex((n) => n[0] === e.cell.value)
        if (idx > -1) {
          return e.cell.header.enum[idx][1]
        }
      }
      return e.cell.value !== undefined ? e.cell.value : '';
    };
    grid.filters.date = function (value, filterFor) {
        if (!filterFor) { return true; }
        return value === filterFor;
    };
    grid.sorters.date = function (columnName, direction) {
    var asc = direction === 'asc';
    return function (a, b) {
      const aValue = a[columnName] || '';
      const bValue = b[columnName] || '';
      if (asc) {
        if (!aValue.localeCompare) {
          return 1;
        }
        return aValue.localeCompare(bValue);
      }
      if (!bValue.localeCompare) {
        return 1;
      }
      return bValue.localeCompare(aValue);
    };
  };
    grid.addEventListener('contextmenu', function (e) {
      e.items.splice(0, e.items.length);
      e.items.push({
          title: 'Delete Rows',
          click: function (ev) {
            vm.editorData = [
              ...vm.editorData.slice(0, grid.selectionBounds.top),
              ...vm.editorData.slice(grid.selectionBounds.bottom + 1)
            ]
            grid.draw()
          }
      });
    });
    grid.addEventListener('appendeditinput', function (e) {
      if (e.cell.header.enum) {
        let container = document.createElement('div');
        // container.className = 'autocomplete';
        let ele = document.createElement('input');
        container.appendChild(ele)
        ele.className = 'autocomplete-input';
        ele.onkeydown = (ev)=> {
          if (ev.code === 'Escape' && container && document.getElementById('app').contains(container)) {
            container.remove()
            grid.endEdit(true)
            grid.draw(true)
          }
        }
        ele.style.boxSizing = 'content-box'
        ele.style.outline = 'none'
        ele.style.margin = '0'
        ele.style.padding = '0 0 0 ' + grid.style.editCellPaddingLeft + 'px'
        ele.style.fontFamily = grid.style.editCellFontFamily
        ele.style.fontSize = grid.style.editCellFontSize
        ele.style.boxShadow = grid.style.editCellBoxShadow
        ele.style.border = grid.style.editCellBorder
        ele.style.color = grid.style.editCellColor
        ele.style.backgroundPosition = 'right'
        ele.style.appearance = 'none'
        ele.style.mozAppearance = 'none'
        ele.style.borderRadius = '0'
        let ul = document.createElement('ul');
        container.appendChild(ul);
        ul.className = 'autocomplete-result-list';
        let val = document.createElement('input');
        container.appendChild(val)
        val.hidden = true
        new window.Autocomplete(container, {
          search: (input) => {
            if (input.length < 1) { return e.cell.header.enum }
            return e.cell.header.enum.filter((d) => {
              return d[1].includes(input)
            })
          },
          // from the Wikipedia results
          renderResult: (result, props) => `
            <li ${props}>
              ${result[1]}
            </li>
          `,
          getResultValue: result => result[1],
          onSubmit: result => {
            vm.editorData[e.cell.rowIndex].isEdited = true;
            vm.editorData[e.cell.rowIndex][e.cell.header.name] = result[0];
            if (e.cell.header.name === 'Driver.DriverID') {
              vm.editorData[e.cell.rowIndex]['Driver.PK'] = `DRIVER#${result[0]}`;
            }
            vm.editorData = [...vm.editorData];
            if (container && document.getElementById('app').contains(container)) {
              container.remove();
            }
            grid.draw();
          }
        })
        
        container.style.position = 'absolute'
        container.style.left = e.ctx.canvas.offsetLeft + e.cell.offsetLeft + 'px'
        container.style.top = e.ctx.canvas.offsetTop + e.cell.offsetTop + 'px'
        container.editCell = e.cell
        container.style.zIndex = grid.style.editCellZIndex.zIndex
        container.style.width = e.cell.width + 'px'
        container.style.height = e.cell.height + 'px'
        
        document.getElementById('app').appendChild(container);
        ele.focus();
        e.preventDefault();
      }
    });

    grid.addEventListener('rendercell', function (e) {
      if (vm.editorTableErrors.idx.includes(e.cell.rowIndex)) {
        e.ctx.fillStyle = '#F1948A';
      } else if (vm.originalData.map((o) => o.PK+o.SK).includes(e.cell.data.PK + e.cell.data.SK)) {
        e.ctx.fillStyle = '#AEEDCF';
      } else if (vm.editorTableEdits.includes(e.cell.data.PK + e.cell.data.SK)) {
        e.ctx.fillStyle = '#F9E79F';
      }
    });
    grid.addEventListener('beforeendedit', function (e) {
      if (e.newValue !== e.oldValue) {
        validateEditCellData(e)
      }
    });
    grid.addEventListener('afterpaste', function (e) {
      for (let c of e.cells) {
        if (vm.editorSchema[c[1]].name === 'Driver.PK') {
          grid.data[c[0]]['Driver.DriverID'] = grid.data[c[0]]['Driver.PK'].split('#')[1]
        }
      }
    })
  }
}
</script>
