<template>
  <div>
    <md-toolbar>
      <form @submit.prevent="handleSearch" class="md-toolbar-row md-layout" >
        <div class="md-layout-item">
          <md-datepicker v-model="formData.StartDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
            <label>Start Date</label>
            <span class="md-error" v-if="errors['Date']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item">
          <md-datepicker v-model="formData.EndDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
            <label>End Date</label>
            <span class="md-error" v-if="errors['Date']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item">
          <md-field :class="{'md-invalid': errors['From']}">
            <label for="from">From</label>
            <md-select v-model="formData.From" name="from" id="from">
              <md-option value="PaidSlip">Paid Slip</md-option>
              <md-option value="Diary">Diary</md-option>
              <md-option value="CheckIO">Check In - Out</md-option>
              <md-option value="PaymentRequest">Payment Request</md-option>
            </md-select>
            <span class="md-error" v-if="errors['From']">{{errors['From']}}</span>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-autocomplete @input="handleDriverSelect" :value="formData.DriverName" :md-options="drivers" :class="{'md-invalid': errors['instance.DriverName']}">
            <label>Driver</label>
            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
              <md-highlight-text :md-term="term">{{ item.DriverName }}</md-highlight-text>
            </template>
            <span class="md-error" v-if="errors['instance.DriverName']">Invalid field input</span>
          </md-autocomplete>
        </div>
        <div class="md-layout-item" v-if="formData.From === 'Diary'">
          <md-autocomplete @input="handleCustomerSelect" :value="formData.CustomerName" :md-options="customers"
            :class="{'md-invalid': errors['instance.Customer']}" :disabled="!!$route.params.orderID">
            <label>Customer</label>
            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
              <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
            </template>

            <template slot="md-autocomplete-empty" slot-scope="{ term }">
              No customer matching "{{ term }}" were found.
            </template>
            <span class="md-error" v-if="errors['instance.Customer']">Invalid field input</span>
          </md-autocomplete>
        </div>
        <div class="md-layout-item"  v-show="formData.From === 'PaidSlip'">
          <md-field>
            <label for="PaymentVoucher">Payment Voucher</label>
            <md-input name="PaymentVoucher" id="PaymentVoucher"  v-model="formData.PaymentVoucher" />
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-button type="submit" class="md-raised md-primary">
            <span>Search</span>
          </md-button>
        </div>
      </form>
    </md-toolbar>
    <div class="md-layout">
      <div class="md-layout-item md-size-25 md-elevation-3" v-for="(img, idx) in images" :key="idx + img" style="padding: 4px">
        <my-s3-image :imagePath="img.src"></my-s3-image>
        <span>{{img.alt}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { API } from 'aws-amplify'
import { mapGetters } from 'vuex'
import MyS3Image from '../components/MyS3Image'

export default {
  name: 'PhotoLibrary',
  components: {MyS3Image},
  data () {
    return {
      driverItems: [],
      customerItems: [],
      pdItems: [],
      diaryItems: [],
      checkIOItems: [],
      PaymentCycle: [],
      paymentRequestItems: [],
      errors: {},
      formData: {
        StartDate: moment().format('YYYY-MM-DD'),
        EndDate: moment().format('YYYY-MM-DD'),
        DriverID: null,
        DriverName: null,
        DriverPhoneNumber: null,
        CustomerID: null,
        CustomerName: null,
        PaymentVoucher: null,
        From: null
      }
    }
  },
  methods: {
    fetchConfig: function () {
      let vm = this
      API.get('ezietruckapi', '/api/options/Config').then((json) => {
        let idx = json.findIndex((c) => c.Name.startsWith('PaymentCycle'))
        if (idx > -1) {
          vm.PaymentCycle = json[idx].Name.split(',').slice(1).map((c) => parseInt(c))
        }
      })
    },
    fetchPayment: function (formData, lastEvaluatedKey) {
      let vm = this
      formData['LastEvaluatedKey'] = lastEvaluatedKey
      API.post('ezietruckapi', '/api/paymentVouchers/q', {body: formData}).then((res) => {
        vm.pdItems = [...vm.pdItems, ...(res.Items || [])]
        if (res['LastEvaluatedKey']) {
          vm.fetchPayment(formData, res['LastEvaluatedKey'])
        }
      })
    },
    fetchedDiary: function (selectedDiaryDate, driverPhoneNumber, customerID, lastEvaluatedKey) {
      let q = []
      let vm = this
      if (lastEvaluatedKey && lastEvaluatedKey.SK) {
        q.push(`LastSK=${lastEvaluatedKey.SK.replace(/#/g, '%23')}`)
      }
      if (driverPhoneNumber) {
        q.push(`DriverPhoneNumber=${driverPhoneNumber}`)
      }
      if (customerID) {
        q.push(`CustomerID=${customerID}`)
      }
      API.get('ezietruckapi', `/api/vehicles/diary/${selectedDiaryDate}${q.length > 0 ? '?' + q.join('&') : ''}`).then((json) => {
        vm.diaryItems = [...vm.diaryItems, ...(json.Items || [])]
        if (json.LastEvaluatedKey) {
          vm.fetchedDiary(selectedDiaryDate, driverPhoneNumber, customerID, json.LastEvaluatedKey)
        }
      })
    },
    fetchedCheckIO: function (status, selectedDiaryDate, driverID, lastEvaluatedKey) {
      let q = []
      let vm = this
      if (lastEvaluatedKey) {
        q.push(`lastEvaluatedKey=${encodeURIComponent(lastEvaluatedKey)}`)
      }
      if (driverID) {
        q.push(`DriverID=${driverID}`)
      }
      API.get('ezietruckapi', `/api/checkios/${status}/date/${selectedDiaryDate}${q.length > 0 ? '?' + q.join('&') : ''}`).then((json) => {
        vm.checkIOItems = [...vm.checkIOItems, ...(json.Items || [])]
        if (json.LastEvaluatedKey) {
          vm.fetchedDiary(status, selectedDiaryDate, driverID, json.LastEvaluatedKey)
        }
      })
    },
    fetchDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.driverItems = []
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          self.driverItems = [...self.driverItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchDriver(json.LastEvaluatedKey)
        }
      })
    },
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    handleCustomerSelect (value) {
      if (typeof value === 'object') {
        this.formData.CustomerID = value.CustomerID
        this.formData.CustomerName = value.CustomerName
        this.formData = Object.assign({}, this.formData)
      } else {
        if (!value) {
          this.formData.CustomerID = ''
          this.formData.CustomerName = ''
          this.formData = Object.assign({}, this.formData)
        }
      }
    },
    handleDriverSelect: function (value) {
      if (typeof value === 'object' && value.DriverID) {
        this.formData.DriverID = value.DriverID
        this.formData.DriverName = value.DriverName
        this.formData.DriverPhoneNumber = value.DriverPhoneNumber || null
        this.formData = Object.assign({}, this.formData)
      } else {
        if (!value) {
          this.formData.DriverID = ''
          this.formData.DriverName = '',
          this.formData.DriverPhoneNumber = null
        }
      }
    },
    handleSearch: function () {
      this.pdItems = []
      this.diaryItems = []
      this.paymentRequestItems = []
      this.checkIOItems = []
      if (this.formData.From === 'PaidSlip') {
        this.fetchPayment(this.formData)
      } else if (this.formData.From === 'Diary') {
        let i = moment(this.formData.StartDate)
        while (i <= moment(this.formData.EndDate)) {
          this.fetchedDiary(i.format('YYYY-MM-DD'), this.formData.DriverPhoneNumber, this.formData.CustomerID, null)
          i.add(1, 'days')
        }
      } else if (this.formData.From === 'CheckIO') {
        let i = moment(this.formData.StartDate)
        while (i <= moment(this.formData.EndDate)) {
          this.fetchedCheckIO('1:CheckIn', i.format('YYYY-MM-DD'), this.formData.DriverID, null)
          this.fetchedCheckIO('2:CheckOut', i.format('YYYY-MM-DD'), this.formData.DriverID, null)
          i.add(1, 'days')
        }
      } else if (this.formData.From === 'PaymentRequest') {
        let s = moment(this.formData.StartDate)
        let e = moment(this.formData.EndDate)
        while (s <= e) {
          this.fetchPaymentRequest({OrderDate: s.format('YYYY-MM-DD')})
          s.add(1, 'days')
        }
      } else {
        this.errors['From'] = 'this field is required.'
      }
    },
    fetchPaymentRequest: function (q, lastEvaluatedKey) {
      if (lastEvaluatedKey) {
        q.LastEvaluatedKey = lastEvaluatedKey
      }
      let vm = this
      API.post('ezietruckapi', '/api/paymentRequests/date', {body: q}).then((json) => {
        vm.paymentRequestItems = [...vm.paymentRequestItems, ...json['Items']]
        if (json['LastEvaluatedKey']) {
          vm.fetchPaymentRequest(q, json['LastEvaluatedKey'])
        }
      })
    },
  },
  computed: {
    ...mapGetters(['user']),
    drivers () {
      let out = []
      for (let o of this.driverItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out.sort((a,b) => a.DriverName.localeCompare(b.DriverName))
    },
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    images: function () {
      return [...this.pdItems.filter((p) => p.PaidSlipPhoto).map((p) => {
          return {alt: `${p.DriverName}_${p.CreatedAt}`, src: p.PaidSlipPhoto}
        }),
        ...this.diaryItems.flatMap((d) => [...d.Photo.map((p) => {
            return {alt: `${d.CustomerID}_${d.CreatedAt}`, src: p}
          }), ...d.PhotoAlbum.map((p) => {
            return {alt: `${d.CustomerID}_${d.CreatedAt}`, src: p}
          }), ...Object.keys(d.CustomField || {}).map((k) => {
            return [d.CustomField[k]].flat().filter((v) => typeof v === 'string' && v.startsWith('Driver_Diary_Images')).map((p) => {
              return {alt: `${d.CustomerID}_${d.CreatedAt}_${k}_${Math.random()}`, src: p}
            })
          }).flat()]),
        ...this.checkIOItems.flatMap((d) => [
            {alt: `${d.CustomerID}_${d.StartDateTime}`, src: d.Photo},
            ...Object.keys(d.CustomField || {}).map((k) => {
              return [d.CustomField[k]].flat().filter((v) => typeof v === 'string' && v.startsWith('Driver_Diary_Images')).map((p) => {
                return {alt: `${d.CustomerID}_${d.StartDateTime}_${k}_${Math.random()}`, src: p}
              })
            }).flat()]),
        ...this.paymentRequestItems.filter((p) => p.TollParkPhoto || p.IncentivePhoto).flatMap((d) => [
          ...d.TollParkPhoto.map((p) => {
            return {alt: `${((d.Driver || {}).DriverName || '')}_${d.OrderDate}_${d.CustomerID}_${d.From.Description}_${d.To.Description}`, src: p}
          }),
          ...d.IncentivePhoto.map((p) => {
            return {alt: `${((d.Driver || {}).DriverName || '')}_${d.OrderDate}_${d.CustomerID}_${d.From.Description}_${d.To.Description}`, src: p}
          })])
      ]
    }
  },
  created () {
    this.fetchConfig()
    this.fetchDriver()
    this.fetchCustomer()
  }
}
</script>