<template>
  <div>
    
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start" v-show="!this.$route.params.batchID">
          <md-autocomplete  @input="handleCustomerChanged" :value="formData.CustomerID" :md-options="customers">
            <label>Customer</label>
            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
              <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
            </template>

            <template slot="md-autocomplete-empty" slot-scope="{ term }">
              No customer matching "{{ term }}" were found.
            </template>
          </md-autocomplete>
        </div>
        <div class="md-toolbar-section-end">
          <md-menu md-direction="bottom-start" v-show="editorTableErrors.messages.length > 0">
            <md-button md-menu-trigger  class="md-icon-button">
              <md-icon>error</md-icon>
            </md-button>
            <md-menu-content>
              <md-menu-item v-for="e in editorTableErrors.messages" :key="e">{{ e }}</md-menu-item>
            </md-menu-content>
          </md-menu>
          <md-button class="md-icon-button" @click="handleRefresh()">
            <md-icon>refresh</md-icon>
          </md-button>
          <md-button class="md-icon-button" @click="handleSave()" :disabled="editorTableErrors.messages.length > 0">
            <md-icon>save</md-icon>
          </md-button>
        </div>
      </div>
    </md-toolbar>
    <canvas-datagrid :data.prop="editorData" :schema.prop="editorSchema" :style="editorStyle" ref="table"></canvas-datagrid>
    <div class="md-layout">
      <div class="md-layout-item  md-size-10">
        <md-field>
          <label>More rows</label>
          <md-input v-model.number="moreRowForm" type="number"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item  md-size-15 md-alignment-center-left">
        <md-button class="md-button" @click="addRow()">
          Add
        </md-button>
      </div>
    </div>
    {{ putItems }} {{ editorData }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import VueScreenSize from 'vue-screen-size'
import * as uuid from 'uuid'

export default {
  name: 'NewOrder',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data () {
    return {
      originalData: [],
      customerItems: [],
      jobTypes: [],
      fieldOptionLookup: {},
      customerJobTypes: [],
      customFields: [],
      driverItems: [],
      ratePlans: [],
      truckTypeOptions: [],
      selectedDrivers: [],
      selectedDriverIDs: [],
      diaries: [],
      formData: {
        CustomerID: null,
        JobType: '---',
        JobSubtype: '---'
      },
      currentPage: 1,
      currentPageSize: 2,
      filterUserInput: null,
      orderFieldForms: {},
      diaryFieldForms: {},
      priceLookup: {},
      mapperLookup: {},
      editorData: [],
      editorStyle: {},
      moreRowForm: 1,
    }
  },
  methods: {
    fetchBatchOrder: async function (lastEvaluatedKey) {
      let q = `?beginsWith=${encodeURIComponent('1:NewOrder#' + this.$route.params.batchID)}`
      if (lastEvaluatedKey) {
        q = `&ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      const json = await API.get('ezietruckapi', '/api/newOrders' + q)
      if (json['Items']) {
        this.originalData = [...this.originalData, ...json['Items']]
      }
      if (json.LastEvaluatedKey) {
        await this.fetchCustomer(json.LastEvaluatedKey)
      } else {
        if (this.originalData.length > 0) {
          this.formData = {
            CustomerID: this.originalData[0].CustomerID,
          }
          await this.fetchData();
          this.editorData = [...this.originalData]
        }
      }
    },
    fetchCustomerDateOrder: async function (lastEvaluatedKey) {
      this.formData = {
        CustomerID: this.$route.params.customerID
      }
      let idx = -1
      let jdx = -1
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      const json = await API.get('ezietruckapi', `/api/newOrders/${this.$route.params.customerID}/${this.$route.params.date}${q}`)
      if (json['Items']) {
        this.originalData = [...this.originalData, ...json['Items']]
        this.editorData = [...this.editorData, ...json['Items']]
      }
      if (json.LastEvaluatedKey) {
        await this.fetchCustomerDateOrder(json.LastEvaluatedKey)
      } else {
        this.ratePlans = []
        await this.fetchFavJobTypes(this.formData.CustomerID)
        await this.fetchRatePlans('---', '---', '---')
        await this.fetchRatePlans(this.formData.CustomerID, '---', '---')
        for (let c of this.customerJobTypes) {
          await this.fetchRatePlans(this.formData.CustomerID, c.Name, '---')
          for (let s of c.SubJobType || []) {
            await this.fetchRatePlans(this.formData.CustomerID, c.Name, s)
          }
        }
        for (let o of this.originalData) {
          idx = this.ratePlans.findIndex((r) => r.SK === o.ServiceRatePlan)
          jdx = this.ratePlans.findIndex((r) => r.SK === o.HireRatePlan)
          if (idx > -1) {
            for (let c of this.ratePlans[idx].CustomFields.filter((f)=> f.ShowInDiary.includes('Staff'))) {
              this.fetchEnum(c.Field)
            }
          }
          if (jdx > -1) {
            for (let c of this.ratePlans[jdx].CustomFields.filter((f)=> f.ShowInDiary.includes('Staff'))) {
              this.fetchEnum(c.Field)
            }
          }
        }
      }
    },
    fetchData: async function () {
      this.ratePlans = []
      this.editorData = []
      await this.fetchRatePlans('---', '---', '---')
      await this.fetchRatePlans(this.formData.CustomerID, '---', '---')
      for (let c of this.customerJobTypes) {
        await this.fetchRatePlans(this.formData.CustomerID, c.Name, '---')
        for (let s of c.SubJobType || []) {
          await this.fetchRatePlans(this.formData.CustomerID, c.Name, s)
        }
      }
    },
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items'].filter((i) => i.SK.startsWith('#METADATA#'))]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchFavJobTypes: async function (customerID) {
      const json = await API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`)
      this.customerJobTypes = json
    },
    fetchOptions: function () {
      let vm = this
      API.get('ezietruckapi', `/api/options/TruckType`).then((json) => {
        vm.truckTypeOptions = [...json]
      })
    },
    fetchRatePlans: async function (customerID, jobType, jobSubType, lastEvaluatedKey) {
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      const json = await API.get('ezietruckapi', `/api/ratePlans/${encodeURIComponent(customerID)}/${encodeURIComponent(jobType)}/${encodeURIComponent(jobSubType)}` + q)
      if (json['Items']) {
        this.ratePlans = [...this.ratePlans, ...json['Items']]
      }
      if (json.LastEvaluatedKey) {
        await this.fetchRatePlans(customerID, jobType, jobSubType, json.LastEvaluatedKey)
      }
    },
    fetchDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.driverItems = []
      }
      let idx = -1
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          for (let i of json['Items']) {
            idx = self.driverItems.findIndex((d) => d.PK === i.PK)
            if (i.SK.startsWith('#METADATA#')) {
              self.driverItems = [
                ...self.driverItems,
                idx > 0 ? Object.assign({}, self.driverItems[idx], i) : i
              ]
            } else {
              self.driverItems = [
                ...self.driverItems,
                idx > 0 ? Object.assign({}, self.driverItems[idx], { Vehicle: i }) : {PK: i.PK, Vehicle: i}
              ]
            }
          }
        }
        if (json.LastEvaluatedKey) {
          self.fetchDriver(json.LastEvaluatedKey)
        }
      })
    },
    fetchCustomField: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.customFields = []
      }
      API.get('ezietruckapi', '/api/customFields'+q).then((json) => {
        if (json['Items']) {
          self.customFields = [...self.customFields, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomField(json.LastEvaluatedKey)
        }
      })
    },
    handleRefresh: function () {
      this.editorData = [...this.editorData]
    },
    handleSave: function () {
      if (this.editorTableErrors.messages.length === 0) {
        if (this.putItems.length > 0 || this.deleteItems.length > 0) {
          API.post('ezietruckapi', '/api/newOrders/' + this.formData.CustomerID, {body: {
            PutItems: this.putItems.map((obj) => 
              Object.keys(obj)
                .filter((k) => !!obj[k])
                .reduce((a, k) => ({ ...a, [k]: obj[k] }), {})
            ),
            DeleteItems: this.deleteItems
          }}).then(() => {
            this.$router.push('/OrderDashboard')
          })
        }
      }
    },
    addRow: function () {
      let o = {PK: '', SK: '', isNew: true, isEdited: false}
      for (let s of this.editorSchema) {
        if (s.type === 'string') {
          o[s.name] = ''
        } else {
          o[s.name] = null
        }
      }
      for (let i = 0; i < this.moreRowForm; i++) {
        this.editorData.push(Object.assign({}, o))
      }
      this.editorData = [...this.editorData]
      this.$refs.table.draw();
    },
    handleCustomerChanged: async function (value) {
      this.customerJobTypes = []
      if (typeof value === 'object') {
        this.formData = Object.assign({}, this.formData, {
          CustomerID: ((value || {}).CustomerID || null)
        })
        if (value && value.CustomerID) {
          await this.fetchFavJobTypes(value.CustomerID)
          this.fetchData()
        }
      } else {
        if (!value) {
          this.formData = Object.assign({}, this.formData, {
            CustomerID: null
          })
        }
      }
    },
    fetchOptionLookup: function (name, customerID, jobType, jobSubType, lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      if (!this.fieldOptionLookup[`${name}/${customerID}/${jobType}/${jobSubType}`]) {
        this.fieldOptionLookup[`${name}/${customerID}/${jobType}/${jobSubType}`] = []
      } else if (!lastEvaluatedKey) {
        return;
      }
      API.get('ezietruckapi', `/api/lookups/OptionLookup/${encodeURIComponent(name)}/${encodeURIComponent(customerID)}/${encodeURIComponent(jobType)}/${encodeURIComponent(jobSubType)}${q}`).then((json) => {
        this.fieldOptionLookup[`${name}/${customerID}/${jobType}/${jobSubType}`] = [
          ...self.fieldOptionLookup[`${name}/${customerID}/${jobType}/${jobSubType}`],
          ...json['Items'].filter((i) => !self.fieldOptionLookup[`${name}/${customerID}/${jobType}/${jobSubType}`].map((l) => l[0]).includes(i.Name)).map((l) => [l.Name, l.Label])
        ]
        this.fieldOptionLookup = Object.assign({}, this.fieldOptionLookup)
        if (json.LastEvaluatedKey) {
          self.fetchOptionLookup(name, customerID, jobType, jobSubType, json.LastEvaluatedKey)
        }
      })
    },
    fetchEnum: function (name) {
      let self = this
      const idx = this.customFields.findIndex((c) => c.Name === name)
      if (idx > -1) {
        if (this.customFields[idx].DataType === 'Select') {
          if (this.customFields[idx].Option) {
            this.fieldOptionLookup[name] = this.customFields[idx].Option
            this.fieldOptionLookup = Object.assign({}, this.fieldOptionLookup)
          } else if (this.customFields[idx].OptionLookup) {
            this.fetchOptionLookup(this.customFields[idx].OptionLookup, '---', '---', '---')
            this.fetchOptionLookup(this.customFields[idx].OptionLookup, this.formData.CustomerID, '---', '---')
            for (let data of this.editorData) {
              if (data.JobType) {
                this.fetchOptionLookup(this.customFields[idx].OptionLookup, this.formData.CustomerID, data.JobType, '---')
                if (data.JobSubtype) {
                  this.fetchOptionLookup(this.customFields[idx].OptionLookup, this.formData.CustomerID, data.JobType, data.JobSubtype)
                }
              }
            }
          }
        }
      }
    },
    fillDriver: function () {
      for (let i = 0; i < this.editorData.length; i++) {
        if (this.editorData[i]['Driver.PK'] && this.editorData[i]['Driver.PK'].startsWith('DRIVER#')) {
          this.editorData[i]['Driver.DriverID'] = this.editorData[i]['Driver.PK'].split('#')[1]
        }
      }
      this.editorData = [...this.editorData]
      this.$refs.table.draw()
    }
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    allJobTypes: function () {
      return this.customerJobTypes.length > 0 ? this.customerJobTypes : this.jobTypes
    },
    currentSubJobTypes: function () {
      // if (this.formData.JobType) {
      //   const idx = this.customerJobTypes.findIndex((j) => j.Name === this.formData.JobType)
      //   return idx > -1 ? this.customerJobTypes[idx].SubJobType : []
      // }
      return this.customerJobTypes.map((j) => j.SubJobType).flat().filter((j) => j).filter((value, index, array) => array.indexOf(value) === index);
    },
    editorSchema () {
      const vm = this
      let pdx = -1
      let hdx = -1
      let cdx = -1
      let customF = []
      let customFieldSchema = []
      for (let d of this.editorData) {
        customF = []
        if (d.ServiceRatePlan) {
          pdx = this.ratePlans.findIndex((r) => r.SK === d.ServiceRatePlan)
          customF = [
            ...customF,
            ...(pdx > -1 ? this.ratePlans[pdx].CustomFields : [])
          ]
        }
        if (d.HireRatePlan) {
          hdx = this.ratePlans.findIndex((r) => r.SK === d.HireRatePlan)
          customF = [
            ...customF,
            ...(hdx > -1 ? this.ratePlans[hdx].CustomFields : [])
          ]
        }
        for (let c of customF.filter((f) => f.ShowInDiary.includes('Staff'))) {
          cdx = this.customFields.findIndex((f) => f.Name === c.Field)
          if (!customFieldSchema.map((s) => s.name).includes(c.Field) && cdx > -1) {
            customFieldSchema = [ ...customFieldSchema, Object.assign({}, {
              "name": this.customFields[cdx].Name,
              "title": this.customFields[cdx].Label,
              "type": this.customFields[cdx].DataType === 'Number' ? 'number' : 'string',
              "width": 145
            }, this.customFields[cdx].DataType === 'Select' ? {
              enum: []
            } : {})]
          }
        }
      }
      return [
        {
        "name": "JobType",
        "title": "Job Type",
        "type": "string",
        "width": 145,
        "enum": this.customerJobTypes.map((p) => [p.Name, p.Name])
        },
        {
        "name": "JobSubtype",
        "title": "Job Sub Type",
        "type": "string",
        "width": 145,
        "enum": this.currentSubJobTypes.map((p) => [p, p])
        },
        {
        "name": "StartDate",
        "title": "Start Date",
        "type": "date",
        "width": 145
        },
        {
        "name": "CustomerBillDate",
        "title": "Customer Date Date",
        "type": "date",
        "width": 145
        },
        {
        "name": "ServiceRatePlan",
        "title": "Service Rate Plan",
        "type": "string",
        "enum": this.ratePlans.map((p) => [p.SK, p.Name])
        },
        {
        "name": "HireRatePlan",
        "title": "Hire Rate Plan",
        "type": "string",
        "enum": this.ratePlans.map((p) => [p.SK, p.Name])
        },
        {
        "name": "TruckType",
        "title": "Truck Type",
        "type": "string",
        "width": 130,
        "enum": this.truckTypeOptions.map((t) => [t.Name, t.Name])
        },
        {
          "name": "Remark",
          "title": "Remark",
          "type": "string"
        },
        {
        "enum": this.driverItems.filter((d) => d.SK && (d.Vehicle || {}).PK).map((t) => [t.DriverID || '', t.DriverName || '']).sort((a,b) => a[1].localeCompare(b[1])),
        "name": "Driver.DriverID",
        "title": "Driver Name",
        "type": "string"
        },
        ...customFieldSchema
      ]
    },
    editorTableEdits () {
      let pksk = []
      let idx = -1
      for (let e of this.items) {
        idx = this.originalData.findIndex((o) => o.SK === e.SK && o.PK === e.PK)
        if (idx > -1 && Object.values(e).join() !== Object.values(this.originalData[idx])) {
          pksk.push(e.PK+e.SK)
        }
      }
      return pksk
    },
    editorTableErrors () {
      let out = []
      let idx = []
      let pksk = []
      let sdx = -1
      let edx = -1
      let did = -1
      let driverID = ''
      let vm = this
      for (let i = 0; i < this.editorData.length; i++) {
        for (let k in this.editorData[i]) {
          sdx = this.editorSchema.findIndex((s) => s.name === k)
          // if (this.editorData[i][k] && sdx > -1 && this.editorSchema[sdx].enum && !this.editorSchema[sdx].enum.map((e) => typeof e === 'object' ? e[0] : e).includes(this.editorData[i][k])) {
          //   out.push(`At ${i + 1}: ${k} has invalid selected value.`)
          //   idx.push(i)
          //   pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          // }
          // if (k === 'Driver.PK' && this.editorData[i][k]) {
          //   driverID = this.editorData[i][k].split('#')[1]
          //   if (this.driverItems.findIndex((i) => i.DriverID === driverID) < 0) {
          //     out.push(`At ${i + 1}: ${k} has invalid Driver PK.`)
          //     idx.push(i)
          //     pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          //   }
          // }
          if (k === 'Driver.DriverID' && this.editorData[i][k]) {
            driverID = this.editorData[i][k]
            did = this.driverItems.findIndex((i) => i.DriverID === driverID)
            if (did < 0) {
              out.push(`At ${i + 1}: ${k} has invalid Driver ID.`)
              idx.push(i)
              pksk.push(this.editorData[i].PK+this.editorData[i].SK)
            }
             else if (this.driverItems[did].Vehicle && this.driverItems[did].Vehicle.VehicleTruckType !== this.editorData[i]['TruckType']) {
              out.push(`At ${i + 1}: ${k} has invalid Driver Truck Type.`)
              idx.push(i)
              pksk.push(this.editorData[i].PK+this.editorData[i].SK)
            }
          }
          if (k === 'JobSubtype' && this.editorData[i][k]) {
            let jdx = this.customerJobTypes.findIndex((j) => j.Name === vm.editorData[i]['JobType'])
            if (jdx > -1 && !this.customerJobTypes[jdx].SubJobType.includes(this.editorData[i][k])) {
              out.push(`At ${i + 1}: ${k} has invalid Job Sub Type.`)
              idx.push(i)
              pksk.push(this.editorData[i].PK+this.editorData[i].SK)  
            }
          }
          if(sdx > -1 && this.editorSchema[sdx].type === 'number' && typeof this.editorData[i][k] === 'string' && isNaN(+(this.editorData[i][k].replace(',', '')))) {
            out.push(`At ${i + 1}: ${k} has invalid number.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
          if(sdx > -1 && this.editorSchema[sdx].type === 'date' && this.editorData[i][k] && isNaN(Date.parse(this.editorData[i][k] + 'T00:00:00Z'))) {
            out.push(`At ${i + 1}: ${k} has invalid date.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
          if(sdx > -1 && this.editorSchema[sdx].type === 'time' && this.editorData[i][k] && isNaN(Date.parse('2022-01-01T' + this.editorData[i][k] + ':00Z'))) {
            out.push(`At ${i + 1}: ${k} has invalid time.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
        }
      }
      return {
        idx: idx,
        messages: out,
        pksk: pksk,
      }
    },
    items: function () {
      return []
    },
    putItems: function () {
      let vm = this
      let cid = this.customerItems.findIndex((c) => c.CustomerID === vm.formData.CustomerID)
      let did = -1
      let out = []
      let u = uuid.v4()
      if (this.editorTableErrors.idx.length > 0) {
        return out
      } else {
        for (let d of this.editorData) {
          did = this.driverItems.findIndex((i) => i.DriverID === d['Driver.DriverID'])
          if (d.OrderID) {
            out.push(Object.assign({}, d, {
              PK: 'Order',
              SK: did > -1 ? d.SK.replace('1:Preparing', '2:Waiting') : d.SK,
              CustomerID: this.formData.CustomerID,
              CustomerName: cid > -1 ? this.customerItems[cid].CustomerName : '',
              CustomerLogo: cid > -1 ? this.customerItems[cid].CustomerLogo : '',
              Date: d.StartDate,
              CustomerBillDate: d.CustomerBillDate,
              JobType: d.JobType,
              JobSubtype: d.JobSubtype,
              StartDateTime: d.StartDate +'T00:00:00+07:00',
              // OrderStatus: did > -1 ? '2:Waiting' : '1:Preparing',
              UpdatedAt: new Date().toISOString(),
              ...(did > -1 ? {
                DriverID: this.driverItems[did].DriverID,
                DriverName: this.driverItems[did].DriverName,
                DriverPhoneNumber: this.driverItems[did].DriverPhoneNumber,
                LicensePlate: this.driverItems[did].Vehicle ? `${this.driverItems[did].Vehicle.VehicleLicenseNumber} ${this.driverItems[did].Vehicle.VehicleLicenseProvince}` : '-'
              } : {})
            }))
          } else {
            out.push(Object.assign({}, d, {
              PK: 'Order',
              SK: did > -1 ? `2:Waiting#${u}` : `1:Preparing#${u}`,
              BatchID: u,
              CustomerID: this.formData.CustomerID,
              CustomerName: cid > -1 ? this.customerItems[cid].CustomerName : '',
              CustomerLogo: cid > -1 ? this.customerItems[cid].CustomerLogo : '',
              Date: d.StartDate,
              CustomerBillDate: d.CustomerBillDate,
              JobType: d.JobType,
              JobSubtype: d.JobSubtype,
              StartDateTime: d.StartDate +'T00:00:00+07:00',
              // OrderStatus: did > -1 ? '2:Waiting' : '1:Preparing',
              CreatedBy: this.user.signInUserSession.idToken.payload.email,
              CreatedAt: new Date().toISOString(),
              ...(did > -1 ? {
                DriverID: this.driverItems[did].DriverID,
                DriverName: this.driverItems[did].DriverName,
                DriverPhoneNumber: this.driverItems[did].DriverPhoneNumber,
                LicensePlate: this.driverItems[did].Vehicle ? `${this.driverItems[did].Vehicle.VehicleLicenseNumber} ${this.driverItems[did].Vehicle.VehicleLicenseProvince}` : '-'
              } : {})
            }))
          }
        }
        return out
      }
    },
    deleteItems: function () {
      let out = []
      const pksk = this.originalData.map((f) => f.PK+f.SK)
      for (let d of this.putItems.filter((f)=> f.PK && f.SK)) {
        if (!pksk.includes(d.PK+d.SK)) {
          out.push(d)
        }
      }
      return out
    }
  },
  mounted() {
    this.fetchCustomField()
    this.fetchCustomer()
    this.fetchOptions()
    this.fetchDriver()
    if (this.$route.params.batchID) {
      this.fetchBatchOrder()
    }
    if (this.$route.params.customerID && this.$route.params.date){
      this.fetchCustomerDateOrder()
    }
    const grid = this.$refs.table
    const vm = this
    grid.style.width = '100%'
    grid.style.height = (this.$vssHeight - 280) + 'px'
    const validateEditCellData = function (e) {
      // if (e.cell.header.name === 'Driver.PK') {
      //   e.cell.data['Driver.DriverID'] = e.newValue.split('#')[1]
      // }
    }
    grid.attributes.showFilter = false;
    grid.formatters.number =function (e) {
      return typeof e.cell.value === 'number' ? vm.$currencyFormater(e.cell.value) : e.cell.value;
    };
    grid.formatters.date = function (e) {
      return e.cell.value !== undefined ? e.cell.value : '';
    };
    grid.formatters.string = function (e) {
      if (e.cell.header.enum) {
        let idx = e.cell.header.enum.findIndex((n) => n[0] === e.cell.value)
        if (idx > -1) {
          return e.cell.header.enum[idx][1]
        }
      }
      return e.cell.value !== undefined ? e.cell.value : '';
    };
    grid.filters.date = function (value, filterFor) {
        if (!filterFor) { return true; }
        return value === filterFor;
    };
    grid.sorters.date = function (columnName, direction) {
      var asc = direction === 'asc';
      return function (a, b) {
        const aValue = a[columnName] || '';
        const bValue = b[columnName] || '';
        if (asc) {
          if (!aValue.localeCompare) {
            return 1;
          }
          return aValue.localeCompare(bValue);
        }
        if (!bValue.localeCompare) {
          return 1;
        }
        return bValue.localeCompare(aValue);
      };
    };
    grid.formatters.time = function (e) {
      return e.cell.value !== undefined ? e.cell.value : '';
    };
    grid.filters.time = function (value, filterFor) {
        if (!filterFor) { return true; }
        return value === filterFor;
    };
    grid.sorters.time = function (columnName, direction) {
      var asc = direction === 'asc';
      return function (a, b) {
        const aValue = a[columnName] || '';
        const bValue = b[columnName] || '';
        if (asc) {
          if (!aValue.localeCompare) {
            return 1;
          }
          return aValue.localeCompare(bValue);
        }
        if (!bValue.localeCompare) {
          return 1;
        }
        return bValue.localeCompare(aValue);
      };
    };
    grid.addEventListener('contextmenu', function (e) {
      e.items.splice(0, e.items.length);
      e.items.push({
          title: 'Delete Rows',
          click: function (ev) {
            vm.editorData = [
              ...vm.editorData.slice(0, grid.selectionBounds.top),
              ...vm.editorData.slice(grid.selectionBounds.bottom + 1)
            ]
            grid.draw()
          }
      });
    });
    grid.addEventListener('appendeditinput', function (e) {
      if (e.cell.header.enum) {
        let container = document.createElement('div');
        // container.className = 'autocomplete';
        let ele = document.createElement('input');
        container.appendChild(ele)
        ele.className = 'autocomplete-input';
        ele.onkeydown = (ev)=> {
          if (ev.code === 'Escape' && container && document.getElementById('app').contains(container)) {
            container.remove()
            // grid.endEdit(true)
            // grid.draw(true)
          }
        }
        ele.style.boxSizing = 'content-box'
        ele.style.outline = 'none'
        ele.style.margin = '0'
        ele.style.padding = '0 0 0 ' + grid.style.editCellPaddingLeft + 'px'
        ele.style.fontFamily = grid.style.editCellFontFamily
        ele.style.fontSize = grid.style.editCellFontSize
        ele.style.boxShadow = grid.style.editCellBoxShadow
        ele.style.border = grid.style.editCellBorder
        ele.style.color = grid.style.editCellColor
        ele.style.backgroundPosition = 'right'
        ele.style.appearance = 'none'
        ele.style.mozAppearance = 'none'
        ele.style.borderRadius = '0'
        let ul = document.createElement('ul');
        container.appendChild(ul);
        ul.className = 'autocomplete-result-list';
        let val = document.createElement('input');
        container.appendChild(val)
        val.hidden = true
        const options = [
          ...e.cell.header.enum,
          ...(vm.fieldOptionLookup[`${e.cell.header.name}/${vm.formData.CustomerID}/---/---`] || []),
          ...(vm.editorData[e.cell.rowIndex].JobType ? vm.fieldOptionLookup[`${e.cell.header.name}/${vm.formData.CustomerID}/${vm.editorData[e.cell.rowIndex].JobType}/---`] || [] : []),
          ...(vm.editorData[e.cell.rowIndex].JobType && vm.editorData[e.cell.rowIndex].JobSubtype ? vm.fieldOptionLookup[`${e.cell.header.name}/${vm.formData.CustomerID}/${vm.editorData[e.cell.rowIndex].JobType}/${vm.editorData[e.cell.rowIndex].JobSubtype}`] || [] : [])
        ]
        new window.Autocomplete(container, {
          search: (input) => {
            if (input.length < 1) { return options }
            return options.filter((d) => {
              return d[1].includes(input)
            })
          },
          // from the Wikipedia results
          renderResult: (result, props) => `
            <li ${props}>
              ${result[1]}
            </li>
          `,
          getResultValue: result => result[1],
          onSubmit: result => {
            vm.editorData[e.cell.rowIndex].isEdited = true;
            vm.editorData[e.cell.rowIndex][e.cell.header.name] = result[0];
            // if (e.cell.header.name === 'Driver.DriverID') {
            //   vm.editorData[e.cell.rowIndex]['Driver.PK'] = `DRIVER#${result[0]}`;
            // }
            if (result[0] && e.cell.header.name === 'ServiceRatePlan' || e.cell.header.name === 'HireRatePlan') {
              let idx = vm.ratePlans.findIndex((r) => r.SK === result[0])
              if (idx > -1) {
                for (let c of vm.ratePlans[idx].CustomFields.filter((f)=> f.ShowInDiary.includes('Staff'))) {
                  vm.fetchEnum(c.Field)
                }
              }
            }
            vm.editorData[e.cell.rowIndex] = Object.assign({}, vm.editorData[e.cell.rowIndex])
            vm.editorData = [...vm.editorData];
            if (container && document.getElementById('app').contains(container)) {
              container.remove();
            }
            grid.draw();
          }
        })
        
        container.style.position = 'absolute'
        container.style.left = e.ctx.canvas.offsetLeft + e.cell.offsetLeft + 'px'
        container.style.top = e.ctx.canvas.offsetTop + e.cell.offsetTop + 'px'
        container.editCell = e.cell
        container.style.zIndex = grid.style.editCellZIndex.zIndex
        container.style.width = e.cell.width + 'px'
        container.style.height = e.cell.height + 'px'
        
        document.getElementById('app').appendChild(container);
        ele.focus();
        e.preventDefault();
      }
    });

    grid.addEventListener('rendercell', function (e) {
      if (vm.editorTableErrors.idx.includes(e.cell.rowIndex)) {
        e.ctx.fillStyle = '#F1948A';
      } else if ((e.cell.data.PK === '' && e.cell.data.SK === '') || vm.originalData.map((o) => o.PK+o.SK).includes(e.cell.data.PK + e.cell.data.SK)) {
        e.ctx.fillStyle = '#AEEDCF';
      } else if (vm.editorTableEdits.includes(e.cell.data.PK + e.cell.data.SK)) {
        e.ctx.fillStyle = '#F9E79F';
      }
    });
    grid.addEventListener('beforeendedit', function (e) {
      if (e.newValue !== e.oldValue) {
        validateEditCellData(e)
      }
    });
    // grid.addEventListener('afterpaste', function (e) {
    //   for (let c of e.cells) {
    //     if (vm.editorSchema[c[1]].name === 'Driver.PK') {
    //       grid.data[c[0]]['Driver.DriverID'] = grid.data[c[0]]['Driver.PK'].split('#')[1]
    //     }
    //   }
    // })
  },
}
</script>
