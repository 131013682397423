<template>
  <div>
    <md-toolbar  :class="{'highlight-field': true}">
      <div class="md-toolbar-section-start">
        <h3 class="md-subtitle">{{group.title}} ({{$currencyFormater(group.sum)}})</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button class="md-icon-button" v-show="!isShowTable" @click="isShowTable=true">
          <md-icon>expand_less</md-icon>
        </md-button>
        <md-button class="md-icon-button" v-show="isShowTable" @click="isShowTable=false">
          <md-icon>expand_more</md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <md-table v-model="sortedItems"  @md-selected="onSelect($event)" v-if="isShowTable" >
      <md-table-toolbar slot="md-table-alternate-header" slot-scope="{}">
        <div class="md-toolbar-section-start">
          <md-field :class="{'md-invalid': errors['instance.PaymentVoucher']}">
            <label>PaymentVoucher</label>
            <md-input v-model="formData.PaymentVoucher"></md-input>
            <span class="md-error" v-if="errors['instance.PaymentVoucher']">Invalid field input</span>
          </md-field>
          <md-button @click="handleMakePV">
            Save
          </md-button>
        </div>
        <div class="md-toolbar-section-end">
          <md-button @click="handleDeletePD">
            Delete
          </md-button>
        </div>
      </md-table-toolbar>
      <md-table-row  slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" :class="{'highlight-field': !item.Diaries || item.Diaries.length < 1 }" md-auto-select :md-disabled="currentSelected && currentSelected !== group.title">
        <md-table-cell md-label="PDNumber"><router-link :to="'/Payments/' + item.PDNumber">{{ item.PDNumber }}</router-link></md-table-cell>
        <md-table-cell md-label="Creaed Date">{{ item.CreatedDate }}</md-table-cell>
        <md-table-cell md-label="Driver">{{ item.DriverName }}</md-table-cell>
        <md-table-cell md-label="Order Owner">{{ item.OrderCreatedBy.split('@')[0] }}</md-table-cell>
        <md-table-cell md-label="Order Start Date">{{ item.OrderStartDate }}</md-table-cell>
        <md-table-cell md-label="Order End Date">{{ item.OrderEndDate }}</md-table-cell>
        <md-table-cell md-label="Total Paid Amount">{{ item.TotalPaidAmount }}</md-table-cell>
        <md-table-cell md-label="Diary Records">{{ item.Diaries ? item.Diaries.length : 0 }}</md-table-cell>
      </md-table-row>
    </md-table>
    <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackBarMsg}}</span>
    </md-snackbar>
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'PDItemTable',
  props: {
    group: Object,
    currentSelected: String
  },
  data () {
    return {
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      isShowTable: false,
      selectedPD: [],
      errors: {},
      formData: {
        PK: null,
        SK: null,
        PaymentVoucher: null,
        DriverPaidKeys: [],
        CreatedAt: null,
        CreatedBy: null
      },
    }
  },
  methods: {
    onSelect (items) {
      this.selectedPD = items
      if (items.length > 0) {
        this.$emit('select', this.group.title)
        let pvNumber = `PV${moment().format('YYYYMM')}`
        API.get('ezietruckapi', `/api/options/LastPaymentVoucher`).then((json) => {
          if (json.length > 0 && json[0].Name.slice(2, 8) === moment().format('YYYYMM')) {
            let s = `${parseInt(json[0].Name.slice(8)) + 1}`
            while (s.length < 3) s = "0" + s
            pvNumber += s
          } else {
            pvNumber += '001'
          }
          this.formData = {
            PK: `PAYMENTVOUCHER#${pvNumber.slice(0,8)}`,
            SK: `#METADATA#${pvNumber}`,
            PaymentVoucher: pvNumber,
            DriverPaidKeys: items.map((i) => {
              return {PK: `PAIDDRIVER#${i.PDNumber.slice(0,8)}`, SK:  `#METADATA#${i.PDNumber}`}
            }),
            CreatedAt:(new Date()).toISOString(),
            CreatedBy: this.user.signInUserSession.idToken.payload.email
          }
        })
      } else {
        this.$emit('select', null)
        this.formData = {
          PK: null,
          SK: null,
          PaymentVoucher: null,
          DriverPaidKeys: [],
          CreatedAt: null,
          CreatedBy: null
        }
      }
    },
    handleMakePV: function () {
      let self = this
      API.get('ezietruckapi', `/api/paymentVouchers/${this.formData.PaymentVoucher}`).then((data) => {
        if(data && data.Items && data.Items.length > 0) {
          self.showSnackbar = true
          self.snackBarMsg = 'PaymentVoucher already exists'
          self.hideSubmit = false
        } else {
          API.post('ezietruckapi', '/api/paymentVouchers', {body: Object.assign({}, self.formData, {PK: `PAYMENTVOUCHER#${self.formData.PaymentVoucher.slice(0,8)}`, SK: `#METADATA#${self.formData.PaymentVoucher}`})}).then(() => {
            self.$emit('pvcreated', self.formData.DriverPaidKeys)
            self.showSnackbar = true
            self.snackBarMsg = 'Successfully saved'
            self.hideSubmit = false
          })
        }
      })
    },
    handleDeletePD: function () {
      let promises = []
      let keys = []
      let self = this
      for (let pd of this.selectedPD) {
        keys.push(pd)
        promises.push(API.del('ezietruckapi', `/api/paidDrivers/${pd.SK.split('#')[2]}`))
      }
      Promise.all(promises).then(() => self.$emit('pddeleted', keys))
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    sortedItems: function () {
      return [...this.group.items].sort((a,b) => a.PDNumber.localeCompare(b.PDNumber))
    }
  }
}
</script>
<style type="text/css" scoped="">
  .md-toolbar.highlight-field {
    background: #ffde03
  }
  .highlight-field {
    background: #ffde03
  }
</style>