<template>
  <div>
    <md-dialog :md-active.sync="showMapDialog">
      <md-dialog-title>Location</md-dialog-title>
      <LastLocationMap :truck-lat-lng="mapOption.markerLatLng" :style="{'height': ($vssHeight - 20) + 'px', 'width': ($vssWidth *0.3) + 'px'}"/>
    </md-dialog>
    <md-dialog :md-active.sync="showPhotoDialog">
      <md-dialog-title>Photo</md-dialog-title>
      <div style="overflow: auto">
        <my-s3-image :imagePath="currentPhoto"></my-s3-image>
      </div>
    </md-dialog>
    <md-toolbar>
      <form @submit.prevent="handleSearchCheckIO" class="md-toolbar-row md-layout" >
        <div class="md-layout-item">
          <md-datepicker v-model="formData.StartDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
            <label>Start Date</label>
            <span class="md-error" v-if="errors['Date']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item">
          <md-datepicker v-model="formData.EndDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
            <label>End Date</label>
            <span class="md-error" v-if="errors['Date']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item">
          <md-button :disabled="!!LastEvaluatedKeyCheckIn && !!LastEvaluatedKeyCheckOut" type="submit" class="md-raised md-primary">
            <span>Search</span>
          </md-button>
          <md-button :disabled="!!LastEvaluatedKeyCheckIn && !!LastEvaluatedKeyCheckOut" @click="handleDownloadAllCSV">
            Download All
          </md-button>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="groupBy">Group By</label>
            <md-select v-model="groupBy" name="groupBy" id="groupBy">
              <md-option value="CustomerID">CustomerID</md-option>
              <md-option value="Driver">Driver</md-option>
            </md-select>
          </md-field>
        </div>
      </form>
    </md-toolbar>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <div v-for="group in groups" :key="group.Name">
      <md-toolbar class="md-primary">
        <div class="md-toolbar-section-start">
          {{group.Name}}
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-icon-button" :disabled="!!LastEvaluatedKey" @click="handleDownloadCSV([...group.CheckInItems, ...group.CheckOutItems])">
            <md-icon>get_app</md-icon>
          </md-button>
        </div>
      </md-toolbar>
      <div>
        <md-table style="width: 100%" v-model="group.CheckInItems">
          <md-table-toolbar style="background-color: gray">
            <div class="md-toolbar-section-start">
              <h3 class="md-subtitle">Check In</h3>
            </div>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }" >
            <md-table-cell md-label="Driver ID">{{item['Driver']['DriverID']}}</md-table-cell>
            <md-table-cell md-label="Driver Name">{{item['Driver']['DriverName']}}</md-table-cell>
            <md-table-cell md-label="Date Time">{{datetimeFormater(item['StartDateTime'])}}</md-table-cell>
            <md-table-cell md-label="Location">
              <a @click="mapFocusAt(item.Location)">
                {{item['Address']}}
              </a>
            </md-table-cell>
            <md-table-cell md-label="Photo">
              <md-button class="md-icon-button" @click="openPhotoDialog(item['Photo'])" v-show="(item['Photo'] || []).length > 0">
                <md-icon>image</md-icon>
              </md-button>
            </md-table-cell>
            <md-table-cell md-label="Custom Field">
              <custom-field :fields="item.CustomField" @photo="openPhotoDialog"></custom-field>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <md-table style="width: 100%" v-model="group.CheckOutItems">
          <md-table-toolbar>
            <md-table-toolbar style="background-color: gray">
              <div class="md-toolbar-section-start">
                <h3 class="md-subtitle">Check Out</h3>
              </div>
            </md-table-toolbar>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }" >
            <md-table-cell md-label="Driver ID">{{item['Driver']['DriverID']}}</md-table-cell>
            <md-table-cell md-label="Driver Name">{{item['Driver']['DriverName']}}</md-table-cell>
            <md-table-cell md-label="Date Time">{{datetimeFormater(item['StartDateTime'])}}</md-table-cell>
            <md-table-cell md-label="Location">
              <a @click="mapFocusAt(item.Location)">
                {{item['Address']}}
              </a>
            </md-table-cell>
            <md-table-cell md-label="Photo">
              <md-button class="md-icon-button" @click="openPhotoDialog(item['Photo'])" v-show="(item['Photo'] || []).length > 0">
                <md-icon>image</md-icon>
              </md-button>
            </md-table-cell>
            <md-table-cell md-label="Custom Field">
              <custom-field :fields="item.CustomField" @photo="openPhotoDialog"></custom-field>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'
import MyS3Image from '../components/MyS3Image'
import LastLocationMap from '../components/LastLocationMap'
import CustomField from '../components/CustomField'
import { latLng } from "leaflet"

export default {
  name: 'DriverCheckInReport',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image, LastLocationMap, CustomField},
  data () {
    return {
      driverItems: [],
      formData: {
        StartDate: moment().format('YYYY-MM-DD'),
        EndDate: moment().format('YYYY-MM-DD'),
        DriverID: null,
        DriverName: null,
        DriverPhoneNumber: null
      },
      errors: {
        Date: null
      },
      showMapDialog: false,
      showPhotoDialog: false,
      currentPhoto: null,
      checkInItems: [],
      checkOutItems: [],
      loading: false,
      LastEvaluatedKeyCheckIn: null,
      LastEvaluatedKeyCheckOut: null,
      groupBy: 'CustomerID',
      mapOption: {
        zoom: 13,
        center: latLng(13.75318, 100.53173),
        url: 'https://ms.longdo.com/mmmap/tile.php?zoom={z}&x={x}&y={y}&key=c146605c52087ac63385d5db545fb84c&proj=epsg3857&HD=1',
        attribution:
          '© Longdo Map',
        markerLatLng: null,
        options: {
          zoomSnap: 0.5
        },
        showMap: true
      }
    }
  },
  methods: {
    handleFetchCheckIO: function () {
      this.checkInItems = []
      this.checkOutItems = []
      let d = new Date(this.formData.Date)
      for (let s = -2; s < 3; s++) {
        d = new Date((new Date(this.formData.Date)).getTime() + s*24*60*60*1000)
        this.handleFetchCheckIn(`${new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)}-${new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)}-${new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)}`)
        this.handleFetchCheckOut(`${new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)}-${new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)}-${new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)}`)
      }
    },
    handleSearchCheckIO: function () {
      let i = new Date(this.formData.StartDate + 'T00:00:00+0700')
      let eDate = new Date(this.formData.EndDate + 'T23:59:59.999+0700')
      this.checkInItems = []
      this.checkOutItems = []
      while (i <= eDate) {
        this.handleFetchCheckIn(i.toISOString().split('T')[0], null)
        this.handleFetchCheckOut(i.toISOString().split('T')[0], null)
        i = new Date(+i+(24*60*60*1000)-1)
      }
    },
    handleFetchCheckIn: function (date, lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.checkInItems = []
      }
      this.loading = true
      let sDate = new Date(this.formData.StartDate + 'T00:00:00+0700').toISOString()
      let eDate = new Date(this.formData.EndDate + 'T23:59:59.999+0700').toISOString()
      API.get('ezietruckapi', `/api/checkios/1:CheckIn/date/${date}${q}`).then((json) => {
        if (json['Items']) {
          self.checkInItems = [...self.checkInItems, ...json['Items'].filter((i) => {
            return i['StartDateTime'] <= eDate && i['StartDateTime'] >= sDate
          })]
        }
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKeyCheckIn = json.LastEvaluatedKey
          self.handleFetchCheckIn(date, json.LastEvaluatedKey)
        } else {
          self.LastEvaluatedKeyCheckIn = null
        }
        self.loading = false
      })
    },
    handleFetchCheckOut: function (date, lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.checkOutItems = []
      }
      this.loading = true
      let sDate = new Date(this.formData.StartDate + 'T00:00:00+0700').toISOString()
      let eDate = new Date(this.formData.EndDate + 'T23:59:59.999+0700').toISOString()
      API.get('ezietruckapi', `/api/checkios/2:CheckOut/date/${date}${q}`).then((json) => {
        if (json['Items']) {
          self.checkOutItems = [...self.checkOutItems, ...json['Items'].filter((i) => {
            return i['StartDateTime'] <= eDate && i['StartDateTime'] >= sDate
          })]
        }
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKey = json.LastEvaluatedKey
          self.handleFetchCheckOut(date, json.LastEvaluatedKey)
        }
        self.loading = false
      })
    },
    handleCurrentCheckIn: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.checkInItems = []
        this.checkOutItems = []
      }
      this.loading = true
      API.get('ezietruckapi', `/api/checkios/1:CheckIn/current${q}`).then((json) => {
        if (json['Items']) {
          self.checkInItems = [...self.checkInItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKeyCheckOut = json.LastEvaluatedKey
          self.handleCurrentCheckIn(json.LastEvaluatedKey)
        } else {
          self.LastEvaluatedKeyCheckOut = out
        }
        self.loading = false
      })
    },
    handleDownloadAllCSV: function () {
      let csvHeader = 'Customer ID,Customer Name, Job Type, Driver ID, Driver Name, Driver Phone number, Photo, Date, Time, Status,';
      let csv = ''
      let customHeaders = []
      let customValues = []
      let hidx = -1
      let dt;

      for (let c of [...this.checkInItems, ...this.checkOutItems].sort((a,b) => a.StartDateTime.localeCompare(b.StartDateTime))) {
        customValues = customHeaders.map((h) => '')
        for (let fk in c.CustomField) {
          hidx = customHeaders.findIndex((e) => e.startsWith(fk))
          if (hidx < 0) {
            if (typeof c.CustomField[fk] === 'string' && c.CustomField[fk].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)) {
              customHeaders = [...customHeaders, fk+ '-Date', fk+'-Time']
              customValues = [...customValues, ...c.CustomField[fk].split('T')]
            } else {
              customHeaders = [...customHeaders, fk]
              customValues = [...customValues, c.CustomField[fk]]
            }
          } else {
            if (typeof c.CustomField[fk] === 'string' && c.CustomField[fk].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)) {
              customValues[hidx] = c.CustomField[fk].split('T')[0]
              customValues[hidx + 1] = c.CustomField[fk].split('T')[1]
            } else {
              customValues[hidx] = c.CustomField[fk]
            }
          }
        }
        dt = (new Date(Date.parse(c.StartDateTime) + (7*60*60*1000))).toISOString().split('T')
        csv += `${((c.Customer || {}).CustomerID || '')},${((c.Customer || {}).CustomerName || '')},${c.JobType},${c.Driver.DriverID},${c.Driver.DriverName},${c.Driver.DriverPhoneNumber},${c.Photo},${dt[0]},${dt[1].split('.')[0]},${c.TripStatus},${customValues.join(',')}\n`
      }
      csvHeader += customHeaders.join(',') + '\n'
      let hiddenElement = document.createElement('a')
      const blob = new Blob(["\ufeff", csvHeader + csv], {type : 'text/csv;charset=utf-8'})
      hiddenElement.href = URL.createObjectURL(blob)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'checkin.csv'
      hiddenElement.click()
    },
    handleDownloadCSV: function (items) {
      let csvHeader = 'Customer ID,Customer Name, Job Type, Driver ID, Driver Name, Driver Phone number, Photo, Date, Time, Status,';
      let csv = ''
      let customHeaders = []
      let customValues = []
      let hidx = -1
      let dt;
      for (let c of [...items].sort((a,b) => a.StartDateTime.localeCompare(b.StartDateTime))) {
        customValues = customHeaders.map((h) => '')
        for (let fk in c.CustomField) {
          hidx = customHeaders.findIndex((e) => e.startsWith(fk))
          if (hidx < 0) {
            if (typeof c.CustomField[fk] === 'string' && c.CustomField[fk].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)) {
              customHeaders = [...customHeaders, fk+ '-Date', fk+'-Time']
              customValues = [...customValues, ...c.CustomField[fk].split('T')]
            } else {
              customHeaders = [...customHeaders, fk]
              customValues = [...customValues, c.CustomField[fk]]
            }
          } else {
            if (typeof c.CustomField[fk] === 'string' && c.CustomField[fk].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)) {
              customValues[hidx] = c.CustomField[fk].split('T')[0]
              customValues[hidx + 1] = c.CustomField[fk].split('T')[1]
            } else {
              customValues[hidx] = c.CustomField[fk]
            }
          }
        }
        dt = (new Date(Date.parse(c.StartDateTime) + (7*60*60*1000))).toISOString().split('T')
        csv += `${((c.Customer || {}).CustomerID || '')},${((c.Customer || {}).CustomerName || '')},${c.JobType},${c.Driver.DriverID},${c.Driver.DriverName},${c.Driver.DriverPhoneNumber},${c.Photo},${dt[0]},${dt[1].split('.')[0]},${c.TripStatus},${customValues.join(',')}\n`
      }
      csvHeader += customHeaders.join(',') + '\n'
      let hiddenElement = document.createElement('a')
      const blob = new Blob(["\ufeff", csvHeader + csv], {type : 'text/csv;charset=utf-8'})
      hiddenElement.href = URL.createObjectURL(blob)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'checkin.csv'
      hiddenElement.click()
    },
    datetimeFormater: function (str) {
      let m = moment(str)
      m.local() 
      return m.format('DD MMM YYYY HH:mm:ss')
    },
    openPhotoDialog: function (photo) {
      this.showPhotoDialog = true
      this.currentPhoto = photo
    },
    mapFocusAt: function (gps) {
      this.showMapDialog = true
      this.mapOption = Object.assign({}, this.mapOption, {
        center: latLng(gps.Lat, gps.Lon),
        markerLatLng: latLng(gps.Lat, gps.Lon),
      })
    },
    isPhoto: function(v) {
      if (typeof v === 'string') {
        return v.startsWith('Driver_Diary_Images/')
      } else {
        return false
      }
    },
    isArray: function(v) {
      return Array.isArray(v)
    }
  },
  computed: {
    ...mapGetters(['user']),
    checkIOItems: function () {
      let drivers = {}
      let s,e,d
      for (let i of this.checkInItems) {
        // d = new Date(i.StartDateTime)
        // s = new Date(this.formData.Date + 'T00:00:00+0700')
        // e = new Date(s.getTime() + 1*24*60*60*1000)
        // if (d >= s && d <= e) {
        //   drivers[i.DriverID] = Object.assign({}, drivers[i.DriverID] || {}, {
        //     Driver: i.Driver,
        //     CheckIn: i
        //   })
        // }
        drivers[i.DriverID] = Object.assign({}, drivers[i.DriverID] || {}, {
            Driver: i.Driver,
            CheckIn: i
          })
      }
      for (let i of this.checkOutItems) {
        drivers[i.DriverID] = Object.assign({}, drivers[i.DriverID] || {}, {
          Driver: i.Driver,
          CheckOut: i
        })
        // if (drivers[i.DriverID] && drivers[i.DriverID]['CheckIn']['StartDateTime'] < i['StartDateTime']) {
        //   if (drivers[i.DriverID]['CheckOut']) {
        //     if (i['StartDateTime'] < drivers[i.DriverID]['CheckOut']['StartDateTime']) {
              
        //     }
            
        //   } else {
        //     drivers[i.DriverID] = Object.assign({}, drivers[i.DriverID] || {}, {
        //       Driver: i.Driver,
        //       CheckOut: i
        //     })
        //   }
        // }
      }
      return Object.values(drivers)
    },
    groups: function () {
      let out = {}
      if (!this.LastEvaluatedKeyCheckIn && !this.LastEvaluatedKeyCheckOut) {
        let gName = ''
        for (let checkIn of this.checkInItems.sort((a,b) => b.StartDateTime.localeCompare(a.StartDateTime))) {
          if (this.groupBy === 'Driver') {
            gName = checkIn.Driver.DriverName
          } else if (this.groupBy === 'CustomerID') {
            gName = checkIn.CustomerID + ' : ' + checkIn.JobType
          }
          if (!out[gName]) {
            out[gName] = {
              Name: gName,
              CheckInItems: [],
              CheckOutItems: []
            }
          }
          if (out[gName].CheckInItems) {
            out[gName].CheckInItems.push(checkIn)
          }
        }
        for (let checkOut of this.checkOutItems.sort((a,b) => b.StartDateTime.localeCompare(a.StartDateTime))) {
          if (this.groupBy === 'Driver') {
            gName = checkOut.Driver.DriverName
          } else if (this.groupBy === 'CustomerID') {
            gName = checkOut.CustomerID + ' : ' + checkOut.JobType
          }
          if (!out[gName]) {
            out[gName] = {
              Name: gName,
              CheckInItems: [],
              CheckOutItems: []
            }
          }
          if (out[gName].CheckOutItems) {
            out[gName].CheckOutItems.push(checkOut)
          }
        }
      }
      return Object.keys(out).sort((a,b) => a.localeCompare(b)).map((k) => out[k])
    },
  },
  created () {
    let d = new Date()
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    this.formData.Date = `${ye}-${mo}-${da}`
    // this.handleFetchCheckIO()
    this.handleSearchCheckIO()
  }
}
</script>