<template>
  <div>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <h3 class="md-title">Cost Dashboard</h3>
          <div style="margin-left: 15px">
            <md-chip class="md-primary" md-deletable v-show="selectedBar" @md-delete="selectedBar=null">{{selectedBar}}</md-chip>
            <md-chip class="md-primary" md-deletable v-show="selectedOwner" @md-delete="selectedOwner=null">{{selectedOwner}}</md-chip>
            <md-chip class="md-primary" md-deletable v-show="selectedCus" @md-delete="selectedCus=null">{{selectedCus}}</md-chip>
          </div>
        </div>
        <div class="md-toolbar-section-end">
          <md-datepicker v-model="startDate" @md-closed="handleYearInput"><label>Start date</label></md-datepicker>
          <md-datepicker v-model="endDate" @md-closed="handleYearInput"><label>End date</label></md-datepicker>
          <md-field>
            <label for="movie">Group By</label>
            <md-select v-model="groupBy" name="groupBy" id="groupBy">
              <md-option value="MONTHLY">Monthly</md-option>
              <md-option value="WEEKLY">Weekly</md-option>
            </md-select>
          </md-field>
          <md-autocomplete v-model="jobType" :md-options="jobTypeOptions" >
            <label>Job Type</label>
          </md-autocomplete>
        </div>
      </div>
    </md-toolbar>
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <horizontal-bar-chart :chart-data="chartData" :options="mainChartOptions" :styles="{position: 'relative', height: $vssHeight-220 + 'px'}"></horizontal-bar-chart>
      </div>
      <div class="md-layout-item md-size-50">
        <bar-chart :chart-data="byUserData" :options="byUserChartOptions" :styles="{position: 'relative', height: $vssHeight-220 + 'px'}"></bar-chart>
      </div>
      <div class="md-layout-item md-size-100">
        <bar-chart :chart-data="byCustomerData" :options="byCustomerChartOptions" :styles="{position: 'relative', height: $vssHeight-20 + 'px'}"></bar-chart>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalBarChart from '../components/HorizontalBarChart.js'
import BarChart from '../components/BarChart.js'
import VueScreenSize from 'vue-screen-size'
import moment from 'moment'
import { API } from 'aws-amplify'

export default {
  name: 'CostDashboard',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {
    'horizontal-bar-chart': HorizontalBarChart,
    'bar-chart': BarChart
  },
  data () {
    let vm = this
    return {
      currentYear: (new Date()).getFullYear(),
      groupBy: 'MONTHLY',
      startDate: moment().startOf('year').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      jobType: null,
      summaryData: [],
      summaryAllData: {},
      summaryCustomerData: {},
      summaryUserData: {},
      selectedBar: null,
      selectedOwner: null,
      loading: false,
      selectedCus: null,
      mainChartOptions: {
        maintainAspectRatio: false,
        title: {
            display: true,
            text: 'Cost summary'
        },
        legend: {
          display: false
        },
        onClick: this.clicked,
        scales: {
            xAxes: [{
                ticks: {
                    callback: this.$currencyFormater,
                    beginAtZero: true,
                    maxTicksLimit: 6
                }
            }]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: 4,
                formatter: function(value, context) {
                  return vm.$currencyFormater(parseFloat(context.chart.data.datasets[0].data[context.dataIndex]));
                }
            }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return vm.$currencyFormater(parseFloat(tooltipItem.value));
            }
          }
        }
      },
      byUserChartOptions: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'By Owner chart'
        },
        legend: {
          display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    callback: this.$currencyFormater,
                    beginAtZero: true
                }
            }]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return vm.$currencyFormater(parseFloat(tooltipItem.value));
            }
          }
        },
        onClick: this.ownerClicked,
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: 4,
                formatter: function(value, context) {
                  return vm.$currencyFormater(parseFloat(context.chart.data.datasets[0].data[context.dataIndex]));
                }
            }
        }
      },
      byCustomerChartOptions: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'By Customer chart'
        },
        legend: {
          display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    callback: this.$currencyFormater,
                    beginAtZero: true
                }
            }]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return vm.$currencyFormater(parseFloat(tooltipItem.value));
            }
          }
        },
        onClick: this.cusClicked,
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: 4,
                formatter: function(value, context) {
                  return vm.$currencyFormater(parseFloat(context.chart.data.datasets[0].data[context.dataIndex]));
                }
            }
        }
      }
    }
  },
  methods: {
    fetchData: function () {
      let vm = this
      this.loading = true
      let sDate = moment(this.startDate)
      let promises = []
      while (sDate.format('YYYY-MM') <= moment(this.endDate).format('YYYY-MM')) {
        promises.push(API.get('ezietruckapi', `/api/summary/sales/${sDate.format('YYYY-MM')}`))
        sDate.add(1, 'months')
      }
      Promise.all(promises).then((results) => {
        vm.loading = false
        vm.summaryData = results.flat().filter((r) => {
          let formatter = 'YYYYMMDD'
          if (r.SK.split('#')[0] === 'MONTHLY') {
            formatter = 'YYYYMM'
          } else if (r.SK.split('#')[0] === 'WEEKLY') {
            formatter = 'YYYYWW'
          }
          return r.SK.split('#')[1] >= moment(vm.startDate).format(formatter) && r.SK.split('#')[1] <= moment(vm.endDate).format(formatter)
        })
      })
    },
    clicked: function (event, array) {
      for (let bar of array) {
        this.selectedBar = this.chartData.labels[bar._index]
      }
      this.selectedOwner = null
      this.selectedCus = null
    },
    ownerClicked: function (event, array) {
      for (let bar of array) {
        this.selectedOwner = this.byUserData.labels[bar._index]
      }
      this.selectedBar = null
      this.selectedCus = null
    },
    cusClicked: function (event, array) {
      for (let bar of array) {
        this.selectedCus = this.byCustomerData.labels[bar._index]
      }
      this.selectedBar = null
      this.selectedOwner = null
    },
    randomColorGenerator: function () { 
      return '#' + (Math.random().toString(16) + '0000000').slice(2, 8); 
    },
    handleYearInput: function () {
      this.$nextTick(function () {
        this.fetchData()
      })
    }
  },
  computed: {
    jobTypeOptions: function () {
      let jt = new Set()
      for (let item of (this.summaryData ||  [])) {
        if (/^\w+#\d+#JOBTYPE#\w+/.test(item.SK)) {
          jt.add(item.SK.split('#')[3])
        }
      }
      return [...jt]
    },
    chartData: function () {
      let chart = {
        labels: [],
        datasets: [{label: 'ALL', data: [], backgroundColor: "rgba(204, 11, 11,0.8)"}]
      }
      let pattern = '^' + this.groupBy + '#\\d+'
      if (this.jobType) {
        pattern += '#JOBTYPE#' + this.jobType
      }
      if (this.selectedCus) {
        pattern += '#CUSTOMER#' + this.selectedCus
      }
      if (this.selectedOwner) {
        pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?'
      }
      let patternReg = new RegExp(pattern+'$')
      let data = {}
      console.log(pattern)
      for (let item of (this.summaryData ||  [])) {
        if (patternReg.test(item.SK)) {
          if (!data[item.SK.split('#')[1]]) {
            data[item.SK.split('#')[1]] = {TotalCostAmount: 0}
          }
          data[item.SK.split('#')[1]].TotalCostAmount += item.TotalCostAmount
          if (item.SK === 'MONTHLY#202104') {
            console.log(item)
          }
        }
      }
      chart.labels = [...Object.keys(data)].sort((a,b) => a.localeCompare(b))
      for (let label of chart.labels) {
        chart.datasets[0].data.push(data[label].TotalCostAmount)
      }
      return chart
    },
    byCustomerData: function () {
      let chart = {
        labels: [],
        datasets: [{label: 'By customer', data: [], backgroundColor: []}]
      }
      let pattern = '^' + this.groupBy + '#'
      if (this.selectedBar) {
        pattern += this.selectedBar
      } else {
        pattern += '\\d+'
      }
      if (this.jobType) {
        pattern += '#JOBTYPE#' + this.jobType
      }
      if (this.selectedOwner) {
        pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?'
      }
      if (this.selectedCus) {
        pattern += '#CUSTOMER#' + this.selectedCus 
      } else {
        pattern += '#CUSTOMER#\\w+'
      }
      let patternReg = new RegExp(pattern+'$')
      for (let item of (this.summaryData ||  [])) {
        if (patternReg.test(item.SK)) {
          let lidx = chart.labels.findIndex((i) => i === item.SK.split('#')[item.SK.split('#').length  - 1])
          if (lidx < 0) {
            chart.labels.push(item.SK.split('#')[item.SK.split('#').length  - 1])
            lidx = chart.labels.length - 1
            chart.datasets[0].data[lidx] = 0
            chart.datasets[0].backgroundColor[lidx] = "rgba(204, 11, 11,0.8)"
          }
          chart.datasets[0].data[lidx] += item.TotalCostAmount
        }
      }
      return chart
    },
    byUserData: function () {
      let chart = {
        labels: [],
        datasets: [{label: 'Order Cost', data: [], backgroundColor: []}]
      }
      let pattern = '^' + this.groupBy + '#'
      if (this.selectedBar) {
        pattern += this.selectedBar
      } else {
        pattern += '\\d+'
      }
      if (this.jobType) {
        pattern += '#JOBTYPE#' + this.jobType
      }
      if (this.selectedOwner) {
        pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?'
      } else {
        if (this.selectedCus) {
          pattern += '#USER#[\\.\\@\\w]+#CUSTOMER#' + this.selectedCus 
        } else {
          pattern += '#USER#[\\.\\@\\w]+'
        }
      }
      let patternReg = new RegExp(pattern+'$')
      for (let item of (this.summaryData ||  [])) {
        if (patternReg.test(item.SK)) {
          let label = item.SK.split('#')[item.SK.split('#').length  - 1].split('@')[0]
        if (item.SK.split('#').length === 8 || (item.SK.split('#').length === 6 && this.selectedCus)) {
          label = item.SK.split('#')[item.SK.split('#').length  - 3].split('@')[0]
        }
          let lidx = chart.labels.findIndex((i) => i === label)
          if (lidx < 0) {
            chart.labels.push(label)
            lidx = chart.labels.length - 1
            chart.datasets[0].data[lidx] = 0
            chart.datasets[0].backgroundColor[lidx] = "rgba(204, 11, 11,0.8)"
          }
          chart.datasets[0].data[lidx] += item.TotalCostAmount
        }
      }
      return chart
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
