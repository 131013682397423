<template>
  <div class="md-layout">
    <div class="md-layout-item md-large-size-15 md-xlarge-size-15 md-medium-size-15 md-small-hide md-xsmall-hide md-elevation-1">
      <div :style="{'position': 'fixed', 'width': $vssWidth*0.15 + 'px', 'max-height': $vssHeight-190 + 'px', 'overflow': 'auto'}">
        <md-list class="md-triple-line" >
          <md-list-item v-for="(order, idx) in orderItems" :key="order.OrderID" @click="handleOrderClick(idx, order.OrderID)" :class="{'activeOrder': order.OrderID === activeOrderID}">
            <div class="md-list-item-text">
              <span>{{order.OrderID}}</span>
              <span>{{order.JobType}} - {{order.JobSubtype}} </span>
              <p v-if="order.DriverName">{{order.DriverName}}</p>
              <p v-if="!order.DriverName && approveForm.searchSelected.length > 0">{{approveForm.searchSelected[0].Driver.DriverName}}</p>
            </div>
            <md-button class="md-icon-button md-list-action">
              <md-icon>delete</md-icon>
            </md-button>
          </md-list-item>
          <md-list-item :class="{'activeOrder': '---' === activeOrderID}" @click="handleOrderClick(-1, '---')" v-show="$route.params.filter === 'unmatch'">
            <div class="md-list-item-text">
              <span style="font-style: italic;">**NEW ORDER**</span>
              <span v-show="activeOrderID === '---'">{{newOrderForm.JobType}} - {{newOrderForm.JobSubtype}} </span>
              <p v-show="activeOrderID === '---'">{{newOrderForm.DriverName}}</p>
            </div>
          </md-list-item>
        </md-list>
      </div>
    </div>
    <div style="padding: 4px" class="md-layout-item md-large-size-85 md-xlarge-size-85 md-medium-size-85 md-small-size-100 md-xsmall-size-100 md-content">
      <md-toolbar class="md-primary" v-show="approveForm.suggestSelected.length + approveForm.searchSelected.length > 0">
        <h3 class="md-title" style="flex: 1">
          {{ approveForm.suggestSelected.length + approveForm.searchSelected.length }} diaries selected
        </h3>
        <div class="md-toolbar-section-end" v-show="Object.keys(errors).length === 0">
          <md-field v-show="approveForm.searchSelected.length > 0">
            <label>Reason</label>
            <md-select v-model="approveForm.reason">
              <md-option value="Order with no driver">Order with no driver</md-option>
              <md-option value="Incorrect filled diary">Incorrect filled diary</md-option>
              <md-option value="Incorrect date diary">Incorrect date diary</md-option>
            </md-select>
          </md-field>
          <md-button class="md-icon-button" @click="handleApproveDiary()">
            <md-icon>save</md-icon>
          </md-button>
        </div>
      </md-toolbar>
      <md-card class="md-layout-item md-size-100 md-primary" v-show="Object.keys(errors).length > 0">
        <md-card-header>
          <div class="md-title">Errors</div>
        </md-card-header>
        <md-card-content>
          <ul>
            <li v-for="(v, k) in errors" :key="k">{{ v }}</li>
          </ul>
        </md-card-content>
      </md-card>
      <form novalidate class="md-layout">
        <md-card class="md-layout-item md-size-100">
          <md-card-header>
            <div class="md-title" v-show="newOrderForm.SK.startsWith('2')">New Order</div>
            <div class="md-title" v-show="newOrderForm.SK.startsWith('1')">Edit Order</div>
            <router-link target="_blank" :to="'/NewOrderCalculator/' + encodeURIComponent(newOrderForm.SK)">Calculator</router-link>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label>Job Type</label>
                  <md-select v-model="newOrderForm.JobType">
                    <md-option v-for="jobType in allJobTypes" :value="jobType.Name" :key="jobType.Name">{{jobType.Name}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label>Job SubType</label>
                  <md-select v-model="newOrderForm.JobSubtype">
                    <md-option v-for="jobType in currentSubJobTypes" :value="jobType" :key="jobType">{{jobType}}</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-datepicker :md-model-type="String" md-immediately v-model="newOrderForm.Date">
                  <label>Date</label>
                </md-datepicker>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-datepicker :md-model-type="String" md-immediately v-model="newOrderForm.CustomerBillDate">
                  <label>Customer Bill Date</label>
                </md-datepicker>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label>Service Rate Plan</label>
                  <md-select v-model="newOrderForm.ServiceRatePlan" @md-selected="fetchEnum">
                    <md-option v-for="ratePlan in ratePlans" :value="ratePlan.SK" :key="ratePlan.SK">{{ratePlan.Name}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label>Hire Rate Plan</label>
                  <md-select v-model="newOrderForm.HireRatePlan" @md-selected="fetchEnum">
                    <md-option v-for="ratePlan in ratePlans" :value="ratePlan.SK" :key="ratePlan.SK">{{ratePlan.Name}}</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label>Remark</label>
                  <md-textarea v-model="newOrderForm.Remark"></md-textarea>
                </md-field>
              </div>
            </div>
            <div class="md-title" v-show="currentCustomFields.length > 0">Custom Fields</div>
            <div class="md-layout  md-gutter" v-for="field of currentCustomFields" :key="field.name">
              <div class="md-layout-item">
                <md-field>
                  <label>{{field.title}}</label>
                  <md-input v-if="field.type === 'string' && !field.enum" v-model="customFieldValue[field.name]"></md-input>
                  <md-input v-if="field.type === 'number'" v-model="customFieldValue[field.name]"></md-input>
                  <md-select v-if="field.type === 'string' && field.enum" v-model="customFieldValue[field.name]">
                    <md-option v-for="opt in field.enum" :value="opt[0]" :key="opt[0]">{{opt[1]}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item" v-show="approveForm.suggestSelected.length + approveForm.searchSelected.length > 0">
                <md-field v-for="d in fromFieldValue[field.name]" :key="d">
                  <label>{{field.title}}</label>
                  <md-input :value="d"></md-input>
                  <md-icon>add_task</md-icon>
                </md-field>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
      <md-toolbar v-show="$route.params.filter === 'unmatch'">
        <form @submit.prevent="handleSearchDiary" class="md-toolbar-row md-layout" >
          <div class="md-layout-item">
            <md-datepicker v-model="formData.StartDate" :md-model-type="String" md-immediately>
              <label>Start Date</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item">
            <md-datepicker v-model="formData.EndDate" :md-model-type="String" md-immediately>
              <label>End Date</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label>CustomerID</label>
              <md-select v-model="formData.CustomerID">
                <md-option value="---">---</md-option>
                <md-option v-for="customer in customers" :key="customer.SK" :value="customer.CustomerID">
                  {{customer.CustomerID}} / {{customer.CustomerName}}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-button type="submit" class="md-raised md-primary">
              <span>Search</span>
            </md-button>
          </div>
        </form>
      </md-toolbar>
      <md-table v-model="filteredDiary" @md-selected="onSuggestSelect" v-show="!isSearch && filteredDiary.length > 0">
        <md-table-toolbar md-elevation="1">
          <div class="md-toolbar-section-start">
            <h3 class="md-subtitle">Suggest Dairy</h3>
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select>
          <md-table-cell md-label="Driver">
            {{ item.Driver.DriverName }} ({{ item.Driver.DriverPhoneNumber }})
            <img src="../assets/LINE_Brand_icon.png" v-show="item.LineUserID" width="16" />
            <p>
              {{ item.Vehicle ? item.Vehicle.VehicleLicenseNumber : "" }} {{ item.Vehicle ? item.Vehicle.VehicleLicenseProvince : "" }} -
              {{ item.Vehicle ? item.Vehicle.VehicleTruckType : "" }} {{ item.Vehicle ? item.Vehicle.VehicleContainerType : "" }}
            </p>
          </md-table-cell>
          <md-table-cell md-label="Date time">
            <p v-show="!item.CheckIn && !item.StartDateTime">
              Created At:<br />
              {{$dateTimeFormater(item.CreatedAt)}}
            </p>
            <p v-if="item.CheckIn && item.CheckIn.split('#').length > 2">
              Check In:<br />
              {{$dateTimeFormater(item.CheckIn.split('#')[1])}}
            </p>
            <p v-show="item.StartDateTime">
              Start DateTime:<br />
              {{$dateTimeFormater(item.StartDateTime)}}
            </p>
          </md-table-cell>
          <md-table-cell md-label="Customer">{{ item.CustomerID }}</md-table-cell>
          <md-table-cell md-label="JobType">{{ item.JobType }}</md-table-cell>
          <md-table-cell md-label="OrderID">
            <router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link>
          </md-table-cell>
          <md-table-cell md-label="Detail">
            <md-button @click="openDiaryDetail(item.PK, item.SK)">Detail</md-button>
          </md-table-cell>
          <md-table-cell md-label="Remove">
            <md-button class="md-icon-button">
              <md-icon>delete</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <h5 class="md-title" v-show="filteredDiary.length === 0 && !isSearch && $route.params.filter === 'unmatch'">
        There is no suggest diaries please using diaries search form above to show more diary.
      </h5>
      <md-table v-model="filterSearchDiary" @md-selected="onSearchSelect" v-show="isSearch">
        <md-table-toolbar md-elevation="1">
          <div class="md-toolbar-section-start">
            <h3 class="md-subtitle">Dairy Search Result</h3>
          </div>
          <md-field md-clearable class="md-toolbar-section-end">
            <md-input placeholder="Search by Driver name..." v-model="formData.SearchTxt" />
          </md-field>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple">
          <md-table-cell md-label="Driver">
            {{ item.Driver.DriverName }} ({{ item.Driver.DriverPhoneNumber }})
            <img src="../assets/LINE_Brand_icon.png" v-show="item.LineUserID" width="16" />
            <p>
              {{ item.Vehicle ? item.Vehicle.VehicleLicenseNumber : "" }} {{ item.Vehicle ? item.Vehicle.VehicleLicenseProvince : "" }} -
              {{ item.Vehicle ? item.Vehicle.VehicleTruckType : "" }} {{ item.Vehicle ? item.Vehicle.VehicleContainerType : "" }}
            </p>
          </md-table-cell>
          <md-table-cell md-label="Date time">
            <p>
              Created At:<br />
              {{$dateTimeFormater(item.CreatedAt)}}
            </p>
            <p v-if="item.CheckIn && item.CheckIn.split('#').length > 2">
              Check In:<br />
              {{$dateTimeFormater(item.CheckIn.split('#')[1])}}
            </p>
            <p>
              Start DateTime:<br />
              {{$dateTimeFormater(item.StartDateTime)}}
            </p>
          </md-table-cell>
          <md-table-cell md-label="Customer">{{ item.CustomerID }}</md-table-cell>
          <md-table-cell md-label="JobType">{{ item.JobType }}</md-table-cell>
          <md-table-cell md-label="OrderID">
            <router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link>
          </md-table-cell>
          <md-table-cell md-label="Detail">
            <md-button @click="openDiaryDetail(item.PK, item.SK)">Detail</md-button>
          </md-table-cell>
          <md-table-cell md-label="Remove">
            <md-button class="md-icon-button">
              <md-icon>delete</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <h3 class="md-title" v-show="isSearch && filterSearchDiary.length == 0">
        No diaries found. Try a different filter or create a new one.
      </h3>
      <md-table v-model="matchedDiaries" v-show="$route.params.filter === 'matched'">
        <md-table-toolbar md-elevation="1">
          <div class="md-toolbar-section-start">
            <h3 class="md-subtitle">Matched Dairy</h3>
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Driver">
            {{ item.Driver.DriverName }} ({{ item.Driver.DriverPhoneNumber }})
            <img src="../assets/LINE_Brand_icon.png" v-show="item.LineUserID" width="16" />
            <p>
              {{ item.Vehicle ? item.Vehicle.VehicleLicenseNumber : "" }} {{ item.Vehicle ? item.Vehicle.VehicleLicenseProvince : "" }} -
              {{ item.Vehicle ? item.Vehicle.VehicleTruckType : "" }} {{ item.Vehicle ? item.Vehicle.VehicleContainerType : "" }}
            </p>
          </md-table-cell>
          <md-table-cell md-label="Date time">
            <p v-show="!item.CheckIn && !item.StartDateTime">
              Created At:<br />
              {{$dateTimeFormater(item.CreatedAt)}}
            </p>
            <p v-if="item.CheckIn && item.CheckIn.split('#').length > 2">
              Check In:<br />
              {{$dateTimeFormater(item.CheckIn.split('#')[1])}}
            </p>
            <p v-show="item.StartDateTime">
              Start DateTime:<br />
              {{$dateTimeFormater(item.StartDateTime)}}
            </p>
          </md-table-cell>
          <md-table-cell md-label="Customer">{{ item.CustomerID }}</md-table-cell>
          <md-table-cell md-label="JobType">{{ item.JobType }}</md-table-cell>
          <md-table-cell md-label="OrderID">
            <router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link>
          </md-table-cell>
          <md-table-cell md-label="Detail">
            <md-button @click="openDiaryDetail(item.PK, item.SK)">Detail</md-button>
          </md-table-cell>
          <md-table-cell md-label="Remove">
            <md-button class="md-icon-button">
              <md-icon>delete</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <md-dialog :md-active.sync="showDialog">
        <md-dialog-title>Diary</md-dialog-title>
        <md-dialog-content>
          <DiaryDetail :pk="currentDiary.PK" :sk="currentDiary.SK"></DiaryDetail>  
        </md-dialog-content>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'
import DiaryDetail from './DiaryDetail'

export default {
  name: 'NewOrderList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {DiaryDetail},
  data () {
    const sdate = this.$route.params.date || moment().format('YYYY-MM-DD')
    const edate = this.$route.params.date || moment().format('YYYY-MM-DD')
    return {
      activeOrderID: null,
      customerItems: [],
      loadedCustomerID: [],
      driverItems: [],
      orderItems: [],
      diaryItems: [],
      searchDiaryItems: [],
      customerJobTypes: [],
      ratePlans: [],
      customFields: [],
      fieldOptionLookup: {},
      diaries: {},
      isSearch: false,
      showDialog: false,
      formData: {
        StartDate: sdate,
        EndDate: edate,
        CustomerID: null,
        SearchTxt: ''
      },
      approveForm: {
        reason: null,
        suggestSelected: [],
        searchSelected: [],
      },
      newOrderForm: {
        PK: 'Order',
        SK: `5:Confirmed#`,
        BatchID: null,
        OrderID: null,
        CustomerID: null,
        CustomerName: null,
        CustomerLogo: null,
        JobType: null,
        JobSubtype: null,
        CustomerBillDate: null,
        Date: null,
        StartDate: null,
        StartTime: null,
        StartDateTime: null,
        ServiceRatePlan: null,
        HireRatePlan: null,
        TruckType: null,
        Remark: null,
        CreatedBy: null,
        CreatedAt: null,
        'Driver.DriverID': null,
        DriverID: null,
        DriverName: null,
        DrivePhoneNumber: null,
        LicensePlate: null
      },
      customFieldValue: {},
      currentDiary: {
        PK: null,
        SK: null
      },
      fromFieldValue: {
        Destination: [
          "AAA"
        ],
        Origin: [
          "BBB",
          "CCC"
        ]
      }
    }
  },
  methods: {
    fetchDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.driverItems = []
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          self.driverItems = [...self.driverItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKey = json.LastEvaluatedKey
          self.fetchDriver(json.LastEvaluatedKey)
        } else {
          self.LastEvaluatedKey = null
        }
      })
    },
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchData: function (lastEvaluatedKey) {
      let self = this
      let q = `?filter=${encodeURIComponent(this.$route.params.filter)}`
      if (lastEvaluatedKey) {
        q = `&ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', `/api/newOrders/${this.$route.params.customerID}/${this.$route.params.date}${q}`).then((json) => {
        if (json['Items']) {
          self.orderItems = [...self.orderItems, ...json['Items']]
          for (let i of json['Items']) {
            if (!self.loadedCustomerID.includes(i.CustomerID)) {
              self.fetchDairy(self.$route.params.date, i.CustomerID, null, false)
              self.loadedCustomerID = [
                ...self.loadedCustomerID,
                i.CustomerID
              ]
            }
          }
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        } else {
          if (self.orderItems.length) {
            self.handleOrderClick(0, self.orderItems[0].OrderID)
          }
        }
      })
    },
    fetchDairy: function (date, customerID, driverPhoneNumber, isSearchDiary, lastEvaluatedKey) {
      let self = this
      let q = `?CustomerID=${customerID}`
      if (driverPhoneNumber) {
        q += `&DriverPhoneNumber=${driverPhoneNumber}`
      }
      if (lastEvaluatedKey) {
        q += `&ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', `/api/vehicles/diary/${date}` + q).then((json) => {
        if (json['Items']) {
          if (isSearchDiary) {
            self.searchDiaryItems = [...self.searchDiaryItems, ...json['Items']]
          } else {
            self.diaryItems = [...self.diaryItems, ...json['Items']]
          }
        }
        if (json.LastEvaluatedKey) {
          self.fetchDairy(date, customerID, driverPhoneNumber, isSearchDiary, json.LastEvaluatedKey)
        }
      })
    },
    handleOrderClick: async function (idx, orderID) {
      this.searchDiaryItems = []
      this.approveForm.suggestSelected = []
      this.approveForm.searchSelected = []
      this.ratePlans = []
      this.isSearch = false
      this.formData = Object.assign({}, this.formData, {
        StartDate: this.$route.params.date || moment().format('YYYY-MM-DD'),
        EndDate: this.$route.params.date || moment().format('YYYY-MM-DD'),
        CustomerID: orderID.slice(0, 3)
      })
      if (orderID === '---') {
        let batchID = this.orderItems[this.orderItems.length - 1].BatchID
        this.newOrderForm = {
          PK: 'Order',
          SK: `5:Confirmed#${batchID}`,
          BatchID: batchID,
          OrderID: null,
          CustomerID: null,
          CustomerName: null,
          CustomerLogo: null,
          JobType: null,
          JobSubtype: null,
          Date: null,
          CustomerBillDate: null,
          StartTime: null,
          StartDateTime: null,
          ServiceRatePlan: null,
          HireRatePlan: null,
          TruckType: null,
          Remark: null,
          CreatedBy: null,
          CreatedAt: null,
          'Driver.DriverID': null,
          DriverID: null,
          DriverName: null,
          DrivePhoneNumber: null,
          LicensePlate: null
        }
      } else {
        const defaultField = Object.keys(this.newOrderForm)
        this.newOrderForm = Object.assign({}, this.orderItems[idx])
        for (let k in this.newOrderForm) {
          if (!defaultField.includes(k)) {
            this.customFieldValue[k] = this.newOrderForm[k]
          }
        }
        this.customFieldValue = {...this.customFieldValue}
        this.customerJobTypes = []
        this.fetchFavJobTypes(this.orderItems[idx].CustomerID)
        await this.fetchRatePlans('---', '---', '---')
        await this.fetchRatePlans(this.orderItems[idx].CustomerID, '---', '---')
        if (this.orderItems[idx].JobType) {
          await this.fetchRatePlans(this.orderItems[idx].CustomerID, this.orderItems[idx].JobType, '---')
          if (this.orderItems[idx].JobSubtype) {
            await this.fetchRatePlans(this.orderItems[idx].CustomerID, this.orderItems[idx].JobType, this.orderItems[idx].JobSubtype)
          }
        }
        if (this.newOrderForm.ServiceRatePlan) {
          this.fetchEnum(this.newOrderForm.ServiceRatePlan)
        }
        if (this.newOrderForm.HireRatePlan) {
          this.fetchEnum(this.newOrderForm.HireRatePlan)
        }
      }
      if (this.activeOrderID === orderID) {
        this.activeOrderID = null
      } else {
        this.activeOrderID = orderID
      }
    },
    handleSearchDiary: function () {
      let vm = this
      let driverPhoneNumber = null
      this.isSearch = true
      let idx = this.orderItems.findIndex((o) => o.OrderID === vm.activeOrderID)
      if (this.activeOrderID && idx > -1) {
        if (this.orderItems[idx].DriverID) {
          let ddx = this.drivers.findIndex((d) => d.DriverID === vm.orderItems[idx].DriverID)
          if (ddx > -1) {
            driverPhoneNumber = this.drivers[ddx].DriverPhoneNumber
          }
        }
      }
      this.searchDiaryItems = []
      let i = moment(this.formData.StartDate)
      while (i <= moment(this.formData.EndDate)) {
        this.fetchDairy(i.format('YYYY-MM-DD'), this.formData.CustomerID, driverPhoneNumber, true)
        i.add(1, 'days')
      }
    },
    onSuggestSelect: function (items) {
      this.approveForm.suggestSelected = items
    },
    onSearchSelect: function (items) {
      this.approveForm.searchSelected = items
      let vm = this
      if (items.length > 0 && !this.newOrderDiaryError && this.activeOrderID === '---') {
        let dt = new Date(Date.parse(items[0].StartDateTime ? items[0].StartDateTime : items[0].PK.split('#')[1]))
        let idx = this.customers.findIndex((c) => c.CustomerID === items[0].CustomerID)
        this.customerJobTypes = []
        this.fetchFavJobTypes(items[0].CustomerID)
        this.fetchRatePlans('---', '---', '---')
        this.fetchRatePlans(items[0].CustomerID, '---', '---')
        this.fetchRatePlans(items[0].CustomerID, items[0].JobType, '---')
        if (items[0].JobSubtype) {
          this.fetchRatePlans(items[0].CustomerID, items[0].JobType, items[0].JobSubtype)
        }
        this.newOrderForm = Object.assign({}, this.newOrderForm, {
          CustomerID: items[0].CustomerID,
          CustomerName: idx > -1 ? this.customers[idx].CustomerName : '-',
          CustomerLogo: idx > -1 ? this.customers[idx].CustomerLogo : '-',
          JobType: items[0].JobType,
          JobSubtype: items[0].JobSubtype || null,
          Date: `${dt.getFullYear()}-${(dt.getMonth() + 1).toString().padStart(2, '0')}-${(dt.getDate()).toString().padStart(2, '0')}`,
          StartTime: `${(dt.getHours()).toString().padStart(2, '0')}:${(dt.getMinutes()).toString().padStart(2, '0')}`,
          TruckType: ((items[0].Vehicle || {}).VehicleTruckType || '-'),
          DriverID: items[0].Driver.DriverID,
          DriverName: items[0].Driver.DriverName,
          DrivePhoneNumber: items[0].Driver.DrivePhoneNumber,
          LicensePlate: `${(items[0].Vehicle || {}).VehicleLicenseNumber || '-'} ${(items[0].Vehicle || {}).VehicleLicenseProvince || '-'}`
        })
      }
    },
    fetchRatePlans: async function (customerID, jobType, JobSubtype, lastEvaluatedKey) {
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      const json = await API.get('ezietruckapi', `/api/ratePlans/${customerID}/${jobType}/${JobSubtype}` + q)
      if (json['Items']) {
        this.ratePlans = [...this.ratePlans, ...json['Items']]
      }
      if (json.LastEvaluatedKey) {
        await this.fetchRatePlans(customerID, jobType, JobSubtype, json.LastEvaluatedKey)
      }
    },
    fetchFavJobTypes: function (customerID) {
      let vm = this
      API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`).then((json) => {
        vm.customerJobTypes = json
      })
    },
    fetchCustomField: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.customFields = []
      }
      API.get('ezietruckapi', '/api/customFields'+q).then((json) => {
        if (json['Items']) {
          self.customFields = [...self.customFields, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomField(json.LastEvaluatedKey)
        }
      })
    },
    fetchOptionLookup: function (name, customerID, jobType, JobSubtype, lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', `/api/lookups/OptionLookup/${name}/${customerID}/${encodeURIComponent(jobType)}/${encodeURIComponent(JobSubtype)}${q}`).then((json) => {
        if (!self.fieldOptionLookup[name]) {
          self.fieldOptionLookup[name] = []
        }
        this.fieldOptionLookup[name] = [
          ...self.fieldOptionLookup[name],
          ...json['Items'].filter((i) => !self.fieldOptionLookup[name].map((l) => l[0]).includes(i.Name)).map((l) => [l.Name, l.Label])
        ]
        this.fieldOptionLookup = Object.assign({}, this.fieldOptionLookup)
        if (json.LastEvaluatedKey) {
          self.fetchOptionLookup(name, customerID, jobType, JobSubtype, json.LastEvaluatedKey)
        }
      })
    },
    fetchEnum: function (sk) {
      let self = this
      const idx = this.ratePlans.findIndex((c) => c.SK === sk)
      let sdx = -1
      if (idx > -1) {
        for (let customField of this.ratePlans[idx].CustomFields.filter((f) => f.ShowInDiary.includes('Staff'))) {
          sdx = this.customFields.findIndex((c) => c.Name === customField.Field)
          if (sdx > -1 && this.customFields[sdx].DataType === 'Select') {
            if (this.customFields[sdx].Option) {
              this.fieldOptionLookup[this.customFields[sdx].Name] = this.customFields[sdx].Option
              this.fieldOptionLookup = Object.assign({}, this.fieldOptionLookup)
            } else if (this.customFields[sdx].OptionLookup) {
              this.fetchOptionLookup(this.customFields[sdx].OptionLookup, '---', '---', '---')
              this.fetchOptionLookup(this.customFields[sdx].OptionLookup, this.newOrderForm.CustomerID, '---', '---')
              if (this.newOrderForm.JobType) {
                this.fetchOptionLookup(this.customFields[sdx].OptionLookup, this.newOrderForm.CustomerID, this.newOrderForm.JobType, '---')
                if (this.newOrderForm.JobSubtype) {
                  this.fetchOptionLookup(this.customFields[sdx].OptionLookup, this.newOrderForm.CustomerID, this.newOrderForm.JobType, this.newOrderForm.JobSubtype)
                }
              }
            }
          }
        }
      }
    },
    handleApproveDiary: function () {
      let vm = this
      let idx = this.orderItems.findIndex((o) => o.OrderID === vm.activeOrderID)
      if(Object.keys(this.errors).length === 0 && this.activeOrderID && this.activeOrderID !== '---' && idx > -1) {
        API.post('ezietruckapi', `/api/newOrders/${encodeURIComponent(this.orderItems[idx].SK)}/diaries`, {body: {
          MatchedDiaries: {
            SuggestDiaries: this.approveForm.suggestSelected,
            SearchDiaries: this.approveForm.searchSelected,
            DiaryReason: this.approveForm.reason || '-'
          }
        }}).then(() => {
          this.$router.push('/OrderDashboard')
        })
      } else if(Object.keys(this.errors).length === 0 && this.activeOrderID && this.activeOrderID === '---') {
        API.post('ezietruckapi', `/api/newOrders/${encodeURIComponent(this.orderItems[idx].SK)}/order-from-diary`, {body: Object.assign({}, this.newOrderForm, {
          MatchedDiaries: {
            SuggestDiaries: this.approveForm.suggestSelected,
            SearchDiaries: this.approveForm.searchSelected,
            DiaryReason: this.approveForm.reason || '-'
          }
        })}).then(() => {
          this.$router.push('/OrderDashboard')
        })
      }
    },
    openDiaryDetail: function (pk, sk) {
      this.currentDiary = {
        PK: pk,
        SK: sk
      }
      this.showDialog = true
    }
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    filteredDiary: function () {
      let vm = this
      let idx = this.orderItems.findIndex((o) => o.OrderID === vm.activeOrderID)
      if (this.activeOrderID && idx > -1) {
        return this.diaryItems.filter((d) => d.Driver.DriverID === vm.orderItems[idx].DriverID)
      }
      return []
    },
    matchedDiaries: function () {
      let vm = this
      let idx = this.orderItems.findIndex((o) => o.OrderID === vm.activeOrderID)
      if (this.activeOrderID && idx > -1) {
        return [
          ...(this.orderItems[idx].MatchedDiaries || {}).SuggestDiaries || [],
          ...(this.orderItems[idx].MatchedDiaries || {}).SearchDiaries || []
        ]
      }
      return []
    },
    filterSearchDiary: function () {
      let vm = this
      return this.searchDiaryItems.filter((d) => vm.formData.SearchTxt.length < 3 || d.Driver.DriverName.includes(vm.formData.SearchTxt))
    },
    drivers () {
      let out = []
      for (let o of this.driverItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out.sort((a,b) => a.DriverName.localeCompare(b.DriverName))
    },
    allJobTypes: function () {
      return this.customerJobTypes.length > 0 ? this.customerJobTypes : this.jobTypes
    },
    currentSubJobTypes: function () {
      return this.customerJobTypes.map((j) => j.SubJobType).flat().filter((j) => j).filter((value, index, array) => array.indexOf(value) === index);
    },
    currentCustomFields: function () {
      let vm = this
      const sdx = this.ratePlans.findIndex((c) => c.SK === vm.newOrderForm.ServiceRatePlan)
      const hdx = this.ratePlans.findIndex((c) => c.SK === vm.newOrderForm.HireRatePlan)
      let customF = [
        ...(sdx > -1 ? this.ratePlans[sdx].CustomFields : []),
        ...(hdx > -1 ? this.ratePlans[hdx].CustomFields : [])
      ]
      let cdx = -1
      let customFieldSchema = []
      for (let c of customF.filter((f) => f.ShowInDiary.includes('Staff'))) {
        cdx = this.customFields.findIndex((f) => f.Name === c.Field)
        if (!customFieldSchema.map((s) => s.name).includes(c.Field) && cdx > -1) {
          customFieldSchema = [ ...customFieldSchema, Object.assign({}, {
            "name": this.customFields[cdx].Name,
            "title": this.customFields[cdx].Label,
            "type": this.customFields[cdx].DataType === 'Number' ? 'number' : 'string',
            "validation": c.Validation
          }, this.customFields[cdx].DataType === 'Select' ? {
            enum: this.customFields[cdx].OptionLookup ? this.fieldOptionLookup[this.customFields[cdx].OptionLookup] || [] : this.customFields[cdx].Option || []
          } : {})]
        }
      }
      return customFieldSchema
    },
    errors: function () {
      let out = {}
      if ((new Set(this.approveForm.searchSelected.map((s) => s.Driver.DriverID))).size > 1) {
        out['searchSelected'] = 'Multiple driver selected'
      }
      if (!this.newOrderForm.JobType) {
        out['JobType'] = 'JobType is required.'
      }
      if (!this.newOrderForm.Date) {
        out['Date'] = 'Date is required.'
      }
      if (!this.newOrderForm.ServiceRatePlan) {
        out['ServiceRatePlan'] = 'Service Rate Plan is required.'
      }
      if (!this.newOrderForm.HireRatePlan) {
        out['HireRatePlan'] = 'Hire Rate Plan is required.'
      }
      for (let f of this.currentCustomFields) {
        if (f.validation.isRequired && !this.customFieldValue[f.name]) {
          out[f.name] = `${f.title} is required.`
        }
      }
      return out
    }
  },
  created () {
    this.fetchCustomField()
    this.fetchCustomer()
    this.fetchDriver()
    this.fetchData()
    this.formData = Object.assign({}, this.formData, {
      StartDate: this.$route.params.date,
      EndDate: this.$route.params.date
    })
  }
}
</script>
<style scoped>
.activeOrder {
  border: 2px solid red;
}
</style>