<template>
  <div>
    <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackBarMsg}}</span>
    </md-snackbar>
    <md-toolbar md-elevation="0">
      <md-field>
        <label for="movie">Group by</label>
        <md-select v-model="groupBy" name="groupBy" id="groupBy">
          <md-option value="JobType">Job Type</md-option>
          <md-option value="Driver.DriverName">Driver</md-option>
          <md-option value="From.Description">From</md-option>
          <md-option value="To.Description">To</md-option>
          <md-option value="TruckType">Truck Type</md-option>
          <md-option value="Date">Order Date</md-option>
          <md-option value="StartDate">Start Date</md-option>
        </md-select>
      </md-field>
    </md-toolbar>
    <order-table v-for="order in groupedByOrder" :order="order" :key="order.title" :allowClick="allowClick" @select="handleOrderSelect" @action="handleOrderAction"></order-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueScreenSize from 'vue-screen-size'
import moment from 'moment'
import { API } from 'aws-amplify'
import OrderTable from '../components/OrderTable.vue'

export default {
  name: 'OrderListByCustomerStatus',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {OrderTable},
  data () {
    return {
      customers: [],
      orders: [],
      trip: {},
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      groupBy: 'JobType',
      LastEvaluatedKey: null,
      allowClick: null
    }
  },
  methods: {
    fetchData: function (lastEvaluatedKey) {
      let self = this
      let body = {'q': {'status': this.$route.params.status}, 'opt': {'IncludeTrips': true}}
      let cb = (json) => {
        self.orderResult = json
        let orders = [...this.orders, ...json['Items'].filter((i) => !!i.From)].map((o) => Object.assign(o, {
          'From.Description': o.From.Description,
          'To.Description': o.To.Description,
          'StartDate': o.StartDateTime.split('T')[0]
        }))
        json['Items'].filter((i) => !!i.DriverName).forEach(function(i) {
          let orderIndex = orders.findIndex((o) => o.PK === i.PK)
          if (orderIndex > -1) {
            orders[orderIndex]['Driver.DriverName'] = i.DriverName
            orders[orderIndex]['Trip.DriverID'] = i.DriverID
            orders[orderIndex]['Trip.DriverName'] = i.DriverName
            orders[orderIndex]['Trip.DeliveryCost'] = i.DeliveryCost
            orders[orderIndex]['Trip.DailyPickerCost'] = i.DailyPickerCost
            orders[orderIndex]['Trip.OtherCost'] = i.OtherCost
            orders[orderIndex]['Trip.SalaryPerTrip'] = i.SalaryPerTrip
            orders[orderIndex]['Trip.FuelAllowance'] = i.FuelAllowance
            orders[orderIndex]['Trip.AdjustAmount'] = (i.AdjustAmount || 0)
          }
        })
        self.orders = [...orders]
        if (json.LastEvaluatedKey) {
          self.fetchData(json.LastEvaluatedKey)
          self.LastEvaluatedKey = json.LastEvaluatedKey
        } else {
          self.LastEvaluatedKey = null
        }
      }
      if (!this.$route.params.customer) {
        let q = ['status='+encodeURIComponent(this.$route.params.status), 'IncludeTrips=true']
        if (this.$route.params.startDate) {
          q.push('startDate='+encodeURIComponent(this.$route.params.startDate))
        }
        if (lastEvaluatedKey) {
          q.push('PK='+encodeURIComponent(lastEvaluatedKey['PK']))
          q.push('SK='+encodeURIComponent(lastEvaluatedKey['SK']))
          q.push('OrderID='+encodeURIComponent(lastEvaluatedKey['OrderID']))
          q.push('OrderStatus='+encodeURIComponent(lastEvaluatedKey['OrderStatus']))
        }
        API.get('ezietruckapi', `/api/orders?${q.join('&')}`).then(cb)
      } else {
        if (lastEvaluatedKey) {
          body['opt']['LastEvaluatedKey'] = lastEvaluatedKey
        }
        API.post('ezietruckapi', `/api/customers/${this.$route.params.customer}/orders`, {body: body}).then(cb)
      }
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY hh:mm")
      } else {
        return ''
      }
    },
    handleOrderSelect: function (event) {
      this.allowClick = event
    },
    handleOrderAction: function (event) {
      this.showSnackbar = true
      this.snackBarMsg = event
      this.allowClick = null
      this.orders = []
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters(['user']),
    groupedByOrder: function () {
      let orders = {}
      for (let o of this.orders) {
        if (orders[o[this.groupBy]]) {
          orders[o[this.groupBy]].items.push(o)
          orders[o[this.groupBy]].trip += 1
          orders[o[this.groupBy]].sales += o['CustomerPaidAmount']
          orders[o[this.groupBy]].cost += o['Trip.DeliveryCost'] + o['Trip.DailyPickerCost'] + o['Trip.OtherCost'] + o['Trip.SalaryPerTrip'] + o['Trip.FuelAllowance'] + o['Trip.AdjustAmount']
          orders[o[this.groupBy]].margin += (o['CustomerPaidAmount'] + (o['AdjustAmount'] || 0)) - (o['Trip.DeliveryCost'] + o['Trip.DailyPickerCost'] + o['Trip.OtherCost'] + o['Trip.SalaryPerTrip'] + o['Trip.FuelAllowance'] + o['Trip.AdjustAmount'])
        } else {
          orders[o[this.groupBy]] = {
            title: o[this.groupBy],
            items: [o],
            trip: 1,
            sales: o['CustomerPaidAmount'] + (o['AdjustAmount'] || 0),
            cost: o['Trip.DeliveryCost'] + o['Trip.DailyPickerCost'] + o['Trip.OtherCost'] + o['Trip.SalaryPerTrip'] + o['Trip.FuelAllowance'] + o['Trip.AdjustAmount'],
            margin: (o['CustomerPaidAmount'] + (o['AdjustAmount'] || 0)) - (o['Trip.DeliveryCost'] + o['Trip.DailyPickerCost'] + o['Trip.OtherCost'] + o['Trip.SalaryPerTrip'] + o['Trip.FuelAllowance'] + o['Trip.AdjustAmount'])
          }
        }
      }
      return orders
    }
  },
  created () {
    this.fetchData()
  }
}
</script>