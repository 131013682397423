import Vue from 'vue'
import utilities from './utilities'
import store from './store'
import App from './App.vue'
import router from './router'
import { MdButton, MdIcon, MdBottomBar, MdApp, MdContent,
  MdToolbar, MdField, MdTabs, MdSnackbar, MdDrawer, MdList,
  MdAvatar, MdCard, MdTable, MdMenu, MdBadge, MdAutocomplete,
  MdHighlightText, MdDatepicker, MdDialog, MdDivider, MdRipple,
  MdRadio, MdSwitch, MdCheckbox, MdSubheader, MdDialogConfirm,
  MdChips } from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import './main.scss'
import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { initializeApp } from "firebase/app"
import "firebase/analytics";
import 'canvas-datagrid';

initializeApp({
  apiKey: "AIzaSyAghlqYB4A2aRYuDpLgBF1BPDldLR_mtno",
  authDomain: "ezie-truck.firebaseapp.com",
  databaseURL: "https://ezie-truck.firebaseio.com",
  projectId: "ezie-truck",
  storageBucket: "ezie-truck.appspot.com",
  messagingSenderId: "922775630400",
  appId: "1:922775630400:web:b3dc7d72df038557afe5a5"
})

Chart.plugins.register(ChartDataLabels);

import awsconfig from './aws-exports'
Amplify.configure(awsconfig)



Vue.use(AmplifyPlugin, AmplifyModules)

Vue.use(utilities)

Vue.use(MdButton)
Vue.use(MdIcon)
Vue.use(MdBottomBar)
Vue.use(MdApp)
Vue.use(MdContent)
Vue.use(MdToolbar)
Vue.use(MdField)
Vue.use(MdTabs)
Vue.use(MdSnackbar)
Vue.use(MdDrawer)
Vue.use(MdList)
Vue.use(MdAvatar)
Vue.use(MdCard)
Vue.use(MdTable)
Vue.use(MdMenu)
Vue.use(MdBadge)
Vue.use(MdAutocomplete)
Vue.use(MdHighlightText)
Vue.use(MdDatepicker)
Vue.use(MdDialog)
Vue.use(MdDivider)
Vue.use(MdRipple)
Vue.use(MdRadio)
Vue.use(MdSwitch)
Vue.use(MdCheckbox)
Vue.use(MdSubheader)
Vue.use(MdDialogConfirm)
Vue.use(MdChips)

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
    methods: {
        isInvalidValue: function isInvalidValue () {
            return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput
        }
    }
}))

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-icon', LIcon);
Vue.component('l-popup', LPopup);

Vue.config.productionTip = false
Vue.config.ignoredElements = ['canvas-datagrid'];

router.beforeEach(function (to, from, next) {
  store.commit('PUSH_HISTORY', {name: to.name, path: to.path, params: to.params})
  try {
    Amplify.Auth.currentAuthenticatedUser().then((user) => {
      store.commit('setUser', user)
      // if (user.signInUserSession) {
      //   // console.log(user.signInUserSession.idToken.payload.email.split('@')[1])
      //   // Amplify.Auth.signOut().then(console.log('Invalid domain'))
      // }
    }).catch(console.log)
  } catch {
    next('/')
  }
  next()
})

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')

