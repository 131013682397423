<template>
  <div>
    <div v-show="isShow">
      <md-field v-for="(v, k) in fields" :key="k">
        <label>{{k}}</label>
        <div v-if="!isArray(v)">
          <md-button class="md-icon-button" @click="openPhotoDialog([v])" v-show="isPhoto(v)">
            <md-icon>image</md-icon>
          </md-button>
          <md-input :value="v" readonly v-show="!isPhoto(v)"></md-input>
        </div>
        <div v-if="isArray(v)">
          <md-button class="md-icon-button" @click="openPhotoDialog([v])" v-show="isPhoto(vv)" v-for="vv in v" :key="'md-image' + vv">
            <md-icon>image</md-icon>
          </md-button>
          <md-input :value="vv" readonly v-show="!isPhoto(vv)" v-for="vv in v" :key="'md-input' + vv"></md-input>
        </div>
      </md-field>
    </div>
    <md-button @click="$router">
      <span v-show="!isShow">Show</span>
      <span v-show="isShow">Hide</span>
    </md-button>
  </div>
</template>

<script>
export default {
  name: 'CustomField',
  props: {
    fields: Object
  },
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    openPhotoDialog: function (photo) {
      this.$emit('photo', photo)
    },
    isPhoto: function(v) {
      if (typeof v === 'string') {
        return v.startsWith('Driver_Diary_Images/')
      } else {
        return false
      }
    },
    isArray: function(v) {
      return Array.isArray(v)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
