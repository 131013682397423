<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-section-start">
        <h3 class="md-title">Announce: {{$route.params.announceID.split('#')[1]}}</h3>
      </div>
    </md-toolbar>
    <md-table>
      <md-table-row>
        <md-table-head>Title</md-table-head>
        <md-table-cell>{{announce.Title}}</md-table-cell>
        <md-table-head>Description</md-table-head>
        <md-table-cell>{{announce.Description}}</md-table-cell>
      </md-table-row>
      <md-table-row>
        <md-table-head>Start Date Time</md-table-head>
        <md-table-cell>{{announce.StartDateTime}}</md-table-cell>
        <md-table-head>End Date Time</md-table-head>
        <md-table-cell>{{announce.EndDateTime}}</md-table-cell>
      </md-table-row>
      <md-table-row>
        <md-table-head>Vehicle Truck Type</md-table-head>
        <md-table-cell>{{announce.Conditions.Truck.VehicleTruckType.join(',')}}</md-table-cell>
        <md-table-head>Vehicle Container Type</md-table-head>
        <md-table-cell>{{announce.Conditions.Truck.VehicleContainerType.join(',')}}</md-table-cell>
      </md-table-row>
      <md-table-row>
        <md-table-head>Driver Team</md-table-head>
        <md-table-cell>{{announce.Conditions.Driver.DriverTeam.join(',')}}</md-table-cell>
        <md-table-head>Driver Tags</md-table-head>
        <md-table-cell>{{announce.Conditions.Driver.DriverTags.join(',')}}</md-table-cell>
      </md-table-row>
      <md-table-row v-show="!!announce.HeroImage">
        <md-table-head>Hero Image</md-table-head>
        <md-table-cell>
          <md-button class="md-icon-button" @click="openPhotoDialog(announce.HeroImage)">
              <md-icon>image</md-icon>
            </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <hr />
    <md-table v-model="sortedAnnounceDriverItems">
      <md-table-row slot="md-table-row" slot-scope="{ item }" >
        <md-table-cell md-label="Driver Name">
          <router-link :to="'/Drivers/' + item.PK.split('#')[1]">{{item.DriverName}}</router-link>
        </md-table-cell>
        <md-table-cell md-label="Driver Phone">
          {{item.DriverPhoneNumber}}
        </md-table-cell>
        <md-table-cell md-label="Vehicle">
          {{item.VehicleTruckType}} {{item.VehicleContainerType}}
        </md-table-cell>
        <md-table-cell md-label="Driver Team">
          {{item.DriverTeam}}
        </md-table-cell>
        <md-table-cell md-label="Driver Tag">
          {{(item.DriverTags || []).join(',')}}
        </md-table-cell>
        <md-table-cell md-label="Attended At">
          {{dateTimeFormatter(item.AttendedAt)}}
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-dialog :md-active.sync="showPhotoDialog">
      <md-dialog-title>Photo</md-dialog-title>
      <div style="overflow: auto">
        <md-content v-for="photo in currentPhoto" :key="photo">
          <my-s3-image :imagePath="photo"></my-s3-image>
        </md-content>
      </div>
    </md-dialog>
    <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackBarMsg}}</span>
    </md-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'
import MyS3Image from '../components/MyS3Image'

export default {
  name: 'JobAnnounceDetail',
  components: {MyS3Image},
  data () {
    return {
      announceDriverItems: [],
      loading: false,
      truckTypeOptions: [],
      containerTypeOptions: [],
      driverTeamOptions: [],
      driverTagOptions: [],
      showNewDialog: false,
      hideSubmit: false,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      announce: {
        Title: null,
        Description: null,
        Conditions: {
          Truck: {
            VehicleTruckType: [],
            VehicleContainerType: []
          },
          Driver: {
            DriverTeam: [],
            DriverTags: []
          }
        },
        StartDateTime: null,
        EndDateTime: null,
        HeroImage: null
      },
      showPhotoDialog: false,
      currentPhoto: null
    }
  },
  methods: {
    fetchData: function () {
      if (this.$route.params.announceID) {
        API.get('ezietruckapi', `/api/announces/${encodeURIComponent(this.$route.params.announceID)}`).then((json) => {
          this.announce = json
        })
      }
    },
    fetchDriverAnnounce: function (lastEvaluatedKey) {
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.announceDriverItems = []
      }
      API.get('ezietruckapi', `/api/announces/${encodeURIComponent(this.$route.params.announceID)}/drivers${q}`).then((json) => {
        this.announceDriverItems = [...this.announceDriverItems, ...json.Items]
        if (json.LastEvaluatedKey) {
          this.fetchDriverAnnounce(json.LastEvaluatedKey)
        }
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("lll")
      } else {
        return ''
      }
    },
    openPhotoDialog: function (photo) {
      this.showPhotoDialog = true
      this.currentPhoto = [photo]
    },
  },
  computed: {
    ...mapGetters(['user']),
    sortedAnnounceDriverItems () {
      return this.announceDriverItems.sort((b,a) => (a.AttendedAt || '~').localeCompare((b.AttendedAt || '~')))
    }
  },
  created () {
    this.fetchData()
    this.fetchDriverAnnounce()
  }
}
</script>
