<template>
  <div id="app">
    <md-app md-waterfall md-mode="fixed" :style="{height: ($vssHeight-56) + 'px'}">
      <md-app-toolbar class="md-primary">
        <md-button class="md-icon-button" @click="menuVisible = !menuVisible" v-show="isAllow">
          <md-icon>menu</md-icon>
        </md-button>
        <div class="md-title" v-show="isAllow">
          <span v-if="['Orders', 'FavoriteOrder', 'ConfirmOrderList', 'Payments', 'NoInvoices', 'CustomerList', 'DriverList'].includes($route.name)">
            <router-link to="/" class="md-accent">
              Home
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
          </span>
          <span v-if="['OrderByStatus', 'NewOrder'].includes($route.name)">
            <router-link to="/" class="md-accent">
              Home
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
            <router-link to="/Orders" class="md-accent">
              Orders
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
          </span>
          <span v-show="['OrderForm'].includes($route.name)">
            <router-link to="/" class="md-accent">
              Home
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
            <router-link to="/Orders" class="md-accent">
              Orders
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
          </span>
          <span v-show="['NewCustomer', 'CustomerDetail'].includes($route.name)">
            <router-link to="/" class="md-accent">
              Home
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
            <router-link to="/Customers" class="md-accent">
              Customers
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
          </span>
          <span v-show="['NoInvoiceDetail'].includes($route.name)">
            <router-link to="/" class="md-accent">
              Home
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
            <router-link to="/NoInvoices" class="md-accent">
              No Invoices
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
            <span>
              {{$route.params.customerID}}  
              ({{$route.params.billDate}}) 
            </span>
          </span>
          <span v-show="['NewDriver', 'DriverDetail'].includes($route.name)">
            <router-link to="/" class="md-accent">
              Home
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
            <router-link to="/Drivers" class="md-accent">
              Drivers
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
          </span>
          <span v-show="['PaymentByDate'].includes($route.name)">
            <router-link to="/" class="md-accent">
              Home
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
            <router-link to="/Payments" class="md-accent">
              Payments
            </router-link>
            <md-icon>keyboard_arrow_right</md-icon>
          </span>
          {{$route.meta.title}}
          <span v-show="['OrderByStatus'].includes($route.name)">
            {{$route.params.customer}} 
            {{$route.params.status}}
          </span>
          <span v-show="['Order', 'OrderForm', 'OrderTripForm', 'TripSearch'].includes($route.name)">
            {{$route.params.orderID}}
          </span>
          <span v-show="['PaymentByDate'].includes($route.name)">
            {{$route.params.date}}
          </span>
        </div>
        <div class="md-title" v-show="!isAllow">Login</div>
        <div class="md-toolbar-section-end" v-if="isAllow">
          <div class="md-layout">
            <div class="md-layout-item">
              <md-avatar class="md-large" style="margin-top: 6px; margin-right:10px;">
                <img :src="user.signInUserSession.idToken.payload.picture" alt="Avatar">
              </md-avatar>
            </div>
            <div class="md-layout-item" style="margin-top: 10px;">
              <amplify-sign-out button-text="Sign Out"></amplify-sign-out>
            </div>
          </div>
        </div>
      </md-app-toolbar>
      <md-app-drawer :md-active.sync="menuVisible" md-immediately>
        <md-toolbar class="md-transparent" md-elevation="0">
          <router-link to="/">
            <img src="./assets/ezie_logo.png" />
            </router-link>
          <router-link to="/">
           <span class="md-title">EzieLogis</span>
          </router-link>
        </md-toolbar>
        <md-list>
          <md-list-item to="/FavoriteOrder" @click="menuVisible = !menuVisible">
            <md-icon>storefront</md-icon>
            <span class="md-list-item-text">Favorite Delivery Job</span>
          </md-list-item>
          <md-list-item to="/ConfirmOrder" @click="menuVisible = !menuVisible">
            <md-icon>check</md-icon>
            <span class="md-list-item-text">Confirmed Delivery</span>
          </md-list-item>
          <md-list-item to="/PaymentLogs" @click="menuVisible = !menuVisible">
            <md-icon>format_list_bulleted</md-icon>
            <span class="md-list-item-text">Payment Log Driver</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/InvoiceLogs">
            <md-icon>format_list_bulleted</md-icon>
            <span class="md-list-item-text">Receipt Log Customer</span>
          </md-list-item>
          <md-list-item to="/TripDashboard" @click="menuVisible = !menuVisible">
            <md-icon>insert_chart_outlined</md-icon>
            <span class="md-list-item-text">Trip Dashboard</span>
          </md-list-item>
          <md-list-item to="/SaleDashboard" @click="menuVisible = !menuVisible">
            <md-icon>show_chart</md-icon>
            <span class="md-list-item-text">Sales Dashboard</span>
          </md-list-item>
          <md-list-item to="/CostDashboard" @click="menuVisible = !menuVisible">
            <md-icon>score</md-icon>
            <span class="md-list-item-text">Cost Dashboard</span>
          </md-list-item>
          <md-list-item to="/PaymentDashboard" @click="menuVisible = !menuVisible">
            <md-icon>score</md-icon>
            <span class="md-list-item-text">Payment Dashboard</span>
          </md-list-item>
          <md-list-item to="/MarginDashboard" @click="menuVisible = !menuVisible">
            <md-icon>pie_chart</md-icon>
            <span class="md-list-item-text">Margin Dashboard</span>
          </md-list-item>
          <md-list-item  v-for="option in links" :href="option.Name.split(',')[1]" target="_blank" :key="option.SK" @click="menuVisible = !menuVisible">
            <md-icon>link</md-icon>
            <span class="md-list-item-text">{{option.Name.split(',')[0]}}</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/PaymentRequest">
            <md-icon>payments</md-icon>
            <span class="md-list-item-text">Payment Request</span>
          </md-list-item>
          <md-list-item to="/PaymentRequestDashboard" @click="menuVisible = !menuVisible">
            <md-icon>align_horizontal_left</md-icon>
            <span class="md-list-item-text">Payment Request Dashboard</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/Drivers">
            <md-icon>portrait</md-icon>
            <span class="md-list-item-text">Driver Profile</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/IncompleteDrivers">
            <md-icon>portrait</md-icon>
            <span class="md-list-item-text">Incomplete Driver Profile</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/DriverCheckInReport">
            <md-icon>portrait</md-icon>
            <span class="md-list-item-text">Driver CheckIn Report</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/DriverAvailableReport">
            <md-icon>portrait</md-icon>
            <span class="md-list-item-text">Driver Available Report</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/JobAnnounce">
            <md-icon>book</md-icon>
            <span class="md-list-item-text">Job Announce</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/DriverLocation">
            <md-icon>map</md-icon>
            <span class="md-list-item-text">Ezie360</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/DiaryList">
            <md-icon>book</md-icon>
            <span class="md-list-item-text">Driver Diary</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/Customers">
            <md-icon>portrait</md-icon>
            <span class="md-list-item-text">Customer Profile</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/LineDiaryFormTemplate">
            <md-icon>portrait</md-icon>
            <span class="md-list-item-text">Line Diary Form Template</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/LineCheckIOFormTemplate">
            <md-icon>portrait</md-icon>
            <span class="md-list-item-text">Line Check In - Out Form Template</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/BatchUpdate">
            <md-icon>save_alt</md-icon>
            <span class="md-list-item-text">Batch update</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/Options">
            <md-icon>category</md-icon>
            <span class="md-list-item-text">Option</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/PhotoLibrary">
            <md-icon>collections</md-icon>
            <span class="md-list-item-text">Photo Library</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/OrderDashboard">
            <md-icon>save_alt</md-icon>
            <span class="md-list-item-text">Order Dashboard</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/OrderCalculator">
            <md-icon>save_alt</md-icon>
            <span class="md-list-item-text">Order Calculator</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/NewOrderV2">
            <md-icon>save_alt</md-icon>
            <span class="md-list-item-text">New Order</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/CustomFieldList">
            <md-icon>save_alt</md-icon>
            <span class="md-list-item-text">Custom Field</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/LookupList">
            <md-icon>save_alt</md-icon>
            <span class="md-list-item-text">Lookup List</span>
          </md-list-item>
          <md-list-item @click="menuVisible = !menuVisible" to="/RatePlanList">
            <md-icon>save_alt</md-icon>
            <span class="md-list-item-text">Rate Plan</span>
          </md-list-item>
        </md-list>
      </md-app-drawer>
      <md-app-content>
        <router-view v-if="isAllow"></router-view>
        <div class="md-layout md-alignment-center">
          <md-card v-show="!isAllow" class="md-layout-item md-xlarge-size-33  md-large-size-33 md-medium-size-33 md-small-size-50 md-xsmall-size-100">
            <md-card-media-actions>
              <md-card-media md-big>
                <img src="./assets/ezie_logo.png" />
              </md-card-media>
              <md-card-actions>
                <md-list>
                  <md-list-item class="md-button" @click="$Amplify.Auth.federatedSignIn()">
                    <md-icon>person</md-icon>
                    <span class="md-list-item-text">Staff</span>
                  </md-list-item>
                  <md-list-item class="md-button" href="https://driver.eziecorp.com/">
                    <md-icon>local_shipping</md-icon>
                    <span class="md-list-item-text">Driver</span>
                  </md-list-item>
                </md-list>
              </md-card-actions>
            </md-card-media-actions>
          </md-card>
        </div>
      </md-app-content>
    </md-app>
    <div>
      <md-bottom-bar class="md-layout" :md-sync-route="true" v-show="isAllow">
        <md-bottom-bar-item to="/Orders" exact md-label="Order" md-icon="local_shipping" :style="{'max-width': $vssWidth/4 + 'px'}"></md-bottom-bar-item>
        <md-bottom-bar-item to="/Payments" md-label="Payment" md-icon="money" :style="{'max-width': $vssWidth/4 + 'px'}"></md-bottom-bar-item>
        <md-bottom-bar-item to="/NoInvoices" md-label="Invoice" md-icon="receipt" :style="{'max-width': $vssWidth/4 + 'px'}"></md-bottom-bar-item>
        <md-bottom-bar-item to="/InvoiceTracks" md-label="Track" md-icon="whatshot" :style="{'max-width': $vssWidth/4 + 'px'}"></md-bottom-bar-item>
      </md-bottom-bar>
    </div>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters, mapMutations } from 'vuex'
import { API } from 'aws-amplify'

export default {
  mixins: [VueScreenSize.VueScreenSizeMixin],
  name: 'app',
  data () {
    return {
      menuVisible: false,
      links: []
    }
  },
  methods: {
    fetchData: function () {
      let self = this
      API.get('ezietruckapi', '/api/options/Link').then((json) => {
        self.links = json
      }).catch(() => {})
    },
    ...mapMutations(['setUser'])
  },
  mounted () {
    this.$Amplify.Hub.listen("auth", ({ payload: { event, data } }) => { 
      switch (event) {
        case "signIn":
          if (data.attributes.email === 'jjlowing@gmail.com' || data.attributes.email.includes('eziecorp.com')) {
            this.setUser(data)
            this.fetchData()
          }
          break
        case "signOut":
          this.setUser(null)
          break
        case "cognitoHostedUI":
          break
      }
    })
    this.fetchData()
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user', 'history']),
    isAllow: function () {
      // if (this.user && this.user.signInUserSession && this.user.signInUserSession.idToken.payload.email.split('@')[1] === 'eziecorp.com') {
      //   return true
      // } else {
      //   return false
      // }
      return this.user
    }
  }
}
</script>

<style type="text/css">
  #app {
    font-family: 'Kanit', sans-serif;
  }
  .icon-cell > .md-table-cell-container {
    padding: 0px;
  }
  .md-avatar div img.amplify-image {
    width: 100%;
    height: 100%;
    display: block;
  }
</style>