<template>
  <div>
    <md-toolbar  :class="{'highlight-field': true}">
      <div class="md-toolbar-section-start">
        <h3 class="md-subtitle">{{payment.title}} ({{$currencyFormater(payment.sum)}})</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button @click="deletePV(payment.pv)">Delete</md-button>
        <md-button class="md-icon-button" v-show="!isShowTable" @click="isShowTable=true">
          <md-icon>expand_less</md-icon>
        </md-button>
        <md-button class="md-icon-button" v-show="isShowTable" @click="isShowTable=false">
          <md-icon>expand_more</md-icon>
        </md-button>
      </div>
    </md-toolbar>
      <md-table class="md-primary" md-card v-if="isShowTable">
        <md-table-row slot="md-table-row" v-for="item in payment.items" :key="item.SK">
          <md-table-cell md-label="Payment Voucher">{{ item.PaymentVoucher }}</md-table-cell>
          <md-table-cell md-label="PDNumber"><router-link :to="'/Payments/' + item.PDNumber">{{ item.PDNumber }}</router-link></md-table-cell>
          <md-table-cell md-label="Driver Name">{{ item.DriverName }}</md-table-cell>
          <md-table-cell md-label="Order Date">{{ dateTimeFormatter(item.OrderStartDate)}} - {{ dateTimeFormatter(item.OrderEndDate)}}</md-table-cell>
          <md-table-cell md-label="Order Owner">{{ item.OrderCreatedBy.split('@')[0] }}</md-table-cell>
          <md-table-cell md-label="Total">{{ $currencyFormater(item.TotalPaidAmount) }}</md-table-cell>
          <md-table-cell md-label="Paid By">{{ item.PaidBy }}</md-table-cell>
          <md-table-cell md-label="Operation">
            <md-button @click="deletePD(item.PDNumber)">Delete</md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
  </div>
</template>

<script>
import moment from 'moment'
import { API } from 'aws-amplify'
import { mapGetters } from 'vuex'

export default {
  name: 'PVItemTable',
  props: {
    payment: Object
  },
  methods: {
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY")
      } else {
        return ''
      }
    },
    deletePV: function (pv) {
      let vm = this
      API.del('ezietruckapi', `/api/paymentVouchers/${pv}`).then(() => {
        vm.$emit('deletepv', pv)
      })
    },
    deletePD: function (pd) {
      let vm = this
      API.del('ezietruckapi', `/api/paidDrivers/${pd}`).then(() => {
        vm.$emit('deletepd', pd)
      })
    }
  },
  data () {
    return {
      isShowTable: false,
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    sortedItems: function () {
      return [...this.payment.items].sort((a,b) => a.PaymentVoucher.localeCompare(b.PaymentVoucher))
    }
  }
}
</script>
