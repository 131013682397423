<template>
  <div>
    <md-dialog :md-active.sync="showPhotoDialog">
      <md-dialog-title>Photo</md-dialog-title>
      <div style="overflow: auto">
        <md-content v-for="photo in currentPhoto" :key="photo">
          <my-s3-image :imagePath="photo"></my-s3-image>
        </md-content>
      </div>
    </md-dialog>
    <div class="md-layout">
      <div class="md-layout-item">Diary</div>
      <div class="md-layout-item">
        <md-datepicker v-model="currentStartDate" :md-model-type="String" md-immediately>
          <label>Start Date</label>
        </md-datepicker>
      </div>
      <div class="md-layout-item">
        <md-datepicker v-model="currentEndDate" :md-model-type="String" md-immediately>
          <label>End Date</label>
        </md-datepicker>
      </div>
      <div class="md-layout-item">
        <md-button @click="searhDiaryAgain()">Search</md-button>
        <md-button :to="history[0] || '/'">Back</md-button>
      </div>
    </div>
    <h3 v-show="foundDiaries.length === 0">No Diary found</h3>
    <h4>No OrderID Diary</h4>
    <md-table v-model="notHasOrderIDDiary" @md-selected="onDiarySelect">
      <md-table-toolbar slot="md-table-alternate-header" slot-scope="{}">
        <div class="md-toolbar-section-start">Update OrderID of selected diary to {{order.OrderID}}</div>

        <div class="md-toolbar-section-end">
          <md-button @click="handleSaveDiary">
            Save
          </md-button>
        </div>
      </md-table-toolbar>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :md-disabled="item.CustomerID !== order.CustomerID" md-selectable="multiple" md-auto-select>
        <md-table-cell md-label="Created At">{{dateTimeFormatter(item.CreatedAt)}}</md-table-cell>
        <md-table-cell md-label="Driver">
          <router-link :to="'/Drivers/' + item.Driver.DriverID">{{ item.Driver.DriverName }} ({{ item.Driver.DriverPhoneNumber }})</router-link>
        </md-table-cell>
        <md-table-cell md-label="Vehicle">
          {{ item.Vehicle ? item.Vehicle.VehicleLicenseNumber : "" }} {{ item.Vehicle ? item.Vehicle.VehicleLicenseProvince : "" }} -
          {{ item.Vehicle ? item.Vehicle.VehicleTruckType : "" }} {{ item.Vehicle ? item.Vehicle.VehicleContainerType : "" }}
        </md-table-cell>
        <md-table-cell md-label="Customer">{{ item.CustomerID }}</md-table-cell>
        <md-table-cell md-label="OrderID"><router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link></md-table-cell>
        <md-table-cell md-label="Stop for">{{ item.StopFor }}</md-table-cell>
        <md-table-cell md-label="Location"><a @click="mapFocusAt(item.Location)">{{ item.Area }}</a></md-table-cell>
        <md-table-cell md-label="Snapshot">
          <md-button class="md-icon-button" @click="openPhotoDialog(item.Photo)">
            <md-icon>image</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell md-label="Album">
          <md-button class="md-icon-button" @click="openPhotoDialog(item.PhotoAlbum)">
            <md-icon>image</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell md-label="Note">{{ item.Note }}</md-table-cell>
        <md-table-cell md-label="Pick Up">{{ item.PickUpItemNo }}</md-table-cell>
        <md-table-cell md-label="Drop Off">{{ item.DropOffItemNo }}</md-table-cell>
      </md-table-row>
    </md-table>
    <h4>Diary with OrderID</h4>
    <md-table v-model="hasOrderIDDiary" @md-selected="onDiarySelect">
      <md-table-toolbar slot="md-table-alternate-header" slot-scope="{}">
        <div class="md-toolbar-section-start">Update OrderID of selected diary to {{order.OrderID}}</div>

        <div class="md-toolbar-section-end">
          <md-button @click="handleSaveDiary">
            Save
          </md-button>
        </div>
      </md-table-toolbar>
      <md-table-row slot="md-table-row" slot-scope="{ item }" :md-disabled="item.CustomerID !== order.CustomerID" md-selectable="multiple" md-auto-select>
        <md-table-cell md-label="Created At">{{dateTimeFormatter(item.CreatedAt)}}</md-table-cell>
        <md-table-cell md-label="Driver">
          <router-link :to="'/Drivers/' + item.Driver.DriverID">{{ item.Driver.DriverName }} ({{ item.Driver.DriverPhoneNumber }})</router-link>
        </md-table-cell>
        <md-table-cell md-label="Vehicle">
          {{ item.Vehicle ? item.Vehicle.VehicleLicenseNumber : "" }} {{ item.Vehicle ? item.Vehicle.VehicleLicenseProvince : "" }} -
          {{ item.Vehicle ? item.Vehicle.VehicleTruckType : "" }} {{ item.Vehicle ? item.Vehicle.VehicleContainerType : "" }}
        </md-table-cell>
        <md-table-cell md-label="Customer">{{ item.CustomerID }}{{order.CustomerID}}</md-table-cell>
        <md-table-cell md-label="OrderID"><router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link></md-table-cell>
        <md-table-cell md-label="Stop for">{{ item.StopFor }}</md-table-cell>
        <md-table-cell md-label="Location"><a @click="mapFocusAt(item.Location)">{{ item.Area }}</a></md-table-cell>
        <md-table-cell md-label="Snapshot">
          <md-button class="md-icon-button" @click="openPhotoDialog(item.Photo)">
            <md-icon>image</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell md-label="Album">
          <md-button class="md-icon-button" @click="openPhotoDialog(item.PhotoAlbum)">
            <md-icon>image</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell md-label="Note">{{ item.Note }}</md-table-cell>
        <md-table-cell md-label="Pick Up">{{ item.PickUpItemNo }}</md-table-cell>
        <md-table-cell md-label="Drop Off">{{ item.DropOffItemNo }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import MyS3Image from '../components/MyS3Image'
import moment from 'moment'

export default {
  name: 'TripSearch',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image},
  data () {
    return {
      diaryItems: [],
      currentPhoto: [],
      showPhotoDialog: false,
      loaded: false,
      currentStartDate: null,
      currentEndDate: null,
      foundDiaries: [],
      order: {},
      selectedDiary: []
    }
  },
  methods: {
    searhDiary (DriverID, OrderStartDate, OrderEndDate, OrderID) {
      this.foundDiaries = []
      let vm = this
      this.currentOrderID = OrderID
      API.get('ezietruckapi', `/api/drivers/${DriverID}`).then((results) => {
        for (let res of results) {
          if (res.SK.startsWith('#METADATA#')) {
            vm.currentDriverPhone = res.DriverPhoneNumber
            let i = moment(OrderStartDate)
            i.subtract(1, 'days')
            vm.currentStartDate = i.format('YYYY-MM-DD')
            while (i <= moment(OrderEndDate)) {
              vm.fetchedDiary(i.format('YYYY-MM-DD'), res.DriverPhoneNumber, null)
              i.add(1, 'days')
            }
            vm.currentEndDate = i.format('YYYY-MM-DD')
            vm.fetchedDiary(i.format('YYYY-MM-DD'), res.DriverPhoneNumber, null)
          }
        }
        vm.showDialog = true
      })
    },
    fetchedDiary: function (selectedDiaryDate, driverPhoneNumber, lastEvaluatedKey) {
      let q = ''
      let vm = this
      if (lastEvaluatedKey && lastEvaluatedKey.SK) {
        q = `&LastSK=${lastEvaluatedKey.SK.replace(/#/g, '%23')}`
      }
      API.get('ezietruckapi', `/api/vehicles/diary/${selectedDiaryDate}?DriverPhoneNumber=${driverPhoneNumber}${q}`).then((json) => {
        vm.foundDiaries = [...vm.foundDiaries, ...(json.Items || [])]
        if (json.LastEvaluatedKey) {
          vm.fetchedDiary(selectedDiaryDate, driverPhoneNumber, json.LastEvaluatedKey)
        }
      })
    },
    fetchData: function () {
      let vm = this
      API.get('ezietruckapi',`/api/orders/${this.$route.params.orderID}`).then((json) => {
        vm.order = Object.assign({}, vm.order, json)
      })
      API.get('ezietruckapi',`/api/orders/${this.$route.params.orderID}/trips`).then((json) => {
        vm.order = Object.assign({}, vm.order, json[0])
        vm.searhDiary(json[0].DriverID, json[0].StartDateTime, json[0].EndDateTime, json[0].OrderID)
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY hh:mm")
      } else {
        return ''
      }
    },
    openPhotoDialog: function (photo) {
      this.showPhotoDialog = true
      this.currentPhoto = photo
    },
    searhDiaryAgain () {
      this.foundDiaries = []
      let i = moment(this.currentStartDate)
      while (i <= moment(this.currentEndDate)) {
        this.fetchedDiary(i.format('YYYY-MM-DD'), this.currentDriverPhone, null)
        i.add(1, 'days')
      }
    },
    onDiarySelect (items) {
      this.selectedDiary = items
    },
    handleSaveDiary: function () {
      let vm = this
      API.patch('ezietruckapi', `/api/vehicles/diary`, {body: this.selectedDiary.map((m) => {
        return {'PK': m.PK, 'SK': m.SK, 'OrderID': this.currentOrderID}
      })}).then(() => {
        vm.searhDiaryAgain()
        vm.selectedDiary = []
      }).catch(() => {
        vm.searhDiaryAgain()
        vm.selectedDiary = []
      })
    }
  },
  computed: {
    ...mapGetters(['user', 'history']),
    hasOrderIDDiary () {
      return this.foundDiaries.filter((o) => !!o.OrderID)
    },
    notHasOrderIDDiary () {
      return this.foundDiaries.filter((o) => !o.OrderID)
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
