<template>
  <md-table class="md-primary" style="margin: 12px 0px">
    <md-table-toolbar>
      <div class="md-toolbar-section-start">
        <h3 class="md-subtitle" style="color: white">{{month.month}} (Original: {{$currencyFormater(month.sumOri)}}, Final: {{$currencyFormater(month.sumFin)}})</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button class="md-icon-button" @click="isShow=!isShow">
          <md-icon v-show="isShow">expand_less</md-icon>
          <md-icon v-show="!isShow">expand_more</md-icon>
        </md-button>
      </div>
    </md-table-toolbar>
    <md-table-row v-show="isShow">
      <md-table-head>Customer</md-table-head>
      <md-table-head>Invoice</md-table-head>
      <md-table-head>Original Total</md-table-head>
      <md-table-head>Final Total</md-table-head>
      <md-table-head>Adjust Amount</md-table-head>
      <md-table-head>Diff. Amount</md-table-head>
      <md-table-head>Operation</md-table-head>
    </md-table-row>
    <md-table-row v-show="isShow" v-for="invoice in month.invoices" :key="invoice.InvoiceNumber" :class="{'highlight-field': (invoice.InvoiceOriginalTotalAmount - invoice.InvoiceFinalTotalAmount != 0)}">
      <md-table-cell>
        <md-avatar><amplify-s3-image :imagePath="invoice.CustomerLogo" class="md-avatar md-large"></amplify-s3-image></md-avatar>
        {{invoice.CustomerName}}
      </md-table-cell>
      <md-table-cell>
        <router-link :to="'/InvoiceTracks/' + invoice.InvoiceNumber">{{invoice.InvoiceNumber}}</router-link>
      </md-table-cell>
      <md-table-cell>{{$currencyFormater(invoice.InvoiceOriginalTotalAmount)}}</md-table-cell>
      <md-table-cell>{{$currencyFormater(invoice.InvoiceFinalTotalAmount)}}</md-table-cell>
      <md-table-cell>{{$currencyFormater(invoice.CustomerPaidAdjustAmount || 0)}}</md-table-cell>
      <md-table-cell>{{$currencyFormater((invoice.InvoiceOriginalTotalAmount*1000 - invoice.InvoiceFinalTotalAmount*1000 + (invoice.CustomerPaidAdjustAmount || 0)*1000)/1000)}}</md-table-cell>
      <md-table-cell>
        <md-button @click="handleDeleteInvoice(invoice.InvoiceNumber)">delete</md-button>
      </md-table-cell>
    </md-table-row>
  </md-table>
</template>

<script>
import { API } from 'aws-amplify'

export default {
  name: 'InvoiceListTable',
  props: {
    month: Object
  },
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    handleDeleteInvoice: function (invoiceNumber) {
      API.del('ezietruckapi', '/api/customerInvoices/' + invoiceNumber).then(() => {
        this.$emit('invoicedeleted', invoiceNumber)
      })
    }
  }
}
</script>