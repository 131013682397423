<template>
  <div>
    <div>
      <md-toolbar>
        <div class="md-toolbar-row">
          <div class="md-toolbar-section-start md-layout">
            <md-autocomplete class="md-layout-item"  @input="handleCustomerChanged" :value="orderSearchForm.CustomerID" :md-options="customerItems">
              <label>Customer</label>
              <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
              </template>

              <template slot="md-autocomplete-empty" slot-scope="{ term }">
                No customer matching "{{ term }}" were found.
              </template>
            </md-autocomplete>
            <div class="md-layout-item">
              <md-datepicker v-model="orderSearchForm.StartDate" :md-model-type="String" md-immediately>
                <label>Start Date</label>
              </md-datepicker>
            </div>
            <div class="md-layout-item">
              <md-datepicker v-model="orderSearchForm.EndDate" :md-model-type="String" md-immediately>
                <label>End Date</label>
              </md-datepicker>
            </div>
          </div>
          <div class="md-toolbar-section-end">
            <md-button @click="handleSearchOrder" class="md-raised md-primary">
              <span>Search</span>
            </md-button>
          </div>
        </div>
      </md-toolbar>
      <canvas-datagrid :data.prop="editorData" :schema.prop="editorSchema" :style="editorStyle" ref="table"></canvas-datagrid>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <table width="100%" border="1">
          <tr>
            <th>Service Rate plan: ทดสอบเหมาต้นทางปลายทาง</th>
          </tr>
          <tr>
            <th>Service Item</th>
            <th>Price</th>
          </tr>
          <tbody v-for="order in orderItems" :key="order.SK">
            <tr>
              <th colspan="2">OrderID: {{ order.OrderID }}</th>
            </tr>
            <tr>
              <td>เหมาต้นทางปลายทาง</td>
              <td>1,234</td>
            </tr>
            <tr>
              <td>ค่าบริการพนักงานขึ้นลงสินค้า</td>
              <td>1,234</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="md-layout-item">
        <table width="100%" border="1">
          <tr>
            <th>Hire Rate plan: ทดสอบเหมาต้นทางปลายทาง</th>
          </tr>
          <tr>
            <th>Hire Item</th>
            <th>Price</th>
          </tr>
          <tbody v-for="order in orderItems" :key="order.SK">
            <tr>
              <th colspan="2">OrderID: {{ order.OrderID }}</th>
            </tr>
            <tr>
              <td>เหมาต้นทางปลายทาง</td>
              <td>1,434</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import moment from 'moment'
export default {
  name: 'OrderCalculator',
  data () {
    return {
      customerItems: [],
      orderItems: [],
      selectedOrder: [],
      ratePlans: {},
      step: 1,
      orderSearchForm: {
        CustomerID: null,
        StartDate: null,
        EndDate: null,
      },
      ratePlanForm: {
        serviceRatePlan: null,
        hireRatePlan: null
      },
      editorData: [],
      editorStyle: {},
      customerJobTypes: {},
      truckTypeOptions: [],
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items'].filter((i) => i.SK.startsWith('#METADATA#'))]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    handleCustomerChanged: function (value) {
      if (typeof value === 'object') {
        this.orderSearchForm = Object.assign({}, this.orderSearchForm, {
          CustomerID: ((value || {}).CustomerID || null)
        })
      }
    },
    fetchOrder: function (date, lastEvaluatedKey) {
      let self = this
      let q = `?filter=${encodeURIComponent('5:Confirmed')}`
      if (lastEvaluatedKey) {
        q += `&lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.orderItems = []
      }
      if (!self.ratePlans[`---|---|---`]) {
        self.ratePlans[`---|---|---`] = []
        self.fetchRatePlans('---', '---', '---')
      }
      if (!this.customerJobTypes[this.orderSearchForm.CustomerID]) {
        this.customerJobTypes[this.orderSearchForm.CustomerID]= []
        this.fetchFavJobTypes(this.orderSearchForm.CustomerID)
      }
      API.get('ezietruckapi', `/api/newOrders/${this.orderSearchForm.CustomerID}/${date}` + q).then((json) => {
        if (json['Items']) {
          self.orderItems = [...self.orderItems, ...json['Items']]
          self.editorData = [...self.editorData, ...json['Items'].map((i) => Object.assign({}, i, {
            MatchedDiaryNo: i.MatchedDiaries.SuggestDiaries.length + i.MatchedDiaries.SearchDiaries.length
          }))]
          for (let item of json['Items']) {
            if (!self.ratePlans[`${item['CustomerID']}|---|---`]) {
              self.ratePlans[`${item['CustomerID']}|---|---`] = []
              self.fetchRatePlans(item['CustomerID'], '---', '---')
            }
            if (!self.ratePlans[`${item['CustomerID']}|${item['JobType']}|---`]) {
              self.ratePlans[`${item['CustomerID']}|${item['JobType']}|---`] = []
              self.fetchRatePlans(item['CustomerID'], item['JobType'], '---')
            }
            if (!self.ratePlans[`${item['CustomerID']}|${item['JobType']}|${item['JobSubtype'] || '---'}`]) {
              self.ratePlans[`${item['CustomerID']}|${item['JobType']}|${item['JobSubtype'] || '---'}`] = []
              self.fetchRatePlans(item['CustomerID'], item['JobType'], item['JobSubtype'] || '---')
            }
          }
        }
        if (json.LastEvaluatedKey) {
          self.fetchOrder(json.LastEvaluatedKey)
        }
      })
    },
    handleSearchOrder: function () {
      let i = moment(this.orderSearchForm.StartDate)
      this.orderItems = []
      while (i <= moment(this.orderSearchForm.EndDate)) {
        this.fetchOrder(i.format('YYYY-MM-DD'))
        i.add(1, 'days')
      }
    },
    onOrderSelect: function (items) {
      this.selectedOrder = items
    },
    fetchRatePlans: async function (customerID, jobType, jobSubType, lastEvaluatedKey) {
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      const json = await API.get('ezietruckapi', `/api/ratePlans/${encodeURIComponent(customerID)}/${encodeURIComponent(jobType)}/${encodeURIComponent(jobSubType)}` + q)
      if (json['Items']) {
        this.ratePlans[`${customerID}|${jobType}|${jobSubType}`] = [...this.ratePlans[`${customerID}|${jobType}|${jobSubType}`], ...json['Items']]
      }
      this.ratePlans = Object.assign({}, this.ratePlans)
      if (json.LastEvaluatedKey) {
        await this.fetchRatePlans(customerID, jobType, jobSubType, json.LastEvaluatedKey)
      }
    },
    fetchFavJobTypes: async function (customerID) {
      const json = await API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`)
      this.customerJobTypes[customerID] = json
    },
    fetchOptions: function () {
      let vm = this
      API.get('ezietruckapi', `/api/options/TruckType`).then((json) => {
        vm.truckTypeOptions = [...json]
      })
    },
  },
  computed: {
    editorSchema: function () {
      return [
        {
        "name": "OrderID",
        "title": "Job Type",
        "type": "string",
        "width": 145
        },
        {
        "name": "JobType",
        "title": "Job Type",
        "type": "string",
        "width": 145,
        "enum": Object.values(this.customerJobTypes).flat().map((p) => [p.SK, p.Name])
        },
        {
        "name": "JobSubtype",
        "title": "Job Sub Type",
        "type": "string",
        "width": 145,
        "enum": []
        },
        {
        "name": "StartDate",
        "title": "Start Date",
        "type": "date",
        "width": 145
        },
        {
        "name": "ServiceRatePlan",
        "title": "Service Rate Plan",
        "type": "string",
        "enum": Object.values(this.ratePlans).flat().map((p) => [p.SK, p.Name])
        },
        {
        "name": "HireRatePlan",
        "title": "Hire Rate Plan",
        "type": "string",
        "enum": Object.values(this.ratePlans).flat().map((p) => [p.SK, p.Name])
        },
        {
        "name": "TruckType",
        "title": "Truck Type",
        "type": "string",
        "width": 130,
        "enum": this.truckTypeOptions.map((t) => [t.Name, t.Name])
        },
        {
          "name": "Remark",
          "title": "Remark",
          "type": "string"
        },
        {
        "name": "DriverName",
        "title": "Driver Name",
        "type": "string"
        },
        {
          "name": "MatchedDiaryNo",
          "title": "Matched Diary",
          "type": "number"
        }
      ]
    },
    ratePlanEnum () {
      return Object.values(this.ratePlans).flat().map((p) => [p.SK, p.Name])
    },
    editorTableErrors () {
      let out = []
      let idx = []
      let pksk = []
      let sdx = -1
      let edx = -1
      let did = -1
      let driverID = ''
      let vm = this
      for (let i = 0; i < this.editorData.length; i++) {
        for (let k in this.editorData[i]) {
          sdx = this.editorSchema.findIndex((s) => s.name === k)
          if (k === 'DriverName' && !this.editorData[i][k]) {
            out.push(`At ${i + 1}: ${k} has noDriver ID.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
          if (k === 'JobSubtype' && this.editorData[i][k]) {
            let jdx = this.customerJobTypes.findIndex((j) => j.Name === vm.editorData[i]['JobType'])
            if (jdx > -1 && !this.customerJobTypes[jdx].SubJobType.includes(this.editorData[i][k])) {
              out.push(`At ${i + 1}: ${k} has invalid Job Sub Type.`)
              idx.push(i)
              pksk.push(this.editorData[i].PK+this.editorData[i].SK)  
            }
          }
          if (k === 'MatchedDiaryNo' && this.editorData[i][k] < 1) {
            out.push(`At ${i + 1}: ${k} has no matched diaries.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
          if(sdx > -1 && this.editorSchema[sdx].type === 'number' && typeof this.editorData[i][k] === 'string' && isNaN(+(this.editorData[i][k].replace(',', '')))) {
            out.push(`At ${i + 1}: ${k} has invalid number.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
          if(sdx > -1 && this.editorSchema[sdx].type === 'date' && this.editorData[i][k] && isNaN(Date.parse(this.editorData[i][k] + 'T00:00:00Z'))) {
            out.push(`At ${i + 1}: ${k} has invalid date.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
          if(sdx > -1 && this.editorSchema[sdx].type === 'time' && this.editorData[i][k] && isNaN(Date.parse('2022-01-01T' + this.editorData[i][k] + ':00Z'))) {
            out.push(`At ${i + 1}: ${k} has invalid time.`)
            idx.push(i)
            pksk.push(this.editorData[i].PK+this.editorData[i].SK)
          }
        }
      }
      return {
        idx: idx,
        messages: out,
        pksk: pksk,
      }
    },
  },
  mounted() {
    this.fetchCustomer()
    this.fetchOptions()
  }
}
</script>
