<template>
  <div>
    <div class="md-layout" v-if="diaryObject.Driver" style="padding: 4px; border-bottom: 1px solid #c0c0c0">
      <div class="md-layout-item md-size-25">
        Driver
      </div>
      <div class="md-layout-item">
        {{ (diaryObject.Driver || {}).DriverName }} ({{ (diaryObject.Driver || {}).DriverPhoneNumber }})
      </div>
      <div class="md-layout-item md-size-25">
        Truck
      </div>
      <div class="md-layout-item">
        {{ diaryObject.Vehicle ? diaryObject.Vehicle.VehicleLicenseNumber : "" }} {{ diaryObject.Vehicle ? diaryObject.Vehicle.VehicleLicenseProvince : "" }} <br />
        {{ diaryObject.Vehicle ? diaryObject.Vehicle.VehicleTruckType : "" }} {{ diaryObject.Vehicle ? diaryObject.Vehicle.VehicleContainerType : "" }}
      </div>
    </div>
    <div class="md-layout"  style="padding: 4px; border-bottom: 1px solid #c0c0c0">
      <div class="md-layout-item md-size-25">
        Customer
      </div>
      <div class="md-layout-item">
        {{ diaryObject.CustomerID }}
      </div>
      <div class="md-layout-item md-size-25">
        Job Type
      </div>
      <div class="md-layout-item">
        {{ diaryObject.JobType }}
      </div>
    </div>
      <div class="md-layout"  style="padding: 4px; border-bottom: 1px solid #c0c0c0">
        <div class="md-layout-item md-size-25">
          <p>Created At</p>
          <p>{{datetimeFormater(diaryObject.CreatedAt)}}</p>
        </div>
        <div class="md-layout-item md-size-25">
          <p>Check In</p>
          <p v-show="diaryObject.CheckIn">{{datetimeFormater((diaryObject.CheckIn || '').split('#')[1])}}</p>
        </div>
        <div class="md-layout-item md-size-25">
          <p>Order Start DateTime</p>
          <p v-show="diaryObject.OrderID && diaryObject.StartDateTime">{{datetimeFormater(diaryObject.StartDateTime)}}</p>
        </div>
      </div>
      <div class="md-layout"  style="padding: 4px; border-bottom: 1px solid #c0c0c0">
        <div class="md-layout-item md-size-25">Order ID</div>
        <div class="md-layout-item">{{ diaryObject.OrderID }}</div>
        <div class="md-layout-item md-size-25">Stop for</div>
        <div class="md-layout-item">{{ diaryObject.StopFor }}</div>
      </div>
      <div class="md-layout"  style="padding: 4px; border-bottom: 1px solid #c0c0c0">
        <div class="md-layout-item md-size-25">Photo</div>
        <div class="md-layout-item" v-if="!diaryObject.SK || showImages">
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-elevation-3" v-for="(img, idx) in diaryObject.Photo" :key="idx + img" style="padding: 4px">
              <my-s3-image :imagePath="img"></my-s3-image>
            </div>
            <div class="md-layout-item md-size-25 md-elevation-3" v-for="(img, idx) in diaryObject.PhotoAlbum || []" :key="idx + img" style="padding: 4px">
              <my-s3-image :imagePath="img"></my-s3-image>
            </div>
          </div>
        </div>
      </div>
      <div class="md-layout"  style="padding: 4px; border-bottom: 1px solid #c0c0c0">
        <div class="md-layout-item md-size-25">Address</div>
        <a href="#" class="md-layout-item" @click="showMap=!showMap">{{ diaryObject.Area }}</a>
      </div>
      <div class="md-layout"  style="padding: 4px; border-bottom: 1px solid #c0c0c0" v-if="showMap">
        <div class="md-layout-item md-size-25">Location</div>
        <div class="md-layout-item">
          <l-map :style="{'height': ($vssHeight *0.3) + 'px', 'width': ($vssWidth *0.7) + 'px'}" :zoom="mapOption.zoom" 
            :center="mapOption.center" :options="mapOption.options">
            <l-tile-layer :url="mapOption.url" :attribution="mapOption.attribution" />
            <l-marker :lat-lng="mapOption.markerLatLng" v-if="mapOption.markerLatLng">
              <l-icon icon-url="https://map.longdo.com/mmmap/images/pin_mark.png"/>
            </l-marker>
          </l-map>
        </div>
      </div>
      <div class="md-layout"  style="padding: 4px; border-bottom: 1px solid #c0c0c0">
        <div class="md-layout-item md-size-25">Note</div>
        <div class="md-layout-item"><pre>{{ diaryObject.Note }}</pre></div>
      </div>
      <div class="md-layout"  style="padding: 4px; border-bottom: 1px solid #c0c0c0">
        <div class="md-layout-item md-size-25">Pick Up / Drop Off</div>
        <div class="md-layout-item">{{ diaryObject.PickUpItemNo }} / {{ diaryObject.DropOffItemNo }}</div>
      </div>
      <div class="md-layout"  style="padding: 4px; border-bottom: 1px solid #c0c0c0">
        <div class="md-layout-item md-size-25">Distance</div>
        <div class="md-layout-item">{{ diaryObject.Distance }}</div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item" v-for="c in customFields" :key="c.key + c.value">
          <strong>{{c.key}} : </strong>
          {{ c.value }}
        </div>
      </div>
      <div class="md-layout" v-if="!diaryObject.SK || showImages">
        <div class="md-layout-item" v-for="c in customFieldPhotos" :key="c.key + c.value">
          <MyS3Image :imagePath="c.value" heigth="128px"></MyS3Image>
          <p>{{ c.key }}</p>
        </div>
      </div>
      <div class="md-layout" v-show="diaryObject.SK">
        <div class="md-layout-item">
          <md-button @click="showImages=!showImages">
            <span v-show="!showImages">Show Images</span>
            <span v-show="showImages">Hide Images</span>
          </md-button>
        </div>
      </div>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'
import MyS3Image from '../components/MyS3Image'
import { latLng } from "leaflet"

export default {
  name: 'DiaryDetail',
  props: {
    pk: String,
    sk: String,
    diary: Object,
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image},
  data () {
    return {
      diaryObject: {},
      errors: {},
      LastEvaluatedKey: null,
      mapOption: {
        zoom: 13,
        center: latLng(13.75318, 100.53173),
        url: 'https://ms.longdo.com/mmmap/tile.php?zoom={z}&x={x}&y={y}&key=c146605c52087ac63385d5db545fb84c&proj=epsg3857&HD=1',
        attribution:
          '© Longdo Map',
        markerLatLng: null,
        options: {
          zoomSnap: 0.5
        },
        showMap: true
      },
      showMap: false,
      showImages: false
    }
  },
  methods: {
    fetchDiary: function (pk, sk) {
      let vm = this
      API.get('ezietruckapi', `/api/vehicles/diary/${encodeURIComponent(pk)}/${encodeURIComponent(sk)}`).then((json) => {
        if (json.Item) {
          vm.diaryObject = json.Item
          vm.mapOption = Object.assign({}, vm.mapOption, {
            center: latLng(json.Item.Location.Lat, json.Item.Location.Lon),
            markerLatLng: latLng(json.Item.Location.Lat, json.Item.Location.Lon),
          })
        }
      })
    },
    datetimeFormater: function (str) {
      let m = moment(str)
      m.local() 
      return m.format('DD MMM YYYY HH:mm:ss')
    },
    mapFocusAt: function (gps) {
      this.mapOption = Object.assign({}, this.mapOption, {
        center: latLng(gps.Lat, gps.Lon),
        markerLatLng: latLng(gps.Lat, gps.Lon),
      })
    },
    isPhoto: function(v) {
      if (typeof v === 'string') {
        return v.startsWith('Driver_Diary_Images/')
      } else {
        return false
      }
    },
    isArray: function(v) {
      return Array.isArray(v)
    }
  },
  computed: {
    ...mapGetters(['user']),
    customFields () {
      const out = []
      for (let k in this.diaryObject.CustomField) {
        if (Array.isArray(this.diaryObject.CustomField[k])) {
          for (let v of this.diaryObject.CustomField[k]) {
            if (!this.isPhoto(v)) {
              out.push({
                value: v,
                key: k
              })
            }
          }
        } else {
          if (!this.isPhoto(this.diaryObject.CustomField[k])) {
            out.push({
              value: this.diaryObject.CustomField[k],
              key: k
            })
          }
        }
      }
      return out
    },
    customFieldPhotos () {
      const out = []
      for (let k in this.diaryObject.CustomField) {
        if (Array.isArray(this.diaryObject.CustomField[k])) {
          for (let v of this.diaryObject.CustomField[k]) {
            if (this.isPhoto(v)) {
              out.push({
                value: v,
                key: k
              })
            }
          }
        } else {
          if (this.isPhoto(this.diaryObject.CustomField[k])) {
            out.push({
              value: this.diaryObject.CustomField[k],
              key: k
            })
          }
        }
      }
      return out
    }
  },
  watch: {
    'pk': function(newVal) {
      if (this.PK && this.SK) {
        this.fetchDiary(this.pk, this.sk)
      }
    },
    'sk': function(newVal) {
      if (this.PK && this.SK) {
        this.fetchDiary(this.pk, this.sk)
      }
    },
    'diary': function(newVal) {
      this.diaryObject = newVal
      this.mapOption = Object.assign({}, this.mapOption, {
        center: latLng(newVal.Location.Lat, newVal.Location.Lon),
        markerLatLng: latLng(newVal.Location.Lat, newVal.Location.Lon),
      })
    }
  },
  created () {
    if (this.$route.params.pk && this.$route.params.sk) {
      this.fetchDiary(this.$route.params.pk, this.$route.params.sk)
    } else if (this.pk && this.sk) {
      this.fetchDiary(this.pk, this.sk)
    } else if (this.diary) {
      this.diaryObject = this.diary
      this.mapOption = Object.assign({}, this.mapOption, {
        center: latLng(this.diary.Location.Lat, this.diary.Location.Lon),
        markerLatLng: latLng(this.diary.Location.Lat, this.diary.Location.Lon),
      })
    }
  }
}
</script>