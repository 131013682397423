<template>
  <div>
  <md-dialog-confirm
    :md-active.sync="active"
    md-title="No Diary record"
    md-content="Proceed to pay driver without Diary evidence found."
    md-confirm-text="Continue"
    md-cancel-text="Cancel"
    @md-cancel="diaryConfirm=false"
    @md-confirm="handleNoDiaryConfirm">
  </md-dialog-confirm>
    <md-dialog :md-active.sync="showMapDialog">
      <md-dialog-title>Location</md-dialog-title>
      <l-map :style="{'height': ($vssHeight - 20) + 'px', 'width': ($vssWidth *0.3) + 'px'}" :zoom="mapOption.zoom" 
          :center="mapOption.center" :options="mapOption.options">
        <l-tile-layer :url="mapOption.url" :attribution="mapOption.attribution" />
        <l-marker :lat-lng="mapOption.markerLatLng" v-if="mapOption.markerLatLng">
          <l-icon icon-url="https://map.longdo.com/mmmap/images/pin_mark.png"/>
        </l-marker>
      </l-map>
    </md-dialog>
    <md-dialog :md-active.sync="showPhotoDialog">
      <md-dialog-title>Photo</md-dialog-title>
      <div style="overflow: auto">
        <md-content v-for="photo in currentPhoto" :key="photo">
          <my-s3-image :imagePath="photo"></my-s3-image>
        </md-content>
      </div>
    </md-dialog>
    <form @submit.prevent="handleCreatePayment">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <div class="md-layout">
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.PDNumber']}">
                <label>PDNumber <span style="color: red">*</span></label>
                <md-input v-model="formData.PDNumber" :disabled="formData.PK !== '-'"></md-input>
                <span class="md-error" v-if="errors['instance.PDNumber']">{{errors['instance.PDNumber']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.OrderCreatedBy']}">
                <label>Order Owner <span style="color: red">*</span></label>
                <md-select v-model="formData.OrderCreatedBy">
                  <md-option v-for="u in users" :value="u" :key="u">{{u}}</md-option>
                </md-select>
                <span class="md-error" v-if="errors['instance.OrderCreatedBy']">{{errors['instance.OrderCreatedBy']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.TripCostAmount']}">
                <label>Trip Cost Amount <span style="color: red">*</span></label>
                <md-input v-model.number="formData.TripCostAmount"></md-input>
                <span class="md-error" v-if="errors['instance.TripCostAmount']">{{errors['instance.TripCostAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.PickerCostAmount']}">
                <label>Picker Cost Amount</label>
                <md-input v-model.number="formData.PickerCostAmount"></md-input>
                <span class="md-error" v-if="errors['instance.PickerCostAmount']">{{errors['instance.PickerCostAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.TollCostAmount']}">
                <label>Toll Cost Amount</label>
                <md-input v-model.number="formData.TollCostAmount"></md-input>
                <span class="md-error" v-if="errors['instance.TollCostAmount']">{{errors['instance.TollCostAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.BrokerCostAmount']}">
                <label>Broker Cost Amount</label>
                <md-input v-model.number="formData.BrokerCostAmount"></md-input>
                <span class="md-error" v-if="errors['instance.BrokerCostAmount']">{{errors['instance.BrokerCostAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.FuelCostAmount']}">
                <label>Fuel Cost Amount</label>
                <md-input v-model.number="formData.FuelCostAmount"></md-input>
                <span class="md-error" v-if="errors['instance.FuelCostAmount']">{{errors['instance.FuelCostAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.SalaryCostAmount']}">
                <label>Salary Cost Amount</label>
                <md-input v-model.number="formData.SalaryCostAmount"></md-input>
                <span class="md-error" v-if="errors['instance.SalaryCostAmount']">{{errors['instance.SalaryCostAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.OtherCostAmount']}">
                <label>Other Cost Amount</label>
                <md-input v-model.number="formData.OtherCostAmount"></md-input>
                <span class="md-error" v-if="errors['instance.OtherCostAmount']">{{errors['instance.OtherCostAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.OtherCostDescription']}">
                <label>Other Cost Description</label>
                <md-input v-model="formData.OtherCostDescription"></md-input>
                <span class="md-error" v-if="errors['instance.OtherCostDescription']">{{errors['instance.OtherCostDescription']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.TotalCostAmount']}">
                <label>Total Cost Amount</label>
                <md-input :value="$currencyFormater(submitForm.TotalCostAmount)" disabled="disabled"></md-input>
                <span class="md-error" v-if="errors['instance.TotalCostAmount']">{{errors['instance.TotalCostAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.TotalDeductAmount']}">
                <label>Total Deduct Amount</label>
                <md-input :value="$currencyFormater(submitForm.TotalDeductAmount)" disabled="disabled"></md-input>
                <span class="md-error" v-if="errors['instance.TotalDeductAmount']">{{errors['instance.TotalDeductAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.TotalPaidAmount']}">
                <label>Total Paid Amount</label>
                <md-input :value="$currencyFormater(submitForm.TotalPaidAmount)" disabled="disabled"></md-input>
                <span class="md-error" v-if="errors['instance.TotalPaidAmount']">{{errors['instance.TotalPaidAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.PaidRemark']}">
                <label>Paid Remark</label>
                <md-input v-model="formData.PaidRemark"></md-input>
                <span class="md-error" v-if="errors['instance.PaidRemark']">{{errors['instance.PaidRemark']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.PaymentCycles']}">
                <label for="PaymentCycles">Payment Cycles</label>
                <md-select v-model="formData.PaymentCycles" name="PaymentCycles" id="PaymentCycles" multiple>
                  <md-option v-for="cycle in cycleDateOptions" :value="cycle[0]" :key="cycle[0]">{{cycle[1]}}</md-option>
                </md-select>
                <span class="md-error" v-if="errors['instance.PaymentCycles']">{{errors['instance.PaymentCycles']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
                <md-button class="md-raised" @click="fetchPaymentRequests">Get Payment Request</md-button>
                <span class="md-error" v-if="errors['instance.PaymentRequests']">{{errors['instance.PaymentRequests']}}</span>
                <md-button class="md-raised" @click="formData.PaymentRequests=[]">Clear Payment Request</md-button>
            </div>
            <div class="md-layout-item md-large-size-100 md-xlarge-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
              <div>
                <md-table v-model="formData.PaymentRequests" md-fixed-header  md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="DriverID">{{item.SK.split('#')[2]}}</md-table-cell>
                    <md-table-cell md-label="From">{{item.From.Description}}</md-table-cell>
                    <md-table-cell md-label="To">{{item.To.Description}}</md-table-cell>
                    <md-table-cell md-label="OrderDate">{{item.OrderDate}}</md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <div class="md-layout">
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-autocomplete @input="handleDriverSelect" :value="formData.PaymentBankAccountName" :md-options="drivers" :class="{'md-invalid': errors['instance.PaymentBankAccountName']}">
                <label>Bank Account Name <span style="color: red">*</span></label>
                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                  <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
                </template>
                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  No Driver matching "{{ term }}" were found.
                </template>
                <span class="md-error" v-if="errors['instance.PaymentBankAccountName']">Invalid field input</span>
              </md-autocomplete>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.DriverName']}">
                <label>Driver</label>
                <md-select v-model="formData.DriverName" @md-selected="selectedDriverName">
                  <md-option v-for="driver in driverList" :value="driver.DriverName" :key="driver.SK">{{driver.DriverName}}</md-option>
                </md-select>
                <span class="md-error" v-if="errors['instance.DriverName']">{{errors['instance.DriverName']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.DriverPhoneNumber']}">
                <label>Driver Phone Number</label>
                <md-input v-model="formData.DriverPhoneNumber"></md-input>
                <span class="md-error" v-if="errors['instance.DriverPhoneNumber']">{{errors['instance.DriverPhoneNumber']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-autocomplete v-model="formData.PaymentBankName" :md-options="bankOptions" :class="{'md-invalid': errors['instance.PaymentBankName']}">
                <label>Bank</label>
                <span class="md-error" v-if="errors['instance.PaymentBankName']">Invalid field input</span>
              </md-autocomplete>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.PaymentBankBranchCode']}">
                <label>Bank Branch Code</label>
                <md-input v-model="formData.PaymentBankBranchCode"></md-input>
                <span class="md-error" v-if="errors['instance.PaymentBankBranchCode']">Invalid field input</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.PaymentBankAccountNumber']}">
                <label>Bank Account NO.</label>
                <md-input v-model="formData.PaymentBankAccountNumber"></md-input>
                <span class="md-error" v-if="errors['instance.PaymentBankAccountNumber']">Invalid field input</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-autocomplete @input="handleCustomerSelect" :value="formData.CustomerName" :md-options="customers"
                :class="{'md-invalid': errors['instance.Customer']}">
                <label>Customer</label>
                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                  <md-avatar>
                    <amplify-s3-image :imagePath="item.CustomerLogo" class="md-avatar md-large"></amplify-s3-image>
                  </md-avatar>
                  <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
                </template>

                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  No customer matching "{{ term }}" were found.
                </template>
                <span class="md-error" v-if="errors['instance.Customer']">Invalid field input</span>
              </md-autocomplete>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-datepicker v-model="formData.OrderStartDate" :md-model-type="String" :class="{'md-invalid': errors['instance.OrderStartDate']}" md-immediately>
                <label>Order Start Date <span style="color: red">*</span></label>
                <span class="md-error" v-if="errors['instance.OrderStartDate']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-datepicker v-model="formData.OrderEndDate" :md-model-type="String" :class="{'md-invalid': errors['instance.OrderEndDate']}" md-immediately>
                <label>Order End Date <span style="color: red">*</span></label>
                <span class="md-error" v-if="errors['instance.OrderEndDate']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.DriverGroup']}">
                <label for="DriverGroup">Driver Group <span style="color: red">*</span></label>
                <md-select v-model="formData.DriverGroup" name="DriverGroup" id="DriverGroup">
                  <md-option value="Freelance">Freelance</md-option>
                  <md-option value="Permanent">Permanent</md-option>
                  <md-option value="Truck Leader">Truck Leader</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.WithholdingTaxAmount']}">
                <label>WithholdingTaxAmount</label>
                <md-input v-model.number="formData.WithholdingTaxAmount"></md-input>
                <span class="md-error" v-if="errors['instance.WithholdingTaxAmount']">{{errors['instance.WithholdingTaxAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.DepositAmount']}">
                <label>DepositAmount</label>
                <md-input v-model.number="formData.DepositAmount"></md-input>
                <span class="md-error" v-if="errors['instance.DepositAmount']">{{errors['instance.DepositAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.LossClaimAmount']}">
                <label>Loss Claim Amount</label>
                <md-input v-model.number="formData.LossClaimAmount"></md-input>
                <span class="md-error" v-if="errors['instance.LossClaimAmount']">{{errors['instance.LossClaimAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.InsurranceAmount']}">
                <label>Insurrance Amount</label>
                <md-input v-model.number="formData.InsurranceAmount"></md-input>
                <span class="md-error" v-if="errors['instance.InsurranceAmount']">{{errors['instance.InsurranceAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.OtherDeductAmount']}">
                <label>Other Deduct Amount</label>
                <md-input v-model.number="formData.OtherDeductAmount"></md-input>
                <span class="md-error" v-if="errors['instance.OtherDeductAmount']">{{errors['instance.OtherDeductAmount']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.OtherDeductDescription']}">
                <label>Other Deduct Description</label>
                <md-input v-model="formData.OtherDeductDescription"></md-input>
                <span class="md-error" v-if="errors['instance.OtherDeductDescription']">{{errors['instance.OtherDeductDescription']}}</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-autocomplete v-model="formData.PaymentVoucher" :md-options="paymentVoucherOptions" @md-changed="getPVs" @md-opened="getPVs" :class="{'md-invalid': errors['instance.PaymentVoucher']}">
                <label>Payment Voucher</label>
                <template slot="md-autocomplete-item" slot-scope="{ item }">{{ item }}</template>
                <span class="md-error" v-if="errors['instance.PaymentVoucher']">{{errors['instance.PaymentVoucher']}}</span>
              </md-autocomplete>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <div style="display: flex">
                <md-avatar class="md-large" v-show="formData.PaidSlipPhoto">
                  <my-s3-image :imagePath="formData.PaidSlipPhoto"></my-s3-image>
                </md-avatar>
                <md-field :class="{'md-invalid': errors['instance.PaidSlipPhoto']}">
                  <md-file v-model="imageSelect" accept="image/*" @md-change="handleImageSelect" placeholder="Paid Slip Photo *" />
                  <span class="md-error" v-if="errors['instance.PaidSlipPhoto']">Invalid field input</span>
                </md-field>
              </div>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-field :class="{'md-invalid': errors['instance.PaidBy']}">
                <label>Paid By</label>
                <md-input :value="formData.PaidBy || user.signInUserSession.idToken.payload.email" disabled="disabled"></md-input>
                <span class="md-error" v-if="errors['instance.PaidBy']">Invalid field input</span>
              </md-field>
            </div>
            <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
              <md-datepicker v-model="formData.CreatedDate" :md-model-type="String" :class="{'md-invalid': errors['instance.CreatedDate']}" md-immediately>
                <label>Created Date <span style="color: red">*</span></label>
                <span class="md-error" v-if="errors['instance.CreatedDate']">Invalid field input</span>
              </md-datepicker>
              <span class="md-error" v-if="errors['instance.CreatedDate']">Invalid field input</span>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-large-size-100 md-xlarge-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
          <md-toolbar>
            <div class="md-toolbar-section-start">
              <h1 class="md-title">
                  Trips: {{paymentRequestTable.items.length}} Dates: {{paymentRequestTable.date.length}} Total Cost: {{
                    $currencyFormater(paymentRequestTable.TotalDeliveryCost + paymentRequestTable.TotalFuelCost + paymentRequestTable.TotalTollParkCost + paymentRequestTable.TotalIncentiveCost + (paymentRequestTable.TotalPicker * paymentRequestCycleField.DailyPickerCost) + (includeSalary ? paymentRequestTable.date.length * (paymentRequestCycleField.DriverMonthlySalary/SalaryDays) : 0))}}
              </h1>
            </div>
            <div class="md-toolbar-section-end">
              <div class="md-toolbar-row md-layout">
                <div class="md-layout-item">
                </div>
                <div class="md-layout-item">
                  <md-field>
                    <label for="movie">Daily Picker Cost</label>
                    <md-input type="number" size="4" :value="paymentRequestCycleField.DailyPickerCost" readonly="readonly"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item">
                  <md-field>
                    <label for="movie">Driver Monthly Salary</label>
                    <md-input type="number" size="4" :value="paymentRequestCycleField.DriverMonthlySalary" readonly="readonly"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item">
                  <md-checkbox v-model="includeSalary">Include Salary</md-checkbox>
                </div>
              </div>
            </div>
          </md-toolbar>
          <md-table v-model="paymentRequestTable.items" @md-selected="onSelectPR">
            <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select :md-disabled="paymentRequestSelected(item)">
              <md-table-cell md-label="Owner Checked">{{item.OwnerChecked ? 'Yes' : 'No'}}</md-table-cell>
              <md-table-cell md-label="Cycle">{{cycleToDate(item.Cycle)}}</md-table-cell>
              <md-table-cell md-label="Driver">{{item.Driver.DriverName}}</md-table-cell>
              <md-table-cell md-label="Trip Date">{{item.OrderDate}}</md-table-cell>
              <md-table-cell md-label="Customer">{{item.CustomerName}}</md-table-cell>
              <md-table-cell md-label="From - To">{{item.From.Description}} - {{item.To.Description}}</md-table-cell>
              <md-table-cell md-label="Delivery Cost">{{item.DeliveryCost}}</md-table-cell>
              <md-table-cell md-label="Fuel Cost">{{item.FuelCost}}</md-table-cell>
              <md-table-cell md-label="Inform Distance">{{item.InformDistance}}</md-table-cell>
              <md-table-cell md-label="Picker Per Trip">
                {{item.PickerPerTrip}}
                <span v-if="item.PickerPerTrip > 0">({{$currencyFormater(item.PickerPerTrip * (paymentRequestCycleField.DailyPickerCost / paymentRequestTable.pickerCost[item.OrderDate]))}})</span>
              </md-table-cell>
              <md-table-cell md-label="Toll/Park Cost">
                {{$currencyFormater(item.TollParkCost)}}
                <md-button class="md-icon-button" v-show="item.TollParkCost > 0" @click="openPhotoDialog(item.TollParkPhoto || [])">
                  <md-icon>photo</md-icon>
                </md-button>
              </md-table-cell>
              <md-table-cell md-label="Inform Deliver">
                {{item.InformDeliverNumbers}}
                 <span v-show="item.InformDeliverNumbers > 0">({{$currencyFormater(item.IncentiveCost)}})</span>
                <md-button class="md-icon-button" v-show="item.InformDeliverNumbers > 0" @click="openPhotoDialog(item.IncentivePhoto || [])">
                  <md-icon>photo</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <div class="md-layout-item md-large-size-100 md-xlarge-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
          <md-table v-model="diaryItems" md-card md-fixed-header @md-selected="onDiarySelect">
            <md-table-row slot="md-table-row" slot-scope="{ item }"  md-selectable="multiple" md-auto-select  :md-disabled="!!item.PDNumber" :class="{'highlight-field': !item.OrderID }">
              <md-table-cell md-label="Created At">{{datetimeFormater(item.CreatedAt)}}</md-table-cell>
              <md-table-cell md-label="Driver">
                <router-link :to="'/Drivers/' + item.Driver.DriverID">{{ item.Driver.DriverName }} ({{ item.Driver.DriverPhoneNumber }})</router-link>
              </md-table-cell>
              <md-table-cell md-label="Vehicle">
                {{ item.Vehicle ? item.Vehicle.VehicleLicenseNumber : "" }} {{ item.Vehicle ? item.Vehicle.VehicleLicenseProvince : "" }} -
                {{ item.Vehicle ? item.Vehicle.VehicleTruckType : "" }} {{ item.Vehicle ? item.Vehicle.VehicleContainerType : "" }}
              </md-table-cell>
              <md-table-cell md-label="Customer">{{ item.CustomerID }}</md-table-cell>
              <md-table-cell md-label="OrderID"><router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link></md-table-cell>
              <md-table-cell md-label="Stop for">{{ item.StopFor }}</md-table-cell>
              <md-table-cell md-label="Location"><a @click="mapFocusAt(item.Location)">{{ item.Area }}</a></md-table-cell>
              <md-table-cell md-label="Snapshot">
                <md-button class="md-icon-button" @click="openPhotoDialog(item.Photo)">
                  <md-icon>image</md-icon>
                </md-button>
              </md-table-cell>
              <md-table-cell md-label="Album">
                <md-button class="md-icon-button" @click="openPhotoDialog(item.PhotoAlbum || [])" v-show="(item.PhotoAlbum || []).length > 0">
                  <md-icon>image</md-icon>
                </md-button>
              </md-table-cell>
              <md-table-cell md-label="Note">{{ item.Note }}</md-table-cell>
              <md-table-cell md-label="Pick Up">{{ item.PickUpItemNo }}</md-table-cell>
              <md-table-cell md-label="Drop Off">{{ item.DropOffItemNo }}</md-table-cell>
              <md-table-cell md-label="PDNumber">{{ item.PDNumber }}</md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-button type="submit" class="md-raised md-primary" v-show="!hideSubmit">
            <span v-show="!$route.params.id">Create an Driver Paid</span>
            <span v-show="!!$route.params.id">Update an Driver Paid</span>
          </md-button>
          <md-button class="md-raised" @click="$router.go(-1)">Back</md-button>
        </div>
      </div>
      <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
        <span>{{snackBarMsg}}</span>
      </md-snackbar>
      </form>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'
import {validate} from 'jsonschema'
import schema from '../schema'
import MyS3Image from '../components/MyS3Image'
import { latLng } from "leaflet"

export default {
  name: 'PaymentForm',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image},
  data () {
    return {
      active: false,
      diaryConfirm: false,
      includeSalary: false,
      hideSubmit: false,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      imageSelect: null,
      errors: {},
      userItems: {Users: []},
      configItems: [],
      PaymentCycles: [],
      driverItemLoading: true,
      driverItems: [],
      paymentRequestItems: [],
      customerItems: [],
      bankOptions: [],
      paymentVoucherOptions: [],
      fetchedPV: [],
      showMapDialog: false,
      showPhotoDialog: false,
      currentPhoto: null,
      isLoading: false,
      mapOption: {
        zoom: 13,
        center: latLng(13.75318, 100.53173),
        url: 'https://ms.longdo.com/mmmap/tile.php?zoom={z}&x={x}&y={y}&key=c146605c52087ac63385d5db545fb84c&proj=epsg3857&HD=1',
        attribution:
          '© Longdo Map',
        markerLatLng: null,
        options: {
          zoomSnap: 0.5
        },
        showMap: true
      },
      hasGetDiary: false,
      diaryItems: [],
      DailyPickerCost: 0,
      SalaryDays: 30,
      selectedPaymentRequests: [],
      formData: {
        PK: '-',
        SK: '-',
        PDNumber: null,
        DriverID: null,
        DriverName: null,
        DriverPhoneNumber: null,
        DriverGroup: null,
        DriverTeam: null,
        OrderCreatedBy:  null,
        OrderStartDate: null,
        OrderEndDate: null,
        CustomerID: null,
        CustomerName: null,
        TripCostAmount: 0,
        PickerCostAmount: 0,
        TollCostAmount: 0,
        BrokerCostAmount: 0,
        FuelCostAmount: 0,
        SalaryCostAmount: 0,
        OtherCostAmount: 0,
        OtherCostDescription: null,
        TotalCostAmount: 0,
        WithholdingTaxAmount: 0,
        DepositAmount: 0,
        LossClaimAmount: 0,
        InsurranceAmount: 0,
        OtherDeductAmount: 0,
        OtherDeductDescription: null,
        TotalDeductAmount: 0,
        TotalPaidAmount: 0,
        PaidSlipPhoto: null,
        PaymentVoucher: null,
        PaymentBankName: null,
        PaymentBankBranchCode: null,
        PaymentBankAccountName: null,
        PaymentBankAccountNumber: null,
        PaidBy: null,
        PaidRemark: null,
        CreatedDate: moment().format('YYYY-MM-DD'),
        CreatedAt: null,
        CreatedBy: null,
        PaymentRequests: [],
        NoDiaryPD: 0,
        PaymentCycles: [],
        Diaries: []
      }
    }
  },
  methods: {
    getPVs: function (searchTerm) {
      if (/^PV([0-9]{6})$/.test(searchTerm)) {
        this.fetchPaymentVoucher(searchTerm)
      }
    },
    fetchConfig: function () {
      let vm = this
      this.isLoading = true
      API.get('ezietruckapi', '/api/options/Config').then((json) => {
        vm.configItems = json
        let idx = vm.configItems.findIndex((c) => c.Name.startsWith('PaymentCycle'))
        if (idx > -1) {
          vm.PaymentCycles = vm.configItems[idx].Name.split(',').slice(1).map((c) => parseInt(c))
        }
        let pidx = vm.configItems.findIndex((c) => c.Name.startsWith('DailyPickerCost'))
        if (pidx > -1) {
          vm.DailyPickerCost = parseInt(vm.configItems[pidx].Name.split(',')[1])
        }
        let sidx = vm.configItems.findIndex((c) => c.Name.startsWith('SalaryDays'))
        if (sidx > -1) {
          vm.SalaryDays = parseInt(vm.configItems[sidx].Name.split(',')[1])
        }
      })
    },
    fetchedDiary: function (selectedDiaryDate, driverPhoneNumber, lastEvaluatedKey) {
      let q = ''
      let vm = this
      if (lastEvaluatedKey && lastEvaluatedKey.SK) {
        q = `&LastSK=${lastEvaluatedKey.SK.replace(/#/g, '%23')}`
      }
      API.get('ezietruckapi', `/api/vehicles/diary/${selectedDiaryDate}?DriverPhoneNumber=${driverPhoneNumber}${q}`).then((json) => {
        vm.diaryItems = [...vm.diaryItems, ...(json.Items || [])].sort((a,b) => ((a.OrderID || '##############') + (a.CreatedAt)).localeCompare(((b.OrderID || '##############') + (b.CreatedAt))))
        if (json.LastEvaluatedKey) {
          vm.fetchedDiary(selectedDiaryDate, driverPhoneNumber, json.LastEvaluatedKey)
        }
      })
    },
    fetchPaymentVoucher: function (pv) {
      if (!this.fetchedPV.includes(pv)) {
        API.get('ezietruckapi', `/api/paymentVouchers/${pv}`).then((json) => {
          this.paymentVoucherOptions = [...this.paymentVoucherOptions, ...(json['Items'] || []).map((j) => j.PaymentVoucher)]
        })
        this.fetchedPV.push(pv)
      }
    },
    fetchDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.driverItems = []
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          self.driverItems = [...self.driverItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchDriver(json.LastEvaluatedKey)
        } else {
          self.driverItemLoading = false
        }
      })
    },
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchData: function () {
      let vm = this
      if (this.$route.params.id) {
        API.get('ezietruckapi', `/api/paidDrivers/${this.$route.params.id}`).then((json) => {
          vm.formData = Object.assign({}, vm.formData, json)
          if (json.PaymentVoucher) {
            vm.fetchPaymentVoucher(json.PaymentVoucher.slice(0,8))
          } else {
            vm.fetchPaymentVoucher(`PV${moment().format('YYYYMM')}`)
          }
          vm.fetchPaymentRequests()
        })
      } else {
        let pdNumber = `PD${moment().format('YYYYMM')}`
        API.get('ezietruckapi', `/api/options/LastPDNumber`).then((json) => {
          if (json.length > 0 && json[0].Name.slice(2, 8) === moment().format('YYYYMM')) {
            let s = `${parseInt(json[0].Name.slice(8)) + 1}`
            while (s.length < 4) s = "0" + s
            pdNumber += s
          } else {
            pdNumber += '0001'
          }
          vm.formData.PDNumber = pdNumber
        })
      }
      API.get('ezietruckapi', `/api/users`).then((json) => {
        vm.userItems = json
      })
      API.get('ezietruckapi', `/api/options/Bank`).then((json) => {
        vm.bankOptions = json.map((j) => j.Name)
      })
      this.fetchDriver()
      this.fetchCustomer()
    },
    formValidate: function () {
      let p = validate(this.submitForm, schema.DriverPaidFormSchema)
      this.errors = {}
      for (let err of p.errors) {
        this.errors[err.property] = err.message
      }
      if (this.submitForm.OrderStartDate && this.submitForm.OrderEndDate && this.submitForm.OrderEndDate < this.submitForm.OrderStartDate) {
        this.errors['instance.OrderStartDate'] = 'Order end date before start date'
        this.errors['instance.OrderEndDate'] = 'Order end date before start date'
      }
      if (this.submitForm.PaymentVoucher && !this.paymentVoucherOptions.includes(this.submitForm.PaymentVoucher)) {
        this.errors['instance.PaymentVoucher'] = 'Must be selected on existing payment voucher'
      }
      this.errors = Object.assign({}, this.errors)
    },
    handleImageSelect: function (event) {
      for (let file of event) {
        this.$Amplify.Storage.put(
          `Paid_Slip_Images/${this.formData.PDNumber}-${file.name}`, file, { level: 'public' }
        ).then((res) => {
          this.formData.PaidSlipPhoto = res.key
        }).catch(console.error)
      }
    },
    handleCreatePayment: function () {
      this.formValidate()
      if (Object.keys(this.errors).length == 0) {
        if (!this.diaryConfirm && this.submitForm.PaymentRequests.length < 1) {
          this.active = true
        } else {
          this.diaryConfirm = true
        }
        if (this.diaryConfirm) {
          this.hideSubmit = true
          let self = this
          if (this.$route.params.id) {
            API.post('ezietruckapi', '/api/paidDrivers', {body: this.submitForm}).then(() => {
              this.showSnackbar = true
              this.snackBarMsg = 'Successfully saved'
              this.hideSubmit = false
              this.$router.push('/Payments')
            })
          } else {
            API.get('ezietruckapi', `/api/paidDrivers/${this.submitForm.PDNumber}`).then((data) => {
              if(data && data.PK) {
                self.errors['instance.PDNumber'] = 'PDNumber already exists'
                self.showSnackbar = true
                self.snackBarMsg = 'PDNumber already exists'
                self.hideSubmit = false
              } else {
                API.post('ezietruckapi', '/api/paidDrivers', {body: self.submitForm}).then(() => {
                  self.showSnackbar = true
                  self.snackBarMsg = 'Successfully saved'
                  self.hideSubmit = false
                  self.$router.push('/Payments')
                }).catch(() => {
                  self.showSnackbar = true
                  self.snackBarMsg = 'Error on server'
                  self.hideSubmit = false
                })
              }
            })
          }
        }
      } else {
        this.showSnackbar = true
        this.snackBarMsg = 'Invalid form input'
        this.hideSubmit = false
      }
    },
    handleNoDiaryConfirm: function () {
      this.diaryConfirm = true
      this.handleCreatePayment()
    },
    handleDriverSelect: function (value) {
      if (!this.driverItemLoading) {
        if (value) {
          this.formData.PaymentBankAccountName = value
        } else {
          this.formData.DriverID = null
          this.formData.DriverName = null
          this.formData.DriverPhoneNumber = null
          this.formData.NoDiaryPD = 0
          this.formData.DriverGroup = null
          this.formData.PaymentBankName = ''
          this.formData.PaymentBankBranchCode = ''
          this.formData.PaymentBankAccountName = ''
          this.formData.PaymentBankAccountNumber = null
          this.formData.DriverTeam = null
        }
      }
    },
    selectedDriverName: function (value) {
      let idx = this.driverItems.findIndex((d) => d.DriverName === value)
      if (idx > -1) {
        this.formData.DriverID = this.driverItems[idx].DriverID || null
        this.formData.DriverName = this.driverItems[idx].DriverName || null
        this.formData.DriverPhoneNumber = this.driverItems[idx].DriverPhoneNumber || null
        this.formData.NoDiaryPD = this.driverItems[idx].NoDiaryPD || 0
        this.formData.DriverGroup = this.driverItems[idx].DriverGroup || null
        this.formData.PaymentBankName = this.driverItems[idx].DriverBankName || ''
        this.formData.PaymentBankBranchCode = this.driverItems[idx].DriverBankBranchCode || ''
        this.formData.PaymentBankAccountName = this.driverItems[idx].DriverBankAccountName || ''
        this.formData.PaymentBankAccountNumber = this.driverItems[idx].DriverBankAccountNumber || null
        this.formData.DriverTeam = this.driverItems[idx].DriverTeam || null
      }
    },
    handleCustomerSelect: function (value) {
      if (typeof value === 'object') {
        this.formData.CustomerID = value.CustomerID
        this.formData.CustomerName = value.CustomerName
      } else {
        if (!value) {
          this.formData.CustomerID = ''
          this.formData.CustomerName = ''
        }
      }
    },
    fetchedTeamDiary: function (driverID, team, lastEvaluatedKey) {
      let vm = this
      API.post('ezietruckapi', `/api/drivers/${driverID}/teams`, {body: {team: team, LastEvaluatedKey: lastEvaluatedKey || null}}).then((json) => {
        if (json.Items) {
          for (let d of json.Items) {
            let i = moment(vm.formData.OrderStartDate)
            while (i <= moment(vm.formData.OrderEndDate)) {
              vm.fetchedDiary(i.format('YYYY-MM-DD'), d.DriverPhoneNumber, null)
              i.add(1, 'days')
            }
          }
        }
        if (json.LastEvaluatedKey) {
          vm.fetchedTeamDiary(driverID, team, json.LastEvaluatedKey)
        }
      })
    },
    openDiaryDialog: function () {
      let ok = true
      if (!this.formData.DriverID) {
        this.errors['instance.DriverName'] = 'Invalid data'
        ok = false
      }
      if (!this.formData.OrderStartDate) {
        this.errors['instance.OrderStartDate'] = 'Invalid data'
        ok = false
      }
      if (!this.formData.OrderEndDate) {
        this.errors['instance.OrderEndDate'] = 'Invalid data'
        ok = false
      }
      if (ok) {
        let i = moment(this.formData.OrderStartDate)
        this.diaryItems = []
        this.hasGetDiary = true
        if ((this.formData.PaymentBankAccountName === this.formData.DriverName) && this.formData.DriverTeam) {
          this.fetchedTeamDiary(this.formData.DriverID, this.formData.DriverTeam)
        } else {
          while (i <= moment(this.formData.OrderEndDate)) {
            this.fetchedDiary(i.format('YYYY-MM-DD'), this.formData.DriverPhoneNumber, null)
            i.add(1, 'days')
          }
        }
      } else {
        this.errors = Object.assign({}, this.errors)
      }
    },
    fetchPaymentRequest: function (dates) {
      let vm = this
      if (dates.length > 0) {
        Promise.all(dates.map((d) => API.post('ezietruckapi', '/api/paymentRequests/date', {body: {
          OrderDate: d.date,
          DriverID: d.DriverID,
          LastEvaluatedKey: d.LastEvaluatedKey || null
        }}))).then((results) => {
          let nextBatch = []
          for (let json of results) {
            vm.paymentRequestItems = [...vm.paymentRequestItems, ...json['Items'].filter((i) => i.OrderOwner === vm.formData.OrderOwner)]
            if (json['LastEvaluatedKey'] && json['Items'].length > 0) {
              nextBatch.push({date: json['Items'][0].OrderDate, DriverID: json['Items'][0].DriverID, LastEvaluatedKey: json['LastEvaluatedKey']})
            }
          }
          vm.fetchPaymentRequest(nextBatch)
        })
      } else {
        this.isLoading = false
      }
    },
    fetchPaymentRequests: function () {
      let ok = true
      if (!this.formData.DriverID) {
        this.errors['instance.DriverName'] = 'Invalid data'
        ok = false
      }
      if (!this.formData.OrderCreatedBy) {
        this.errors['instance.OrderCreatedBy'] = 'Order Owner is required'
        ok = false
      }
      if (!this.formData.OrderStartDate) {
        this.errors['instance.OrderStartDate'] = 'Order Start Date is required'
        ok = false
      }
      if (!this.formData.OrderEndDate) {
        this.errors['instance.OrderEndDate'] = 'Order End Date is required'
        ok = false
      }
      if (ok) {
        this.paymentRequestItems = []
        let s = moment(this.formData.OrderStartDate)
        let e = moment(this.formData.OrderEndDate)
        let dates = []
        while (s <= e) {
          if (this.formData.DriverGroup === 'Truck Leader' && this.formData.DriverTeam) {
            for (let driver of this.driverItems.filter((d) => d.DriverTeam === this.formData.DriverTeam && d.SK.startsWith('#METADATA#'))) {
              dates.push({date: s.format('YYYY-MM-DD'), DriverID: driver.DriverID})
            }
          } else {
            dates.push({date: s.format('YYYY-MM-DD'), DriverID: this.formData.DriverID})
          }
          s.add(1, 'days')
        }
        this.fetchPaymentRequest(dates)
      } else {
        this.errors = Object.assign({}, this.errors)
      }
    },
    datetimeFormater: function (str) {
      let m = moment(str)
      m.local() 
      return m.format('DD MMM YYYY HH:mm:ss')
    },
    mapFocusAt: function (gps) {
      this.showMapDialog = true
      this.mapOption = Object.assign({}, this.mapOption, {
        center: latLng(gps.Lat, gps.Lon),
        markerLatLng: latLng(gps.Lat, gps.Lon),
      })
    },
    openPhotoDialog: function (photo) {
      this.showPhotoDialog = true
      this.currentPhoto = photo
    },
    onDiarySelect: function (items) {
      this.formData.Diaries = items
    },
    cycleToDate: function (cycle) {
      let c = cycle.split('-').map((i) => parseInt(i))
      return moment([c[0], c[1] - 1, this.PaymentCycles[c[2] - 1]]).format('YYYY-MM-DD')
    },
    onSelectPR: function (items) {
      this.selectedPaymentRequests = items.map((i) => {
        return {PK: i.PK, SK: i.SK, OrderDate: i.OrderDate, From: i.From, To: i.To}
      })
    },
    paymentRequestSelected: function (item) {
      return this.formData.PaymentRequests.findIndex((p) => p.PK === item.PK && p.SK === item.SK) > -1
    }
  },
  computed: {
    ...mapGetters(['user']),
    submitForm () {
      let form = JSON.parse(JSON.stringify(this.formData))
      if (form.PDNumber && form.PK === '-') {
        form.PK = `PAIDDRIVER#${form.PDNumber.slice(0,8)}`
      }
      if (form.PDNumber && form.SK === '-') {
        form.SK = `#METADATA#${form.PDNumber}`
      }
      for (let propName of ['DriverPhoneNumber', 'CustomerID', 'CustomerName', 'OtherDeductDescription', 'OtherCostDescription', 'PaidRemark', 'PaymentVoucher', 'PaymentBankName', 'DriverID', 'DriverName', 'PaymentBankAccountNumber', 'DriverTeam', 'PaymentBankBranchCode', 'DriverGroup']) {
        if (!form[propName]) {
          delete form[propName];
        }
      }
      form.PaymentRequests = [...form.PaymentRequests, ...this.selectedPaymentRequests]
      form.TotalCostAmount = (form.TripCostAmount*1000 + form.PickerCostAmount*1000 + form.TollCostAmount*1000 + form.BrokerCostAmount*1000 + form.FuelCostAmount*1000 + form.SalaryCostAmount*1000 + form.OtherCostAmount*1000)/1000
      form.TotalDeductAmount = (form.WithholdingTaxAmount*1000 + form.DepositAmount*1000 + form.LossClaimAmount*1000 + form.InsurranceAmount*1000 + form.OtherDeductAmount*1000)/1000
      form.TotalPaidAmount = (form.TotalCostAmount*1000 - form.TotalDeductAmount*1000)/1000
      form.CreatedDate = form.CreatedDate || moment().format('YYYY-MM-DD')
      form.CreatedAt = form.CreatedAt || (new Date()).toISOString()
      form.CreatedBy = form.CreatedBy || this.user.signInUserSession.idToken.payload.email
      form.PaidBy = this.user.signInUserSession.idToken.payload.email
      form.Diaries = form.Diaries.map((d) => {
        return {'PK': d['PK'], 'SK': d['SK']}
      })
      if (this.hasGetDiary && form.SalaryCostAmount === 0) {
        form.NoDiaryPD = (form.NoDiaryPD || 0) + (form.Diaries.length > 0 ? 0 : 1)
      }
      form = Object.assign({}, form)
      return form
    },
    users () {
      return this.userItems.Users.map((u) => {
        let idx = u.Attributes.findIndex((a) => a.Name === 'email')
        if (idx > -1) {
          return u.Attributes[idx].Value
        }
      })
    },
    drivers () {
      let out = []
      for (let o of this.driverItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return [...(new Set(this.driverItems.map((d) => d.DriverBankAccountName).filter(d => d)))].sort((a,b) => a.localeCompare(b))
    },
    driverList () {
      let vm = this
      return this.driverItems.filter((d) => d.DriverBankAccountName === vm.formData.PaymentBankAccountName)
    },
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    cycleDateOptions: function () {
      let j = this.PaymentCycles.length
      let d = new Date()
      let options = []
      if (this.PaymentCycles.length > 0) {
        while (options.length < 12) {
          options.push([`${moment([d.getFullYear(), d.getMonth(), this.PaymentCycles[j - 1]]).format('YYYY-MM-')}${j}`,
          `${moment([d.getFullYear(), d.getMonth(), this.PaymentCycles[j - 1]]).format('YYYY-MM-DD')}`])
          if (j - 1 < 1) {
            j = this.PaymentCycles.length
            if (d.getMonth() === 0) {
              d = new Date(d.getFullYear(), 11, d.getDate())
            } else {
              d = new Date(d.getFullYear(), d.getMonth() - 1, d.getDate())
            }
          } else {
            j -= 1
          }
        }
      }
      return options
    },
    paymentRequests: function () {
      let items = []
      let prMeta = {}
      for (let pr of this.paymentRequestItems.filter((pr) => pr.SK.startsWith('#METADATA#'))) {
        let id = pr.SK.split('#').slice(2,4).join('#')
        prMeta[id] = pr
      }
      for (let pr of this.paymentRequestItems.filter((pr) => pr.SK.startsWith('Trip#'))) {
        let id = pr.SK.split('#').slice(2,4).join('#')
        if (prMeta[id]) {
          items.push(Object.assign({}, prMeta[id], pr))
        }
      }
      return items
    },
    paymentRequestCycleField: function () {
      let vm = this
      let didx = this.driverItems.findIndex((d) => d.DriverID === vm.formData.DriverID && d.SK.startsWith('#METADATA#'))
      let out = {DailyPickerCost: this.DailyPickerCost, DriverMonthlySalary: didx > -1 ? this.driverItems[didx].DriverMonthlySalary || 0 : 0}
      let idx = this.paymentRequestItems.findIndex((p) => p.SK.startsWith('Field'))
      if (idx > -1) {
        out.DailyPickerCost = this.paymentRequestItems[idx].DailyPickerCost || out.DailyPickerCost
        out.DriverMonthlySalary = this.paymentRequestItems[idx].DriverMonthlySalary || out.DriverMonthlySalary
      }
      return out
    },
    paymentRequestTable: function () {
      let out = {
        items: [],
        date: [],
        TotalDeliveryCost: 0,
        TotalFuelCost: 0,
        TotalTollParkCost: 0,
        TotalSalaryCost: 0,
        TotalIncentiveCost: 0,
        TotalPicker: 0,
        pickerCost: {}
      }
      for (let pr of this.paymentRequests) {
        if (this.formData.OrderCreatedBy === pr.OrderOwner && this.formData.OrderStartDate <= pr.OrderDate && this.formData.OrderEndDate >= pr.OrderDate && (!this.formData.CustomerID || this.formData.CustomerID === pr.CustomerID)) {
          out.items.push(pr)
          if (!out.date.includes(pr.OrderDate)) {
            out.date.push(pr.OrderDate)
          }
          if (pr.PickerPerTrip > 0) {
            if (out.pickerCost[pr.OrderDate]) {
              if (pr.PickerPerTrip > out.pickerCost[pr.OrderDate]) {
                out.pickerCost[pr.OrderDate] = pr.PickerPerTrip
                out.TotalPicker += pr.PickerPerTrip - out.pickerCost[pr.OrderDate]
              }
            } else {
              out.pickerCost[pr.OrderDate] = pr.PickerPerTrip
              out.TotalPicker += pr.PickerPerTrip
            }
          }
          out.TotalDeliveryCost += pr.DeliveryCost
          out.TotalFuelCost += pr.FuelCost
          out.TotalTollParkCost += pr.TollParkCost
          out.TotalIncentiveCost += pr.IncentiveCost
        }
      }
      return out
    }
  },
  created () {
    this.fetchData()
    this.fetchConfig()
  }
}
</script>
<style type="text/css" scoped="">
  .md-toolbar.highlight-field {
    background: #ffde03
  }
  .highlight-field {
    background: #ffde03
  }
</style>