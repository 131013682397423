<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <h3 v-show="(step === 1)">Job Condition</h3>
          <h3 v-show="(step === 2)">Computed Field</h3>
          <h3 v-show="(step === 3)">Price Calculator</h3>
          <h3 v-show="(step === 4)">Preview</h3>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-raised" @click="(step=step-1)" v-show="(step > 1)">Previous</md-button>
          <md-button class="md-raised" @click="(step=step+1)" v-show="(step < 4)">Next</md-button>
          <md-button class="md-raised" @click="handleSubmitForm" v-show="(step === 4)">Save</md-button>
        </div>
      </div>
    </md-toolbar>
    <div v-show="(step === 1)">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field :class="{'md-invalid': errors['instance.CustomerID']}">
            <label>Customer</label>
            <md-select v-model="formData.CustomerID" @md-selected="fetchFavJobTypes($event)">
              <md-option value="---">---</md-option>
              <md-option v-for="customer in customerItems" :key="customer.SK" :value="customer.CustomerID">
                {{customer.CustomerID}} / {{customer.CustomerName}}
              </md-option>
            </md-select>
            <span class="md-error" v-if="errors['instance.CustomerID']">{{errors['instance.CustomerID']}}</span>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="{'md-invalid': errors['instance.JobType']}">
            <label>Job Type</label>
            <md-select v-model="formData.JobType">
              <md-option value="---">---</md-option>
              <md-option v-for="jobType in allJobTypes" :key="jobType.Name" :value="jobType.Name">{{jobType.Name}}</md-option>
            </md-select>
            <span class="md-error" v-if="errors['instance.JobType']">{{errors['instance.JobType']}}</span>
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field :class="{'md-invalid': errors['instance.JobSubtype']}">
            <label>Job Type</label>
            <md-select v-model="formData.JobSubtype">
              <md-option value="---">---</md-option>
              <md-option v-for="subJobType in currentSubJobTypes" :key="subJobType" :value="subJobType">{{ subJobType }}</md-option>
            </md-select>
            <span class="md-error" v-if="errors['instance.JobType']">{{errors['instance.JobType']}}</span>
          </md-field>
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-checkbox v-model="formData.IsMultipleDiary">Allow Multiple Diary</md-checkbox>
        </div>
        <div class="md-layout-item">
          <md-checkbox v-model="formData.IsAggregateOrders">Aggregate Order Rate</md-checkbox>
        </div>
      </div>
      <h3>Custom Field</h3>
      <md-table>
        <md-table-row>
          <md-table-head>Field Name</md-table-head>
          <md-table-head>Diary</md-table-head>
          <md-table-head>Validation</md-table-head>
          <md-table-head>Operation</md-table-head>
        </md-table-row>
        <md-table-row v-for="selected in selectedCustomFields" :key="selected.Name">
          <md-table-cell>
            {{selected.Field}} ({{labelSelectedField(selected.Field)}})
          </md-table-cell>
          <md-table-cell>
            {{selected.ShowInDiary}}
          </md-table-cell>
          <md-table-cell>
            <ul>
              <li>Required: {{selected.Validation.isRequired}}</li>
            </ul>
          </md-table-cell>
          <md-table-cell>
            <md-button @click="handleDeleteSelectedField(selected.Name)">
              <md-icon>remove</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>
            <md-field>
              <md-select v-model="newSelectedCustomFieldForm.Field">
                <md-option :value="field.Name" v-for="field in customFields" :key="field.SK">{{field.Label}}</md-option>
              </md-select>
            </md-field>
          </md-table-cell>
          <md-table-cell>
            <md-field>
              <md-select v-model="newSelectedCustomFieldForm.ShowInDiary">
                <md-option value="None">None</md-option>
                <md-option value="All">All</md-option>
                <md-option value="First">First</md-option>
                <md-option value="Last">Last</md-option>
              </md-select>
            </md-field>
          </md-table-cell>
          <md-table-cell>
            <md-checkbox v-model="newSelectedCustomFieldForm.Validation.isRequired">Required</md-checkbox>
          </md-table-cell>
          <md-table-cell>
            <md-button @click="handleAddSelectedField">Add</md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div v-show="(step === 2)">
      <md-table>
        <md-table-row>
          <md-table-head>Name</md-table-head>
          <md-table-head>Function</md-table-head>
          <md-table-head>Expression</md-table-head>
          <md-table-head>Operation</md-table-head>
        </md-table-row>
        <md-table-row v-for="form in computedFields" :key="form.Name">
          <md-table-cell>
            {{form.Name}}
          </md-table-cell>
          <md-table-cell>
            {{form.Function}}
          </md-table-cell>
          <md-table-cell>
            <ul>
              <li v-show="form.Lookup">Lookup: {{form.Lookup}}</li>
              <li v-show="form.Expression">Expression: {{form.Expression}}</li>
            </ul>
          </md-table-cell>
          <md-table-cell>
            <md-button @click="handleDeleteComputedField(form.Name)">
            <md-icon>remove</md-icon>
          </md-button>
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>
            <md-field>
              <md-input v-model="newComputedFieldForm.Name"></md-input>
            </md-field>
          </md-table-cell>
          <md-table-cell>
            <md-field>
              <md-select v-model="newComputedFieldForm.Function">
                <md-option value="Expression">Expression</md-option>
                <md-option value="PriceLookup">PriceLookup</md-option>
                <md-option value="RangeMapper">RangeMapper</md-option>
                <md-option value="StepSum">StepSum</md-option>
              </md-select>
            </md-field>
          </md-table-cell>
          <md-table-cell>
            <div class="md-layout md-gutter">
              <div class="md-layout-item" v-show="newComputedFieldForm.Function === 'PriceLookup'">
                <md-field>
                  <label>Lookup</label>
                  <md-select v-model="newComputedFieldForm.Lookup">
                    <md-option :value="price.SK" v-for="price in priceLookups" :key="price.SK">{{price.Label}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item" v-show="['RangeMapper', 'StepSum'].includes(newComputedFieldForm.Function)">
                <md-field>
                  <label>Lookup</label>
                  <md-select v-model="newComputedFieldForm.Lookup">
                    <md-option :value="mapper.SK" v-for="mapper in mapperLookups" :key="mapper.SK">{{mapper.Label}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <md-input v-model="newComputedFieldForm.Expression"></md-input>
                </md-field>
              </div>
            </div>
          </md-table-cell>
          <md-table-cell>
            <md-button @click="handleAddComputedField">Add</md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div v-show="(step === 3)">
      <md-table>
        <md-table-row>
          <md-table-head>Name</md-table-head>
          <md-table-head>Function</md-table-head>
          <md-table-head>Expression</md-table-head>
          <md-table-head>Operation</md-table-head>
        </md-table-row>
        <md-table-row v-for="form in serviceRatePlans" :key="form.Name">
          <md-table-cell>
            {{form.Name}}
          </md-table-cell>
          <md-table-cell>
            {{form.Function}}
          </md-table-cell>
          <md-table-cell>
            <ul>
              <li v-show="form.Lookup">Lookup: {{form.Lookup}}</li>
              <li v-show="form.Expression">Expression: {{form.Expression}}</li>
            </ul>
          </md-table-cell>
          <md-table-cell>
            <md-button @click="handleDeleteServiceRatePlan(form.Name)">
              <md-icon>remove</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>
            <md-field>
              <md-input v-model="newServiceRatePlanForm.Name"></md-input>
            </md-field>
          </md-table-cell>
          <md-table-cell>
            <md-field>
              <md-select v-model="newServiceRatePlanForm.Function">
                <md-option value="Expression">Expression</md-option>
                <md-option value="PriceLookup">PriceLookup</md-option>
                <md-option value="RangeMapper">RangeMapper</md-option>
                <md-option value="StepSum">StepSum</md-option>
              </md-select>
            </md-field>
          </md-table-cell>
          <md-table-cell>
            <div class="md-layout md-gutter">
              <div class="md-layout-item" v-show="newServiceRatePlanForm.Function === 'PriceLookup'">
                <md-field>
                  <label>Lookup</label>
                  <md-select v-model="newServiceRatePlanForm.Lookup">
                    <md-option :value="price.SK" v-for="price in priceLookups" :key="price.SK">{{price.Label}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item" v-show="['RangeMapper', 'StepSum'].includes(newServiceRatePlanForm.Function)">
                <md-field>
                  <label>Lookup</label>
                  <md-select v-model="newServiceRatePlanForm.Lookup">
                    <md-option :value="mapper.SK" v-for="mapper in mapperLookups" :key="mapper.SK">{{mapper.Label}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <md-input v-model="newServiceRatePlanForm.Expression"></md-input>
                </md-field>
              </div>
            </div>
          </md-table-cell>
          <md-table-cell>
            <md-button @click="handleAddServiceRatePlan">Add</md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <h3 v-show="formData.IsAggregateOrders">Aggregate Orders Amount</h3>
      <md-table v-show="formData.IsAggregateOrders">
        <md-table-row>
          <md-table-head>Name</md-table-head>
          <md-table-head>Function</md-table-head>
          <md-table-head>Expression</md-table-head>
          <md-table-head>Operation</md-table-head>
        </md-table-row>
        <md-table-row v-for="form in aggregateOrderPlans" :key="form.Name">
          <md-table-cell>
            {{form.Name}}
          </md-table-cell>
          <md-table-cell>{{form.Function}}</md-table-cell>
          <md-table-cell>{{form.Expression}}</md-table-cell>
          <md-table-cell>
            <md-button @click="handleDeleteAggregateOrderPlan(form.Name)">
              <md-icon>remove</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell>
            <md-field>
              <md-input v-model="newAggregateOrderPlanForm.Name"></md-input>
            </md-field>
          </md-table-cell>
          <md-table-cell>
            <md-field>
              <md-select v-model="newAggregateOrderPlanForm.Function">
                <md-option value="Min">Min</md-option>
                <md-option value="Max">Max</md-option>
                <md-option value="Average">Average</md-option>
                <md-option value="First">First</md-option>
                <md-option value="Last">Last</md-option>
              </md-select>
            </md-field>
          </md-table-cell>
          <md-table-cell>
            <md-field>
              <md-input v-model="newAggregateOrderPlanForm.Expression"></md-input>
            </md-field>
          </md-table-cell>
          <md-table-cell>
            <md-button @click="handleAddAggregateOrderPlan">Add</md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div v-show="(step === 4)">
      <md-field>
        <label>Name</label>
        <md-input v-model="formData.Name"></md-input>
      </md-field>
      <md-table>
        <md-table-row>
          <md-table-cell><strong>CustomerID:</strong> {{submitForm.CustomerID}}</md-table-cell>
          <md-table-cell><strong>JobType:</strong> {{submitForm.JobType}}</md-table-cell>
          <md-table-cell><strong>IsMultipleDiary:</strong> {{submitForm.IsMultipleDiary}}</md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell colspan="4">
            Custom Fields:
          </md-table-cell>
        </md-table-row>
        <md-table-row v-for="field in submitForm.CustomFields" :key="field.Field">
          <md-table-cell><strong>Name:</strong> {{field.Field}}</md-table-cell>
          <md-table-cell><strong>ShowInDiary:</strong> {{field.ShowInDiary}}</md-table-cell>
          <md-table-cell><strong>Validation:</strong><span v-show="field.Validation.isRequired">Required</span></md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell colspan="4">
            Computed Fields:
          </md-table-cell>
        </md-table-row>
        <md-table-row v-for="field in submitForm.ComputedFields" :key="field.Name">
          <md-table-cell><strong>Name:</strong> {{field.Name}}</md-table-cell>
          <md-table-cell><strong>Function:</strong> {{field.Function}}</md-table-cell>
          <md-table-cell><strong>Expression:</strong> {{field.Expression}}</md-table-cell>
          <md-table-cell><strong>Lookup:</strong> {{field.Lookup}}</md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell colspan="4">
            Rates:
          </md-table-cell>
        </md-table-row>
        <md-table-row v-for="rate in submitForm.ServiceRatePlans" :key="rate.Name">
          <md-table-cell><strong>Name:</strong> {{rate.Name}}</md-table-cell>
          <md-table-cell><strong>Function:</strong> {{rate.Function}}</md-table-cell>
          <md-table-cell><strong>Expression:</strong> {{rate.Expression}}</md-table-cell>
          <md-table-cell><strong>Lookup:</strong> {{rate.Lookup}}</md-table-cell>
        </md-table-row>
        <md-table-row v-show="submitForm.AggregateOrderPlans.length > 0">
          <md-table-cell colspan="4">
            Aggregate:
          </md-table-cell>
        </md-table-row>
        <md-table-row v-for="rate in submitForm.AggregateOrderPlans" :key="rate.Name">
          <md-table-cell><strong>Name:</strong> {{rate.Name}}</md-table-cell>
          <md-table-cell><strong>Function:</strong> {{rate.Function}}</md-table-cell>
          <md-table-cell><strong>Expression:</strong> {{rate.Expression}}</md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'

export default {
  name: 'RatePlanForm',
  data () {
    return {
      customerItems: [],
      jobTypes: [],
      customerJobTypes: [],
      step: 1,
      formData: {
        Name: null,
        CustomerID: '---',
        JobType: '---',
        JobSubtype: '---',
        IsMultipleDiary: false,
        IsAggregateOrders: false
      },
      errors: {},
      customFields: [],
      selectedCustomFields: [],
      priceLookups: [],
      mapperLookups: [],
      newSelectedCustomFieldForm: {
        Field: null,
        ShowInDiary: null,
        Validation: {
          isRequired: false
        }
      },
      computedFields: [],
      newComputedFieldForm: {
        Name: null,
        Function: null,
        Expression: null,
        Lookup: null
      },
      serviceRatePlans: [],
      newServiceRatePlanForm: {
        Name: null,
        Function: null,
        Expression: null,
        Lookup: null
      },
      aggregateOrderPlans: [],
      newAggregateOrderPlanForm: {
        Name: null,
        Function: null,
        Expression: null
      }
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items'].filter((i) => i.SK.startsWith('#METADATA#'))]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchFavJobTypes: function (customerID) {
      let vm = this
      API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`).then((json) => {
        vm.customerJobTypes = json
      })
    },
    fetchJobTypes: function () {
      let vm = this
      API.get('ezietruckapi', `/api/options/JobType`).then((json) => {
        vm.jobTypes = json
      })
    },
    fetchCustomField: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.customFields = []
      }
      API.get('ezietruckapi', '/api/customFields'+q).then((json) => {
        if (json['Items']) {
          self.customFields = [...self.customFields, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomField(json.LastEvaluatedKey)
        }
      })
    },
    fetchPriceLookup: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q += `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', `/api/lookups${q}`).then((json) => {
        if (json['Items']) {
          for (let i of json['Items']) {
            if (i.PK === 'PriceLookup') {
              self.priceLookups = [...self.priceLookups, i]
            } else if (i.PK === 'MapperLookup') {
              self.mapperLookups = [...self.mapperLookups, i]
            }
          }
        }
        if (json.LastEvaluatedKey) {
          self.fetchPriceLookup(json.LastEvaluatedKey)
        }
      })
    },
    handleAddSelectedField: function () {
      this.selectedCustomFields = [...this.selectedCustomFields, this.newSelectedCustomFieldForm]
      this.newSelectedCustomFieldForm = {
        Field: null,
        ShowInDiary: null,
        Validation: {
          isRequired: false
        }
      }
    },
    handleDeleteSelectedField: function (name) {
      let i = this.selectedCustomFields.findIndex((c) => c.Name === name)
      if (i > -1) {
        this.selectedCustomFields = [
          ...this.selectedCustomFields .slice(0, i),
          ...this.selectedCustomFields .slice(i+1)
        ]
      }
    },
    handleAddComputedField: function () {
      let newField = {
        Name: this.newComputedFieldForm.Name,
        Function: this.newComputedFieldForm.Function,
        Expression: this.newComputedFieldForm.Expression
      }
      if (this.newComputedFieldForm.Lookup) {
        newField['Lookup'] = this.newComputedFieldForm.Lookup
      }
      this.computedFields = [...this.computedFields, newField]
      this.newComputedFieldForm = {
        Name: null,
        Function: null,
        Expression: null,
        Lookup: null
      }
    },
    handleDeleteComputedField: function (name) {
      let i = this.computedFields.findIndex((c) => c.Name === name)
      if (i > -1) {
        this.computedFields = [
          ...this.computedFields .slice(0, i),
          ...this.computedFields .slice(i+1)
        ]
      }
    },
    handleAddServiceRatePlan: function () {
      let newField = {
        Name: this.newServiceRatePlanForm.Name,
        Function: this.newServiceRatePlanForm.Function,
        Expression: this.newServiceRatePlanForm.Expression
      }
      if (this.newServiceRatePlanForm.Lookup) {
        newField['Lookup'] = this.newServiceRatePlanForm.Lookup
      }
      this.serviceRatePlans = [...this.serviceRatePlans, newField]
      this.newServiceRatePlanForm = {
        Name: null,
        Function: null,
        Expression: null,
        Lookup: null
      }
    },
    handleDeleteServiceRatePlan: function (name) {
      let i = this.serviceRatePlans.findIndex((c) => c.Name === name)
      if (i > -1) {
        this.serviceRatePlans = [
          ...this.serviceRatePlans.slice(0, i),
          ...this.serviceRatePlans.slice(i+1)
        ]
      }
    },
    handleAddAggregateOrderPlan: function () {
      this.aggregateOrderPlans= [...this.aggregateOrderPlans, this.newAggregateOrderPlanForm]
      this.newAggregateOrderPlanForm = {
        Name: null,
        Function: null,
        Expression: null
      }
    },
    handleDeleteAggregateOrderPlan: function (name) {
      let i = this.aggregateOrderPlans.findIndex((c) => c.Name === name)
      if (i > -1) {
        this.aggregateOrderPlans = [
          ...this.aggregateOrderPlans.slice(0, 1),
          ...this.aggregateOrderPlans.slice(i + 1)
        ]
      }
    },
    handleSubmitForm: function () {
      // let sk = encodeURIComponent(`${this.submitForm.CustomerID}#${this.submitForm.JobType}#${this.submitForm.Name}`)
      API.post('ezietruckapi', `/api/ratePlans`, {body: this.submitForm}).then(() => {
          this.$router.push('/RatePlanList')
        })
    },
    labelSelectedField: function (name) {
      let i = this.customFields.findIndex((c) => c.Name === name)
      if (i > -1) {
        return this.customFields[i]['Label']
      } else {
        return ''
      }
    },
  },
  computed: {
    ...mapGetters(['user']),
    currentSubJobTypes: function () {
      if (this.formData.JobType) {
        const idx = this.customerJobTypes.findIndex((j) => j.Name === this.formData.JobType)
        return idx > -1 ? this.customerJobTypes[idx].SubJobType : []
      }
      return []
    },
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    allJobTypes: function () {
      return this.customerJobTypes.length > 0 ? this.customerJobTypes : this.jobTypes
    },
    submitForm: function () {
      return {
        Name: this.formData.Name,
        CustomerID: this.formData.CustomerID,
        JobType: this.formData.JobType,
        IsMultipleDiary: this.formData.IsMultipleDiary,
        CustomFields: this.selectedCustomFields,
        ComputedFields: this.computedFields,
        ServiceRatePlans: this.serviceRatePlans,
        AggregateOrderPlans: this.aggregateOrderPlans,
      }
    }
    // filterPriceLookups: function () {
    //   let out = {}
    //   for (let price of this.priceLookups.filter((p) => p.CustomerID === '---' || p.JobType === '---' || p.CustomerID === this.formData.CustomerID || p.JobType === this.formData.JobType)) {
    //     if (!out[price.SK.split('#').slice(0, -1).join("#")]) {
    //       out[price.SK.split('#').slice(0, -1).join("#")] = price
    //     } else if (out[price.SK.split('#').slice(0, -1).join("#")].EffectiveDate < price.EffectiveDate) {
    //       out[price.SK.split('#').slice(0, -1).join("#")] = price
    //     }
    //   }
    //   return Object.values(out)
    // }
  },
  created () {
    this.fetchCustomer()
    this.fetchJobTypes()
    this.fetchCustomField()
    this.fetchPriceLookup()
  }
}
</script>
