<template>
  <div>
    <md-toolbar md-elevation="0">
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start" style="flex: 5">
          <form @submit.prevent="handleSearchOrder"  class="md-toolbar-row md-layout" >
            <div class="md-layout-item">
              <md-autocomplete @input="handleCustomerSelect" :value="currentCustomer.CustomerName" :md-options="customers">
                <label>Customer</label>
                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                  <md-avatar>
                    <amplify-s3-image :imagePath="item.CustomerLogo" class="md-avatar md-large"></amplify-s3-image>
                  </md-avatar>
                  <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
                </template>

                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  No customer matching "{{ term }}" were found.
                </template>
              </md-autocomplete>
            </div>
            <div class="md-layout-item">
              <md-datepicker v-model="formData.StartDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
                <label>Start Date</label>
                <span class="md-error" v-if="errors['Date']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item">
              <md-datepicker v-model="formData.EndDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
                <label>End Date</label>
                <span class="md-error" v-if="errors['Date']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item">
              <md-autocomplete v-model="formData.JobType" :md-options="jobTypeAllOptions" :class="{'md-invalid': errors['instance.JobType']}">
                <label>Job Type</label>
                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  <a @click="handleSaveCustomerJobType(term)">Create a new</a> Job Type!
                </template>
                <span class="md-error" v-if="errors['instance.JobType']">Invalid field input</span>
              </md-autocomplete>
            </div>
            <div class="md-layout-item">
              <md-button :disabled="!!LastEvaluatedKey" type="submit" class="md-raised md-primary">
                <span>Search</span>
              </md-button>
              <md-button :disabled="!!LastEvaluatedKey" @click="handleDownloadAllCSV">
                Download
              </md-button>
            </div>
          </form>
        </div>
        <div class="md-toolbar-section-end" style="flex: 1">
          <md-field>
            <label for="movie">Group by</label>
            <md-select v-model="groupBy" name="groupBy" id="groupBy">
              <md-option value="StartDate">Start Date</md-option>
              <md-option value="Date">Order Date</md-option>
              <md-option value="JobType">Job Type</md-option>
              <md-option value="DriverName">Driver</md-option>
              <md-option value="From.Description">From</md-option>
              <md-option value="To.Description">To</md-option>
              <md-option value="TruckType">Truck Type</md-option>
            </md-select>
          </md-field>
          <md-checkbox v-model="hasDiary">Diary</md-checkbox>
        </div>
      </div>
    </md-toolbar>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Adjust Detail</md-dialog-title>
      <md-dialog-content>
        <md-field :class="{'md-invalid': errors['adjustForm.AdjustField']}">
          <label>Adjust field</label>
          <md-select v-model="adjustForm.AdjustField" @md-selected="handleAdjustFieldChange">
            <md-option value="CustomerPaidAmount">Customer Paid Amount</md-option>
            <md-option value="DeliveryCost">Deliver Cost</md-option>
          </md-select>
          <span class="md-error" v-if="errors['adjustForm.AdjustField']">{{errors['adjustForm.AdjustField']}}</span>
        </md-field>
        <md-field :class="{'md-invalid': errors['instance.CurrentAmount']}">
          <label>Current Amount</label>
          <md-input :value="adjustForm.CurrentAmount" readonly="readonly" />
          <span class="md-error" v-if="errors['adjustForm.CurrentAmount']">{{errors['adjustForm.CurrentAmount']}}</span>
        </md-field>
        <md-field :class="{'md-invalid': errors['adjustForm.TargetAmount']}">
          <label>Target Amount</label>
          <md-input v-model="adjustForm.TargetAmount" />
          <span class="md-error" v-if="errors['adjustForm.TargetAmount']">{{errors['adjustForm.TargetAmount']}}</span>
        </md-field>

      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">Close</md-button>
        <md-button class="md-primary" @click="handleAdjustAmount">Adjust</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackBarMsg}}</span>
    </md-snackbar>
    <md-table v-for="group in groupedByOrder" :key="group.title" v-model="group.items" class="md-primary">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h3 class="md-subtitle" style="color: white">{{group.title}} Trip: {{group.trip}} Sales: {{$currencyFormater(group.sales)}} Cost: {{$currencyFormater(group.cost)}} Margin: {{$currencyFormater(group.margin)}} </h3>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-raised" @click="opendAdjustDialog(group.title)">Adjust</md-button>
        </div>
      </md-table-toolbar>
      <md-table-row slot="md-table-row" slot-scope="{ item }" >
        <md-table-cell md-label="OrderID"><router-link :to="'/Orders/' + item.OrderID">{{item.OrderID}}</router-link></md-table-cell>
        <md-table-cell md-label="Driver Assign">{{item.DriverName}}</md-table-cell>
        <md-table-cell md-label="Truck Type">{{item.TruckType}} - {{item.ContainerType}}</md-table-cell>
        <md-table-cell md-label="Start at">{{dateTimeFormatter(item.StartDateTime)}}</md-table-cell>
        <md-table-cell md-label="From">{{item.From.Description}}</md-table-cell>
        <md-table-cell md-label="To">{{item.To.Description}}</md-table-cell>
        <md-table-cell md-label="Driver Paid">
          <span v-if="item['Driver.DriverPaidID']">{{item['Driver.DriverPaidID']}}</span>
          <span v-if="!item['Driver.DriverPaidID']">
            <md-icon>warning</md-icon> <span>Unassign Paid ID</span>
          </span>
        </md-table-cell>
        <md-table-cell md-label="Total Sale Amont">{{ $currencyFormater(item.CustomerPaidAmount) }} ({{ $currencyFormater(item.AdjustAmount || 0) }})</md-table-cell>
        <md-table-cell md-label="Total Cost Amont">{{ $currencyFormater(item.DailyPickerCost + item.DeliveryCost + item.FuelAllowance + item.OtherCost + item.SalaryPerTrip + (item.CostAdjustAmount || 0)) }} ({{ $currencyFormater(item.CostAdjustAmount || 0) }})</md-table-cell>
        <md-table-cell md-label="Diary Count">{{diaries[item.OrderID] || 0}}</md-table-cell>
        <md-table-cell md-label="Operation">
          <md-button  :to="'/Orders/' + item.OrderID + '/TripSearch'">Add Trip detail</md-button>
          <md-button v-show="!['1:Preparing'].includes(item.OrderStatus)" :to="'/Orders/' + item.OrderID + '/Trip'">Trip detail</md-button>
          <md-button v-show="['1:Preparing'].includes(item.OrderStatus)" :to="'/Orders/' + item.OrderID">Order detail</md-button>
          <md-button v-show="allowClick" @click="handleDeleteOrder(item.OrderID)">Delete</md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueScreenSize from 'vue-screen-size'
import moment from 'moment'
import { API } from 'aws-amplify'

export default {
  name: 'ConfirmOrderList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data () {
    return {
      currentMonth: moment().format('YYYYMM'),
      currentCustomer: {},
      customerItems: [],
      orderItems: [],
      tripItems: [],
      receipt: {},
      showMakePDDialog: false,
      errors: {},
      trips: [],
      diaries: {},
      adjustForm: {
        AdjustField: 'CustomerPaidAmount',
        CurrentAmount: null,
        TargetAmount: 0,
        CostDistribution: 'all-percent',
        SelectedOrders: [],
        Records: ""
      },
      showDialog: false,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      groupBy: 'StartDate',
      LastEvaluatedKey: null,
      allowClick: true,
      jobTypeOptions: [],
      jobTypeCustomerOptions: [],
      formData: {
        StartDate: moment().format('YYYY-MM-DD'),
        EndDate: moment().format('YYYY-MM-DD'),
        JobType: null,
        status: '5:Confirmed'
      },
      loading: false,
      hasDiary: false
    }
  },
  methods: {
    handleSearchOrder () {
      if (this.currentCustomer.CustomerID) {
        this.fetchCustomerOrder(this.currentCustomer.CustomerID, null)
      }
    },
    loadCustomerJobType (customerID) {
      API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`).then((json) => {
        this.jobTypeCustomerOptions = json.map((j) => j.Name)
      })
    },
    fetchOptions: function () {
      let self = this
      API.get('ezietruckapi', `/api/options/JobType`).then((json) => {
        self.jobTypeOptions = json.map((j) => j.Name)
      })
    },
    handleCustomerSelect (value) {
      if (typeof value === 'object') {
        this.currentCustomer = value
        this.loadCustomerJobType(value.CustomerID)
      }
    },
    fetchOrderDiary: function (orderId) {
      let vm = this
      API.get('ezietruckapi', `/api/orders/${orderId}/diary`).then((json) => {
        vm.diaries[orderId] = json.Count
        vm.diaries = Object.assign({}, vm.diaries)
      })
    },
    fetchReceipt: function (invoiceNumber) {
      let vm = this
      if (invoiceNumber && !this.receipt[invoiceNumber]) {
        API.get('ezietruckapi', `/api/customerInvoices/${invoiceNumber}`).then((json) => {
          vm.receipt[invoiceNumber] = json
        })
      }
    },
    fetchCustomerOrder: function (customerId, lastEvaluatedKey) {
      let self = this
      this.loading = true
      let body = {'q': this.formData, 'opt': {'IncludeTrips': true}}
      if (lastEvaluatedKey) {
        body['opt']['LastEvaluatedKey'] = lastEvaluatedKey
      } else {
        this.orderItems = []
        this.tripItems = []
      }
      API.post('ezietruckapi', `/api/customers/${customerId}/orders`, {body: body}).then((json) => {
        json['Items'].forEach((o) => {
          if (o.SK.startsWith('#METADATA#')) {
            self.orderItems.push(o)
            self.fetchOrderDiary(o.OrderID)
            self.fetchReceipt(o.InvoiceNumber || '')
          } else {
            self.tripItems.push(o)
          }
        })
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKey = json.LastEvaluatedKey
          self.fetchCustomerOrder(customerId, json.LastEvaluatedKey)
        } else {
          self.LastEvaluatedKey = null
          self.loading = false
        }
      })
    },
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?LastCustomer=${lastEvaluatedKey.PK.slice(9)}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchData: function () {
      this.fetchCustomer()
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY hh:mm")
      } else {
        return ''
      }
    },
    handleDeleteOrder: function (orderId) {
      let self = this
      this.allowClick = false
      API.del('ezietruckapi', `/api/orders/${orderId}`).then(() => {
        self.fetchCustomerOrder(self.currentCustomer.CustomerID, null)
        self.allowClick = true
        self.showSnackbar = true
        self.snackBarMsg = 'Successfully saved'
      }).catch((e) => {
        self.showSnackbar = true
        self.allowClick = true
        self.snackBarMsg = e.message
      })
    },
    handleDownloadAllCSV: function () {
      let ordercsv = 'Order_ID,Cust_ID, Cust_Name, Order_Date,Job_Type,Product_Type,Truck_Type(W), Container_Type,Load_Per_Truck(Ton), Truck_Request, Stop_Seq_Ref,Trip_Request,Picker_Per_Truck,From_LocDesc,From_FavLocName,From_LocGPS,To_LocDesc,To_FavLocName,To_LocGPS,Start_DateTime,End_DateTime,CustPaid_Amt,CustBilling_Date,CustPaidDue_Date,Order_Status,Order_Owner_Name,Invoice_Number,Order_Remark,Order_TotalCost, Order_Margin,Trip_Count,YearWeek_Number, Receipt_Number,Order_Key,OrderAdjustAmount,Dummy_Key,Trip_ID,Driver_Name,License_Number,Driver_ID,Delivery_Cost,DailyPicker_Cost,Other_Cost,Salary_PerTrip,Fuel_Allowance,DriverPaidDue_Date,Trip_Status,Approved_By,Truck_Supporter,Paid_Number,CostAdjustAmount,Count_Diary';
      let orders = {}
      for (let order of this.orderItems) {
        orders[order.OrderID] = `${order.OrderID},${order.CustomerID},${order.CustomerName},${moment(order.CreatedAt).format('YYYY-MM-DD')},${order.JobType},${order.ProductType},` +
          `${order.TruckType},${order.ContainerType},${order.LoadPerTruck},${order.TruckRequest},${order.StopNumber},${order.TripRequest},${order.PickerPerTruck},` +
          `${order.From.Description},,${order.From.GPS ? order.From.GPS.Lat : ''} ${order.From.GPS ? order.From.GPS.Lon : ''},${order.To.Description},,` +
          `${order.To.GPS ? order.To.GPS.Lat : ''} ${order.To.GPS ? order.To.GPS.Lon : ''},${order.StartDateTime},${order.EndDateTime},${order.CustomerPaidAmount},` +
          `${order.CustomerBillDate},${order.CustomerPaidDueDate || ''},${order.OrderStatus},${order.CreatedBy.split('@')[0]},${order.InvoiceNumber || ''},${order.Remark || ''},,,,,` +
          `${(this.receipt[order.InvoiceNumber] || {}).ReceiptNumber || ''},${order.OrderSheetRef},${order.AdjustAmount || 0}`
      }
      for (let trip of this.tripItems) {
        orders[trip.OrderID] += `,${trip.AssignSheetRef},${trip.TripID},${trip.DriverName},${trip.LicensePlate},${trip.DriverID},`+
          `${trip.DeliveryCost},${trip.DailyPickerCost},${trip.OtherCost},${trip.SalaryPerTrip},${trip.FuelAllowance},${trip.DriverPaidDueDate},${trip.TripStatus},`+
          `${trip.ApprovedBy ? trip.ApprovedBy.split('@')[0] : ''},${trip.TruckSupporter || ''},${trip.PDNumber || ''},${trip.AdjustAmount || 0},${this.diaries[trip.OrderID] || 0}`
      }
      let ordercsvElement = document.createElement('a')
      const blob = new Blob(["\ufeff", [ordercsv, ...Object.values(orders)].join('\n')], {type : 'text/csv;charset=utf-8'})
      ordercsvElement.href = URL.createObjectURL(blob)
      ordercsvElement.target = '_blank'
      ordercsvElement.download = 'Cust_Order.csv'
      ordercsvElement.click()
    },
    opendAdjustDialog: function (group) {
      this.adjustForm.cuurrentGroup = group
      console.log(+(new Date()))
      this.handleAdjustFieldChange()
      console.log(+(new Date()))
      this.showDialog = true
      console.log(+(new Date()))
    },
    handleAdjustFieldChange: function () {
      if (this.groupedByOrder[this.adjustForm.cuurrentGroup]) {
        this.adjustForm.SelectedOrders = []
        this.adjustForm.CurrentAmount = 0
        for (let i of this.groupedByOrder[this.adjustForm.cuurrentGroup].items) {
          this.adjustForm.SelectedOrders.push(i.OrderID)
          if (this.adjustForm.AdjustField === 'CustomerPaidAmount') {
            this.adjustForm.CurrentAmount = (this.adjustForm.CurrentAmount*1000 + i.CustomerPaidAmount*1000)/1000
          } else if (this.adjustForm.AdjustField === 'DeliveryCost') {
            this.adjustForm.CurrentAmount =  (this.adjustForm.CurrentAmount*1000 + i.DeliveryCost*1000 + i.DailyPickerCost*1000 + i.OtherCost*1000 + i.SalaryPerTrip*1000 + i.FuelAllowance*1000)/1000
          }
        }
      }
    },
    handleAdjustAmount: function () {
      let self = this
      let includedItem = []
      for (let i of this.groupedByOrder[this.adjustForm.cuurrentGroup].items) {
        if (this.adjustForm.AdjustField === 'CustomerPaidAmount') {
          includedItem.push([i.OrderID,(this.adjustForm.CurrentAmount*1000 + i.CustomerPaidAmount*1000)/1000])
        } else if (this.adjustForm.AdjustField === 'DeliveryCost') {
          includedItem.push([`${i.OrderID}.1`,(this.adjustForm.CurrentAmount*1000 + i.DeliveryCost*1000 + i.DailyPickerCost*1000 + i.OtherCost*1000 + i.SalaryPerTrip*1000 + i.FuelAllowance*1000)/1000])
        }
      }
      let adjustSum = 0
      let percent = this.adjustForm.TargetAmount/this.adjustForm.CurrentAmount
      for (let i = 0; i < includedItem.length - 1; i ++) {
        let diff = (parseInt(includedItem[i][1] * percent)*1000 - includedItem[i][1]*1000)/1000
        adjustSum = (adjustSum*1000 + diff*1000)/1000
        includedItem[i][1] = diff
      }
      includedItem[includedItem.length - 1][1] = (this.adjustForm.TargetAmount*1000 - (this.adjustForm.CurrentAmount*1000 + adjustSum*1000))/1000
      this.adjustForm.Records = includedItem.map((i) => i.join(',')).join('\n')
      API.post('ezietruckapi', `/api/orders/---/adjust`, {body: this.adjustForm}).then(() => {
          self.showDialog = false
          self.showSnackbar = true
          self.snackBarMsg = 'Successfully saved'
          self.adjustForm = {
            AdjustField: 'CustomerPaidAmount',
            CurrentAmount: null,
            TargetAmount: 0,
            CostDistribution: 'all-percent',
            SelectedOrders: [],
            Records: ""
          }
          self.handleSearchOrder()
        }).catch((e) => {
          self.sending = false
          self.showSnackbar = true
          self.snackBarMsg = JSON.stringify(e)
          self.showDialog = false
          self.adjustForm = {
            AdjustField: 'CustomerPaidAmount',
            CurrentAmount: null,
            TargetAmount: 0,
            CostDistribution: 'all-percent',
            SelectedOrders: [],
            Records: ""
          }
        })
    }
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    groupedByOrder: function () {
      let out = {}
      if (!this.LastEvaluatedKey) {
        let orders = {}
        for (let item of [...this.orderItems, ...this.tripItems]) {
          if (orders[item.PK]) {
            let u = {}
            if (item.StartDateTime) {
              u['StartDate'] = item.StartDateTime.split('T')[0]
            }
            if (item.From) {
              u['From.Description'] = item.From.Description
              u['To.Description'] = item.To.Description
            }
            if (item.SK.startsWith('TRIP') && item.AdjustAmount) {
              u['CostAdjustAmount'] = item.AdjustAmount
              delete item.AdjustAmount
            }
            orders[item.PK] = Object.assign({}, orders[item.PK], item, u)
          } else {
            let u = {}
            if (item.StartDateTime) {
              u['StartDate'] = item.StartDateTime.split('T')[0]
            }
            if (item.From) {
              u['From.Description'] = item.From.Description
              u['To.Description'] = item.To.Description
            }
            if (item.SK.startsWith('TRIP') && item.AdjustAmount) {
              u['CostAdjustAmount'] = item.AdjustAmount
              delete item.AdjustAmount
            }
            orders[item.PK] = Object.assign({}, item, u)
          }
        }
        for (let k in orders) {
          if (!this.hasDiary || this.diaries[k.split('#')[1]]) {
            if (out[orders[k][this.groupBy]]) {
              out[orders[k][this.groupBy]].trip += 1
              out[orders[k][this.groupBy]].sales += orders[k].CustomerPaidAmount + (orders[k].AdjustAmount || 0)
              out[orders[k][this.groupBy]].cost += orders[k].DailyPickerCost + orders[k].DeliveryCost + orders[k].FuelAllowance + orders[k].OtherCost + orders[k].SalaryPerTrip + (orders[k].CostAdjustAmount || 0)
              out[orders[k][this.groupBy]].margin += (orders[k].CustomerPaidAmount + (orders[k].AdjustAmount || 0)) - (orders[k].DailyPickerCost + orders[k].DeliveryCost + orders[k].FuelAllowance + orders[k].OtherCost + orders[k].SalaryPerTrip + (orders[k].CostAdjustAmount || 0))
              out[orders[k][this.groupBy]].items.push(orders[k])
            } else {
              out[orders[k][this.groupBy]] = {
                title: orders[k][this.groupBy],
                trip: 1,
                sales: orders[k].CustomerPaidAmount + (orders[k].AdjustAmount || 0),
                cost: orders[k].DailyPickerCost + orders[k].DeliveryCost + orders[k].FuelAllowance + orders[k].OtherCost + orders[k].SalaryPerTrip + (orders[k].CostAdjustAmount || 0),
                margin: (orders[k].CustomerPaidAmount + (orders[k].AdjustAmount || 0)) - (orders[k].DailyPickerCost + orders[k].DeliveryCost + orders[k].FuelAllowance + orders[k].OtherCost + orders[k].SalaryPerTrip + (orders[k].CostAdjustAmount || 0)),
                items: [orders[k]]
              }
            }
          }
        }
        for (let k in out) {
          out[k].items.sort((a,b) => a.OrderID.localeCompare(b.OrderID))
        }
      }
      return out
    },
    jobTypeAllOptions: function () {
      return [...this.jobTypeOptions, ...this.jobTypeCustomerOptions]
    }
  },
  created () {
    this.fetchData()
    this.fetchOptions()
  }
}
</script>