<script>
import moment from 'moment'
import { API } from 'aws-amplify'

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    },
    currentDriver: {
      type: Object
    },
    driver: {
      type: Object
    }
  },
  data() {
    return {
      markerObj: null,
      infoObj: null,
      diaryItems: []
    }
  },
  methods: {
    fetchDiary: function (driverId) {
      let vm = this
      let d = moment()
      Promise.all([
        API.get('ezietruckapi', '/api/drivers/' + driverId + '/diary/' + d.format('YYYY-MM-DD')),
        API.get('ezietruckapi', '/api/drivers/' + driverId + '/diary/' + d.subtract(1, 'days').format('YYYY-MM-DD'))
      ]).then((results) => {
        let content = '<h1> '+vm.driver.DriverName+' - Diary</h1><table border="1" style="border:1px solid black collapse;"><tr><th>Created At</th><th>Customer ID</th><th>Order ID</th><th>Stop for</th><th>Note</th></tr>'
        for (let result of results) {
          if (result) {
            vm.diaryItems = [...this.diaryItems, ...result]
            for (let item of result) {
              content += '<tr><td>'+ moment(item['CreatedAt']).format("lll") + '</td><td>' + (item.CustomerID || '') + '</td><td>' + (item.OrderID || '') + '</td><td>' + (item.StopFor || '') + '</td><td>' + (item.Note || '') + '</td></tr>'
            }
          }
        }
        content += '</table>'
        vm.infoObj.setContent(content)
      })
    }
  },
  watch: {
    currentDriver: function (val) {
      let icon = '/truck.png'
      if (val.DriverID === this.marker.id) {
        this.map.setCenter({lat: val.LastLocation.GPS.Lat, lng: val.LastLocation.GPS.Lon})
        this.fetchDiary(this.driver.DriverPhoneNumber)
        this.infoObj.open(this.map, this.markerObj)
        if (Date.now() - this.marker.lastUpdated > 15*60*1000) {
          icon = '/active_gray_truck.png'
        } else {
          icon = '/active_truck.png'
        }
      } else {
        if (Date.now() - this.marker.lastUpdated > 15*60*1000) {
          icon = '/gray_truck.png'
        }
        this.infoObj.close()
      }
      if (this.markerObj.getIcon() !== icon) {
        this.markerObj.setIcon(icon)
      }
    },
    marker: function (val) {
      let icon = '/truck.png'
      if (this.currentDriver) {
        if (this.currentDriver.DriverID === this.marker.id) {
          if (Date.now() - val.lastUpdated > 15*60*1000) {
            icon = '/active_gray_truck.png'
          } else {
            icon = '/active_truck.png'
          }
        } else {
          if (Date.now() - val.lastUpdated > 15*60*1000) {
            icon = '/gray_truck.png'
          }
        }
      }
      this.markerObj.setIcon(icon)
      this.markerObj.setPosition(val.position)
    }
  },
  mounted() {
    const { Marker, InfoWindow } = this.google.maps;
    let icon = '/truck.png'
    if (this.currentDriver) {
      if (this.currentDriver.DriverID === this.marker.id) {
        if (Date.now() - this.marker.lastUpdated > 15*60*1000) {
          icon = '/active_gray_truck.png'
        } else {
          icon = '/active_truck.png'
        }
      } else {
        if (Date.now() - this.marker.lastUpdated > 15*60*1000) {
          icon = '/gray_truck.png'
        }
      }
    }
    this.markerObj = new Marker({
      title: this.marker.title,
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      icon: icon
    })
    this.infoObj = new InfoWindow({
      content: '',
    }) 
    let vm = this
    this.markerObj.addListener("click", () => {
      vm.$emit('click', vm.marker.id)
      vm.fetchDiary(vm.driver.DriverPhoneNumber)
      vm.infoObj.open(vm.map, vm.markerObj)
    })
  },
  beforeDestroy () {
    this.markerObj.setMap(null);
  },
  render() {
    return null
  }
};
</script>
