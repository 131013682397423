<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-section-start">
        <h3 class="md-subtitle">{{order.title}}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <h3 class="md-subtitle">Trip: {{order.trip}} Sales: {{$currencyFormater(order.sales)}} Cost: {{$currencyFormater(order.cost)}} Margin: {{$currencyFormater(order.margin)}}</h3>
      </div>
    </md-toolbar>
    <md-table v-model="order.items" @md-selected="onSelect">
      <md-table-toolbar slot="md-table-alternate-header" slot-scope="{}">
        <div class="md-toolbar-section-end">
          <md-button @click="handleConfirmOrder" v-show="canConfirm">
            Confirm
          </md-button>
          <div class="md-warn" v-show="!canConfirm">
            Unable to confirm order without diary.
          </div>
          <md-button @click="handleDeleteOrder">
            Delete
          </md-button>
        </div>
      </md-table-toolbar>
      <md-table-row  slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select :md-disabled="allowClick && allowClick !== order.title">
        <md-table-cell md-label="OrderID">
          <router-link :to="'/Orders/' + item.OrderID">{{item.OrderID}}</router-link>
        </md-table-cell>
        <md-table-cell md-label="Driver Assign">
          <span v-if="item['Driver.DriverName']">{{item['Driver.DriverName']}}</span>
          <span v-if="!item['Driver.DriverName']">
            <md-icon>warning</md-icon> <span>Unassign Driver</span>
          </span>
        </md-table-cell>
        <md-table-cell md-label="Truck Type">
          {{item.TruckType}} - {{item.ContainerType}}
        </md-table-cell>
        <md-table-cell md-label="Start at">{{dateTimeFormatter(item.StartDateTime)}}</md-table-cell>
        <md-table-cell md-label="From">{{item.From.Description}}</md-table-cell>
        <md-table-cell md-label="To">{{item.To.Description}}</md-table-cell>
        <md-table-cell md-label="Operation">
          <md-button  :to="'/Orders/' + item.OrderID + '/TripSearch'">Add Trip detail</md-button>
          <md-button v-show="(diaries[item.OrderID] || 0) > 0" :to="'/Orders/' + item.OrderID + '/Trip'">Trip detail</md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>
        <div class="md-layout">
          <div class="md-layout-item">Diary</div>
          <div class="md-layout-item">
            <md-datepicker v-model="currentStartDate" :md-model-type="String" md-immediately>
              <label>Start Date</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item">
            <md-datepicker v-model="currentEndDate" :md-model-type="String" md-immediately>
              <label>End Date</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item">
            <md-button @click="searhDiaryAgain()">Search</md-button>
          </div>
        </div>
      </md-dialog-title>
      <div>
        <h3 v-show="foundDiaries.length === 0">No Diary found</h3>
        <md-table v-model="foundDiaries" md-card @md-selected="onDiarySelect" md-fixed-header>
          <md-table-row slot="md-table-row" slot-scope="{ item }" :md-disabled="item.CustomerID !== $route.params.customer" md-selectable="multiple" md-auto-select>
            <md-table-cell md-label="Created At">{{dateTimeFormatter(item.CreatedAt)}}</md-table-cell>
            <md-table-cell md-label="Driver">
              <router-link :to="'/Drivers/' + item.Driver.DriverID">{{ item.Driver.DriverName }} ({{ item.Driver.DriverPhoneNumber }})</router-link>
            </md-table-cell>
            <md-table-cell md-label="Vehicle">
              {{ item.Vehicle ? item.Vehicle.VehicleLicenseNumber : "" }} {{ item.Vehicle ? item.Vehicle.VehicleLicenseProvince : "" }} -
              {{ item.Vehicle ? item.Vehicle.VehicleTruckType : "" }} {{ item.Vehicle ? item.Vehicle.VehicleContainerType : "" }}
            </md-table-cell>
            <md-table-cell md-label="Customer">{{ item.CustomerID }}</md-table-cell>
            <md-table-cell md-label="OrderID"><router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link></md-table-cell>
            <md-table-cell md-label="Stop for">{{ item.StopFor }}</md-table-cell>
            <md-table-cell md-label="Location"><a @click="mapFocusAt(item.Location)">{{ item.Area }}</a></md-table-cell>
            <md-table-cell md-label="Snapshot">
              <md-content v-for="photo in item.Photo" :key="photo">
                <my-s3-image :imagePath="photo"></my-s3-image>
              </md-content>
            </md-table-cell>
            <md-table-cell md-label="Album">
              <md-content v-for="photo in item.PhotoAlbum" :key="photo">
                <my-s3-image :imagePath="photo"></my-s3-image>
              </md-content>
            </md-table-cell>
            <md-table-cell md-label="Note">{{ item.Note }}</md-table-cell>
            <md-table-cell md-label="Pick Up">{{ item.PickUpItemNo }}</md-table-cell>
            <md-table-cell md-label="Drop Off">{{ item.DropOffItemNo }}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">Close</md-button>
        <md-button class="md-primary" @click="handleSaveDiary">Save</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { API } from 'aws-amplify'
import { mapGetters } from 'vuex'
import MyS3Image from './MyS3Image'

export default {
  name: 'OrderTable',
  components: {MyS3Image},
  props: {
    order: Object,
    allowClick: String
  },
  data () {
    return {
      selectedOrders: [],
      configOption: {},
      diaries: {},
      showDialog: false,
      foundDiaries: [],
      currentDriverPhone: null,
      currentStartDate: null,
      currentEndDate: null,
      currentOrderID: null,
      selectedDiary: []
    }
  },
  methods: {
    fetchData: function () {
      let vm = this
      this.order.items.forEach((o) => API.get('ezietruckapi', `/api/orders/${o.OrderID}/diary`).then((json) => {
        vm.diaries[o.OrderID] = json.Count
        vm.diaries = Object.assign({}, vm.diaries)
      }))
    },
    fetchOptions: function () {
      let self = this
      API.get('ezietruckapi', `/api/options/Config`).then((json) => {
        json.forEach((j) => {
          self.configOption[j.Name.split(',')[0]] = j.Name.split(',')[1]
        })
      })
    },
    fetchedDiary: function (selectedDiaryDate, driverPhoneNumber, lastEvaluatedKey) {
      let q = ''
      let vm = this
      if (lastEvaluatedKey && lastEvaluatedKey.SK) {
        q = `&LastSK=${lastEvaluatedKey.SK.replace(/#/g, '%23')}`
      }
      API.get('ezietruckapi', `/api/vehicles/diary/${selectedDiaryDate}?DriverPhoneNumber=${driverPhoneNumber}${q}`).then((json) => {
        vm.foundDiaries = [...vm.foundDiaries, ...(json.Items || [])]
        if (json.LastEvaluatedKey) {
          vm.fetchedDiary(selectedDiaryDate, driverPhoneNumber, json.LastEvaluatedKey)
        }
      })
    },
    searhDiary (DriverID, OrderStartDate, OrderEndDate, OrderID) {
      this.foundDiaries = []
      let vm = this
      this.currentOrderID = OrderID
      API.get('ezietruckapi', `/api/drivers/${DriverID}`).then((results) => {
        for (let res of results) {
          if (res.SK.startsWith('#METADATA#')) {
            vm.currentDriverPhone = res.DriverPhoneNumber
            let i = moment(OrderStartDate)
            i.subtract(1, 'days')
            vm.currentStartDate = i.format('YYYY-MM-DD')
            while (i <= moment(OrderEndDate)) {
              vm.fetchedDiary(i.format('YYYY-MM-DD'), res.DriverPhoneNumber, null)
              i.add(1, 'days')
            }
            vm.currentEndDate = i.format('YYYY-MM-DD')
            vm.fetchedDiary(i.format('YYYY-MM-DD'), res.DriverPhoneNumber, null)
          }
        }
        vm.showDialog = true
      })
    },
    searhDiaryAgain () {
      this.foundDiaries = []
      let i = moment(this.currentStartDate)
      while (i <= moment(this.currentEndDate)) {
        this.fetchedDiary(i.format('YYYY-MM-DD'), this.currentDriverPhone, null)
        i.add(1, 'days')
      }
    },
    onSelect (items) {
      this.selectedOrders = items
      if (items.length > 0) {
        this.$emit('select', this.order.title)
      } else {
        this.$emit('select', null)
      }
    },
    onDiarySelect (items) {
      this.selectedDiary = items
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY hh:mm")
      } else {
        return ''
      }
    },
    handleConfirmOrder: function () {
      let self = this

      Promise.all(this.selectedOrders.map((o) => API.post('ezietruckapi', `/api/orders/${o.OrderID}/confirm`, {body: {ApprovedBy: this.user.signInUserSession.idToken.payload.email}}))).then(() => {
        self.$emit('action', 'Successfully saved')
      }).catch((e) => {
        self.$emit('action', e.message)
      })
    },
    handleDeleteOrder: function () {
      let self = this
      Promise.all(this.selectedOrders.map((o) => API.del('ezietruckapi', `/api/orders/${o.OrderID}`))).then(() => {
        self.$emit('action', 'Successfully saved')
      }).catch((e) => {
        self.$emit('action', e.message)
      })
    },
    handleSaveDiary: function () {
      let vm = this
      API.patch('ezietruckapi', `/api/vehicles/diary`, {body: this.selectedDiary.map((m) => {
        return {'PK': m.PK, 'SK': m.SK, 'OrderID': this.currentOrderID}
      })}).then(() => {
        vm.fetchData()
        vm.showDialog = false
      }).catch(() => {
        vm.fetchData()
        vm.showDialog = false
      })
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    canConfirm: function () {
      let hasDiary = true
      let vm = this
      this.selectedOrders.forEach((o) => hasDiary &= (vm.diaries[o.OrderID] || 0) > 0)
      return hasDiary || (!this.configOption['SpecialConfirm'] || this.user.signInUserSession.idToken.payload.email === this.configOption['SpecialConfirm'])
    }
  },
  created () {
    this.fetchData()
    this.fetchOptions()
  }
}
</script>