<template>
  <div>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-xlarge-size-50  md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100" v-for="month in sortedMonthList" :key="month">
        <invoice-list-table :month="ivMonths[month]" @invoicedeleted="handleDeleteInvoice"></invoice-list-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { API } from 'aws-amplify'
import { mapGetters } from 'vuex'
import InvoiceListTable from '../components/InvoiceListTable.vue'

export default {
  name: 'InvoiceTrackList',
  components: {InvoiceListTable},
  data () {
    return {
      showDialog: false,
      sending: false,
      ivMonths: {},
      errors: {},
      customerItems: [],
      monthList: [],
      driverUnPaids: [],
      loading: false
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      this.loading = true
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        } else {
          self.loading = false
        }
      })
    },
    fetchData: function () {
      this.fetchCustomer()
      API.get('ezietruckapi', `/api/unRCMonths/`).then((json) => {
        this.driverUnPaids = json
        let self = this
        for (let up of json) {
          if (self.ivMonths[up.SK.split('#')[3].slice(0,8)]) {
            self.ivMonths[up.SK.split('#')[3].slice(0,8)].invoices.push({'CustomerID': up.CustomerID, 'CustomerName': up.CustomerName, 'CustomerLogo': up.CustomerLogo, 'InvoiceOriginalTotalAmount': parseFloat(up.InvoiceOriginalTotalAmount), 'InvoiceFinalTotalAmount': parseFloat(up.InvoiceFinalTotalAmount), 'InvoiceNumber': up.InvoiceNumber, 'CustomerPaidAdjustAmount': parseFloat(up.CustomerPaidAdjustAmount || 0)})
            self.ivMonths[up.SK.split('#')[3].slice(0,8)].sumOri = (self.ivMonths[up.SK.split('#')[3].slice(0,8)].sumOri*1000 + parseFloat(up.InvoiceOriginalTotalAmount)*1000)/1000
            self.ivMonths[up.SK.split('#')[3].slice(0,8)].sumFin = (self.ivMonths[up.SK.split('#')[3].slice(0,8)].sumFin*1000 + parseFloat(up.InvoiceFinalTotalAmount)*1000)/1000
          } else {
            self.monthList.push(up.SK.split('#')[3].slice(0,8))
            self.ivMonths[up.SK.split('#')[3].slice(0,8)] = {
              month: moment(up.SK.split('#')[3].slice(2,8), "YYYYMM").format('MMM YYYY'),
              invoices: [{'CustomerID': up.CustomerID, 'CustomerName': up.CustomerName, 'CustomerLogo': up.CustomerLogo, 'InvoiceOriginalTotalAmount': parseFloat(up.InvoiceOriginalTotalAmount), 'InvoiceFinalTotalAmount': parseFloat(up.InvoiceFinalTotalAmount), 'InvoiceNumber': up.InvoiceNumber, 'CustomerPaidAdjustAmount': parseFloat(up.CustomerPaidAdjustAmount || 0)}],
              sumOri: parseFloat(up.InvoiceOriginalTotalAmount),
              sumFin: parseFloat(up.InvoiceFinalTotalAmount)
            }
          }
        }
        self.ivMonths = Object.assign({}, this.ivMonths)
      })
    },
    handleDeleteInvoice: function () {
      this.monthList = []
      this.ivMonths = {}
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    sortedMonthList: function () {
      return [...this.monthList].sort((a, b) => a.localeCompare(b))
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
<style type="text/css" scoped="">
  .highlight-field {
    background: #ffde03
  }
</style>