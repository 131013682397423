import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../pages/SignIn.vue'
import OrderList from '../pages/OrderList.vue'
import OrderForm from '../pages/OrderForm.vue'
import OrderDashboard from '../pages/OrderDashboard.vue'
import NewOrderMatcher from '../pages/NewOrderMatcher.vue'
import TripForm from '../pages/TripForm.vue'
import TripSearch from '../pages/TripSearch.vue'
import Home from '../pages/Home.vue'
import OrderListByCustomerStatus from '../pages/OrderListByCustomerStatus.vue'
import PaymentList from '../pages/PaymentList.vue'
import NoInvoiceList from '../pages/NoInvoiceList.vue'
import NoInvoiceDetail from '../pages/NoInvoiceDetail.vue'
import InvoiceTrackList from '../pages/InvoiceTrackList.vue'
import InvoiceDetail from '../pages/InvoiceDetail.vue'
import NotFound from '../pages/NotFound.vue'
import FavoriteOrder from '../pages/FavoriteOrder.vue'
import ConfirmOrderList from '../pages/ConfirmOrderList.vue'
import CustomerList from '../pages/CustomerList.vue'
import CustomerForm from '../pages/CustomerForm.vue'
import DriverList from '../pages/DriverList.vue'
import DriverForm from '../pages/DriverForm.vue'
import DriverLocation from '../pages/DriverLocation.vue'
import PDNumberList from '../pages/PDNumberList.vue'
import PaymentForm from '../pages/PaymentForm.vue'
import IVNumberList from '../pages/IVNumberList.vue'
import OptionList from '../pages/OptionList.vue'
import DiaryList from '../pages/DiaryList.vue'
import DiaryDetail from '../pages/DiaryDetail.vue'
import PhotoLibrary from '../pages/PhotoLibrary.vue'
import TripDashboard from '../pages/TripDashboard.vue'
import SaleDashboard from '../pages/SaleDashboard.vue'
import MarginDashboard from '../pages/MarginDashboard.vue'
import CostDashboard from '../pages/CostDashboard.vue'
import PaymentDashboard from '../pages/PaymentDashboard.vue'
import BatchUpdate from '../pages/BatchUpdate.vue'
import PaymentRequestList from '../pages/PaymentRequestList.vue'
import PaymentRequestDashboard from '../pages/PaymentRequestDashboard.vue'
import Privacy from '../pages/Privacy.vue'
import IncompleteDriverList from '../pages/IncompleteDriverList.vue'
import LineDiaryFormTemplate from '../pages/LineDiaryFormTemplate.vue'
import LineCheckIOFormTemplate from '../pages/LineCheckIOFormTemplate.vue'
import DriverCheckInReport from '../pages/DriverCheckInReport.vue'
import DriverAvailableReport from '../pages/DriverAvailableReport.vue'
import JobAnnounce from '../pages/JobAnnounce.vue'
import JobAnnounceForm from '../pages/JobAnnounceForm.vue'
import JobAnnounceDetail from '../pages/JobAnnounceDetail.vue'
import JobAnnounceAttendance from '../pages/JobAnnounceAttendance.vue'
import BatchOrder from '../pages/BatchOrder.vue'
import RatePlanList from '../pages/RatePlanList.vue'
import CustomFieldList from '../pages/CustomFieldList.vue'
import RatePlanForm from '../pages/RatePlanForm.vue'
import LookupList from '../pages/LookupList.vue'
import NewOrder from '../pages/NewOrder.vue'
import OrderCalculator from '../pages/OrderCalculator.vue'
import DiaryMatcher from '../pages/DiaryMatcher.vue'
import NewOrderFromDiary from '../pages/NewOrderFromDiary.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/SignIn', component: SignIn, name: 'Login', meta: {title: 'Login'} },
    { path: '/', component: Home, name: 'Home', meta: {title: 'Home Dashboard'} },
    { path: '/Orders', component: OrderList, name: 'Orders', meta: {title: 'Orders'} },
    { path: '/OrderCalculator', component: OrderCalculator, name: 'OrderCalculator', meta: {title: 'OrderCalculator'} },
    { path: '/OrderDashboard', component: OrderDashboard, name: 'OrderDashboard', meta: {title: 'Orders Dashboard'} },
    { path: '/OrderMatcher/:customerID/:date/:filter', component: NewOrderMatcher, name: 'NewOrderMatcher', meta: {title: 'Orders Matcher'} },
    { path: '/DiaryMatcher/:customerID/:date/:filter', component: DiaryMatcher, name: 'DiaryMatcher', meta: {title: 'Diary Matcher'} },
    { path: '/NewOrderFromDiary/:pk/:sk', component: NewOrderFromDiary, name: 'NewOrderFromDiary', meta: {title: 'New order from diary: '} },
    { path: '/Orders/:orderID', component: OrderForm, name: 'OrderForm', meta: {title: 'Order: '} },
    { path: '/Orders/:orderID/Trip', component: TripForm, name: 'OrderTripForm', meta: {title: 'Order: '} },
    { path: '/Orders/:orderID/TripSearch', component: TripSearch, name: 'TripSearch', meta: {title: 'Order: '} },
    { path: '/OrderStatus/:status/:startDate', component: OrderListByCustomerStatus, name: 'OrderByStatus', meta: {title: ''} },
    { path: '/FavoriteOrder', component: FavoriteOrder, name: 'FavoriteOrder', meta: {title: 'Favorite Order'} },
    { path: '/FavoriteOrder/:CustomerID', component: FavoriteOrder, name: 'FavoriteOrderCustomer', meta: {title: 'Favorite Order'} }, 
    { path: '/ConfirmOrder', component: ConfirmOrderList, name: 'ConfirmOrderList', meta: {title: 'Comfirmed Order'} },
    { path: '/Customers/:customer/:status', name: 'OrderByCustomerByStatus', component: OrderListByCustomerStatus, meta: {title: ''} },
    { path: '/Customers', name: 'CustomerList', component: CustomerList, meta: {title: 'Customers'}},
    { path: '/NewCustomer', name: 'NewCustomer', component: CustomerForm, meta: {title: 'New Customer'}},
    { path: '/Customers/:customerID', name: 'CustomerDetail', component: CustomerForm, meta: {title: 'Customer Detail'}},
    { path: '/Drivers', name: 'DriverList', component: DriverList, meta: {title: 'Drivers'}},
    { path: '/IncompleteDrivers', name: 'IncompleteDriverList', component: IncompleteDriverList, meta: {title: "Incomplete Driver Report"}},
    { path: '/DriverLocation', name: 'DriverLocation', component: DriverLocation, meta: {title: 'Ezie360'}},
    { path: '/NewDriver', name: 'NewDriver', component: DriverForm, meta: {title: 'New Driver'}},
    { path: '/Drivers/:driverID', name: 'DriverDetail', component: DriverForm, meta: {title: 'Driver Detail'}},
    { path: '/NewOrder', component: OrderForm, name: 'NewOrder', meta: {title: 'New Order'}},
    { path: '/NewOrderV2', component: NewOrder, name: 'NewOrderV2', meta: {title: 'New Order'}},
    { path: '/NewOrderV2/:batchID', component: NewOrder, name: 'NewOrderV2Detail', meta: {title: 'Edit Order'}},
    { path: '/NewOrderV2/:customerID/:date', component: NewOrder, name: 'NewOrderV2DetailDate', meta: {title: 'Edit Order'}},
    { path: '/NewOrderFromFav/:customer/:name', name: 'NewOrderFromFav', component: OrderForm, meta: {title: 'New Order'}},
    { path: '/NewTrip', component: TripForm, name: 'NewTrip', meta: {title: 'New Trip'} },
    { path: '/Payments', component: PaymentList, name: 'Payments', meta: {title: 'Payment'} },
    { path: '/NewPayment', component: PaymentForm, name: 'NewPayment', meta: {title: 'New Payment'} },
    { path: '/Payments/:id', component: PaymentForm, name: 'PaymentDetail', meta: {title: 'Payment Detail'} },
    { path: '/PaymentLogs', component: PDNumberList, name: 'PaymentLogs', meta: {title: 'Payment Logs'} },
    { path: '/NoInvoices', component: NoInvoiceList, name: 'NoInvoices', meta: {title: 'No Invoice'} },
    { path: '/NoInvoices/:month/:billDate/:customerID', component: NoInvoiceDetail, name: 'NoInvoiceDetail', meta: {title: ''} },
    { path: '/InvoiceTracks/:invoiceID', component: InvoiceDetail, name: 'InvoiceDetail', meta: {title: 'Invoice Detail'} },
    { path: '/InvoiceTracks', component: InvoiceTrackList, name: 'InvoiceTracks', meta: {title: 'Track Invoice'} },
    { path: '/InvoiceLogs', component: IVNumberList, name: 'InvoiceLogs', meta: {title: 'Invoice Logs'} },
    { path: '/Options', component: OptionList, name: 'Options', meta: {title: 'Options'} },
    { path: '/DiaryList', name: 'DiaryList', component: DiaryList, meta: {title: 'Diary List'}},
    { path: '/DiaryList/:date', name: 'DiaryListDate', component: DiaryList, meta: {title: 'Diary List'}},
    { path: '/DiaryDetail/:pk/:sk', name: 'DiaryDetail', component: DiaryDetail, meta: {title: 'Diary Detail'}},    
    { path: '/PhotoLibrary', name: 'PhotoLibrary', component: PhotoLibrary, meta: {title: 'Photo Library'}},
    { path: '/CostDashboard', name: 'CostDashboard', component: CostDashboard, meta: {title: 'Cost Dashboard'}},
    { path: '/PaymentDashboard', name: 'PaymentDashboard', component: PaymentDashboard, meta: {title: 'Payment Dashboard'}},
    { path: '/TripDashboard', name: 'TripDashboard', component: TripDashboard, meta: {title: 'Trip Dashboard'}},
    { path: '/SaleDashboard', name: 'SaleDashboard', component: SaleDashboard, meta: {title: 'Sale Dashboard'}},
    { path: '/MarginDashboard', name: 'MarginDashboard', component: MarginDashboard, meta: {title: 'Margin Dashboard'}},
    { path: '/BatchUpdate', name: 'BatchUpdate', component: BatchUpdate, meta: {title: 'Batch Update'}},
    { path: '/PaymentRequest', name: 'PaymentRequest', component: PaymentRequestList, meta: {title: 'Payment Request'}},
    { path: '/PaymentRequestDashboard', name: 'PaymentRequestDashboard', component: PaymentRequestDashboard, meta: {title: 'Payment Request Dashboard'}},
    { path: '/Privacy', name: 'Privacy', component: Privacy},
    { path: '*', component: NotFound },
    { path: '/LineDiaryFormTemplate', name: 'LineDiaryFormTemplate', component: LineDiaryFormTemplate, meta: {title: 'Line Diary Form Template'}},
    { path: '/LineCheckIOFormTemplate', name: 'LineCheckIOFormTemplate', component: LineCheckIOFormTemplate, meta: {title: 'Line Check In - Out Form Template'}},
    { path: '/DriverCheckInReport', name: 'DriverCheckInReport', component: DriverCheckInReport, meta: {title: 'Driver CheckIn Report'}},
    { path: '/DriverAvailableReport', name: 'DriverAvailableReport', component: DriverAvailableReport, meta: {title: 'Driver Available Report'}},
    { path: '/JobAnnounce', name: 'JobAnnounce', component: JobAnnounce, meta: {title: 'Job Announce'}},
    { path: '/JobAnnounceForm', name: 'JobAnnounceForm', component: JobAnnounceForm, meta: {title: 'Job Announce Form'}},
    { path: '/JobAnnounce/:announceID', name: 'JobAnnounceDetail', component: JobAnnounceDetail, meta: {title: 'Job Announce Detail'}},
    { path: '/JobAnnounceAttendance', name: 'JobAnnounceAttendance', component: JobAnnounceAttendance, meta: {title: 'Job Announce Attendance'}},
    { path: '/BatchOrder', name: 'BatchOrder', component: BatchOrder, meta: {title: 'Batch Order'}},
    { path: '/CustomFieldList', name: 'CustomFieldList', component: CustomFieldList, meta: {title: 'Custom Field'}},
    { path: '/LookupList', name: 'LookupList', component: LookupList, meta: {title: 'Lookup List'}},
    { path: '/RatePlanList', name: 'RatePlanList', component: RatePlanList, meta: {title: 'Rate Plan List'}},
    { path: '/RatePlanForm', name: 'RatePlanForm', component: RatePlanForm, meta: {title: 'Rate Plan Form'}}
  ]
})

export default router