<template>
  <a :href="url" target="_blank"><img :src="url" class="md-image" :style="{'heigth': heigth, 'width': width}" /></a>
</template>

<script>
export default {
  name: 'MyS3Image',
  props: {
    imagePath: String,
    width: String,
    heigth: String
  },
  data () {
    return {
      error: '',
      logger: {},
      url: null
    }
  },
  watch: {
    imagePath: function (newVal) {
      this.$Amplify.Storage
        .get(newVal)
        .then((url) => {
          this.url = url
        }).catch(console.error)
    }
  },
  created () {
    this.$Amplify.Storage
      .get(this.imagePath)
      .then((url) => {
        this.url = url
      }).catch(console.error)
  }
}
</script>