<template>
  <div>
    <md-button class="md-fab md-plain" to="/NewOrder" :style="{
      'z-index': '100',
      position: 'fixed',
      left:($vssWidth-100) + 'px', 
      top: ($vssHeight-130) + 'px'}">
      <md-icon>add</md-icon>
    </md-button>
    <md-table>
      <md-table-row>
        <md-table-head>Customer ID</md-table-head>
        <md-table-head>Customer Name</md-table-head>
        <md-table-head>Order Status</md-table-head>
      </md-table-row>
      <md-table-row v-for="customer in customers" :key="customer.CustomerID"
        v-show="customerOrderStatus[customer.CustomerID] && Object.values(customerOrderStatus[customer.CustomerID]).reduce((accumulator, currentValue) => accumulator + currentValue) > 0">
        <md-table-cell>
          <md-avatar><my-s3-image :imagePath="customer.CustomerLogo" class="md-avatar md-large"></my-s3-image></md-avatar>
        </md-table-cell>
        <md-table-cell>{{customer.CustomerName}}</md-table-cell>
        <md-table-cell>
          <router-link v-if="customerOrderStatus[customer.CustomerID] && customerOrderStatus[customer.CustomerID]['1:Preparing'] > 0" :to="`/Customers/${customer.CustomerID}/1:Preparing`">
              Preparing ({{customerOrderStatus[customer.CustomerID]['1:Preparing']}})
          </router-link>
          <router-link v-if="customerOrderStatus[customer.CustomerID] && customerOrderStatus[customer.CustomerID]['2:Waiting'] > 0" :to="`/Customers/${customer.CustomerID}/2:Waiting`">
              Waiting ({{customerOrderStatus[customer.CustomerID]['2:Waiting']}})
          </router-link>
          <router-link v-if="customerOrderStatus[customer.CustomerID] && customerOrderStatus[customer.CustomerID]['3:Active'] > 0" :to="`/Customers/${customer.CustomerID}/3:Active`">
              Active ({{customerOrderStatus[customer.CustomerID]['3:Active']}})
          </router-link>
          <router-link v-if="customerOrderStatus[customer.CustomerID] && customerOrderStatus[customer.CustomerID]['4:Delivered'] > 0" :to="`/Customers/${customer.CustomerID}/4:Delivered`">
              Delivered ({{customerOrderStatus[customer.CustomerID]['4:Delivered']}})
          </router-link>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import MyS3Image from '../components/MyS3Image'

export default {
  name: 'OrderList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image},
  data () {
    return {
      customerItems: []
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    }
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    customerOrderStatus: function () {
      let out = {}
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 12) === 'ORDERSTATUS#') {
          if (out[o.PK.slice(9)]) {
            if (o.SK.slice(12) !== '5:Confirmed') {
              out[o.PK.slice(9)][o.SK.slice(12)] = o.StatusCount
            }
          } else {
            out[o.PK.slice(9)] = {
              '1:Preparing': 0,
              '2:Waiting': 0,
              '3:Active': 0,
              '4:Delivered': 0,
              '5:Confirmed': 0,
            }
            if (o.SK.slice(12) !== '5:Confirmed') {
              out[o.PK.slice(9)][o.SK.slice(12)] = o.StatusCount
            }
          }
        }
      }
      return out
    }
  },
  created () {
    this.fetchCustomer()
  }
}
</script>
