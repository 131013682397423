import Amplify from 'aws-amplify'
import store from './store'
// let baseURL = 'https://app-begistics.eziecorp.com'
let baseURL = 'https://kfqo5wztbb.execute-api.ap-southeast-1.amazonaws.com/ezietruck'
// let baseURL = 'http://localhost:8000'
export default {
  post (endpoint, body = {}, token = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = token
      Amplify.Auth.currentAuthenticatedUser().then((user) => store.commit('setUser', user)).catch(console.log)
    }
    return fetch(baseURL + endpoint, {
      method: 'POST',
      headers, body: JSON.stringify(body)
    })
  },
  get (endpoint, token = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = token
      Amplify.Auth.currentAuthenticatedUser().then((user) => store.commit('setUser', user)).catch(console.log)
    }
    return fetch(baseURL + endpoint, {
      method: 'GET',
      headers
    })
  },
  put (endpoint, body = {}, token = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = token
    }
    return fetch(baseURL + endpoint, {
      method: 'PUT',
      headers, body: JSON.stringify(body)
    })
  },
  upload (endpoint, body = {}, token = null) {
    let formData = new window.FormData()
    for (let key in body) {
      formData.append(key, body[key])
    }
    let headers = {}
    if (token) {
      headers['Authorization'] = token
    }
    return fetch(baseURL + endpoint, {
      method: 'POST',
      headers, body: formData
    })
  },
  patch (endpoint, body = {}, token = null) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = token
    }
    return fetch(baseURL + endpoint, {
      method: 'PATCH',
      headers, body: JSON.stringify(body)
    })
  },
  delete (endpoint, token = null, body = {}) {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = token
    }
    return fetch(baseURL + endpoint, {
      method: 'DELETE',
      headers, body: JSON.stringify(body)
    })
  },
  download (endpoint, token = null) {
    let headers = {}
    if (token) {
      headers['Authorization'] = token
    }
    return fetch(baseURL + endpoint, {
      method: 'GET',
      headers
    })
  },
  tracking (endpoint, licenseId = null, body = {}) {
    let headers = {
      'Content-Type': 'application/json'
    }
    if (licenseId) {
      headers['X-DRIVERCODE'] = licenseId
    }
    return fetch(baseURL + endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    })
  }
}
