<script>
import { POINT_MARKER_ICON_CONFIG } from "@/constants/mapSettings";
import moment from 'moment'

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    marker: {
      type: Object,
      required: true
    }
  },
  methods: {
    datetimeFormater: function (str) {
      let m = moment(str)
      m.local() 
      return m.format('DD MMM YYYY HH:mm:ss')
    }
  },
  mounted() {
    const { Marker, InfoWindow } = this.google.maps;

    let promises = []
    for (let p of this.marker.Diary.Photo) {
      promises.push(this.$Amplify.Storage.get(p))
    }
    for (let p of this.marker.Diary.PhotoAlbum) {
      promises.push(this.$Amplify.Storage.get(p))
    }
    let m = new Marker({
      position: this.marker.LatLng,
      map: this.map,
      label: this.label,
      icon: Object.assign({}, POINT_MARKER_ICON_CONFIG, {
        strokeColor: this.marker.Diary.StopFor === 'ส่งของ' ? '#c30b82' : '#030ba2'
      })
    });
    Promise.all(promises).then((results) => {
      let imgs = {}
      for (let r of results) {
        const parsedUrl = new URL(r);
        imgs[parsedUrl.pathname.slice(8)] = r
      }
      let contentString = '<div id="content">'+
        '<table>'+
        '<tr><th>Created At</th><td>'+ this.datetimeFormater(this.marker.Diary.CreatedAt)+'</td></tr>'+
        '<tr><th>Driver</th><td>'+ this.marker.Diary.Driver.DriverName +' ('+ this.marker.Diary.Driver.DriverPhoneNumber +') </td></tr>'+
        '<tr><th>Vehicle</th><td>'+ ((this.marker.Diary.Vehicle || {}).VehicleLicenseNumber || "") +' '+
          ((this.marker.Diary.Vehicle || {}).VehicleLicenseProvince || "") + ' ' +
          ((this.marker.Diary.Vehicle || {}).VehicleTruckType || "") + ' ' +
          ((this.marker.Diary.Vehicle || {}).VehicleContainerType || "") + '</td></tr>'+
        '<tr><th>Stop for</th><td>'+ this.marker.Diary.StopFor +'</td></tr>'+
        '<tr><th>Location</th><td>'+ this.marker.Diary.Area +'</td></tr>'+
        '<tr><th>Snapshot</th><td>';
      for (let p of this.marker.Diary.Photo) {
        contentString += '<p><a href="' + imgs[p] +'" target="_blank"><img src="' + imgs[p] +'" width="120" height="120"  /></a></p>'
      }
      contentString += '</td></tr><tr><th>Album</th><td>';
      for (let p of this.marker.Diary.PhotoAlbum) {
        contentString += '<p><a href="' + imgs[p] +'" target="_blank"><img src="' + imgs[p] +'" width="120" height="120"  /></a></p>'
      }
      contentString += '<tr><th>Note</th><td>'+ (this.marker.Diary.Note || '') +'</td></tr>'+
        '<tr><th>Pick Up</th><td>'+ this.marker.Diary.PickUpItemNo +'</td></tr>'+
        '<tr><th>Drop Off</th><td>'+ this.marker.Diary.DropOffItemNo +'</td></tr>'+
        '<tr><th>Distance (km)</th><td>'+ this.marker.Diary.Distance +'</td></tr>'+
        '</table>'+
        '</div>';
      let infowindow = new InfoWindow({
        content: contentString
      });
      m.addListener('click', function() {
        infowindow.open(this.map, m);
      });
    })
  },

  render() {
    return null
  }
};
</script>
