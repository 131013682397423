<template>
  <div>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <h3 class="md-title">Payment Request Dashboard</h3>
          <div style="margin-left: 15px">
            <md-chip class="md-primary" md-deletable v-show="selectedBar" @md-delete="selectedBar=null">{{selectedBar}}</md-chip>
            <md-chip class="md-primary" md-deletable v-show="selectedOwner" @md-delete="selectedOwner=null">{{selectedOwner}}</md-chip>
            <md-chip class="md-primary" md-deletable v-show="selectedCus" @md-delete="selectedCus=null">{{selectedCus}}</md-chip>
          </div>
        </div>
        <div class="md-toolbar-section-end">
          <md-datepicker v-model="startDate" @md-closed="handleYearInput"><label>Start date</label></md-datepicker>
          <md-datepicker v-model="endDate" @md-closed="handleYearInput"><label>End date</label></md-datepicker>
          <md-field>
            <label for="movie">Group By</label>
            <md-select v-model="groupBy" name="groupBy" id="groupBy">
              <md-option value="MONTHLY">Monthly</md-option>
              <md-option value="WEEKLY">Weekly</md-option>
              <md-option value="DAILY">Daily</md-option>
            </md-select>
          </md-field>
          <md-field>
            <label for="movies">Costs</label>
            <md-select v-model="includeCost" multiple>
              <md-option value="DeliveryCost">DeliveryCost</md-option>
              <md-option value="FuelCost">FuelCost</md-option>
              <md-option value="TollParkCost">TollParkCost</md-option>
              <md-option value="IncentiveCost">IncentiveCost</md-option>
              <md-option value="PickerCost">PickerCost</md-option>
            </md-select>
          </md-field>
          <md-switch v-model="showTrip">Trip</md-switch>
        </div>
      </div>
    </md-toolbar>
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <horizontal-bar-chart :chart-data="chartData" :options="mainChartOptions" :styles="{position: 'relative', height: $vssHeight-220 + 'px'}"></horizontal-bar-chart>
      </div>
      <div class="md-layout-item md-size-50">
        <bar-chart :chart-data="byUserData" :options="byUserChartOptions" :styles="{position: 'relative', height: $vssHeight-220 + 'px'}"></bar-chart>
      </div>
      <div class="md-layout-item md-size-100">
        <bar-chart :chart-data="byCustomerData" :options="byCustomerChartOptions" :styles="{position: 'relative', height: $vssHeight-20 + 'px'}"></bar-chart>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalBarChart from '../components/HorizontalBarChart.js'
import BarChart from '../components/BarChart.js'
import VueScreenSize from 'vue-screen-size'
import moment from 'moment'
import { API } from 'aws-amplify'

export default {
  name: 'PaymentRequestDashboard',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {
    'horizontal-bar-chart': HorizontalBarChart,
    'bar-chart': BarChart
  },
  data () {
    let vm = this
    return {
      currentYear: (new Date()).getFullYear(),
      groupBy: 'MONTHLY',
      jobType: null,
      startDate: moment().startOf('year').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      summaryData: [],
      summaryAllData: {},
      summaryCustomerData: {},
      summaryUserData: {},
      includeCost: ['DeliveryCost', 'FuelCost', 'TollParkCost', 'IncentiveCost', 'PickerCost'],
      DailyPickerCost: 0,
      selectedBar: null,
      loading: false,
      selectedOwner: null,
      selectedCus: null,
      showTrip: false,
      mainChartOptions: {
        maintainAspectRatio: false,
        title: {
            display: true,
            text: 'Request summary'
        },
        legend: {
          display: false
        },
        onClick: this.clicked,
        scales: {
            xAxes: [{
                ticks: {
                    callback: this.$currencyFormater,
                    beginAtZero: true
                }
            }]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return vm.$currencyFormater(parseFloat(tooltipItem.value));
            }
          }
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: 4,
                formatter: function(value, context) {
                  return vm.$currencyFormater(parseFloat(context.chart.data.datasets[0].data[context.dataIndex]));
                }
            }
        }
      },
      byUserChartOptions: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'By Owner chart'
        },
        legend: {
          display: false
        },
        onClick: this.ownerClicked,
        scales: {
            yAxes: [{
                ticks: {
                    callback: this.$currencyFormater,
                    beginAtZero: true
                }
            }]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return vm.$currencyFormater(parseFloat(tooltipItem.value));
            }
          }
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: 4,
                formatter: function(value, context) {
                  return vm.$currencyFormater(parseFloat(context.chart.data.datasets[0].data[context.dataIndex]));
                }
            }
        }
      },
      byCustomerChartOptions: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'By Customer chart'
        },
        legend: {
          display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    callback: this.$currencyFormater,
                    beginAtZero: true,
                    maxTicksLimit: 6
                }
            }]
        },
        onClick: this.cusClicked,
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return vm.$currencyFormater(parseFloat(tooltipItem.value));
            }
          }
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: 4,
                formatter: function(value, context) {
                  return vm.$currencyFormater(parseFloat(context.chart.data.datasets[0].data[context.dataIndex]));
                }
            }
        }
      }
    }
  },
  methods: {
    fetchData: function () {
      let vm = this
      this.loading = true
      let sDateStr = moment(this.startDate).format('YYYY-MM-DD')
      let eDateStr = moment(this.endDate).format('YYYY-MM-DD')
      let sDate = moment(this.startDate)
      let eDate = moment(this.endDate)
      eDate.add(3, 'months')
      let promises = []
      while (sDate.format('YYYY-MM') <= eDate.format('YYYY-MM')) {
        promises.push(API.get('ezietruckapi', `/api/summary/payment_requests/${sDate.format('YYYY-MM')}-1/${sDateStr}/${eDateStr}`))
        promises.push(API.get('ezietruckapi', `/api/summary/payment_requests/${sDate.format('YYYY-MM')}-2/${sDateStr}/${eDateStr}`))
        promises.push(API.get('ezietruckapi', `/api/summary/payment_requests/${sDate.format('YYYY-MM')}-3/${sDateStr}/${eDateStr}`))
        promises.push(API.get('ezietruckapi', `/api/summary/payment_requests/${sDate.format('YYYY-MM')}-4/${sDateStr}/${eDateStr}`))
        sDate.add(1, 'months')
      }
      Promise.all(promises).then((results) => {
        vm.loading = false
        vm.summaryData = [...results.flat()]
      })
    },
    fetchConfig: function () {
      let vm = this
      API.get('ezietruckapi', '/api/options/Config').then((json) => {
        let idx = json.findIndex((c) => c.Name.startsWith('DailyPickerCost'))
        if (idx > -1) {
          vm.DailyPickerCost = parseFloat(json[idx].Name.split(',')[1])
        }
      })
    },
    clicked: function (event, array) {
      for (let bar of array) {
        this.selectedBar = this.chartData.labels[bar._index]
      }
      this.selectedOwner = null
      this.selectedCus = null
    },
    ownerClicked: function (event, array) {
      for (let bar of array) {
        this.selectedOwner = this.byUserData.labels[bar._index]
      }
      this.selectedBar = null
      this.selectedCus = null
    },
    cusClicked: function (event, array) {
      for (let bar of array) {
        this.selectedCus = this.byCustomerData.labels[bar._index]
      }
      this.selectedBar = null
      this.selectedOwner = null
    },
    randomColorGenerator: function () { 
      return '#' + (Math.random().toString(16) + '0000000').slice(2, 8); 
    },
    handleYearInput: function () {
      this.$nextTick(function () {
        this.fetchData()
      })
    }
  },
  computed: {
    chartData: function () {
      let chart = {
        labels: [],
        datasets: [{label: 'ALL', data: [], backgroundColor: "rgba(255,150,1, 0.8)"}]
      }
      let dateFormat = 'YYYYMM'
      if (this.groupBy === 'WEEKLY') {
        dateFormat = 'YYYYWW'
      } else if (this.groupBy === 'DAILY') {
        dateFormat = 'YYYY-MM-DD'
      }
      let data = {}
      for (let item of (this.summaryData ||  []).filter((s) => (!this.selectedCus || this.selectedCus === s.CustomerID) && (!this.selectedOwner || this.selectedOwner === s.OrderOwner))) {
        if (!data[moment(item.OrderDate).format(dateFormat)]) {
          data[moment(item.OrderDate).format(dateFormat)] = {Trips: 0, Total: 0, PickerDate: {}}
        }
        data[moment(item.OrderDate).format(dateFormat)].Trips += 1
        data[moment(item.OrderDate).format(dateFormat)].Total += (this.includeCost.includes('DeliveryCost') ? item.DeliveryCost : 0) + (this.includeCost.includes('FuelCost') ? item.FuelCost : 0) + (this.includeCost.includes('TollParkCost') ? item.TollParkCost : 0) + (this.includeCost.includes('IncentiveCost') ? item.IncentiveCost : 0)
        if (this.includeCost.includes('PickerCost') && item.PickerPerTrip > 0) {
          if (!data[moment(item.OrderDate).format(dateFormat)].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`]) {
            data[moment(item.OrderDate).format(dateFormat)].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`] = item.PickerPerTrip
            data[moment(item.OrderDate).format(dateFormat)].Total += item.PickerPerTrip * this.DailyPickerCost
          }
          if (item.PickerPerTrip > data[moment(item.OrderDate).format(dateFormat)].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`]) {
            data[moment(item.OrderDate).format(dateFormat)].Total += (item.PickerPerTrip - data[moment(item.OrderDate).format(dateFormat)].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`]) * this.DailyPickerCost
            data[moment(item.OrderDate).format(dateFormat)].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`] = item.PickerPerTrip
          }
        }
      }
      console.log(data)
      chart.labels = [...Object.keys(data)].sort((a,b) => a.localeCompare(b))
      for (let label of chart.labels) {
        chart.datasets[0].data.push(this.showTrip ? data[label].Trips : data[label].Total)
      }
      return chart
    },
    byCustomerData: function () {
      let chart = {
        labels: [],
        datasets: [{label: 'By customer', data: [], backgroundColor: "rgba(255,150,1, 0.8)"}]
      }
      let dateFormat = 'YYYYMM'
      if (this.groupBy === 'WEEKLY') {
        dateFormat = 'YYYYWW'
      } else if (this.groupBy === 'DAILY') {
        dateFormat = 'YYYY-MM-DD'
      }
      let data = {}
      for (let item of (this.summaryData ||  []).filter((s) => (!this.selectedCus || this.selectedCus === s.CustomerID) && (!this.selectedOwner || this.selectedOwner === s.OrderOwner) && (!this.selectedBar || this.selectedBar === moment(s.OrderDate).format(dateFormat)))) {
        if (!data[item.CustomerID]) {
          data[item.CustomerID] = {Trips: 0, Total: 0, PickerDate: {}}
        }
        data[item.CustomerID].Trips += 1
        data[item.CustomerID].Total += (this.includeCost.includes('DeliveryCost') ? item.DeliveryCost : 0) + (this.includeCost.includes('FuelCost') ? item.FuelCost : 0) + (this.includeCost.includes('TollParkCost') ? item.TollParkCost : 0) + (this.includeCost.includes('IncentiveCost') ? item.IncentiveCost : 0)
        if (this.includeCost.includes('PickerCost') && item.PickerPerTrip > 0) {
          if (!data[item.CustomerID].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`]) {
            data[item.CustomerID].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`] = item.PickerPerTrip
            data[item.CustomerID].Total += item.PickerPerTrip * this.DailyPickerCost
          }
          if (item.PickerPerTrip > data[item.CustomerID].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`]) {
            data[item.CustomerID].Total += (item.PickerPerTrip - data[item.CustomerID].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`]) * this.DailyPickerCost
            data[item.CustomerID].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`] = item.PickerPerTrip
          }
        }
      }
      chart.labels = [...Object.keys(data)].sort((a,b) => a.localeCompare(b))
      for (let label of chart.labels) {
        chart.datasets[0].data.push(this.showTrip ? data[label].Trips : data[label].Total)
      }
      return chart
    },
    byUserData: function () {
      let chart = {
        labels: [],
        datasets: [{label: 'By onwer', data: [], backgroundColor: "rgba(255,150,1, 0.8)"}]
      }
      let dateFormat = 'YYYYMM'
      if (this.groupBy === 'WEEKLY') {
        dateFormat = 'YYYYWW'
      } else if (this.groupBy === 'DAILY') {
        dateFormat = 'YYYY-MM-DD'
      }
      let data = {}
      for (let item of (this.summaryData ||  []).filter((s) => (!this.selectedCus || this.selectedCus === s.CustomerID) && (!this.selectedOwner || this.selectedOwner === s.OrderOwner) && (!this.selectedBar || this.selectedBar === moment(s.OrderDate).format(dateFormat)))) {
        if (!data[item.OrderOwner]) {
          data[item.OrderOwner] = {Trips: 0, Total: 0, PickerDate: {}}
        }
        data[item.OrderOwner].Trips += 1
        data[item.OrderOwner].Total += (this.includeCost.includes('DeliveryCost') ? item.DeliveryCost : 0) + (this.includeCost.includes('FuelCost') ? item.FuelCost : 0) + (this.includeCost.includes('TollParkCost') ? item.TollParkCost : 0) + (this.includeCost.includes('IncentiveCost') ? item.IncentiveCost : 0)
        if (this.includeCost.includes('PickerCost') && item.PickerPerTrip > 0) {
          if (!data[item.OrderOwner].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`]) {
            data[item.OrderOwner].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`] = item.PickerPerTrip
            data[item.OrderOwner].Total += item.PickerPerTrip * this.DailyPickerCost
          }
          if (item.PickerPerTrip > data[item.OrderOwner].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`]) {
            data[item.OrderOwner].Total += (item.PickerPerTrip - data[item.OrderOwner].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`]) * this.DailyPickerCost
            data[item.OrderOwner].PickerDate[`${item.CreatedBy.DriverID}.${item.Driver.DriverID}.${item.OrderDate}`] = item.PickerPerTrip
          }
        }
      }
      chart.labels = [...Object.keys(data)].sort((a,b) => a.localeCompare(b))
      for (let label of chart.labels) {
        chart.datasets[0].data.push(this.showTrip ? data[label].Trips : data[label].Total)
      }
      return chart
    }
  },
  created () {
    this.fetchConfig()
    this.fetchData()
  }
}
</script>
