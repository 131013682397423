<script>
import moment from 'moment'

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    coordinates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      polylineObj: [],
      colors: ['#ff0000', '#ffff00', '#ff3399', '#33cc33', '#ff9900', '#3333ff', '#9900ff']
    }
  },
  watch: {
    coordinates: function (val) {
      const { Marker, SymbolPath } = this.google.maps;
      for (let p of this.polylineObj) {
        p.setMap(null)
      }
      for (let v of val) {
        this.polylineObj.push(new Marker({
          position: v.GPS,
          map: this.map,
          title: moment(v.dt).format("lll") + "\nSpeed: " + Math.round(v.speed * 3.6) + " km/h",
        icon: {
          path: SymbolPath.CIRCLE,
          fillColor: this.colors[v.dt.getDay()],
          fillOpacity: 1,
          scale: 5,
          strokeWeight: 0,
        }
        }))
      }
    }
  },
  // mounted() {
  //   const { Marker, SymbolPath } = this.google.maps;
  //   for (let v of this.coordinates) {
  //     this.polylineObj.push(new Marker({
  //       position: v.GPS,
  //       map: this.map,
  //       icon: {
  //         path: SymbolPath.CIRCLE,
  //         fillColor: this.colors[v.dt.getDay()],
  //         fillOpacity: 1,
  //         scale: 5,
  //         strokeWeight: 0,
  //       }
  //     }))
  //   }
  // },
  beforeDestroy () {
    for (let p of this.polylineObj) {
      p.setMap(null)
    }
  },
  render() {
    return null
  }
};
</script>
