<template>
  <div class="md-layout">
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <form @submit.prevent="handleSearchOrder"  class="md-toolbar-row md-layout" >
            <div class="md-layout-item  md-size-20">
              <md-datepicker v-model="form.StartDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
                <label>Start Date</label>
                <span class="md-error" v-if="errors['Date']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item  md-size-20">
              <md-datepicker v-model="form.EndDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
                <label>End Date</label>
                <span class="md-error" v-if="errors['Date']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item">
              <md-autocomplete @input="handleCustomerSelect" :value="form.Customer.CustomerName" :md-options="customers"
                :class="{'md-invalid': errors['instance.Customer']}" :disabled="!!$route.params.orderID">
                <label>Customer</label>
                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                  <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
                </template>

                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  No customer matching "{{ term }}" were found.
                </template>
                <span class="md-error" v-if="errors['instance.Customer']">Invalid field input</span>
              </md-autocomplete>
            </div>
            <div class="md-layout-item">
              <md-autocomplete v-model="form.JobType" :md-options="jobTypeAllOptions" :class="{'md-invalid': errors['instance.JobType']}">
                <label>Job Type</label>
                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  <a @click="handleSaveCustomerJobType(term)">Create a new</a> Job Type!
                </template>
                <span class="md-error" v-if="errors['instance.JobType']">Invalid field input</span>
              </md-autocomplete>
            </div>
            <div class="md-layout-item">
              <md-button type="submit" class="md-raised md-primary">
                <span>Search</span>
              </md-button>
            </div>
          </form>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-raised" @click="nextStep(step + 1)">
            <span>Next</span>
          </md-button>
        </div>
      </div>
    </md-toolbar>
    <div class="md-layout-item md-content">
      <md-table @md-selected="onSelect" v-model="sortedOrders" v-show="step === 1">
        <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select>
          <md-table-cell md-label="Order ID">{{item.OrderID}}</md-table-cell>
          <md-table-cell md-label="Customer Name">{{item.CustomerName}}</md-table-cell>
          <md-table-cell md-label="Job Type">{{item.JobType}}</md-table-cell>
          <md-table-cell md-label="Route">{{item.From.Description}} - {{item.To.Description}}</md-table-cell>
          <md-table-cell md-label="Start Date">{{dateTimeFormat(item.StartDateTime)}}</md-table-cell>
          <md-table-cell md-label="Remark">{{item.Remark}}</md-table-cell>
          <md-table-cell md-label="Driver">
            <span v-if="trip[item.PK]">{{trip[item.PK].DriverName}}</span>
          </md-table-cell>
          <md-table-cell md-label="Customer Paid / Driver Paid">
            {{$currencyFormater(item.CustomerPaidAmount)}} /
            <span v-if="trip[item.PK]">
              {{$currencyFormater(trip[item.PK].DailyPickerCost + trip[item.PK].DeliveryCost + trip[item.PK].FuelAllowance + trip[item.PK].OtherCost + trip[item.PK].SalaryPerTrip)}}
            </span>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div v-show="step === 2">
        <md-field>
          <label>Select Order</label>
          <md-textarea :value="orderValString" readonly></md-textarea>
        </md-field>
        <md-field>
          <label>Edit Order</label>
          <md-textarea v-model="orderString"></md-textarea>
        </md-field>
        <md-field>
          <label>Edit Driver</label>
          <md-textarea v-model="tripString"></md-textarea>
        </md-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueScreenSize from 'vue-screen-size'
import moment from 'moment'
import { API } from 'aws-amplify'

export default {
  name: 'BatchOrder',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data () {
    let m = moment()
    let s = moment().subtract(30, 'days')
    return {
      isLoading: false,
      errors: {},
      form: {
        StartDate: s.format('YYYY-MM-DD'),
        EndDate: m.format('YYYY-MM-DD'),
        Customer: {},
        JobType: null
      },
      customerItems: [],
      jobTypeOptions: [],
      jobTypeCustomerOptions: [],
      priceOptions: [],
      truckTypeOptions: [],
      containerTypeOptions: [],
      customerFavoriteLocations: [],
      userItems: [],
      orders: [],
      trip: {},
      selectedOrder: [],
      step: 1,
      orderValString: '',
      orderString: '',
      tripString: ''
    }
  },
  methods: {
    loadCustomerJobType (customerID) {
      API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`).then((json) => {
        this.jobTypeCustomerOptions = json.map((j) => j.Name)
      })
    },
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchOptions: function () {
      let self = this
      this.fetchCustomer()
      API.get('ezietruckapi', `/api/options/JobType`).then((json) => {
        self.jobTypeOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/TruckType`).then((json) => {
        self.truckTypeOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/ContainerType`).then((json) => {
        self.containerTypeOptions = json.map((j) => j.Name)
      })
    },
    fetchUser: function () {
      let self = this
      API.get('ezietruckapi', '/api/users').then((json) => {
        self.userItems = json['Users']
      })
    },
    handleCustomerSelect (value) {
      if (!this.loading) {
        if (typeof value === 'object') {
          this.form.Customer = value
          let self = this
          API.get('ezietruckapi', `/api/customers/${value.CustomerID}/locations`).then((json) => {
            self.customerFavoriteLocations = json
          })
          this.loadCustomerJobType(value.CustomerID)
        } else {
          if (!value) {
            this.form.Customer = {CustomerID: '', CustomerName: '', CustomerLogo: ''}
            this.form = Object.assign({}, this.form)
            this.customerFavoriteLocations = []
          }
        }
      }
    },
    loadCustomerOrders (customerID, status, lastEvaluatedKey) {
      let self = this
      let body = {'q': {'status': status, 'StartDate': this.form.StartDate, 'EndDate': this.form.EndDate}, 'opt': {'IncludeTrips': true}}
      if (this.form.JobType) {
        body['q']['JobType'] = this.form.JobType
      }
      if (lastEvaluatedKey) {
        body['opt']['LastEvaluatedKey'] = lastEvaluatedKey
      }
      API.post('ezietruckapi', `/api/customers/${customerID}/orders`, {body: body}).then((json) => {
        self.orders = [...self.orders, ...json['Items'].filter((i) => i.SK.startsWith('#METADATA#'))]
        json['Items'].filter((i) => i.SK.startsWith('TRIP#')).forEach((i) => self.trip[i.PK] = i)
        self.trip = Object.assign({}, self.trip)
        if (json.LastEvaluatedKey) {
          self.loadCustomerOrders(customerID, status, json.LastEvaluatedKey)
        }
      })
    },
    handleSearchOrder () {
      this.step = 1
      this.loadCustomerOrders(this.form.Customer.CustomerID, '1:Preparing', null)
      this.loadCustomerOrders(this.form.Customer.CustomerID, '2:Waiting', null)
      this.loadCustomerOrders(this.form.Customer.CustomerID, '3:Active', null)
      this.loadCustomerOrders(this.form.Customer.CustomerID, '4:Delivered', null)
      this.loadCustomerOrders(this.form.Customer.CustomerID, '5:Confirmed', null)
    },
    dateTimeFormat (s) {
      return moment(s).format('YYYY-MM-DD HH:MM')
    },
    onSelect (items) {
      this.selectedOrder = items
    },
    numberFormatter (n) {
      return new Intl.NumberFormat().format(n)
    },
    nextStep (n) {
      if (n === 2) {
        this.orderValString = ["CustomerID|JobType|From|To|TruckType|ContainerType", ...this.selectedOrder.map((o) => [
          o.CustomerID,
          o.JobType,
          o.From.Description,
          o.To.Description,
          o.TruckType,
          o.ContainerType
        ].join("|"))].join("\n")
        this.orderString = ["StartDateTime|PickerPerTruck|Remark", ...this.selectedOrder.map((o) => [
          o.StartDateTime,
          o.PickerPerTruck,
          o.Remark
        ].join("|"))].join("\n")
        let vm = this
        this.tripString = ["Driver", ...this.selectedOrder.map((o) => [
          vm.trip[o.PK].DriverID
        ].join("|"))].join("\n")
      } else if (n === 3) {
        
      }
      this.step = n
    }
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      return this.customerItems.filter((o) => o.SK.startsWith('#METADATA#'))
    },
    jobTypeAllOptions: function () {
      return [...this.jobTypeOptions, ...this.jobTypeCustomerOptions]
    },
    userOptions: function () {
      return this.userItems.map((u) => {
        let idx = u.Attributes.findIndex((a) => a.Name === 'email')
        if (idx > -1) {
          return u.Attributes[idx].Value
        }
      })
    },
    sortedOrders: function () {
      return this.orders.sort((a,b) => a.PK.localeCompare(b.PK))
    }
  },
  created () {
    this.fetchOptions()
    this.fetchUser()
  }
}
</script>