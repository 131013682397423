<template>
  <div class="md-layout md-gutter">
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <div class="md-layout-item">
      <GoogleMapLoader
        :mapConfig="mapConfig"
        apiKey="AIzaSyAGQeljdWK0A-z_4Hmj9eNrufJMh0mC8S4"
         :style="{'height': ($vssHeight - 150) + 'px', 'width': ($vssWidth*0.7) + 'px'}"
      >
      <template slot-scope="{ google, map }">
        <GoogleMapTruckMarker
          v-for="marker in markers"
          :key="marker.id"
          :marker="marker"
          :google="google"
          :map="map"
          :current-driver="currentDriver"
          :driver="drivers[marker.id]"
          @click="handleMarkerClick($event)"
        />
        <GoogleMapTruckPolyline :coordinates="historyCoordinates" :google="google" :map="map" />
        </template>
      </GoogleMapLoader>
    </div>
    <div class="md-layout-item">
      <div class="md-layout">
        <div class="md-layout-item md-size-70">
          <md-field>
            <label>Driver Name</label>
            <md-input v-model="driverFilter"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-15">
          <md-switch v-model="isShowOffline">
            <span v-show="!isShowOffline">Online</span>
            <span v-show="isShowOffline">All</span>
          </md-switch>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-70">
          <md-field>
            <label>Driver Group</label>
            <md-select v-model="selectedDriverGroup">
              <md-option v-for="(dg, k) in driverGroups" :key="k" :value="k">{{k}}</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-15" style="display: flex;">
          <md-button class="md-icon-button" @click="showDialog = true">
            <md-icon>campaign</md-icon>
          </md-button>
          <p>
          News
          </p>
        </div>
      </div>
      <div class="md-layout">
        <md-datepicker  v-model="selectedDate" md-immediately :md-disabled-dates="disabledDates">
          <label>Location history Date</label>
          <span class="md-error">Invalid field input</span>
        </md-datepicker>
      </div>
      <div :style="{'height': ($vssHeight - 280) + 'px', 'overflow': 'auto'}">
        <md-list class="md-triple-line">
          <md-list-item v-for="l in filterDrivers" :key="l.DriverID" :class="{'md-elevation-6': currentDriver.DriverID === l.DriverID}">
            <div class="md-list-item-text">
              <span><a @click="handleMarkerClick(l.DriverID)" href="#">{{l.DriverName}}</a> (<a :href="'tel:+66' + l.DriverPhoneNumber">{{l.DriverPhoneNumber}}</a>)</span>
              <span>{{(groupDrivers[l.DriverPhoneNumber] || []).join(',')}}</span>
              <span>{{dateTimeFormatter(l.LastGPSUpdatedAt)}}</span>
            </div>
            <div></div>
            <md-button class="md-icon-button md-list-action" style="align-self: flex-start" v-show="(messages[l.DriverID] || []).length > 0" @click="openMessageDialog(l.DriverID)">
              <md-icon>priority_high</md-icon>
            </md-button>
            <md-button class="md-icon-button md-list-action" style="align-self: flex-start"  @click="handleOpenSendMessage(l.DriverID)">
              <md-icon class="md-primary">chat_bubble</md-icon>
            </md-button>
            <md-button class="md-icon-button md-list-action" style="align-self: flex-start"  :to="'/DiaryList?DriverPhoneNumber=' + l.DriverPhoneNumber" target="_blank">
              <md-icon class="md-primary">book</md-icon>
            </md-button>
        </md-list-item>
      </md-list>
      </div>
    </div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Create new announcement</md-dialog-title>
      <md-content>
        <md-field>
          <label>Title</label>
          <md-input v-model="announcementForm.title"></md-input>
        </md-field>
        <md-field>
          <label>Body</label>
          <md-input v-model="announcementForm.body"></md-input>
        </md-field>
      </md-content>
        <md-dialog-actions>
          <md-button class="md-primary" @click="showDialog = false">Close</md-button>
          <md-button class="md-primary" @click="sendAnnouncement">Send</md-button>
        </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showMessageDialog">
      <md-dialog-title>Message</md-dialog-title>
      <md-card v-for="m in (messages[currentSendMessageDriver.DriverID] || [])" :key="m.SK">
        <md-card-header>
          <div class="md-title">{{m.title}}</div>
          <div class="md-subhead">{{dateTimeFormatter(m.CreatedAt)}}</div>
        </md-card-header>
        <md-card-content>
          {{m.body}}
          <md-divider></md-divider>
          <md-field>
            <label>Reply</label>
            <md-input v-model="m.replyMessage"></md-input>
          </md-field>
            <div class="md-layout">
            <div class="md-layout-item"></div>
            <div class="md-layout-item"></div>
            <div class="md-layout-item">
              <md-button @click="handleSendReply(m.PK, m.SK)">Send</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
        <md-dialog-actions>
          <md-button class="md-primary" @click="showMessageDialog = false">Close</md-button>
        </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showSendMessageDialog">
      <md-dialog-title>Send message to: {{currentSendMessageDriver.DriverName}}</md-dialog-title>
      <md-card>
        <md-card-content>
          <md-field>
            <md-input v-model="sendMessage"></md-input>
          </md-field>
        </md-card-content>
      </md-card>
      <md-dialog-actions>
        <md-button class="md-primary" @click="handleSendMessage(currentSendMessageDriver.DriverID)">Send</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackBarMsg}}</span>
    </md-snackbar>
  </div>
</template>

<script>
import moment from 'moment'
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import firebase from "firebase/app"
import GoogleMapLoader from "@/components/GoogleMapLoader";
import GoogleMapTruckMarker from "@/components/GoogleMapTruckMarker";
import GoogleMapTruckPolyline from "@/components/GoogleMapTruckPolyline";
import { mapSettings } from "@/constants/mapSettings";
import "firebase/firestore";

export default {
  name: 'DriverLocation',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {
    GoogleMapLoader, GoogleMapTruckMarker, GoogleMapTruckPolyline
  },
  data () {
    let now = new Date()
    return {
      drivers: {},
      location: {},
      locationHistoryItems: [],
      driverGroups: {ALL: []},
      LastEvaluatedKey: null,
      showDialog: false,
      showSendMessageDialog: false,
      showMessageDialog: false,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      interval: null,
      currentDriver: {},
      loading: false,
      truckLatLng: null,
      driverFilter: '',
      isShowOffline: false,
      announcementForm: {
        title: null,
        body: null
      },
      messages: {},
      sendMessage: '',
      currentSendMessageDriver: {},
      selectedDriverGroup: 'ALL',
      selectedDate: now
    }
  },
  methods: {
    fetchDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          for (let item of json['Items']) {
            if (item.SK && item.SK.startsWith('#METADATA#')) {
              self.drivers[item['DriverID']] = item
            }
          }
          self.drivers = Object.assign({}, self.drivers)
        }
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKey = json.LastEvaluatedKey
          self.fetchDriver(json.LastEvaluatedKey)
        } else {
          self.loading = false
          self.LastEvaluatedKey = null
        }
      })
    },
    fetchMessage: function (driverId, lastEvaluatedKey) {
      let self = this
      let b = {body: {}}
      if (lastEvaluatedKey) {
        b.body.LastEvaluatedKey = lastEvaluatedKey
      } else {
        this.messages = {}
        if (driverId === '0') {
          this.messages = {}
        } else {
          this.messages[driverId] = []
        }
      }
      API.post('ezietruckapi', '/api/drivers/' + driverId + '/fromMessages', b).then((json) => {
        if (json['Items']) {
          for (let i of json['Items']) {
            if (!lastEvaluatedKey || !this.messages[i.PK.split('#')[1]]) {
              this.messages[i.PK.split('#')[1]] = []
            }
            this.messages[i.PK.split('#')[1]].push(i)
          }
          this.messages = Object.assign({}, this.messages)
        }
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKey = json.LastEvaluatedKey
          self.fetchMessage(driverId, json.LastEvaluatedKey)
        } else {
          self.LastEvaluatedKey = null
        }
      })
    },
    fetchDriverGroup : function () {
      let self = this
      API.get('ezietruckapi', '/api/options/CustomerLoginCode').then((json) => {
        for (let j of json) {
          let n = j.Name.split(',')
          self.driverGroups[n[0]] = n.slice(1)
        }
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("lll")
      } else {
        return ''
      }
    },
    handleMarkerClick: function (driverID) {
      this.locationHistoryItems = []
      if (this.drivers[driverID].DriverID === this.currentDriver.DriverID) {
        this.currentDriver = {}
      } else {
        this.currentDriver = Object.assign({}, this.drivers[driverID])
        this.fetchLocationHistory(driverID)
      }
    },
    handleOpenSendMessage: function (driverID) {
      if (this.drivers[driverID]) {
        this.currentSendMessageDriver = Object.assign({}, this.drivers[driverID])
        this.showSendMessageDialog = true
      }
    },
    openMessageDialog: function (driverID) {
      if (this.messages[driverID]) {
        this.currentSendMessageDriver = Object.assign({}, this.drivers[driverID])
        this.showMessageDialog = true
      }
    },
    sendAnnouncement: function () {
      let self = this
      API.post('ezietruckapi', '/api/users/messages', {body: {
        data: {
          title: this.announcementForm.title,
          body: this.announcementForm.body
        },
        topic: "announcement"
      }}).then(() => {
        self.showSnackbar = true
        self.snackBarMsg = 'Send successfully'
        self.showDialog = false
        self.announcementForm = {
          title: null,
          body: null
        }
      }).catch(() => {
        self.showSnackbar = true
        self.snackBarMsg = 'Error on server'
        self.showDialog = false
      })
    },
    handleSendReply: function (pk, sk) {
      let self = this
      let idx = this.messages[pk.split('#')[1]].findIndex((m) => m.SK === sk)
      if (idx > -1) {
        API.post('ezietruckapi', `/api/drivers/${pk.split('#')[1]}/reply/${sk.split('#')[1]}`, {body: {
          data: {
            title: this.messages[pk.split('#')[1]][idx].title,
            body: this.messages[pk.split('#')[1]][idx].replyMessage
          },
          topic: "reply"
        }}).then(() => {
          self.showSnackbar = true
          self.snackBarMsg = 'Send successfully'
          self.showMessageDialog = false
          self.fetchMessage('0')
        }).catch(() => {
          self.showSnackbar = true
          self.snackBarMsg = 'Error on server'
          self.showMessageDialog = false
          self.fetchMessage('0')
        })
      } else {
        this.showMessageDialog = false
      }
    },
    handleSendMessage: function (driverID) {
      let self = this
      API.post('ezietruckapi', `/api/drivers/${driverID}/sendMessages`, {body: {
        data: {
          title: this.sendMessage,
          body: this.sendMessage
        },
        topic: "message"
      }}).then(() => {
        self.showSnackbar = true
        self.snackBarMsg = 'Send successfully'
        self.showSendMessageDialog = false
      }).catch(() => {
        self.showSnackbar = true
        self.snackBarMsg = 'Error on server'
        self.showSendMessageDialog = false
      })
    },
    fetchLocationHistory: function (driverID, lastEvaluatedKey) {
      let q = ''
      let vm = this
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.locationHistoryItems = []
      }
      API.get('ezietruckapi', '/api/drivers/' + driverID + '/locations' + q).then((json) => {
        vm.locationHistoryItems = [...vm.locationHistoryItems, ...json.Items]
        if (json.LastEvaluatedKey) {
          vm.fetchLocationHistory(driverID, json.LastEvaluatedKey)
        }
      })
    },
    disabledDates: function (date) {
      let now = (new Date()).setHours(0,0,0,0)
      let startDate = (+now - 7*24*60*60*1000)
      let endDate = (+now + 24*60*60*1000)
      return +date <= startDate || +date >= endDate
    }
  },
  computed: {
    ...mapGetters(['user']),
    filterDrivers: function () {
      let vm = this
      let items = []
      if (!self.loading) {
        for (let k in this.drivers) {
          if (vm.location[`GPS#${this.drivers[k].DriverID}`] &&
            vm.location[`GPS#${this.drivers[k].DriverID}`].UpdatedAt.seconds * 1000 > Date.parse(this.drivers[k].LastGPSUpdatedAt)) {
            vm.drivers[k].LastGPSUpdatedAt = (new Date(vm.location[`GPS#${this.drivers[k].DriverID}`].UpdatedAt.seconds * 1000)).toISOString()
            vm.drivers[k].LastLocation = {
              Address: vm.location[`GPS#${this.drivers[k].DriverID}`].Address,
              GPS: vm.location[`GPS#${this.drivers[k].DriverID}`].GPS
            }
          }
          if (
              (
                vm.driverFilter.length > 2 &&
                this.drivers[k].DriverName.includes(vm.driverFilter) && 
                (
                  vm.selectedDriverGroup === 'ALL' ||
                  vm.driverGroups[vm.selectedDriverGroup].includes(this.drivers[k].DriverPhoneNumber)
                )
              ) ||
              vm.messages[k] ||
              (
                vm.isShowOffline &&
                (
                  vm.selectedDriverGroup === 'ALL' ||
                  vm.driverGroups[vm.selectedDriverGroup].includes(this.drivers[k].DriverPhoneNumber)
                )
              ) || 
              (
               !vm.isShowOffline &&
                (
                  Date.now() - Date.parse(this.drivers[k].LastGPSUpdatedAt) < 15*60*1000 
                ) &&
                (
                  vm.selectedDriverGroup === 'ALL' ||
                  vm.driverGroups[vm.selectedDriverGroup].includes(this.drivers[k].DriverPhoneNumber)
                )
              )
            ) {
            items.push(Object.assign({}, this.drivers[k]))
          }
        }
      }
      return items.sort((a,b) => {
        if (vm.currentDriver.DriverID) {
          if (vm.currentDriver.DriverID === a.DriverID) {
            return -1
          } else if (vm.currentDriver.DriverID === b.DriverID) {
            return 1
          }
        }
        if ((vm.messages[a.DriverID] || []).length > 0) {
          return -1
        } else if ((vm.messages[b.DriverID] || []).length > 0) {
          return 1
        }
        return Date.parse(b.LastGPSUpdatedAt || '0') - Date.parse(a.LastGPSUpdatedAt || '0')
      })
    },
    mapConfig() {
      if (this.currentDriver.GPS) {
        return Object.assign({}, mapSettings, {
          center: {lat: this.currentDriver.GPS.Lat, lng: this.currentDriver.GPS.Lon},
          zoom: 15
        })
      } else {
        return {
          ...mapSettings,
          zoom: 15
        };
      }
    },
    markers () {
      return this.filterDrivers.filter((d) => !!d.LastLocation).map((d) => {
        return {
          id: d.DriverID,
          position: {lat: d.LastLocation.GPS.Lat, lng: d.LastLocation.GPS.Lon},
          lastUpdated: Date.parse(d.LastGPSUpdatedAt)
        }
      })
    },
    historyCoordinates () {
      if (this.currentDriver.SK) {
        let startDate = +((new Date(+this.selectedDate)).setHours(0,0,0,0))
        let endDate = +((new Date(+this.selectedDate)).setHours(23,59,59,999))
        return this.locationHistoryItems.filter((l) => Date.parse(l.UpdatedAt) > (Date.now() - 7*24*60*60*1000) && (startDate <= Date.parse(l.UpdatedAt) && Date.parse(l.UpdatedAt) <= endDate)).map((l) => {
          return {dt: new Date(Date.parse(l.UpdatedAt)), GPS: { lat: l.GPS.Lat, lng: l.GPS.Lon }, speed: l.GPS.Speed || 0} 
        })
      } else {
        return []
      }
    },
    groupDrivers () {
      let out = {}
      for (let g in this.driverGroups) {
        for (let d of this.driverGroups[g]) {
          if (!out[d]) {
            out[d] = []
          }
          out[d].push(g)
        }
      }
      return out
    }
  },
  created () {
    this.loading = true
    this.fetchDriver()
    this.fetchMessage('0')
    this.fetchDriverGroup()
    const db = firebase.firestore();
    db.collection("locations").onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let now = new Date()
        let newLocationData = change.doc.data()
        if (change.type === "modified") {
          let newLocationData = change.doc.data()
          this.location[newLocationData.SK] = newLocationData
          // let idx = this.driverItems.findIndex((d) => d.DriverID === newLocationData.SK.split('#')[1])
          // if (idx > -1) {
          //   this.driverItems = [
          //     ...this.driverItems.slice(0, idx),
          //     Object.assign({}, this.driverItems[idx], {
          //       LastLocation: {Address: newLocationData.Address, GPS: newLocationData.GPS},
          //       LastGPSUpdatedAt: (new Date(newLocationData.UpdatedAt.seconds * 1000)).toISOString()
          //     }),
          //     ...this.driverItems.slice(idx + 1)
          //   ]
          // }
        } else if (change.type === "added") {
          if (+now - (newLocationData.UpdatedAt.seconds * 1000) <= 5*60*1000) {
            this.location[newLocationData.SK] = newLocationData
          }
        }
      });
    });
    db.collection("messages").onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          let messageData = change.doc.data()
          if (messageData.SK.startsWith('FROM')) {
            this.fetchMessage(messageData.PK.split('#')[1])
          }
        }
      });
    });
  }
}
</script>
