<template>
  <div>
    <md-tabs :md-active-tab="Object.keys(totalDate)[0] || 0">
      <md-tab v-for="CustomerID in customerTabs" :key="CustomerID" :md-label="CustomerID" :id="CustomerID" @click="handleTabClick(CustomerID)">
      </md-tab>
    </md-tabs>
    <md-table md-fixed-header v-model="totalDate">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Date">
          {{ item.date }}
        </md-table-cell>
        <md-table-cell md-label="Total Order">
          <router-link :to="'/NewOrderV2/'+item.customerID+'/'+item.date">{{ item.totalOrder }}</router-link>
        </md-table-cell>
        <md-table-cell md-label="Matched Order">
          <router-link :to="'/OrderMatcher/'+item.customerID+'/'+item.date+'/matched'">
            {{ item.matched }}
          </router-link>
        </md-table-cell>
        <md-table-cell md-label="Unmatched Order">
          <router-link :to="'/OrderMatcher/'+item.customerID+'/'+item.date+'/unmatch'">
            {{ item.unmatch }}
          </router-link>
        </md-table-cell>
        <md-table-cell md-label="Total Diary">
          <router-link :to="'/DiaryMatcher/'+item.customerID+'/'+item.date+'/unmatched'">{{ item.unmatchedDiary }}</router-link>
        </md-table-cell>
        <md-table-cell md-label="Diary with OrderID">
          <router-link :to="'/DiaryMatcher/'+item.customerID+'/'+item.date+'/orderID'">{{ item.orderDiary }}</router-link>
        </md-table-cell>
        <md-table-cell md-label="Diary without OrderID">
          <router-link :to="'/DiaryMatcher/'+item.customerID+'/'+item.date+'/withOutOrderID'">{{ item.withOutOrderDiary }}</router-link>
        </md-table-cell>
        <md-table-cell md-label="Deleted Diary">
          <router-link :to="'/DiaryMatcher/'+item.customerID+'/'+item.date+'/deleted'">{{ item.deletedDiary }}</router-link>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'

export default {
  name: 'OrderDashboard',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data () {
    return {
      diaryDates: [],
      customerItems: [],
      orderItems: [],
      diaryItems: [],
      editorData: [],
      editorStyle: {},
      customerID: undefined,
      customerTabs: []
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchOrder: function (lastEvaluatedKey) {
      let self = this
      let q = '?lte=3'
      if (lastEvaluatedKey) {
        q += `&lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.orderItems = []
      }
      API.get('ezietruckapi', '/api/newOrders' + q).then((json) => {
        if (json['Items']) {
          self.orderItems = [...self.orderItems, ...json['Items']]
          for (let o of json['Items']) {
            if (!self.diaryDates.includes(o.Date)) {
              self.fetchDairy(o.Date)
              self.diaryDates = [
                ...self.diaryDates, o.Date
              ]
            }
            if (!self.customerTabs.includes(o.CustomerID)) {
              self.customerTabs.push(o.CustomerID)
            }
          }
        }
        self.customerTabs = self.customerTabs
        if (json.LastEvaluatedKey) {
          self.fetchOrder(json.LastEvaluatedKey)
        }
      })
    },
    fetchDairy: function (date, lastEvaluatedKey) {
      let self = this
      let q = '?filter=unmatched'
      if (lastEvaluatedKey) {
        q += `&ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', `/api/vehicles/diary/${date}` + q).then((json) => {
        if (json['Items']) {
          self.diaryItems = [...self.diaryItems, ...json['Items']]
          for (let d of json['Items']) {
            if (!self.customerTabs.includes(d.CustomerID)) {
              self.customerTabs.push(d.CustomerID)
            }
          }
        }
        if (json.LastEvaluatedKey) {
          self.fetchDairy(date, json.LastEvaluatedKey)
        }
      })
    },
    handleTabClick: function (customerID) {
      this.customerID = customerID
    }
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    totalDate: function () {
      if (this.customerTabs.length < 1)
        return []
      const currentCustomerID = this.customerID ? this.customerID : this.customerTabs[0]
      const out = {}
      for (let o of this.orderItems) {
        if (o.CustomerID == currentCustomerID) {
          if (!out[o.Date]) {
            out[o.Date] = {
              customerID: o.CustomerID,
              date: o.Date,
              totalOrder: 0,
              matchedOrder: 0,
              noDriver: 0,
              matched: 0,
              unmatch: 0,
              totalDiary: 0,
              unmatchedDiary: 0,
              deletedDiary: 0,
              orderDiary: 0,
              withOutOrderDiary: 0
            }
          }
          out[o.Date].totalOrder += 1
          if (!o.DriverID) {
            out[o.Date].noDriver += 1
            out[o.Date].unmatch += 1
          } else if (o.MatchedOrders && o.MatchedOrders.length > 0) {
            out[o.Date].matchedOrder += 1
          } else if (o.SugestedOrders && o.SugestedOrders.length > 0) {
            out[o.Date].sugested += 1
          } else if (o.SK.startsWith('5:')) {
            out[o.Date].matched += 1
          } else if (o.SK < '5:') {
            out[o.Date].unmatch += 1
          }
        }
      }
      for (let d of this.diaryItems) {
        if (d.CustomerID == currentCustomerID) {
          if (!out[d.PK.split('#')[1]]) {
            out[d.PK.split('#')[1]] = {
              customerID: d.CustomerID,
              date: d.PK.split('#')[1],
              totalOrder: 0,
              matchedOrder: 0,
              noDriver: 0,
              matched: 0,
              unmatch: 0,
              totalDiary: 0,
              unmatchedDiary: 0,
              deletedDiary: 0,
              orderDiary: 0,
              withOutOrderDiary: 0
            }
          }
          if (d.Deleted) {
            out[d.PK.split('#')[1]].deletedDiary += 1 
          } else {
            out[d.PK.split('#')[1]].totalDiary += 1
            if (!d.MatchedOrderID) {
              out[d.PK.split('#')[1]].unmatchedDiary += 1
              if (d.OrderID) {
                out[d.PK.split('#')[1]].orderDiary += 1
              } else {
                out[d.PK.split('#')[1]].withOutOrderDiary += 1
              }
            }
          }
        }
      }
      return Object.keys(out).map((d) => ({
        ...out[d]
      }))
    }
  },
  mounted () {
    this.fetchCustomer()
    this.fetchOrder()
    const lastDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
    for (const s = moment(); s.format('YYYY-MM-DD') > lastDate; s.subtract(1, 'days') ) {
      if (!this.diaryDates.includes(s.format('YYYY-MM-DD'))) {
        this.fetchDairy(s.format('YYYY-MM-DD'))
        this.diaryDates = [
          ...this.diaryDates, s.format('YYYY-MM-DD')
        ]
      }
    }
  }
}
</script>
