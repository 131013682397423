/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:2d202625-c040-4b02-b989-dc5ff81612eb",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_X7FoG9anN",
    "aws_user_pools_web_client_id": "6m0bcheus4e8l6n6tveg4h51rt",
    "oauth": {
        "domain": "ezie-truck-ezietruck.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://ezie-truck.eziecorp.com/",
        "redirectSignOut": "https://ezie-truck.eziecorp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ezie-truck57a6d960c1a54c03bd8a02c7e2aaf388ezietruck-ezietruck",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ezie-ezietruck",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "ezietruckapi",
            "endpoint": "https://kfqo5wztbb.execute-api.ap-southeast-1.amazonaws.com/ezietruck",
            "region": "ap-southeast-1"
        }
    ],
    "aws_content_delivery_bucket": "ezie-truck-hosting-bucket-ezietruck",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d3l5i36n6knqwa.cloudfront.net"
};


export default awsmobile;
