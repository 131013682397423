<template>
  <div>
    <md-dialog :md-active.sync="showPDDialog">
      <md-dialog-title>Driver Paid</md-dialog-title>
      <md-content style="overflow-y: auto;">
        <md-table v-for="(item, driver) in paymentIntegrityDriverTable" :key="driver">
          <md-table-toolbar>
            <div class="md-toolbar-section-start">
              {{item.DriverName}} ({{$currencyFormater(item.sum)}})
            </div>
          </md-table-toolbar>
          <md-table-row>
            <md-table-head>PDNumber</md-table-head>
            <md-table-head>TotalPaidAmount</md-table-head>
          </md-table-row>
          <md-table-row v-for="pd in item.pd" :key="pd.PDNumber">
            <md-table-cell>
              <router-link :to="'/Payments/' + item.PDNumber">{{pd.PDNumber}}</router-link>
            </md-table-cell>
            <md-table-cell>{{$currencyFormater(pd.TotalPaidAmount)}}</md-table-cell>
          </md-table-row>
        </md-table>
      </md-content>
    </md-dialog>
    <md-dialog :md-active.sync="showDiaryDialog">
      <md-dialog-title>{{selectedPayment}}</md-dialog-title>
      <md-content style="overflow-y: auto;">
        <div v-for="(citem, cus) in orderQualityCustomerTable" :key="cus">
          <md-toolbar md-elevation="0">{{citem.CustomerID}}</md-toolbar>
          <md-table v-for="(jitem, jt) in citem.jobTypes" :key="jt" v-model="jitem.Orders" class="md-primary">
            <md-table-toolbar>{{jitem.JobType}}</md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }" >
              <md-table-cell md-label="OrderID"><router-link :to="'/Orders/' + item.OrderID">{{item.OrderID}}</router-link></md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </md-content>
    </md-dialog>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-toolbar md-elevation="1" class="md-dense">
          <span style="flex: 1">Volume Status</span>
          <div class="md-layout" style="flex: 4">
            <div class="md-layout-item md-size-15">
              <md-autocomplete  @input="handleCustomerSelect" :value="formData.CustomerName" :md-options="customers">
                <label>Customer</label>
                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                  <md-avatar>
                    <amplify-s3-image :imagePath="item.CustomerLogo" class="md-avatar md-large"></amplify-s3-image>
                  </md-avatar>
                  <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
                </template>

                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  No customer matching "{{ term }}" were found.
                </template>
              </md-autocomplete>
            </div>
            <div class="md-layout-item md-size-15">
              <md-field>
                <md-select v-model="formData.JobType" md-dense @md-selected="reloadStatus">
                  <md-option :value="'-'">All Job Type</md-option>
                  <md-option v-for="job in jobTypeAllOptions" :value="job" :key="job">{{job}}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-15">
              <md-field>
                <md-select v-model="formData.User" md-dense @md-selected="reloadStatus">
                  <md-option :value="'-'">All Owner</md-option>
                  <md-option v-for="user in users" :value="user" :key="user">{{user.split('@')[0]}}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-button @click="statusPeriod='MONTHLY'" :class="{'md-primary': statusPeriod === 'MONTHLY'}">MONTHLY</md-button>
              <md-button @click="statusPeriod='WEEKLY'" :class="{'md-primary': statusPeriod === 'WEEKLY'}">Weekly</md-button>
              <md-button @click="statusPeriod='DIALY'" :class="{'md-primary': statusPeriod === 'DIALY'}">Daily</md-button>
            </div>
          </div>
        </md-toolbar>
        <bar-chart :chart-data="volumeStatus" :options="volumeStatusOptions" :styles="{position: 'relative', height: $vssHeight-220 + 'px'}"></bar-chart>
      </div>
      <div class="md-layout-item md-size-30">
        <pie-chart :chart-data="volumeStatusToday" :options="volumeStatusTodayOptions" :styles="{position: 'relative', height: $vssHeight-160 + 'px'}"></pie-chart>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-toolbar md-elevation="1" class="md-dense">
              <span style="flex: 1">Order Quality</span>
              <div class="md-layout-item md-small-size-100">
                <md-field md-inline>
                  <md-input v-model="orderQualityStartWith"/>
                  <md-button class="md-icon-button" @click="reloadOrderQuality">
                    <md-icon>refresh</md-icon>
                  </md-button>
                </md-field>
              </div>
            </md-toolbar>
          </div>
          <div class="md-layout-item md-size-50">
            <bar-chart :chart-data="orderQuality" :options="orderQualityOptions" :styles="{position: 'relative', height: ($vssHeight/2)-120 + 'px'}"></bar-chart>
          </div>
          <div class="md-layout-item md-size-50">
            <div :style="{'max-height': ($vssHeight/2)-120 + 'px', 'overflow': 'auto'}">
              <md-table>
                <md-table-row>
                  <md-table-head>{{selectedPayment}} Owner</md-table-head>
                  <md-table-head md-numeric>Quality (%)</md-table-head>
                </md-table-row>
                <md-table-row v-for="row in orderQualityTable" :key="row.Owner">
                  <md-table-cell><a @click="openOwnerDiaryDialog(row.Owner)">{{row.Owner.split('@')[0]}}</a></md-table-cell>
                  <md-table-cell md-numeric>{{row.NoDiary}}</md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>
          <div class="md-layout-item md-size-100">
            <md-toolbar md-elevation="1" class="md-dense">
              <span style="flex: 1">Payment Integrity</span>
              <div class="md-layout-item md-small-size-100">
                <md-field md-inline>
                  <md-input v-model="paymentIntegrityStartWith" @blur="reloadPaymentIntegrity" />
                  <md-button class="md-icon-button" @click="reloadPaymentIntegrity">
                    <md-icon>refresh</md-icon>
                  </md-button>
                </md-field>
              </div>
            </md-toolbar>
          </div>
          <div class="md-layout-item md-size-50">
            <horizontal-bar-chart :chart-data="paymentIntegrity" :options="paymentIntegrityOptions" :styles="{position: 'relative', height: ($vssHeight/2)-120 + 'px'}"></horizontal-bar-chart>
          </div>
          <div class="md-layout-item md-size-50">
            <div :style="{'max-height': ($vssHeight/2)-120 + 'px', 'overflow': 'auto'}">
              <md-table>
                <md-table-row>
                  <md-table-head>{{selectedPayment}} Order Owner</md-table-head>
                  <md-table-head md-numeric>Amount</md-table-head>
                </md-table-row>
                <md-table-row v-for="row in paymentIntegrityTable" :key="row.DriverID">
                  <md-table-cell><a @click="opendPDDialog(row.OrderCreatedBy)">{{row.OrderCreatedBy.split('@')[0]}}</a></md-table-cell>
                  <md-table-cell md-numeric>{{$currencyFormater(row.TotalPaidAmount)}}</md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>
        </div>
      </div>
      <div class="md-layout-item">
        <md-toolbar md-elevation="1" class="md-dense">
          <span style="flex: 1">Billing Review</span>
          <div class="md-layout-item md-small-size-100">
            <md-field md-inline>
              <md-input v-model="billingReviewStartWith"  @blur="reloadBillingReview" />
              <md-button class="md-icon-button" @click="reloadBillingReview">
                <md-icon>refresh</md-icon>
              </md-button>
            </md-field>
          </div>
        </md-toolbar>
        <div :style="{'max-height': $vssHeight-120 + 'px', 'overflow': 'auto'}">
          <md-table>
            <md-table-row>
              <md-table-head>CustomerID</md-table-head>
              <md-table-head>Invoice</md-table-head>
              <md-table-head md-numeric>Diff</md-table-head>
            </md-table-row>
            <md-table-row v-for="row in billingReview" :key="row.CustomerID">
              <md-table-cell>{{row.CustomerID}}</md-table-cell>
              <md-table-cell>
                <div v-for="inv in row.Invoices" :key="inv.InvoiceNumber">
                  <router-link :to="'/InvoiceTracks/' + inv.InvoiceNumber">
                    {{inv.InvoiceNumber}}
                  </router-link>
                </div>
              </md-table-cell>
              <md-table-cell md-numeric>
                <div v-for="inv in row.Invoices" :key="inv.InvoiceNumber">
                  {{$currencyFormater(inv.InvoiceOriginalTotalAmount + (inv.CustomerPaidAdjustAmount || 0) - inv.InvoiceFinalTotalAmount)}}
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import BarChart from '../components/BarChart.js'
import HorizontalBarChart from '../components/HorizontalBarChart.js'
import PieChart from '../components/PieChart.js'
import moment from 'moment'
import { API } from 'aws-amplify'

export default {
  name: 'Home',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {
    'bar-chart': BarChart,
    'pie-chart': PieChart,
    'horizontal-bar-chart': HorizontalBarChart
  },
  methods: {
    reloadOrderQuality: function () {
      let self = this
      API.get('ezietruckapi', `/api/summary/diary/${this.orderQualityStartWith}`).then((json) => {
        self.diaryData = json
      })
    },
    reloadPaymentIntegrity: function () {
      let self = this
      API.get('ezietruckapi', `/api/summary/payment/${this.paymentIntegrityStartWith}`).then((json) => {
        self.paymentData = json
      })
    },
    reloadBillingReview: function () {
      let self = this
      API.get('ezietruckapi', `/api/summary/invoice/${this.billingReviewStartWith}`).then((json) => {
        self.invoiceData = json
      })
    },
    reloadStatus: function () {
      let self = this
      let since = moment().subtract(12, 'months').format('YYYY-MM-DD')
      let params = []
      if (this.formData.CustomerName) {
        params.push(encodeURI('CustomerName='+this.formData.CustomerName))
      }
      if (this.formData.JobType && this.formData.JobType !== '-') {
        params.push(encodeURI('JobType='+this.formData.JobType))
      }
      if (this.formData.User && this.formData.User !== '-') {
        params.push(encodeURI('User='+this.formData.User))
      }
      API.get('ezietruckapi', '/api/summary/status/'+since+ (params.length > 0 ? '?' + params.join('&') : '')).then((json) => {
        self.statusData = json
      })
    },
    fetchData: function () {
      this.fetchCustomer()
      this.fetchOptions()
      this.fetchUsers()
      this.reloadStatus()
      this.reloadOrderQuality()
      this.reloadPaymentIntegrity()
      this.reloadBillingReview()
    },
    paymentClicked: function (event, array) {
      for (let bar of array) {
        this.selectedPayment = this.paymentIntegrity.labels[bar._index]
      }
    },
    todayClicked: function (event, array) {
      for (let bar of array) {
        this.$router.push('/OrderStatus/' + this.volumeStatusToday.labels[bar._index] + '/' + this.today)
        break
      }
    },
    barClicked: function (event, array) {
      for (let bar of array) {
        this.today = this.volumeStatus.labels[bar._index]
        break
      }
      this.volumeStatusTodayOptions.title.text = this.today
      this.volumeStatusTodayOptions = Object.assign({}, this.volumeStatusTodayOptions)
    },
    loadCustomerJobType (customerID) {
      API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`).then((json) => {
        this.jobTypeCustomerOptions = json.map((j) => j.Name)
      })
    },
    fetchOptions: function () {
      let self = this
      API.get('ezietruckapi', `/api/options/JobType`).then((json) => {
        self.jobTypeOptions = json.map((j) => j.Name)
      })
    },
    fetchUsers: function () {
      let self = this
      API.get('ezietruckapi', `/api/users`).then((json) => {
        self.userItems = json.Users
      })
    },
    handleCustomerSelect (value) {
      if (typeof value === 'object') {
        this.currentCustomer = value
        this.loadCustomerJobType(value.CustomerID)
      }
    },
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    opendPDDialog: function (owner) {
      this.selectedOwner = owner
      this.showPDDialog = true
    },
    openOwnerDiaryDialog: function (owner) {
      this.selectedOwner = owner
      this.showDiaryDialog = true
    },
    handleCustomerSelect: function (value) {
      if (typeof value === 'object') {
        this.formData = Object.assign({}, this.formData, {
          CustomerName: ((value || {}).CustomerName || null)
        })
      } else {
        if (!value) {
          this.formData = Object.assign({}, this.formData, {
            CustomerName: null
          })
        }
      }
      this.reloadStatus()
    }
  },
  data () {
    return {
      orderQualityStartWith: moment().format('YYYY-MM'),
      paymentIntegrityStartWith: 'PD' + moment().format('YYYYMM'),
      billingReviewStartWith: 'IV' + moment().format('YYYYMM'),
      statusData: {},
      diaryData: {},
      paymentData: {},
      invoiceData: {},
      showDiaryDialog: false,
      showPDDialog: false,
      selectedOwner: null,
      statusPeriod: 'WEEKLY',
      selectedPayment: 'NoDiary',
      today: moment().format('YYYY-MM-DD'),
      formData: {
        CustomerName: null,
        JobType: '-',
        User: '-'
      },
      jobTypeOptions: [],
      customerItems: [],
      userItems: [],
      jobTypeCustomerOptions: [],
      volumeStatusOptions: {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        onClick: this.barClicked,
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              callback: function(value) {
                let now = moment()
                if (value === now.format('YYYYww') || value === now.format('YYYY-MM-DD') ) {
                  return '*' + value + '*';
                } else {
                  return value
                }
              }
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              callback: this.$currencyFormater,
              beginAtZero: true,
              maxTicksLimit: 10
            }
          }]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: 2,
                formatter: function(value, context) {
                  if (context.datasetIndex === 4 && parseInt(value) > 0) {
                    return context.chart.data.datasets.reduce((accumulator, currentValue) => accumulator + currentValue.data[context.dataIndex], 0)
                  } else {
                    return ''
                  }
                }
            }
        }
      },
      volumeStatusTodayOptions: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Today'
        },
        legend: {
          position: 'bottom'
        },
        onClick: this.todayClicked,
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: 4,
                formatter: function(value) {
                  if (parseInt(value) > 0) {
                    return value
                  } else {
                    return ''
                  }
                }
            }
        }
      },
      orderQualityOptions: {
        maintainAspectRatio: false,
        title: {
          display: false
        },
        legend: {
          display: false
        },
        onClick: this.paymentClicked,
      },
      paymentIntegrityOptions: {
        maintainAspectRatio: false,
        title: {
          display: false
        },
        legend: {
          display: false
        },
        onClick: this.paymentClicked,
        scales: {
            xAxes: [{
                ticks: {
                    callback: this.$currencyFormater,
                    beginAtZero: true
                }
            }]
        },
        plugins: {
            datalabels: {
                formatter: function(value, context) {
                  return parseFloat(context.chart.data.datasets[0].data[context.dataIndex]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                }
            }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return parseFloat(tooltipItem.value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
          }
        }

      }
    }
  },
  computed: {
    volumeStatus: function () {
      let data = {
        labels: [],
        datasets: [
          {label: '1:Preparing', data: [], backgroundColor: "rgba(201, 203, 207, 0.8)"},
          {label: '2:Waiting', data: [], backgroundColor: "rgba(54, 162, 235, 0.8)"},
          {label: '3:Active', data: [], backgroundColor: "rgba(75, 192, 192, 0.8)"},
          {label: '4:Delivered', data: [], backgroundColor: "rgba(255, 159, 64, 0.8)"},
          {label: '5:Confirmed', data: [], backgroundColor: "rgba(255, 99, 132, 0.8)"}
        ]
      }
      let dates = [...Object.keys(this.statusData)].sort((a,b) => a.localeCompare(b))
      let since = moment().subtract(26, 'weeks').format('YYYYWW')
      if (this.statusPeriod === 'DIALY') {
        since = moment().subtract(30, 'days').format('YYYY-MM-DD')
      }
      if (this.statusPeriod === 'MONTHLY') {
        since = moment().subtract(12, 'months').format('YYYY-MM')
      }
      for (let date of dates) {
        if ((this.statusPeriod === 'WEEKLY' && date.length === 6) || (this.statusPeriod === 'DIALY' && date.length === 10) || (this.statusPeriod === 'MONTHLY' && date.length === 7)) {
          if (date >= since) {
            let idx = data.labels.findIndex((l) => l === date)
            if (idx < 0) {
              idx = data.labels.length
              data.labels.push(date)
            }
            data.datasets[0].data[idx] = (data.datasets[0].data[idx] || 0) + this.statusData[date]['1:Preparing']
            data.datasets[1].data[idx] = (data.datasets[1].data[idx] || 0) + this.statusData[date]['2:Waiting']
            data.datasets[2].data[idx] = (data.datasets[2].data[idx] || 0) + this.statusData[date]['3:Active']
            data.datasets[3].data[idx] = (data.datasets[3].data[idx] || 0) + this.statusData[date]['4:Delivered']
            data.datasets[4].data[idx] = (data.datasets[4].data[idx] || 0) + this.statusData[date]['5:Confirmed']
          }
        }
      }
      return data
    },
    volumeStatusToday: function () {
      if (this.statusData[this.today]) {
        return {
          labels: ['1:Preparing', '2:Waiting', '3:Active', '4:Delivered', '5:Confirmed'],
          datasets: [
            {label: 'Status', data: [
              this.statusData[this.today]['1:Preparing'],
              this.statusData[this.today]['2:Waiting'],
              this.statusData[this.today]['3:Active'],
              this.statusData[this.today]['4:Delivered'],
              this.statusData[this.today]['5:Confirmed'],
            ], backgroundColor: ["rgba(201, 203, 207, 0.8)", "rgba(54, 162, 235, 0.8)", "rgba(75, 192, 192, 0.8)", "rgba(255, 159, 64, 0.8)", "rgba(255, 99, 132, 0.8)"]}
          ]
        }
      } else {
        return {
          labels: ['1:Preparing', '2:Waiting', '3:Active', '4:Delivered', '5:Confirmed'],
          datasets: [
            {label: 'Status', data: [], backgroundColor: ["rgba(201, 203, 207, 0.8)", "rgba(54, 162, 235, 0.8)", "rgba(75, 192, 192, 0.8)", "rgba(255, 159, 64, 0.8)", "rgba(255, 99, 132, 0.8)"]}
          ]
        }
      }
    },
    orderQuality: function () {
      let data = {
        labels: ['Complete', 'NoDiary'],
        datasets: [
          {label: 'Order Quality', data: [0, 0], backgroundColor: ["rgba(21, 203, 207, 0.8)", "rgba(201, 203, 27, 0.8)"]},
        ]
      }
      let sum = 0
      for (let o in this.diaryData) {
        if (this.diaryData[o].Diary > 0) {
          data.datasets[0].data[0] += 1
        } else {
          data.datasets[0].data[1] += 1
        }
        sum += 1
      }
      data.datasets[0].data[0] =  Math.round(data.datasets[0].data[0] / sum * 10000)/100
      data.datasets[0].data[1] =  Math.round(data.datasets[0].data[1] / sum * 10000)/100
      return data
    },
    orderQualityTable: function () {
      let data = []
      let sum = 0
      for (let o in this.diaryData) {
        let idx = data.findIndex(d => d.Owner === this.diaryData[o].CreatedBy)
        if (idx < 0) {
          idx = data.length
          data.push({Owner: this.diaryData[o].CreatedBy, NoDiary: 0})
        }
        if ((this.selectedPayment === 'Complete' && this.diaryData[o].Diary > 0) || (this.selectedPayment === 'NoDiary' && this.diaryData[o].Diary === 0))  {
          data[idx].NoDiary += 1
        }
        sum += 1
      }
      for (let i = data.length - 1; i >= 0; i--) {
        data[i].NoDiary = Math.round(data[i].NoDiary / sum * 10000)/100
      }
      return data
    },
    orderQualityCustomerTable: function () {
      let data = {}
      for (let d in this.diaryData) {
        if (this.diaryData[d].CreatedBy === this.selectedOwner && ((this.selectedPayment === 'Complete' && this.diaryData[d].Diary > 0) || (this.selectedPayment === 'NoDiary' && this.diaryData[d].Diary === 0))) {
          if (!data[this.diaryData[d].CustomerID]) {
            data[this.diaryData[d].CustomerID] = {
              CustomerID: this.diaryData[d].CustomerID,
              jobTypes: {}
            }
          }
          if (!data[this.diaryData[d].CustomerID].jobTypes[this.diaryData[d].JobType]) {
            data[this.diaryData[d].CustomerID].jobTypes[this.diaryData[d].JobType] = {
              JobType: this.diaryData[d].JobType,
              Orders: []
            }
          }
          data[this.diaryData[d].CustomerID].jobTypes[this.diaryData[d].JobType].Orders.push({OrderID: this.diaryData[d].OrderID})
        }
      }
      return data
    },
    paymentIntegrity: function () {
      let data = {
        labels: ['Complete', 'NoDiary'],
        datasets: [
          {label: 'Payment Integreity', data: [0, 0], backgroundColor: ["rgba(21, 203, 207, 0.8)", "rgba(201, 203, 27, 0.8)"]},
        ]
      }
      data.datasets[0].data[0] = (this.paymentData.Complete || []).reduce((accumulator, currentValue) => accumulator + currentValue.TotalPaidAmount, 0) 
      data.datasets[0].data[1] = (this.paymentData.NoDiary || []).reduce((accumulator, currentValue) => accumulator + currentValue.TotalPaidAmount, 0)
      return data
    },
    paymentIntegrityTable: function () {
      let data = []
      if (this.selectedPayment && this.paymentData[this.selectedPayment]) {
        for (let p of this.paymentData[this.selectedPayment]) {
          let idx = data.findIndex((d) => d.OrderCreatedBy === p.OrderCreatedBy)
          if (idx < 0) {
            data.push({
              OrderCreatedBy: p.OrderCreatedBy,
              TotalPaidAmount: p.TotalPaidAmount
            })
          } else {
            data[idx].TotalPaidAmount += p.TotalPaidAmount
          }
        }
      }
      return data
    },
    paymentIntegrityDriverTable: function () {
      let data = {}
      if (this.selectedPayment && this.paymentData[this.selectedPayment]) {
        for (let p of this.paymentData[this.selectedPayment]) {
          if (p.OrderCreatedBy === this.selectedOwner) {
            if (!data[p.DriverID]) {
              data[p.DriverID] = {
                DriverID: p.DriverID,
                DriverName: p.DriverName,
                pd: [],
                sum: 0
              }
            }
            data[p.DriverID].sum += p.TotalPaidAmount
            data[p.DriverID].pd.push({PDNumber: p.PDNumber, TotalPaidAmount: p.TotalPaidAmount})
          }
        }
      }
      return data
    },
    billingReview: function () {
      let data = []
      for (let iv in this.invoiceData) {
        let idx = data.findIndex(d => d.CustomerID === this.invoiceData[iv].CustomerID)
        if (idx < 0) {
          idx = data.length
          data.push({CustomerID: this.invoiceData[iv].CustomerID, Invoices: [], sum: 0})
        }
        data[idx].Invoices.push(this.invoiceData[iv])
        data[idx].sum += Math.abs(this.invoiceData[iv].InvoiceOriginalTotalAmount + (this.invoiceData[iv].CustomerPaidAdjustAmount || 0) - this.invoiceData[iv].InvoiceFinalTotalAmount)
      }
      data = data.map((d) => Object.assign({}, d, d.Invoices.sort((a,b) => Math.abs(b.InvoiceOriginalTotalAmount + (b.CustomerPaidAdjustAmount || 0) - b.InvoiceFinalTotalAmount) - Math.abs(a.InvoiceOriginalTotalAmount + (a.CustomerPaidAdjustAmount || 0) - a.InvoiceFinalTotalAmount))))
      return data.sort((a,b) => b.sum - a.sum)
    },
    jobTypeAllOptions: function () {
      return [...this.jobTypeOptions, ...this.jobTypeCustomerOptions]
    },
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    users: function () {
      let out = []
      for (let u of this.userItems) {
        let eidx = u.Attributes.findIndex((i) => i.Name === 'email')
        if (eidx > -1) {
          out.push(u.Attributes[eidx].Value)
        }
      }
      return out
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
<style type="text/css" scoped="">
  .md-toolbar.md-dense {
    font-size: 8pt;
    font-weight: bold;
    margin: 0px;
    min-height: 24px;
  }
  .md-toolbar .md-layout-item .md-field.md-theme-default.md-inline.md-has-value {
    padding: 0px;
    margin: 0px;
    min-height: 0px;
  }
  .md-toolbar .md-layout-item .md-field .md-input {
    font-size: 8pt;
    margin: 0px;
    font-weight: bold;
  }
  .md-toolbar .md-field {
    margin: 0px;
    font-size: 8pt;
    font-weight: bold;
  }
  .md-toolbar .md-field .md-menu.md-select .md-input.md-input.md-select-value {
    margin: 0px;
    font-size: 8pt;
    font-weight: bold;
  }
  .md-toolbar .md-button {
    font-size: 8pt;
    margin: 0px;
    font-weight: bold;
  }
</style>

