<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <form @submit.prevent="handleSearchPD" class="md-toolbar-row" >
            <div class="md-layout">
              <md-field class="md-layout-item">
                <label for="movie">Month</label>
                <md-select v-model="month">
                  <md-option v-for="m in months" :key="m" :value="m">{{m}}</md-option>
                </md-select>
              </md-field>
              <md-field class="md-layout-item">
                <label>Year</label>
                <md-input v-model="year"></md-input>
              </md-field>
              <div class="md-layout-item">
                <md-button type="submit" class="md-raised md-primary">
                  <span>Search</span>
                </md-button>
              </div>
            </div>
          </form>
        </div>
        <div class="md-toolbar-section-end">
          <span class="md-title">
            Trip: {{group.trip}} Sales: {{$currencyFormater(group.sales)}} Cost: {{$currencyFormater(group.cost)}} Margin: {{$currencyFormater(group.margin)}}
          </span>
        </div>
      </div>
    </md-toolbar>
    <md-table class="md-primary" md-card v-model="sortedInvoices">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Receipt Number">{{ item.ReceiptNumber }}</md-table-cell>
        <md-table-cell md-label="Invoice Number"><router-link :to="'/InvoiceTracks/' + item.InvoiceNumber">{{ item.InvoiceNumber }}</router-link></md-table-cell>
        <md-table-cell md-label="Customer Name">
          <md-avatar><amplify-s3-image :imagePath="item.CustomerLogo" class="md-avatar md-large"></amplify-s3-image></md-avatar>
          {{ item.CustomerName }}
        </md-table-cell>
        <md-table-cell md-label="Paid Due">{{ dateTimeFormatter(item.CustomerPaidDueDate)}}</md-table-cell>
        <md-table-cell md-label="Original Total Amount">{{ $currencyFormater(item.InvoiceOriginalTotalAmount) }}</md-table-cell>
        <md-table-cell md-label="Final Total Amount">{{ $currencyFormater(item.InvoiceFinalTotalAmount) }}</md-table-cell>
        <md-table-cell md-label="Diff Amount">{{ $currencyFormater((item.InvoiceOriginalTotalAmount*1000 - item.InvoiceFinalTotalAmount*1000)/1000) }}</md-table-cell>
        <md-table-cell md-label="Creaetd By">{{ item.CreatedBy ? item.CreatedBy.split('@')[0] : '' }}</md-table-cell>
        <md-table-cell md-label="Operation">
          <md-button @click="handleDeleteReceipt(item.InvoiceNumber)">delete</md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { API } from 'aws-amplify'

export default {
  name: 'IVNumberList',
  data () {
    return {
      invoiceNumberItems: [],
      currentRC: `RC${moment().format('YYYYMM')}`,
      months: [...Array(12).keys()].map((a) => moment(`${a+1}`, 'M').format('MMMM')),
      year: moment().format('YYYY'),
      month: moment().format('MMMM')
    }
  },
  methods: {
    fetchData: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?LastRCMonth=${lastEvaluatedKey.RCMonth}&LastCustomerID=${lastEvaluatedKey.CustomerID}`
      }
      API.get('ezietruckapi', `/api/receipts/${this.currentRC}${q}`).then((json) => {
        self.invoiceNumberItems = [...self.invoiceNumberItems, ...json['Items']]
        if (json.LastEvaluatedKey) {
          self.fetchData(json.LastEvaluatedKey)
        }
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY")
      } else {
        return ''
      }
    },
    handleSearchPD: function () {
      this.currentRC = `RC${this.year}${moment(this.month, 'MMMM').format('MM')}`
      this.invoiceNumberItems = []
      this.fetchData()
    },
    handleDeleteReceipt: function (invoiceNumber) {
      let vm = this
      API.del('ezietruckapi', '/api/receipts/' + invoiceNumber).then(() => {
        vm.handleSearchPD()
      })
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    sortedInvoices: function () {
      return [...this.invoiceNumberItems].sort((a, b) => b.ReceiptNumber.localeCompare(a.ReceiptNumber))
    },
    invoiceSum: function () {
      return this.invoiceNumberItems.reduce((accumulator, currentValue) => (accumulator*1000 + currentValue.InvoiceFinalTotalAmount*1000)/1000, 0)
    },
    group: function () {
      return {
        trip: this.invoiceNumberItems.reduce((accumulator, currentValue) => (accumulator + currentValue.OrderKeys.length), 0),
        sales: this.invoiceNumberItems.reduce((accumulator, currentValue) => (accumulator*1000 + currentValue.InvoiceOriginalTotalAmount*1000 + (currentValue.CustomerPaidAdjustAmount || 0)*1000)/1000, 0),
        cost: this.invoiceNumberItems.reduce((accumulator, currentValue) => (accumulator*1000 + currentValue.TotalTripCostAmount*1000 + (currentValue.DeliverCostAdjustAmount || 0)*1000)/1000, 0),
        margin: this.invoiceNumberItems.reduce((accumulator, currentValue) => (accumulator*1000 + ((currentValue.InvoiceOriginalTotalAmount*1000 + (currentValue.CustomerPaidAdjustAmount || 0)*1000) - (currentValue.TotalTripCostAmount*1000 + (currentValue.DeliverCostAdjustAmount || 0)*1000)))/1000, 0)
      }
    }
  },
  created () {
    this.fetchData()
  }
}
</script>