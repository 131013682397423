<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <md-field>
            <label for="groupBy">Group by</label>
            <md-select v-model="groupBy" name="groupBy" id="groupBy">
              <md-option value="DriverID">Driver</md-option>
              <md-option value="PaymentBankAccountName">Account Name</md-option>
              <md-option value="CreatedDate">Created Date</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-toolbar-section-end">
          <md-button to="/NewPayment">Create Payment</md-button>
        </div>
      </div>
    </md-toolbar>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackBarMsg}}</span>
    </md-snackbar>
    <div class="md-layout md-gutter">
      <div class="md-layout-item" v-if="groupBy === 'DriverID' || groupBy === 'PaymentBankAccountName'">
        <div v-for="(group, month) in groupByDriver" :key="month">
          <pd-item-table :group="group" :current-selected="currentSelected" @select="currentSelected=$event" @pvcreated="handlePVCreated" @pddeleted="handlePDDeleted"></pd-item-table>
        </div>
      </div>
      <div class="md-layout-item" v-if="groupBy === 'CreatedDate'">
        <md-toolbar md-elevation="0"><h4 class="md-title">Freelance</h4></md-toolbar>
        <div v-for="group in groupedByFreelance" :key="group.title">
          <pd-item-table :group="group" :current-selected="currentSelected" @select="currentSelected=$event" @pvcreated="handlePVCreated" @pddeleted="handlePDDeleted"></pd-item-table>
        </div>
      </div>
      <div class="md-layout-item" v-if="groupBy === 'CreatedDate'">
        <md-toolbar md-elevation="0"><h4 class="md-title">Permanent</h4></md-toolbar>
        <div v-for="group in groupedByPermanent" :key="group.title">
          <pd-item-table :group="group" :current-selected="currentSelected" @select="currentSelected=$event" @pvcreated="handlePVCreated" @pddeleted="handlePDDeleted"></pd-item-table>
        </div>
      </div>
      <div class="md-layout-item" v-if="groupBy === 'CreatedDate'">
        <md-toolbar md-elevation="0"><h4 class="md-title">Truck Leader</h4></md-toolbar>
        <div v-for="group in groupedByTruckLeader" :key="group.title">
          <pd-item-table :group="group" :current-selected="currentSelected" @select="currentSelected=$event" @pvcreated="handlePVCreated" @pddeleted="handlePDDeleted"></pd-item-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { API } from 'aws-amplify'
import VueScreenSize from 'vue-screen-size'
import PDItemTable from '../components/PDItemTable.vue'

export default {
  name: 'PaymentList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {'pd-item-table': PDItemTable},
  data () {
    return {
      now: moment().format('YYYY-MM-DD'),
      driverPaids: [],
      LastEvaluatedKey: null,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      startMonthSlice: 0,
      selectedDriver: null,
      showTable: {},
      groupBy: 'DriverID',
      currentSelected: null,
      loading: false
    }
  },
  methods: {
    fetchPayment: function (lastEvaluatedKey) {
      let q = ''
      let self = this
      this.loading = true
      if (lastEvaluatedKey && lastEvaluatedKey.PK) {
        q = `?PK=${lastEvaluatedKey.PK.replace(/#/g, '_')}&PVMonth=${lastEvaluatedKey.PVMonth}&SK=${lastEvaluatedKey.SK.replace(/#/g, '_')}&DriverID=${lastEvaluatedKey.DriverID}`
      }
      API.get('ezietruckapi', `/api/unPVMonths${q}`).then((json) => {
        self.driverPaids = [...self.driverPaids, ...json.Items.filter((j) => j.OrderCreatedBy)]
        if (json.LastEvaluatedKey) {
          self.fetchPayment(json.LastEvaluatedKey)
        } else {
          self.loading = false
        }
      })
    },
    fetchData: function () {
      this.fetchPayment()
    },
    handlePVCreated: function (event) {
      for (let k of event) {
        let idx = this.driverPaids.findIndex((d) => d.PDNumber === k.SK.split('#')[2])
        if (idx > -1) {
          this.driverPaids = [
            ...this.driverPaids.slice(0, idx),
            ...this.driverPaids.slice(idx + 1)
          ]
        }
      }
    },
    handleSelectDriver: function (event) {
      this.selectedDriver = event
    },
    toggleTable: function (month) {
      if (month in this.showTable) {
        this.showTable[month] = !this.showTable[month]
      } else {
        this.showTable[month] = true
      }
      this.showTable = Object.assign({}, this.showTable)
    },
    handlePDDeleted: function (event) {
      for (let k of event) {
        let idx = this.driverPaids.findIndex((d) => d.PDNumber === k.SK.split('#')[2])
        if (idx > -1) {
          this.driverPaids = [
            ...this.driverPaids.slice(0, idx),
            ...this.driverPaids.slice(idx + 1)
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    groupByDriver: function () {
      let orders = {}
      if (this.groupBy === 'DriverID' || this.groupBy === 'PaymentBankAccountName') {
        for (let p of this.driverPaids) {
          if (orders[(p[this.groupBy] || p['DriverID'])]) {
            orders[(p[this.groupBy] || p['DriverID'])].sum = (orders[(p[this.groupBy] || p['DriverID'])].sum*1000 + parseFloat(p.TotalPaidAmount)*1000)/1000
            orders[(p[this.groupBy] || p['DriverID'])].items.push(p)
          } else {
            orders[(p[this.groupBy] ||  p['DriverID'])] = {
              title: this.groupBy === 'PaymentBankAccountName' ? p['PaymentBankAccountName'] || p.DriverName : p.DriverName,
              id: p.DriverID,
              items: [p],
              sum: parseFloat(p.TotalPaidAmount)
            }
          }
        }
      }
      return Object.values(orders).sort((a,b) => (a.title || '').localeCompare((b.title || '')))
    },
    groupedByFreelance: function () {
      let orders = {}
      if (this.groupBy === 'CreatedDate') {
        for (let p of this.driverPaids.filter((d) => d.DriverGroup === 'Freelance')) {
          if (orders[p[this.groupBy]]) {
            orders[p[this.groupBy]].sum = (orders[p[this.groupBy]].sum*1000 + parseFloat(p.TotalPaidAmount)*1000)/1000
            orders[p[this.groupBy]].items.push(p)
          } else {
            orders[p[this.groupBy]] = {
              title: this.groupBy === 'DriverID' ? p.DriverName : moment(p[this.groupBy], 'YYYY-MM-DD').format(' DD MMM YYYY'),
              groupBy: p[this.groupBy],
              items: [p],
              sum: parseFloat(p.TotalPaidAmount)
            }
          }
        }
        return [...Object.values(orders)].sort((a,b) => (a.groupBy && b.groupBy) ?  (a.groupBy || '').localeCompare((b.groupBy || '')) : -1)
      }
      return orders
    },
    groupedByPermanent: function () {
      let orders = {}
      if (this.groupBy === 'CreatedDate') {
        for (let p of this.driverPaids.filter((d) => d.DriverGroup === 'Permanent')) {
          if (orders[p[this.groupBy]]) {
            orders[p[this.groupBy]].sum = (orders[p[this.groupBy]].sum*1000 + parseFloat(p.TotalPaidAmount)*1000)/1000
            orders[p[this.groupBy]].items.push(p)
          } else {
            orders[p[this.groupBy]] = {
              title: this.groupBy === 'DriverID' ? p.DriverName : moment(p[this.groupBy], 'YYYY-MM-DD').format(' DD MMM YYYY'),
              groupBy: p[this.groupBy],
              items: [p],
              sum: parseFloat(p.TotalPaidAmount)
            }
          }
        }
        return [...Object.values(orders)].sort((a,b) => (a.groupBy && b.groupBy) ?  (a.groupBy || '').localeCompare((b.groupBy || '')) : -1)
      }
      return orders
    },
    groupedByTruckLeader: function () {
      let orders = {}
      if (this.groupBy === 'CreatedDate') {
        for (let p of this.driverPaids.filter((d) => d.DriverGroup === 'Truck Leader')) {
          if (orders[p[this.groupBy]]) {
            orders[p[this.groupBy]].sum = (orders[p[this.groupBy]].sum*1000 + parseFloat(p.TotalPaidAmount)*1000)/1000
            orders[p[this.groupBy]].items.push(p)
          } else {
            orders[p[this.groupBy]] = {
              title: this.groupBy === 'DriverID' ? p.DriverName : moment(p[this.groupBy], 'YYYY-MM-DD').format(' DD MMM YYYY'),
              groupBy: p[this.groupBy],
              items: [p],
              sum: parseFloat(p.TotalPaidAmount)
            }
          }
        }
        return [...Object.values(orders)].sort((a,b) => (a.groupBy && b.groupBy) ?  (a.groupBy || '').localeCompare((b.groupBy || '')) : -1)
      }
      return orders
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
