<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-section-start">
        <h3 class="md-title">Announce Form</h3>
      </div>
    </md-toolbar>
    <form @submit.prevent="handleCreateAnnounce">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-field :class="{'md-invalid': errors['instance.Title']}">
            <label>Title</label>
            <md-input v-model="formData.Title"></md-input>
            <span class="md-error" v-if="errors['instance.Title']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-field :class="{'md-invalid': errors['instance.Description']}">
            <label>Description</label>
            <md-textarea v-model="formData.Description"></md-textarea>
            <span class="md-error" v-if="errors['instance.Description']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-datepicker v-model="formData.StartDateTime" :md-model-type="String" :class="{'md-invalid': errors['StartDateTime']}" md-immediately>
            <label>Start Date</label>
            <span class="md-error" v-if="errors['StartDateTime']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-datepicker v-model="formData.EndDateTime" :md-model-type="String" :class="{'md-invalid': errors['EndDateTime']}" md-immediately>
            <label>End Date</label>
            <span class="md-error" v-if="errors['EndDateTime']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-field>
            <label>Truck Type</label>
            <md-select v-model="formData.Conditions.Truck.VehicleTruckType" multiple>
              <md-option v-for="tag in truckTypeOptions" :value="tag" :key="tag">{{tag}}</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-field>
            <label>Container Type</label>
            <md-select v-model="formData.Conditions.Truck.VehicleContainerType" multiple>
              <md-option v-for="tag in containerTypeOptions" :value="tag" :key="tag">{{tag}}</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-field>
            <label>Driver Team</label>
            <md-select v-model="formData.Conditions.Driver.DriverTeam" multiple>
              <md-option v-for="tag in driverTeamOptions" :value="tag" :key="tag">{{tag}}</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-field>
            <label>Driver Tag</label>
            <md-select v-model="formData.Conditions.Driver.DriverTags" multiple>
              <md-option v-for="tag in driverTagOptions" :value="tag" :key="tag">{{tag}}</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-field :class="{'md-invalid': errors['instance.HeroImage']}">
            <md-file v-model="imageSelect" accept="image/*" @md-change="handleImageSelect" placeholder="Hero Image" />
            <span class="md-error" v-if="errors['instance.HeroImage']">Invalid field input</span>
          </md-field>
        </div>
        <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <md-button type="submit" class="md-raised md-primary" v-show="!hideSubmit">
            <span v-show="!$route.params.announceID">Create Announce</span>
            <span v-show="!!$route.params.announceID">Edit Announce</span>
          </md-button>
          <md-button class="md-raised" @click="handleCloseAnnounce" v-show="!!$route.params.announceID">Close</md-button>
          <md-button class="md-raised" @click="$router.go(-1)">Back</md-button>
        </div>
      </div>
    </form>
    <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackBarMsg}}</span>
    </md-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'

export default {
  name: 'JobAnnounceForm',
  data () {
    return {
      announceItems: [],
      loading: false,
      truckTypeOptions: [],
      containerTypeOptions: [],
      driverTeamOptions: [],
      driverTagOptions: [],
      formData: {
        Title: null,
        Description: null,
        HeroImage: null,
        Conditions: {
          Truck: {
            VehicleTruckType: [],
            VehicleContainerType: []
          },
          Driver: {
            DriverTeam: [],
            DriverTags: []
          }
        },
        StartDateTime: null,
        EndDateTime: null
      },
      errors: {},
      showNewDialog: false,
      hideSubmit: false,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      imageSelect: null
    }
  },
  methods: {
    fetchOptions: function () {
      let self = this
      API.get('ezietruckapi', `/api/options/TruckType`).then((json) => {
        self.truckTypeOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/ContainerType`).then((json) => {
        self.containerTypeOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/DriverTeam`).then((json) => {
        self.driverTeamOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/Tag`).then((json) => {
        self.driverTagOptions = json.map((j) => j.Name)
      })
    },
    formValidate: function () {
      this.errors = {}
      if (!this.formData.Title) {
        this.errors['instance.Title'] = 'Title is required'
      }
      if (!this.formData.Description) {
        this.errors['instance.Description'] = 'Description is required'
      }
      this.errors = Object.assign({}, this.errors)
    },
    handleCreateAnnounce: function () {
      this.formValidate()
      if (Object.keys(this.errors).length == 0) {
        if (this.$route.params.announceID) {
          this.hideSubmit = true
          API.put('ezietruckapi', `/api/announces/${encodeURIComponent(this.$route.params.announceID)}`, {body: Object.assign({}, this.formData, {CreatedBy: this.user.attributes.email })}).then(() => {
            this.showSnackbar = true
            this.snackBarMsg = 'Successfully saved'
            this.hideSubmit = false
            this.$router.push('/JobAnnounce')
          }).catch((e) => {
            this.showSnackbar = true
            this.snackBarMsg = e.response.data.message || e.message
            this.hideSubmit = false
          })
        } else {
          this.hideSubmit = true
          API.post('ezietruckapi', '/api/announces', {body: Object.assign({}, this.formData, { CreatedBy: this.user.attributes.email })}).then(() => {
            this.showSnackbar = true
            this.snackBarMsg = 'Successfully saved'
            this.hideSubmit = false
            this.$router.push('/JobAnnounce')
          }).catch((e) => {
            this.showSnackbar = true
            this.snackBarMsg = e.response.data.message || e.message
            this.hideSubmit = false
          })
        }
      }
    },
    handleCloseAnnounce: function () {
      API.del('ezietruckapi', `/api/announces/${encodeURIComponent(this.$route.params.announceID)}`).then(() => {
        this.showSnackbar = true
        this.snackBarMsg = 'Successfully saved'
        this.hideSubmit = false
        this.$router.push('/JobAnnounce')
      }).catch((e) => {
        this.showSnackbar = true
        this.snackBarMsg = e.response.data.message || e.message
        this.hideSubmit = false
      })
    },
    fetchData: function () {
      if (this.$route.params.announceID) {
        API.get('ezietruckapi', `/api/announces/${encodeURIComponent(this.$route.params.announceID)}`).then((json) => {
          this.formData = {
            Title: json.Title,
            Description: json.Description,
            Conditions: json.Conditions,
            StartDateTime: json.StartDateTime,
            EndDateTime: json.EndDateTime,
            HeroImage: json.HeroImage
          }
        })
      }
    },
    handleImageSelect: function (event) {
      let vm = this
      for (let file of event) {
        console.log(file.name)
        this.$Amplify.Storage.put(
          `Announce_Hero_Images/${(new Date()).toISOString()}.`+ file.name, file, { level: 'public' }
        ).then((res) => {
          vm.formData.HeroImage = res.key
        }).catch(console.error)
      }
    },
  },
  computed: {
    ...mapGetters(['user'])
  },
  created () {
    let d = new Date()
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    this.formData.StartDateTime = `${ye}-${mo}-${da}`
    this.formData.EndDateTime = `${ye}-${mo}-${da}`
    this.fetchOptions()
    this.fetchData()
  }
}
</script>
