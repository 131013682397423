<template>
  <div>
    <md-toolbar md-elevation="0">
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start" style="flex: 4">
          <form @submit.prevent="handleSearchOrder"  class="md-toolbar-row md-layout" >
            <div class="md-layout-item  md-size-20">
              <md-datepicker v-model="form.StartDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
                <label>Start Date</label>
                <span class="md-error" v-if="errors['Date']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item  md-size-20">
              <md-datepicker v-model="form.EndDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
                <label>End Date</label>
                <span class="md-error" v-if="errors['Date']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item">
              <md-button :disabled="!!isLoading" type="submit" class="md-raised md-primary">
                <span>Search</span>
              </md-button>
              <md-button :disabled="!!isLoading" @click="handleDownloadAllCSV">
                Download
              </md-button>
              <md-button :disabled="!!isLoading" @click="handleSaveCycle">
                Save
              </md-button>
            </div>
          </form>
        </div>
        <div class="md-toolbar-section-end" style="flex: 6">
          <div class="md-toolbar-row md-layout" >
            <div class="md-layout-item">
              <md-field>
                <label for="movie">Group by</label>
                <md-select v-model="groupBy">
                  <md-option value="Customer">Customer</md-option>
                  <md-option value="OrderDate">OrderDate</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="movie">Owner</label>
                <md-select v-model="currentOwner">
                  <md-option v-for="owner in Object.keys(groupByPaymentRequests)" :value="owner" :key="owner">{{owner}}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label for="movie">Cycles</label>
                <md-select v-model="selectedCycles" multiple>
                  <md-option v-for="c in cycleDateOptions" :value="c" :key="c">{{c}}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-checkbox v-model="includeSalary">Include Salary</md-checkbox>
            </div>
            <div class="md-layout-item">
              <h3 class="md-title" v-if="groupByPaymentRequests[currentOwner]">
                <span v-show="groupByPaymentRequests[currentOwner].TotalDeliveryCost + groupByPaymentRequests[currentOwner].TotalFuelCost + groupByPaymentRequests[currentOwner].TotalTollParkCost + (includeSalary ? groupByPaymentRequests[currentOwner].TotalSalaryCost : 0) + groupByPaymentRequests[currentOwner].TotalPickerCost + groupByPaymentRequests[currentOwner].TotalIncentiveCost > 0">
                  Total Cost: {{$currencyFormater(groupByPaymentRequests[currentOwner].TotalDeliveryCost + groupByPaymentRequests[currentOwner].TotalFuelCost + groupByPaymentRequests[currentOwner].TotalTollParkCost + (includeSalary ? groupByPaymentRequests[currentOwner].TotalSalaryCost : 0) + groupByPaymentRequests[currentOwner].TotalPickerCost + groupByPaymentRequests[currentOwner].TotalIncentiveCost)}}
                </span>
                <span v-show="groupByPaymentRequests[currentOwner].CheckedTotalDeliveryCost + groupByPaymentRequests[currentOwner].CheckedTotalFuelCost + groupByPaymentRequests[currentOwner].CheckedTotalTollParkCost + (includeSalary ? groupByPaymentRequests[currentOwner].CheckedTotalSalaryCost : 0) + groupByPaymentRequests[currentOwner].CheckedTotalPickerCost + groupByPaymentRequests[currentOwner].CheckedTotalIncentiveCost > 0">
                Checked Total Cost: {{$currencyFormater(groupByPaymentRequests[currentOwner].CheckedTotalDeliveryCost + groupByPaymentRequests[currentOwner].CheckedTotalFuelCost + groupByPaymentRequests[currentOwner].CheckedTotalTollParkCost + (includeSalary ? groupByPaymentRequests[currentOwner].CheckedTotalSalaryCost : 0) + groupByPaymentRequests[currentOwner].CheckedTotalPickerCost + groupByPaymentRequests[currentOwner].CheckedTotalIncentiveCost)}}
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </md-toolbar>
    <div v-if="groupByPaymentRequests[currentOwner]">
      <payment-request-table v-for="(byKey, key) in groupByPaymentRequests[currentOwner].Groups" :key="key" :group="byKey" :drivers="drivers" :cycle-dates="cycleDates" :group-title="key" :include-salary="includeSalary" @input-driver="drivers=$event" :salary-days="SalaryDays"></payment-request-table>
    </div>
    <progress max="100" style="width: 100%" v-show="isLoading || loadingDriver">60%</progress>
    <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{snackBarMsg}}</span>
    </md-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueScreenSize from 'vue-screen-size'
import moment from 'moment'
import { API } from 'aws-amplify'
import PaymentRequestTable from '../components/PaymentRequestTable'

export default {
  name: 'PaymentRequestList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {PaymentRequestTable},
  data () {
    let m = moment()
    let s = moment().subtract(30, 'days')
    return {
      currentMonth: moment().format('YYYYMM'),
      currentOwner: null,
      customerItems: [],
      configItems: [],
      drivers: {},
      diaryItems: [],
      paymentRequestItems: [],
      selectedCycles: [],
      driverField: {},
      isLoading: false,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      DailyPickerCost: 0,
      loadingDriver:  false,
      selectedPR: [],
      errors: {},
      SalaryDays: 25.0,
      form: {
        Cycle: [1],
        Month: m.month() + 1,
        Year: m.year(),
        StartDate: s.format('YYYY-MM-DD'),
        EndDate: m.format('YYYY-MM-DD')
      },
      includeSalary: false,
      selectedDriver: null,
      groupBy: 'Customer'
    }
  },
  methods: {
    fetchReceipt: function (invoiceNumber) {
      let vm = this
      if (invoiceNumber && !this.receipt[invoiceNumber]) {
        API.get('ezietruckapi', `/api/customerInvoices/${invoiceNumber}`).then((json) => {
          vm.receipt[invoiceNumber] = json
        })
      }
    },
    fetchFeild: function () {
      let vm = this
      Promise.all(this.currentCycle.map((c) => API.get('ezietruckapi', `/api/paymentRequests/field/${c}`))).then((results) => {
        vm.driverField = (results[results.length - 1].Driver || {})
        vm.loadingDriver = true
        vm.fetchDriver()
      })
    },
    fetchConfig: function () {
      let vm = this
      this.isLoading = true
      API.get('ezietruckapi', '/api/options/Config').then((json) => {
        vm.configItems = json
        let d = new Date()
        for (let i = 0; i < vm.cycleDates.length; i++) {
          if (d.getDate() <= vm.cycleDates[i]) {
            vm.form.Cycle = [i + 1]
            break
          }
        }
        let idx = vm.configItems.findIndex((c) => c.Name.startsWith('DailyPickerCost'))
        if (idx > -1) {
          vm.DailyPickerCost = parseFloat(vm.configItems[idx].Name.split(',')[1])
        }
        idx = vm.configItems.findIndex((c) => c.Name.startsWith('SalaryDays'))
        if (idx > -1) {
          vm.SalaryDays = parseFloat(vm.configItems[idx].Name.split(',')[1])
        }
        vm.handleSearchOrder()
        vm.fetchFeild()
      })
    },
    fetchDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.driverItems = []
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          for (let item of json['Items']) {
            if (item.SK.startsWith('#METADATA#')) {
              self.drivers[item.DriverID] = Object.assign({}, item, {
                DriverMonthlySalary: (item.DriverMonthlySalary || ((self.driverField[item.driverID] || {}).DriverMonthlySalary || 0)),
                DailyPickerCost: ((self.driverField[item.DriverID] || {}).DailyPickerCost || self.DailyPickerCost)
              })
            }
          }
          self.drivers = Object.assign({}, self.drivers)
        }
        if (json.LastEvaluatedKey) {
          self.fetchDriver(json.LastEvaluatedKey)
        } else {
          self.loadingDriver = false
          if (Object.keys(self.groupByPaymentRequests).length > 0) {
            self.currentOwner = Object.keys(self.groupByPaymentRequests)[0]
          }
        }
      })
    },
    handleSearchOrder: function () {
      this.isLoading = true
      this.paymentRequestItems = []
      let s = moment(this.form.StartDate)
      let e = moment(this.form.EndDate)
      let dates = []
      while (s <= e) {
        dates.push({date: s.format('YYYY-MM-DD')})
        s.add(1, 'days')
      }
      this.fetchPaymentRequest(dates)
    },
    fetchPaymentRequest: function (dates) {
      let vm = this
      if (dates.length > 0) {
        Promise.all(dates.map((d) => API.post('ezietruckapi', '/api/paymentRequests/date', {body: {
          OrderDate: d.date,
          LastEvaluatedKey: d.LastEvaluatedKey || null
        }}))).then((results) => {
          let nextBatch = []
          for (let json of results) {
            vm.paymentRequestItems = [...vm.paymentRequestItems, ...json['Items']]
            if (json['LastEvaluatedKey'] && json['Items'].length > 0) {
              nextBatch.push({date: json['Items'][0].OrderDate, LastEvaluatedKey: json['LastEvaluatedKey']})
            }
          }
          vm.fetchPaymentRequest(nextBatch)
        })
      } else {
        this.isLoading = false
      }
    },
    handleDeleteOrder: function (orderId) {
      let self = this
      this.allowClick = false
      API.del('ezietruckapi', `/api/orders/${orderId}`).then(() => {
        self.fetchCustomerOrder(self.currentCustomer.CustomerID, null)
        self.allowClick = true
        self.showSnackbar = true
        self.snackBarMsg = 'Successfully saved'
      }).catch((e) => {
        self.showSnackbar = true
        self.allowClick = true
        self.snackBarMsg = e.message
      })
    },
    handleDownloadAllCSV: function () {
      let ordercsv = 'CreatedAt,CreatedBy.DriverID,CreatedBy.DriverName,CreatedBy.DriverPhoneNumber,CustomerID,CustomerName,Cycle,DeliveryCost,DiaryRecords,Driver.DriverID,Driver.DriverName,Driver.DriverPhoneNumber,From,FuelCost,IncentiveCost,IncentivePhoto,InformDeliverNumbers,InformDistance,OrderDate,OrderOwner,PK,PickerPerTrip,PickerCost,SK,SalaryPerTrip,To,TollParkCost,TollParkPhoto,DriverBankAccountName,DriverBankAccountNumber';
      let orders = []
      for (let pr of this.paymentRequestItems) {
        if (pr.Driver && pr.CreatedBy) {
          orders.push(`${moment(pr.CreatedAt).format('YYYY-MM-DD')},${pr.CreatedBy.DriverID},${pr.CreatedBy.DriverName},${pr.CreatedBy.DriverPhoneNumber},${pr.CustomerID},${pr.CustomerName},${this.cycleToDate(pr.PK.split("#")[1])},${pr.DeliveryCost},${pr.DiaryRecords.length},${pr.Driver.DriverID},${pr.Driver.DriverName},${pr.Driver.DriverPhoneNumber},${pr.From.Description},${pr.FuelCost},${pr.IncentiveCost},${pr.IncentivePhoto.join(' ')},${pr.InformDeliverNumbers},${pr.InformDistance},${pr.OrderDate},${pr.OrderOwner},${pr.PK},${pr.PickerPerTrip},${pr.PickerPerTrip > 0 ? (pr.PickerPerTrip * ((this.drivers[pr.Driver.DriverID] || {}).DailyPickerCost || 0) / this.groupByPaymentRequests[pr.OrderOwner].Groups[this.groupBy === 'Customer' ? pr.CustomerID : pr.OrderDate].Creators[pr.Driver.DriverBankAccountNumber].Drivers[pr.Driver.DriverID].pickerCost[pr.OrderDate].trips) : 0},${pr.SK},${((this.drivers[pr.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays / this.groupByPaymentRequests[pr.OrderOwner].Groups[this.groupBy === 'Customer' ? pr.CustomerID : pr.OrderDate].Creators[pr.Driver.DriverBankAccountNumber].Drivers[pr.Driver.DriverID].salaryCost[pr.OrderDate]},${pr.To.Description},${pr.TollParkCost},${pr.TollParkPhoto.join(' ')},${pr.Driver.DriverBankAccountName},${pr.Driver.DriverBankAccountNumber}`)
        }
      }
      let ordercsvElement = document.createElement('a')
      const blob = new Blob(["\ufeff", [ordercsv, ...orders].join('\n')], {type : 'text/csv;charset=utf-8'})
      ordercsvElement.href = URL.createObjectURL(blob)
      ordercsvElement.target = '_blank'
      ordercsvElement.download = 'paymentRequests.csv'
      ordercsvElement.click()
    },
    onSelectPR: function (items) {
      this.selectedPR = items
      if (items.length > 0) {
        this.selectedDriver = items[0].CreatedBy.DriverID
      } else {
        this.selectedDriver = null
      }
    },
    handleConfirmPaymentRequest: function (value, key) {
      let vm = this
      API.post('ezietruckapi', '/api/paymentRequests/checked', {body: {Keys: key ? [{Key: key, Value: value}] : this.selectedPR.map((pr) => {
        return {Key: {PK: pr.PK, SK: pr.SK}, Value: value}
      })}}).then(() => {
        vm.showSnackbar = true
        vm.snackBarMsg = 'Successfully saved'
        vm.selectedDriver = null
        vm.handleSearchOrder()
      })
    },
    cycleToDate: function (cycle) {
      return cycle.slice(0, 8) + this.cycleDates[parseInt(cycle.slice(8)) - 1]
    },
    handleSaveCycle: function () {
      let vm = this
      let field = {}
      for (let d in this.drivers) {
        field[d] = {DriverMonthlySalary: (this.drivers[d].DriverMonthlySalary || 0), DailyPickerCost: (this.drivers[d].DailyPickerCost || vm.DailyPickerCost)}
      }
      for (let cycle of this.currentCycle) {
        API.post('ezietruckapi', `/api/paymentRequests/field/${cycle}`, {body: field}).then(() => {
          vm.showSnackbar = true
          vm.snackBarMsg = 'Successfully saved'
        })
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    currentCycle: function () {
      return this.form.Cycle.map((c) => moment([this.form.Year, this.form.Month - 1]).format('YYYY-MM-') + c)
    },
    cycleDates: function () {
      let idx = this.configItems.findIndex((c) => c.Name.startsWith('PaymentCycle'))
      if (idx > -1) {
        return this.configItems[idx].Name.split(',').slice(1).map((c) => parseInt(c))
      } else {
        return []
      }
    },
    paymentRequests: function () {
      let items = []
      let prMeta = {}
      for (let pr of this.paymentRequestItems.filter((pr) => pr.SK.startsWith('#METADATA#'))) {
        let id = pr.SK.split('#').slice(2,4).join('#')
        prMeta[id] = pr
      }
      for (let pr of this.paymentRequestItems.filter((pr) => pr.SK.startsWith('Trip#'))) {
        let id = pr.SK.split('#').slice(2,4).join('#')
        if (prMeta[id]) {
          items.push(Object.assign({}, prMeta[id], pr))
        }
      }
      return items
    },
    cycleDateOptions: function () {
      return Array.from(new Set(this.paymentRequestItems.map((pr) => this.cycleToDate(pr.PK.split("#")[1]))))
    },
    groupByPaymentRequests: function () {
      let out = {}
      let groupByKey = ''
      if (!this.isLoading && !this.loadingDriver) {
        for (let i of this.paymentRequestItems.filter((pr) => this.selectedCycles.length > 0 ? this.selectedCycles.includes(this.cycleToDate(pr.PK.split("#")[1])) : true)) {
          if (this.groupBy === 'Customer') {
            groupByKey = i.CustomerID
          } else if (this.groupBy === 'OrderDate')  {
            groupByKey = i.OrderDate
          }
          if (out[i.OrderOwner]) {
            if (out[i.OrderOwner].Groups) {
              if (out[i.OrderOwner].Groups[groupByKey]) {
                if (out[i.OrderOwner].Groups[groupByKey].Creators) {
                  if (out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber]) {
                    if (out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers) {
                      if (out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID]) {
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].items.push(i)
                        if (!out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].date.includes(i.OrderDate)) {
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].date.push(i.OrderDate)
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                          out[i.OrderOwner].Groups[groupByKey].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                          out[i.OrderOwner].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                        }
                        if (i.OwnerChecked && !out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].checkedDate.includes(i.OrderDate)) {
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].checkedDate.push(i.OrderDate)
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedTotalSalaryCost += (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalSalaryCost += (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0
                          out[i.OrderOwner].Groups[groupByKey].CheckedTotalSalaryCost += (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0
                          out[i.OrderOwner].CheckedTotalSalaryCost += (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0
                        }
                        if (i.PickerPerTrip > 0) {
                          if (out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].pickerCost[i.OrderDate]) {
                            out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].pickerCost[i.OrderDate].trips += 1
                            if (i.PickerPerTrip > out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].pickerCost[i.OrderDate].pickers) {
                              out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].TotalPickerCost += (i.PickerPerTrip - out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].pickerCost[i.OrderDate].pickers) * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                              out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalPickerCost += (i.PickerPerTrip - out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].pickerCost[i.OrderDate].pickers) * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                              out[i.OrderOwner].Groups[groupByKey].TotalPickerCost += (i.PickerPerTrip - out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].pickerCost[i.OrderDate].pickers) * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                              out[i.OrderOwner].TotalPickerCost += (i.PickerPerTrip - out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].pickerCost[i.OrderDate].pickers) * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                            }
                          } else {
                            out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].pickerCost[i.OrderDate] = {trips: 1, pickers: i.PickerPerTrip}
                            out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                            out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                            out[i.OrderOwner].Groups[groupByKey].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                            out[i.OrderOwner].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                          }
                        }

                        if (i.OwnerChecked && i.PickerPerTrip > 0) {
                          if (out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedPickerCost[i.OrderDate]) {
                            out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedPickerCost[i.OrderDate].trips += 1
                            if (i.PickerPerTrip > out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedPickerCost[i.OrderDate].pickers) {
                              out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedTotalPickerCost += (i.PickerPerTrip - out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedPickerCost[i.OrderDate].pickers) * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                              out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalPickerCost += (i.PickerPerTrip - out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedPickerCost[i.OrderDate].pickers) * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                              out[i.OrderOwner].Groups[groupByKey].CheckedTotalPickerCost += (i.PickerPerTrip - out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedPickerCost[i.OrderDate].pickers) * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                              out[i.OrderOwner].CheckedTotalPickerCost += (i.PickerPerTrip - out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedPickerCost[i.OrderDate].pickers) * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                            }
                          } else {
                            out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedPickerCost[i.OrderDate] = {trips: 1, pickers: i.PickerPerTrip}
                            out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedTotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                            out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                            out[i.OrderOwner].Groups[groupByKey].CheckedTotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                            out[i.OrderOwner].CheckedTotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                          }
                        }

                        if (out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].salaryCost[i.OrderDate]) {
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].salaryCost[i.OrderDate] += 1
                        } else {
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].salaryCost[i.OrderDate] = 1
                        }

                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].TotalDeliveryCost += i.DeliveryCost
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].TotalFuelCost += i.FuelCost
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].TotalTollParkCost += i.TollParkCost
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].TotalIncentiveCost += i.IncentiveCost

                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalDeliveryCost += i.DeliveryCost
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalFuelCost += i.FuelCost
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalTollParkCost += i.TollParkCost
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalIncentiveCost += i.IncentiveCost

                        out[i.OrderOwner].Groups[groupByKey].TotalDeliveryCost += i.DeliveryCost
                        out[i.OrderOwner].Groups[groupByKey].TotalFuelCost += i.FuelCost
                        out[i.OrderOwner].Groups[groupByKey].TotalTollParkCost += i.TollParkCost
                        out[i.OrderOwner].Groups[groupByKey].TotalIncentiveCost += i.IncentiveCost
                        out[i.OrderOwner].Groups[groupByKey].Trips += 1

                        out[i.OrderOwner].TotalDeliveryCost += i.DeliveryCost
                        out[i.OrderOwner].TotalFuelCost += i.FuelCost
                        out[i.OrderOwner].TotalTollParkCost += i.TollParkCost
                        out[i.OrderOwner].TotalIncentiveCost += i.IncentiveCost
                        out[i.OrderOwner].Trips += 1

                        if (i.OwnerChecked) {
                          if (out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].checkedSalaryCost[i.OrderDate]) {
                            out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].checkedSalaryCost[i.OrderDate] += 1
                          } else {
                            out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].checkedSalaryCost[i.OrderDate] = 1
                          }

                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedTotalDeliveryCost += i.DeliveryCost
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedTotalFuelCost += i.FuelCost
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedTotalTollParkCost += i.TollParkCost
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID].CheckedTotalIncentiveCost += i.IncentiveCost

                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalDeliveryCost += i.DeliveryCost
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalFuelCost += i.FuelCost
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalTollParkCost += i.TollParkCost
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalIncentiveCost += i.IncentiveCost

                          out[i.OrderOwner].Groups[groupByKey].CheckedTotalDeliveryCost += i.DeliveryCost
                          out[i.OrderOwner].Groups[groupByKey].CheckedTotalFuelCost += i.FuelCost
                          out[i.OrderOwner].Groups[groupByKey].CheckedTotalTollParkCost += i.TollParkCost
                          out[i.OrderOwner].Groups[groupByKey].CheckedTotalIncentiveCost += i.IncentiveCost
                          out[i.OrderOwner].Groups[groupByKey].Trips += 1

                          out[i.OrderOwner].CheckedTotalDeliveryCost += i.DeliveryCost
                          out[i.OrderOwner].CheckedTotalFuelCost += i.FuelCost
                          out[i.OrderOwner].CheckedTotalTollParkCost += i.TollParkCost
                          out[i.OrderOwner].CheckedTotalIncentiveCost += i.IncentiveCost
                          out[i.OrderOwner].Trips += 1
                        }
                      } else {
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].Drivers[i.Driver.DriverID] = {
                          items: [i],
                          date: [i.OrderDate],
                          TotalDeliveryCost: i.DeliveryCost,
                          TotalFuelCost: i.FuelCost,
                          TotalTollParkCost: i.TollParkCost,
                          TotalIncentiveCost: i.IncentiveCost,
                          TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                          TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                          pickerCost: Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}),
                          salaryCost: {
                            [i.OrderDate]: 1
                          },
                          checkedDate: i.OwnerChecked ? [i.OrderDate] : [],
                          CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                          CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                          CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                          CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                          CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                          CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                          CheckedPickerCost: i.OwnerChecked ? Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}) : {},
                          checkedSalaryCost: {
                            [i.OrderDate]: i.OwnerChecked ? 1 : 0
                          }
                        }

                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalDeliveryCost += i.DeliveryCost
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalFuelCost += i.FuelCost
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalTollParkCost += i.TollParkCost
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalIncentiveCost += i.IncentiveCost
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                        out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)


                        out[i.OrderOwner].Groups[groupByKey].TotalDeliveryCost += i.DeliveryCost
                        out[i.OrderOwner].Groups[groupByKey].TotalFuelCost += i.FuelCost
                        out[i.OrderOwner].Groups[groupByKey].TotalTollParkCost += i.TollParkCost
                        out[i.OrderOwner].Groups[groupByKey].TotalIncentiveCost += i.IncentiveCost
                        out[i.OrderOwner].Groups[groupByKey].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                        out[i.OrderOwner].Groups[groupByKey].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                        out[i.OrderOwner].Groups[groupByKey].Trips += 1

                        out[i.OrderOwner].TotalDeliveryCost += i.DeliveryCost
                        out[i.OrderOwner].TotalFuelCost += i.FuelCost
                        out[i.OrderOwner].TotalTollParkCost += i.TollParkCost
                        out[i.OrderOwner].TotalIncentiveCost += i.IncentiveCost
                        out[i.OrderOwner].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                        out[i.OrderOwner].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                        out[i.OrderOwner].Trips += 1

                        if (i.OwnerChecked) {
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalDeliveryCost += i.DeliveryCost
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalFuelCost += i.FuelCost
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalTollParkCost += i.TollParkCost
                          out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber].CheckedTotalIncentiveCost += i.IncentiveCost

                          out[i.OrderOwner].Groups[groupByKey].CheckedTotalDeliveryCost += i.DeliveryCost
                          out[i.OrderOwner].Groups[groupByKey].CheckedTotalFuelCost += i.FuelCost
                          out[i.OrderOwner].Groups[groupByKey].CheckedTotalTollParkCost += i.TollParkCost
                          out[i.OrderOwner].Groups[groupByKey].CheckedTotalIncentiveCost += i.IncentiveCost

                          out[i.OrderOwner].CheckedTotalDeliveryCost += i.DeliveryCost
                          out[i.OrderOwner].CheckedTotalFuelCost += i.FuelCost
                          out[i.OrderOwner].CheckedTotalTollParkCost += i.TollParkCost
                          out[i.OrderOwner].CheckedTotalIncentiveCost += i.IncentiveCost
                        }
                      }
                    } else {
                      out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber] = {
                        Drivers: {
                          [i.Driver.DriverID]: {
                            items: [i],
                            date: [i.OrderDate],
                            TotalDeliveryCost: i.DeliveryCost,
                            TotalFuelCost: i.FuelCost,
                            TotalTollParkCost: i.TollParkCost,
                            TotalIncentiveCost: i.IncentiveCost,
                            TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                            TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                            pickerCost: Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}),
                            salaryCost: {
                              [i.OrderDate]: 1
                            },
                            checkedDate: i.OwnerChecked ? [i.OrderDate] : [],
                            CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                            CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                            CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                            CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                            CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                            CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                            CheckedPickerCost: i.OwnerChecked ? Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}) : {},
                            checkedSalaryCost: {
                              [i.OrderDate]: i.OwnerChecked ? 1 : 0
                            }
                          }
                        },
                        TotalDeliveryCost: i.DeliveryCost,
                        TotalFuelCost: i.FuelCost,
                        TotalTollParkCost: i.TollParkCost,
                        TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                        TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                        TotalIncentiveCost: i.IncentiveCost,
                        CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                        CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                        CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                        CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                        CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                        CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0
                      }

                      out[i.OrderOwner].Groups[groupByKey].TotalDeliveryCost += i.DeliveryCost
                      out[i.OrderOwner].Groups[groupByKey].TotalFuelCost += i.FuelCost
                      out[i.OrderOwner].Groups[groupByKey].TotalTollParkCost += i.TollParkCost
                      out[i.OrderOwner].Groups[groupByKey].TotalIncentiveCost += i.IncentiveCost
                      out[i.OrderOwner].Groups[groupByKey].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                      out[i.OrderOwner].Groups[groupByKey].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                      out[i.OrderOwner].Groups[groupByKey].Trips += 1

                      out[i.OrderOwner].TotalDeliveryCost += i.DeliveryCost
                      out[i.OrderOwner].TotalFuelCost += i.FuelCost
                      out[i.OrderOwner].TotalTollParkCost += i.TollParkCost
                      out[i.OrderOwner].TotalIncentiveCost += i.IncentiveCost
                      out[i.OrderOwner].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                      out[i.OrderOwner].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                      out[i.OrderOwner].Trips += 1

                      if (i.OwnerChecked) {
                        out[i.OrderOwner].Groups[groupByKey].CheckedTotalDeliveryCost += i.DeliveryCost
                        out[i.OrderOwner].Groups[groupByKey].CheckedTotalFuelCost += i.FuelCost
                        out[i.OrderOwner].Groups[groupByKey].CheckedTotalTollParkCost += i.TollParkCost
                        out[i.OrderOwner].Groups[groupByKey].CheckedTotalIncentiveCost += i.IncentiveCost

                        out[i.OrderOwner].CheckedTotalDeliveryCost += i.DeliveryCost
                        out[i.OrderOwner].CheckedTotalFuelCost += i.FuelCost
                        out[i.OrderOwner].CheckedTotalTollParkCost += i.TollParkCost
                        out[i.OrderOwner].CheckedTotalIncentiveCost += i.IncentiveCost
                      }
                    }
                  } else {
                    out[i.OrderOwner].Groups[groupByKey].Creators[i.Driver.DriverBankAccountNumber] = {
                      Creator: i.CreatedBy,
                      Drivers: {
                        [i.Driver.DriverID]: {
                          items: [i],
                          date: [i.OrderDate],
                          TotalDeliveryCost: i.DeliveryCost,
                          TotalFuelCost: i.FuelCost,
                          TotalTollParkCost: i.TollParkCost,
                          TotalIncentiveCost: i.IncentiveCost,
                          TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                          TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                          pickerCost: Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}),
                          salaryCost: {
                            [i.OrderDate]: 1
                          },
                          checkedDate: i.OwnerChecked ? [i.OrderDate] : [],
                          CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                          CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                          CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                          CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                          CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                          CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                          CheckedPickerCost: i.OwnerChecked ? Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}) : {},
                          checkedSalaryCost: {
                            [i.OrderDate]: i.OwnerChecked ? 1 : 0
                          }
                        }
                      },
                      TotalDeliveryCost: i.DeliveryCost,
                      TotalFuelCost: i.FuelCost,
                      TotalTollParkCost: i.TollParkCost,
                      TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                      TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                      TotalIncentiveCost: i.IncentiveCost,
                      CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                      CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                      CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                      CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                      CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                      CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0
                    }

                    out[i.OrderOwner].Groups[groupByKey].TotalDeliveryCost += i.DeliveryCost
                    out[i.OrderOwner].Groups[groupByKey].TotalFuelCost += i.FuelCost
                    out[i.OrderOwner].Groups[groupByKey].TotalTollParkCost += i.TollParkCost
                    out[i.OrderOwner].Groups[groupByKey].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                    out[i.OrderOwner].Groups[groupByKey].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                    out[i.OrderOwner].Groups[groupByKey].Trips += 1

                    out[i.OrderOwner].TotalDeliveryCost += i.DeliveryCost
                    out[i.OrderOwner].TotalFuelCost += i.FuelCost
                    out[i.OrderOwner].TotalTollParkCost += i.TollParkCost
                    out[i.OrderOwner].TotalIncentiveCost += i.IncentiveCost
                    out[i.OrderOwner].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                    out[i.OrderOwner].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                    out[i.OrderOwner].Trips += 1

                    if (i.OwnerChecked) {
                      out[i.OrderOwner].Groups[groupByKey].CheckedTotalDeliveryCost += i.DeliveryCost
                      out[i.OrderOwner].Groups[groupByKey].CheckedTotalFuelCost += i.FuelCost
                      out[i.OrderOwner].Groups[groupByKey].CheckedTotalTollParkCost += i.TollParkCost
                      out[i.OrderOwner].Groups[groupByKey].CheckedTotalIncentiveCost += i.IncentiveCost

                      out[i.OrderOwner].CheckedTotalDeliveryCost += i.DeliveryCost
                      out[i.OrderOwner].CheckedTotalFuelCost += i.FuelCost
                      out[i.OrderOwner].CheckedTotalTollParkCost += i.TollParkCost
                      out[i.OrderOwner].CheckedTotalIncentiveCost += i.IncentiveCost
                    }
                  }
                } else {
                  out[i.OrderOwner].Groups[groupByKey] = {
                    Creators: {
                      [i.Driver.DriverBankAccountNumber]: {
                        Creator: i.CreatedBy,
                        Drivers: {
                          [i.Driver.DriverID]: {
                            items: [i],
                            date: [i.OrderDate],
                            TotalDeliveryCost: i.DeliveryCost,
                            TotalFuelCost: i.FuelCost,
                            TotalTollParkCost: i.TollParkCost,
                            TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                            TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                            TotalIncentiveCost: i.IncentiveCost,
                            pickerCost: Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}),
                            salaryCost: {
                              [i.OrderDate]: 1
                            },
                            checkedDate: i.OwnerChecked ? [i.OrderDate] : [],
                            CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                            CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                            CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                            CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                            CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                            CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                            CheckedPickerCost: i.OwnerChecked ? Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}) : {},
                            checkedSalaryCost: {
                              [i.OrderDate]: i.OwnerChecked ? 1 : 0
                            }
                          }
                        },
                        TotalDeliveryCost: i.DeliveryCost,
                        TotalFuelCost: i.FuelCost,
                        TotalTollParkCost: i.TollParkCost,
                        TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                        TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                        TotalIncentiveCost: i.IncentiveCost,
                        CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                        CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                        CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                        CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                        CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                        CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0
                      }
                    },
                    TotalDeliveryCost: i.DeliveryCost,
                    TotalFuelCost: i.FuelCost,
                    TotalTollParkCost: i.TollParkCost,
                    TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                    TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                    TotalIncentiveCost: i.IncentiveCost,
                    CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                    CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                    CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                    CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                    CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                    CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                    Trips: 1
                  }

                  out[i.OrderOwner].TotalDeliveryCost += i.DeliveryCost
                  out[i.OrderOwner].TotalFuelCost += i.FuelCost
                  out[i.OrderOwner].TotalTollParkCost += i.TollParkCost
                  out[i.OrderOwner].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                  out[i.OrderOwner].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                  out[i.OrderOwner].TotalIncentiveCost += i.IncentiveCost
                  out[i.OrderOwner].Trips += 1

                  if (i.OwnerChecked) {
                    out[i.OrderOwner].CheckedTotalDeliveryCost += i.DeliveryCost
                    out[i.OrderOwner].CheckedTotalFuelCost += i.FuelCost
                    out[i.OrderOwner].CheckedTotalTollParkCost += i.TollParkCost
                    out[i.OrderOwner].CheckedTotalIncentiveCost += i.IncentiveCost
                  }
                }
              } else {
                out[i.OrderOwner].Groups[groupByKey] = {
                  Creators: {
                    [i.Driver.DriverBankAccountNumber]: {
                      Creator: i.CreatedBy,
                      Drivers: {
                        [i.Driver.DriverID]: {
                          items: [i],
                          date: [i.OrderDate],
                          TotalDeliveryCost: i.DeliveryCost,
                          TotalFuelCost: i.FuelCost,
                          TotalTollParkCost: i.TollParkCost,
                          TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                          TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                          TotalIncentiveCost: i.IncentiveCost,
                          pickerCost: Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}),
                          salaryCost: {
                            [i.OrderDate]: 1
                          },
                          checkedDate: i.OwnerChecked ? [i.OrderDate] : [],
                          CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                          CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                          CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                          CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                          CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                          CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                          CheckedPickerCost: i.OwnerChecked ? Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}) : {},
                          checkedSalaryCost: {
                            [i.OrderDate]: i.OwnerChecked ? 1 : 0
                          }
                        }
                      },
                      TotalDeliveryCost: i.DeliveryCost,
                      TotalFuelCost: i.FuelCost,
                      TotalTollParkCost: i.TollParkCost,
                      TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                      TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                      TotalIncentiveCost: i.IncentiveCost,
                      CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                      CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                      CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                      CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                      CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                      CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0
                    }
                  },
                  TotalDeliveryCost: i.DeliveryCost,
                  TotalFuelCost: i.FuelCost,
                  TotalTollParkCost: i.TollParkCost,
                  TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                  TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                  TotalIncentiveCost: i.IncentiveCost,
                  CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                  CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                  CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                  CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                  CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                  CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                  Trips: 1
                }

                out[i.OrderOwner].TotalDeliveryCost += i.DeliveryCost
                out[i.OrderOwner].TotalFuelCost += i.FuelCost
                out[i.OrderOwner].TotalTollParkCost += i.TollParkCost
                out[i.OrderOwner].TotalSalaryCost += ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays
                out[i.OrderOwner].TotalPickerCost += i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0)
                out[i.OrderOwner].TotalIncentiveCost += i.IncentiveCost
                out[i.OrderOwner].Trips += 1

                if (i.OwnerChecked) {
                  out[i.OrderOwner].CheckedTotalDeliveryCost += i.DeliveryCost
                  out[i.OrderOwner].CheckedTotalFuelCost += i.FuelCost
                  out[i.OrderOwner].CheckedTotalTollParkCost += i.TollParkCost
                  out[i.OrderOwner].CheckedTotalIncentiveCost += i.IncentiveCost
                }
              }
            } else {
              out[i.OrderOwner] = {
                Groups: {
                  [groupByKey]: {
                    Creators: {
                      [i.Driver.DriverBankAccountNumber]: {
                        Creator: i.CreatedBy,
                        Drivers: {
                          [(i.Driver || {}).DriverID]: {
                            items: [i],
                            date: [i.OrderDate],
                            TotalDeliveryCost: i.DeliveryCost,
                            TotalFuelCost: i.FuelCost,
                            TotalTollParkCost: i.TollParkCost,
                            TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                            TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                            TotalIncentiveCost: i.IncentiveCost,
                            pickerCost: Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}),
                            salaryCost: {
                              [i.OrderDate]: 1
                            },
                            checkedDate: i.OwnerChecked ? [i.OrderDate] : [],
                            CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                            CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                            CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                            CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                            CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                            CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                            CheckedPickerCost: i.OwnerChecked ? Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}) : {},
                            checkedSalaryCost: {
                              [i.OrderDate]: i.OwnerChecked ? 1 : 0
                            }
                          }
                        },
                        TotalDeliveryCost: i.DeliveryCost,
                        TotalFuelCost: i.FuelCost,
                        TotalTollParkCost: i.TollParkCost,
                        TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                        TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                        TotalIncentiveCost: i.IncentiveCost,
                        CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                        CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                        CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                        CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                        CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                        CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0
                      },
                    },
                    TotalDeliveryCost: i.DeliveryCost,
                    TotalFuelCost: i.FuelCost,
                    TotalTollParkCost: i.TollParkCost,
                    TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                    TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                    TotalIncentiveCost: i.IncentiveCost,
                    CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                    CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                    CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                    CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                    CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                    CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                    Trips: 1
                  }
                },
                TotalDeliveryCost: i.DeliveryCost,
                TotalFuelCost: i.FuelCost,
                TotalTollParkCost: i.TollParkCost,
                TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                TotalIncentiveCost: i.IncentiveCost,
                CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                Trips: 1
              }
            }
          } else {
            out[i.OrderOwner] = {
              Groups: {
                [groupByKey]: {
                  Creators: {
                    [i.Driver.DriverBankAccountNumber]: {
                      Creator: i.CreatedBy,
                      Drivers: {
                        [i.Driver.DriverID]: {
                          items: [i],
                          date: [i.OrderDate],
                          TotalDeliveryCost: i.DeliveryCost,
                          TotalFuelCost: i.FuelCost,
                          TotalTollParkCost: i.TollParkCost,
                          TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                          TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                          TotalIncentiveCost: i.IncentiveCost,
                          pickerCost: Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}),
                          salaryCost: {
                            [i.OrderDate]: 1
                          },
                          checkedDate: i.OwnerChecked ? [i.OrderDate] : [],
                          CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                          CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                          CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                          CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                          CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                          CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                          CheckedPickerCost: i.OwnerChecked ? Object.assign({}, i.PickerPerTrip > 0 ? {[i.OrderDate]: {trips: 1, pickers: i.PickerPerTrip}} : {}) : {},
                          checkedSalaryCost: {
                            [i.OrderDate]: i.OwnerChecked ? 1 : 0
                          }
                        }
                      },
                      TotalDeliveryCost: i.DeliveryCost,
                      TotalFuelCost: i.FuelCost,
                      TotalTollParkCost: i.TollParkCost,
                      TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                      TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                      TotalIncentiveCost: i.IncentiveCost,
                      CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                      CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                      CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                      CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                      CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                      CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0
                    },
                  },
                  TotalDeliveryCost: i.DeliveryCost,
                  TotalFuelCost: i.FuelCost,
                  TotalTollParkCost: i.TollParkCost,
                  TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
                  TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
                  TotalIncentiveCost: i.IncentiveCost,
                  CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
                  CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
                  CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
                  CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
                  CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
                  CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
                  Trips: 1
                }
              },
              TotalDeliveryCost: i.DeliveryCost,
              TotalFuelCost: i.FuelCost,
              TotalTollParkCost: i.TollParkCost,
              TotalSalaryCost: ((this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0) / this.SalaryDays,
              TotalPickerCost: i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0),
              TotalIncentiveCost: i.IncentiveCost,
              CheckedTotalDeliveryCost: i.OwnerChecked ? i.DeliveryCost : 0,
              CheckedTotalFuelCost: i.OwnerChecked ? i.FuelCost : 0,
              CheckedTotalTollParkCost: i.OwnerChecked ? i.TollParkCost : 0,
              CheckedTotalSalaryCost: i.OwnerChecked ? (this.drivers[i.Driver.DriverID] || {}).DriverMonthlySalary || 0 : 0,
              CheckedTotalPickerCost: i.OwnerChecked ? i.PickerPerTrip * ((this.drivers[i.Driver.DriverID] || {}).DailyPickerCost || 0) : 0,
              CheckedTotalIncentiveCost: i.OwnerChecked ? i.IncentiveCost : 0,
              Trips: 1
            }
          }
        }
      }
      return out
    }
  },
  created () {
    this.fetchConfig()
  }
}
</script>