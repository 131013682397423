<template>
  <div>
    <md-toolbar class="md-dense">
       <div class="md-toolbar-section-start">
        <form @submit.prevent="handleSearchPD" class="md-toolbar-row" >
          <div class="md-layout">
            <md-field class="md-layout-item">
              <label for="movie">Month</label>
              <md-select v-model="month">
                <md-option v-for="m in months" :key="m" :value="m">{{m}}</md-option>
              </md-select>
            </md-field>
            <md-field class="md-layout-item">
              <label>Year</label>
              <md-input v-model="year"></md-input>
            </md-field>
            <div class="md-layout-item">
              <md-button type="submit" class="md-raised md-primary" :disabled="!!LastEvaluatedKey">
                <span>Search</span>
              </md-button>
            </div>
          </div>
        </form>
      </div>
      <div class="md-toolbar-section-end">
        <span class="md-title">
          Items: {{sortedPaidDriver.length}} Amount: {{$currencyFormater(pdSum)}}
        </span>
      </div>
    </md-toolbar>
    <pv-item-table v-for="payment in sortedPaidDriver" :payment="payment" :key="payment.title" @deletepd="handleSearchPD" @deletepv="handleSearchPD"></pv-item-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { API } from 'aws-amplify'
import PVItemTable from '../components/PVItemTable.vue'

export default {
  name: 'PDNumberList',
  components: {'pv-item-table': PVItemTable},
  data () {
    return {
      paidDriverItems: [],
      LastEvaluatedKey: null,
      currentPV: `PV${moment().format('YYYYMM')}`,
      months: [...Array(12).keys()].map((a) => moment(`${a+1}`, 'M').format('MMMM')),
      year: moment().format('YYYY'),
      month: moment().format('MMMM')
    }
  },
  methods: {
    fetchData: function (lastEvaluatedKey) {
      let self = this
      let body = {'PaymentVoucher': this.currentPV}
      if (lastEvaluatedKey) {
        body['LastEvaluatedKey'] = lastEvaluatedKey
      }
      API.post('ezietruckapi', `/api/paymentVouchers/q`, {body: body}).then((json) => {
        if (json) {
          self.paidDriverItems = [...self.paidDriverItems, ...json['Items'].filter((i) => i.PK.startsWith('PAIDDRIVER'))]
          if (json.LastEvaluatedKey) {
            self.LastEvaluatedKey = json.LastEvaluatedKey
            self.fetchData(json.LastEvaluatedKey)
          } else {
            self.LastEvaluatedKey = null
          }
        }
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY")
      } else {
        return ''
      }
    },
    handleSearchPD: function () {
      this.currentPV = `PV${this.year}${moment(this.month, 'MMMM').format('MM')}`
      this.paidDriverItems = []
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    sortedPaidDriver: function () {
      let paidDrivers = {}
      if (!this.LastEvaluatedKey) {
        for (let pd of [...this.paidDriverItems].sort((a, b) => b.SK.localeCompare(a.SK))) {
          if (paidDrivers[pd.PaymentVoucher]) {
            paidDrivers[pd.PaymentVoucher].items.push(pd)
            paidDrivers[pd.PaymentVoucher].sum = (paidDrivers[pd.PaymentVoucher].sum*1000 + pd.TotalCostAmount*1000)/1000
          } else {
            paidDrivers[pd.PaymentVoucher] = {
              title: pd.PaymentVoucher ,
              pv: pd.PaymentVoucher ,
              items: [pd],
              sum: pd.TotalCostAmount
            }
          }
        }
      }
      for (let pd in paidDrivers) {
        paidDrivers[pd].title += ' ' + [...(new Set(paidDrivers[pd].items.map(i => i.DriverName)))].join(' ') + ' / '
        paidDrivers[pd].title += ' ' + [...(new Set(paidDrivers[pd].items.map(i => i.PaymentBankAccountName)))].join(' ')
      }
      return [...Object.values(paidDrivers)].sort((a, b) => a.title.localeCompare(b.title))
    },
    pdSum: function () {
      if (!this.LastEvaluatedKey) {
        return this.paidDriverItems.reduce((accumulator, currentValue) => (accumulator*1000 + currentValue.TotalCostAmount*1000)/1000, 0)
      } else {
        return 0
      }
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
