<template>
  <div>
    <md-snackbar class="md-error" md-position="center" :md-active.sync="showSnackbar" md-persistent :md-duration="snackBarDuration">
      <span>{{snackBarMsg}}</span>
      <md-button class="md-primary" @click="showSnackbar = false"><md-icon>close</md-icon></md-button>
    </md-snackbar>
    <md-tabs class="md-accent" md-alignment="centered">
      <md-tab id="tab-staff" md-label="Staff" :md-active-tab="!formData.isDriver" @click="formData.isDriver=false">
        <md-button class="md-raised md-primary">
          <g-signin-button
            :params="googleSignInParams"
            @success="onSignInSuccess"
            @error="onSignInError">
            Sign in with Google
          </g-signin-button>
        </md-button>
      </md-tab>
      <md-tab id="tab-driver" md-label="ผู้ขนส่ง" :md-active-tab="formData.isDriver" @click="formData.isDriver=true">
        <form @submit.prevent="signIn">
          <md-field :class="{'md-invalid': $checkNested(errors, 'username')}">
            <label>ชื่อ</label>
            <md-input v-model="formData.username"></md-input>
          </md-field>
          <md-field :class="{'md-invalid': $checkNested(errors, 'password')}">
            <label>เลขที่ใบขับขี่</label>
            <md-input v-model="formData.password"></md-input>
          </md-field>
          <md-button type="submit"  class="md-raised md-primary">Log in</md-button>
        </form>
      </md-tab>
    </md-tabs>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'SignIn',
  data () {
    return {
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      formData: {
        username: null,
        password: null,
        isDriver: false
      },
      googleSignInParams: {
        client_id: '970999475763-0jd8ah3utc5fa1vccu5v6jv64kr57g3u.apps.googleusercontent.com'
      },
      errors: {}
    }
  },
  methods: {
    ...mapMutations(['loginSuccess', 'setProfile']),
    onSignInSuccess (googleUser) {
      this.setProfile(googleUser.getBasicProfile())
      this.loginSuccess(googleUser.getAuthResponse().id_token)
      this.$router.push(this.$route.query.next || '/')
    },
    onSignInError (error) {
      self.showSnackbar = true
      self.snackBarMsg = error
    }
  }
}
</script>
