<template>
  <div>
    <md-toolbar class="md-accent" md-elevation="1">
      <h3 class="md-title" style="flex: 6">{{groupTitle}}</h3>
      <span style="margin-right: 2pt">
        Trip: {{$currencyFormater(group.Trips)}} 
      </span>
      <span style="margin-right: 2pt" v-show="group.TotalDeliveryCost + group.TotalFuelCost + group.TotalTollParkCost + (includeSalary ? group.TotalSalaryCost : 0 + group.TotalPickerCost) + group.TotalIncentiveCost > 0">
        Total Cost: {{$currencyFormater(group.TotalDeliveryCost + group.TotalFuelCost + group.TotalTollParkCost + (includeSalary ? group.TotalSalaryCost : 0) + group.TotalPickerCost + group.TotalIncentiveCost)}}
      </span>
      <span style="margin-right: 2pt" v-show="group.CheckedTotalDeliveryCost + group.CheckedTotalFuelCost + group.CheckedTotalTollParkCost + (includeSalary ? group.CheckedTotalSalaryCost : 0) + group.CheckedTotalPickerCost + group.CheckedTotalIncentiveCost > 0">
        Checked Total Cost: {{$currencyFormater(group.CheckedTotalDeliveryCost + group.CheckedTotalFuelCost + group.CheckedTotalTollParkCost + (includeSalary ? group.CheckedTotalSalaryCost : 0) + group.CheckedTotalPickerCost + group.CheckedTotalIncentiveCost)}}
      </span>
    </md-toolbar>
    <div v-for="(byCreator, creator) in group.Creators" :key="creator">
      <md-toolbar class="md-primary" md-elevation="4">
        <h3 class="md-title" style="flex: 1">
          <md-button class="md-icon-button" @click="handleToggle(creator)" v-show="!isShow[creator]">
            <md-icon>unfold_more</md-icon>
          </md-button>
          <md-button class="md-icon-button" @click="handleToggle(creator)" v-show="isShow[creator]">
            <md-icon>unfold_less</md-icon>
          </md-button>
          {{(byCreator.Creator || {}).DriverName}} / {{(byCreator.Creator || {}).DriverBankAccountName}} ({{creator}})
        </h3>
        <span style="margin-right: 2pt" v-show="byCreator.TotalDeliveryCost + byCreator.TotalFuelCost + byCreator.TotalTollParkCost + (includeSalary ? byCreator.TotalSalaryCost : 0) + byCreator.TotalPickerCost + byCreator.TotalIncentiveCost > 0">
          Total Cost: {{$currencyFormater(byCreator.TotalDeliveryCost + byCreator.TotalFuelCost + byCreator.TotalTollParkCost + (includeSalary ? byCreator.TotalSalaryCost : 0) + byCreator.TotalPickerCost + byCreator.TotalIncentiveCost)}}
        </span>
        <span v-show="byCreator.CheckedTotalDeliveryCost + byCreator.CheckedTotalFuelCost + byCreator.CheckedTotalTollParkCost + (includeSalary ? byCreator.CheckedTotalSalaryCost : 0) + byCreator.CheckedTotalPickerCost + byCreator.CheckedTotalIncentiveCost > 0">
          Checked Total Cost: {{$currencyFormater(byCreator.CheckedTotalDeliveryCost + byCreator.CheckedTotalFuelCost + byCreator.CheckedTotalTollParkCost + (includeSalary ? byCreator.CheckedTotalSalaryCost : 0) + byCreator.CheckedTotalPickerCost + byCreator.CheckedTotalIncentiveCost)}}
        </span>
      </md-toolbar>
      <md-table v-for="(byDriver, driverID) in filterByCreator(creator)" v-model="byDriver.items" :key="driverID" @md-selected="onSelectPR" md-sort="OrderDate" md-sort-order="asc">
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <h1 class="md-title">{{((drivers[driverID] || {}).DriverName || driverID)}}</h1>
          </div>
          <div class="md-toolbar-section-end">
            <div class="md-toolbar-row md-layout">
              <div class="md-layout-item">
                Trips: {{byDriver.items.length}} Dates: {{byDriver.date.length}}
                <span v-show="byDriver.TotalDeliveryCost + byDriver.TotalFuelCost + byDriver.TotalTollParkCost + (includeSalary ? byDriver.TotalSalaryCost : 0) + byDriver.TotalPickerCost + byDriver.TotalIncentiveCost > 0">
                  Total Cost: {{$currencyFormater(byDriver.TotalDeliveryCost + byDriver.TotalFuelCost + byDriver.TotalTollParkCost + (includeSalary ? byDriver.TotalSalaryCost : 0) + byDriver.TotalPickerCost + byDriver.TotalIncentiveCost)}}
                </span>
                <span v-show="byDriver.CheckedTotalDeliveryCost + byDriver.CheckedTotalFuelCost + byDriver.CheckedTotalTollParkCost + (includeSalary ? byDriver.CheckedTotalSalaryCost : 0) + byDriver.CheckedTotalPickerCost + byDriver.CheckedTotalIncentiveCost > 0">
                  Checked Total Cost: {{$currencyFormater(byDriver.CheckedTotalDeliveryCost + byDriver.CheckedTotalFuelCost + byDriver.CheckedTotalTollParkCost + (includeSalary ? byDriver.CheckedTotalSalaryCost : 0) + byDriver.CheckedTotalPickerCost + byDriver.CheckedTotalIncentiveCost)}}
                </span>
              </div>
              <div class="md-layout-item" v-if="drivers[driverID]">
                <md-field>
                  <label for="movie">Daily Picker Cost</label>
                  <md-input type="number" :value="(drivers[driverID] || {}).DailyPickerCost" @blur="handleDriverInput(driverID, 'DailyPickerCost', parseFloat($event.target.value))" name="DailyPickerCost" size="4"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item" v-if="drivers[driverID]">
                <md-field>
                  <label for="movie">Driver Monthly Salary</label>
                  <md-input type="number" :value="(drivers[driverID] || {}).DriverMonthlySalary" @blur="handleDriverInput(driverID, 'DriverMonthlySalary', parseFloat($event.target.value))" name="DriverMonthlySalary"  size="4"></md-input>
                </md-field>
              </div>
            </div>
          </div>
        </md-table-toolbar>
        <md-table-toolbar slot="md-table-alternate-header" slot-scope="{}">
        <div class="md-toolbar-section-end">
          <md-button @click="handleConfirmPaymentRequest(true)">
            Confirm
          </md-button>
        </div>
      </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select :md-disabled="item.OrderOwner !== user.attributes.email || item.OwnerChecked">
          <md-table-cell md-label="Cycle"  md-sort-by="PK">{{cycleToDate(item.PK.split("#")[1])}}</md-table-cell>
          <md-table-cell md-label="Trip Date" md-sort-by="OrderDate">{{item.OrderDate}}</md-table-cell>
          <md-table-cell md-label="Customer" md-sort-by="CustomerName">{{item.CustomerName}}</md-table-cell>
          <md-table-cell md-label="From - To">{{item.From.Description}} - {{item.To.Description}}</md-table-cell>
          <md-table-cell md-label="Delivery Cost">{{item.DeliveryCost}}</md-table-cell>
          <md-table-cell md-label="Fuel Cost">{{item.FuelCost}}</md-table-cell>
          <md-table-cell md-label="Inform Distance">{{item.InformDistance}}</md-table-cell>
          <md-table-cell md-label="Picker Per Trip">
            {{item.PickerPerTrip}}
            <span v-if="item.PickerPerTrip > 0">({{$currencyFormater(item.PickerPerTrip * (((drivers[driverID] || {}).DailyPickerCost || 0)/ byDriver.pickerCost[item.OrderDate].trips))}})</span>
          </md-table-cell>
          <md-table-cell md-label="Toll/Park Cost">
            {{$currencyFormater(item.TollParkCost)}}
            <md-button class="md-icon-button" v-show="item.TollParkCost > 0" @click="openPhotoDialog(item.TollParkPhoto || [])">
              <md-icon>photo</md-icon>
            </md-button>
          </md-table-cell>
          <md-table-cell md-label="Inform Deliver">
            {{item.InformDeliverNumbers}}
             <span v-show="item.InformDeliverNumbers > 0">({{$currencyFormater(item.IncentiveCost)}})</span>
            <md-button class="md-icon-button" v-show="item.InformDeliverNumbers > 0" @click="openPhotoDialog(item.IncentivePhoto || [])">
              <md-icon>photo</md-icon>
            </md-button>
          </md-table-cell>
          <md-table-cell md-label="Salary Per Trip">
            <span v-if="includeSalary">{{$currencyFormater(((drivers[driverID] || {}).DriverMonthlySalary || 0) / SalaryDays / byDriver.salaryCost[item.OrderDate])}}</span>
          </md-table-cell>
          <md-table-cell md-label="Action">
            <md-button @click="fetchDiary(item.DiaryRecords)">View Diary</md-button>
            <md-button v-show="item.OrderOwner === user.attributes.email && item.OwnerChecked === true" @click="handleConfirmPaymentRequest(false, {PK: item.PK, SK: item.SK})">
              Uncheck
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Diary</md-dialog-title>
      <md-table v-model="diaryItems" class="md-primary">
        <md-table-row slot="md-table-row" slot-scope="{ item }" >
          <md-table-cell md-label="Created At">{{dateTimeFormatter(item.CreatedAt)}}</md-table-cell>
          <md-table-cell md-label="Driver">
            <router-link :to="'/Drivers/' + item.Driver.DriverID">{{ item.Driver.DriverName }} ({{ item.Driver.DriverPhoneNumber }})</router-link>
          </md-table-cell>
          <md-table-cell md-label="Vehicle">
            {{ item.Vehicle ? item.Vehicle.VehicleLicenseNumber : "" }} {{ item.Vehicle ? item.Vehicle.VehicleLicenseProvince : "" }} -
            {{ item.Vehicle ? item.Vehicle.VehicleTruckType : "" }} {{ item.Vehicle ? item.Vehicle.VehicleContainerType : "" }}
          </md-table-cell>
          <md-table-cell md-label="Customer">{{ item.CustomerID }}</md-table-cell>
          <md-table-cell md-label="OrderID"><router-link :to="'/Orders/' + item.OrderID">{{ item.OrderID }}</router-link></md-table-cell>
          <md-table-cell md-label="Stop for">{{ item.StopFor }}</md-table-cell>
          <md-table-cell md-label="Location"><a @click="mapFocusAt(item.Location)">{{ item.Area }}</a></md-table-cell>
          <md-table-cell md-label="Snapshot">
            <md-button class="md-icon-button" @click="openPhotoDialog(item.Photo)">
              <md-icon>image</md-icon>
            </md-button>
          </md-table-cell>
          <md-table-cell md-label="Album">
            <md-button class="md-icon-button" @click="openPhotoDialog(item.PhotoAlbum || [])" v-show="(item.PhotoAlbum || []).length > 0">
              <md-icon>image</md-icon>
            </md-button>
          </md-table-cell>
          <md-table-cell md-label="Note">{{ item.Note }}</md-table-cell>
          <md-table-cell md-label="Pick Up">{{ item.PickUpItemNo }}</md-table-cell>
          <md-table-cell md-label="Drop Off">{{ item.DropOffItemNo }}</md-table-cell>
          <md-table-cell md-label="Distance (km)">{{item.Distance}}</md-table-cell>
        </md-table-row>
      </md-table>
    </md-dialog>
    <md-dialog :md-active.sync="showMapDialog">
      <md-dialog-title>Location</md-dialog-title>
      <LastLocationMap :truck-lat-lng="mapOption.markerLatLng" :style="{'height': ($vssHeight - 20) + 'px', 'width': ($vssWidth *0.3) + 'px'}"/>
    </md-dialog>
    <md-dialog :md-active.sync="showPhotoDialog">
      <md-dialog-title>Photo</md-dialog-title>
      <div style="overflow: auto">
        <md-content v-for="photo in currentPhoto" :key="photo">
          <my-s3-image :imagePath="photo"></my-s3-image>
        </md-content>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { latLng } from "leaflet"
import LastLocationMap from '../components/LastLocationMap'
import MyS3Image from '../components/MyS3Image'
import VueScreenSize from 'vue-screen-size'
import moment from 'moment'
import { API } from 'aws-amplify'

export default {
  name: 'PaymentRequestTable',
  props: {
    group: Object,
    drivers: Object,
    groupTitle: String,
    cycleDates: Array,
    includeSalary: Boolean,
    SalaryDays: Number
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image, LastLocationMap},
  data () {
    return {
      selectedPR: [],
      isShow: {},
      diaryItems: [],
      mapOption: {
        zoom: 13,
        center: latLng(13.75318, 100.53173),
        markerLatLng: null,
        options: {
          zoomSnap: 0.5
        },
        showMap: true
      },
      showMapDialog: false,
      showDialog: false,
      showPhotoDialog: false,
      currentPhoto: [],
    }
  },
  methods: {
    cycleToDate: function (cycle) {
      return cycle.slice(0, 8) + this.cycleDates[parseInt(cycle.slice(8)) - 1]
    },
    onSelectPR: function (items) {
      this.selectedPR = items
    },
    handleDriverInput: function (driverID, name, event) {
      console.log(event)
      this.$emit('input-driver', Object.assign({}, this.drivers, {
        [driverID]: Object.assign({}, this.drivers[driverID], {
          [name]: event
        })
      }))
    },
    handleToggle: function (creator) {
      if (!this.isShow[creator]) {
        this.isShow[creator] = true
      } else {
        this.isShow[creator] = false
      }
      this.isShow = Object.assign({}, this.isShow)
    },
    filterByCreator: function (creator) {
      if (this.isShow[creator]) {
        return this.group.Creators[creator].Drivers
      } else {
        return []
      }
    },
    fetchDiary: function (diaryKeys) {
      let vm = this
      this.diaryItems = []
      API.post('ezietruckapi', `/api/paymentRequests/diaries`, {body: {Keys: diaryKeys}}).then((json) => {
        vm.diaryItems = json
        vm.showDialog = true
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("DD MMM YYYY hh:mm")
      } else {
        return ''
      }
    },
    mapFocusAt: function (gps) {
      this.showMapDialog = true
      this.mapOption = Object.assign({}, this.mapOption, {
        center: latLng(gps.Lat, gps.Lon),
        markerLatLng: latLng(gps.Lat, gps.Lon),
      })
    },
    openPhotoDialog: function (photo) {
      this.showPhotoDialog = true
      this.currentPhoto = photo
    },
  },
  computed: {
    ...mapGetters(['user']),
  }
}
</script>
