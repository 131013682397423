import Vue from 'vue'
import Vuex from 'vuex'
import findIndex from 'lodash/findIndex'
import api from '../api'

Vue.use(Vuex)

const checkAuthLocalstorage = function () {
  if (localStorage.getItem('token')) {
    return { isLogin: true, token: localStorage.getItem('token') }
  } else {
    return { isLogin: false }
  }
}

const checkProfileLocalstorage = function () {
  if (localStorage.getItem('profile')) {
    return window.JSON.parse(localStorage.getItem('profile'))
  } else {
    return {}
  }
}

const state = {
  auth: checkAuthLocalstorage(),
  user: null,
  trucks: {},
  orders: [],
  isLoading: true,
  profile: checkProfileLocalstorage(),
  notifications: [],
  fcm_token: null,
  history: [],
}

const mutations = {
  loginSuccess (state, token) {
    localStorage.setItem('token', token)
    state.auth = { isLogin: true, token }
  },
  REFRESH_TOKEN (state, token) {
    state.auth.token = token
  },
  setUser (state, user) {
    state.user = user
  },
  setProfile (state, profile) {
    localStorage.setItem('profile', window.JSON.stringify(profile))
    state.profile = profile
  },
  SHOW_LOADING (state) {
    state.isLoading = true
  },
  SIGNOUT_SUCCESS (state) {
    state.auth = { isLogin: false }
    state.profile = {}
    state.orders = []
    localStorage.removeItem('token')
    localStorage.removeItem('profile')
    state.route = { path: '/', query: {}, params: {} }
  },
  ADD_NOTIFICATION (state, noti) {
    state.notifications.push(noti)
  },
  REMOVE_NOTIFICATION (state) {
    state.notifications = []
  },
  SELECT_ORDER_ID (state, id) {
    state.route = Object.assign(state.route, {params: {orderId: id}, path: '/OrderTracking/' + id})
  },
  REDIRECT (state, route) {
    state.route = Object.assign(state.route, route)
  },
  UPDATE_FCM_TOKEN (state, token) {
    state.fcm_token = token
  },
  UPDATE_ORDER_BY_ID (state, id, newOrder) {
    let orderIndex = findIndex(state.orders, (o) => o.id === id)
    if (orderIndex < 0) {
      state.orders = [...state.orders, newOrder]
    } else {
      state.orders = [...state.orders.slice(0, orderIndex), newOrder, ...state.orders.slice(orderIndex + 1)]
    }
  },
  PUSH_HISTORY (state, path) {
    if (state.history.length  === 2) {
      state.history = [...state.history.slice(1), path]
    } else {
      state.history = [...state.history, path]
    }
  }
}

// const authMiddleware = {
//   onMutation (mutation, state, store) {
//     if (['REFRESH_TOKEN', 'SIGNOUT_SUCCESS', 'LOGIN_SUCCESS'].indexOf(mutation.type) === -1) {
//       refreshToken(store)
//     }
//   }
// }

export default new Vuex.Store({
  getters: {
    isLoggedIn: state => {
      return state.auth.isLogin
    },
    token: state => {
      return state.auth.token
    },
    user: state => {
      return state.user
    },
    history: state => state.history
  },
  actions: {
    signIn (context, formData) {
      api.post('/auth/', formData).then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            api.get('/api/me/', json.token).then((response) => {
              response.json().then((profile) => {
                context.commit('LOGIN_SUCCESS', json.token)
                context.commit('SET_PROFILE', profile)
              })
            })
          })
        } else {
          context.commit('LOGIN_FAIL')
          context.commit('ADD_NOTIFICATION', {
            text: Vue.t('message.loginFail'),
            type: 'uk-alert-danger'
          })
        }
      })
    }
  },
  mutations,
  state
  // middlewares: [authMiddleware]
})
