<template>
  <div>
    <md-button class="md-fab md-plain" to="/NewCustomer" :style="{
      'z-index': '100',
      position: 'fixed',
      left:($vssWidth-100) + 'px', 
      top: ($vssHeight-130) + 'px'}">
      <md-icon>add</md-icon>
    </md-button>
    <md-table>
      <md-table-row>
        <md-table-head>Logo</md-table-head>
        <md-table-head>ID</md-table-head>
        <md-table-head>Name</md-table-head>
        <md-table-head>Address</md-table-head>
        <md-table-head>Contact</md-table-head>
        <md-table-head>Operation</md-table-head>
      </md-table-row>
      <md-table-row v-for="customer in customers" :key="customer.CustomerID">
        <md-table-cell>
          <md-avatar class="md-large">
            <my-s3-image :imagePath="customer.CustomerLogo"></my-s3-image>
          </md-avatar>
        </md-table-cell>
        <md-table-cell>
          <router-link :to="'/Customers/' + customer.CustomerID">{{customer.CustomerID}}</router-link>
        </md-table-cell>
        <md-table-cell>{{customer.CustomerName}}</md-table-cell>
        <md-table-cell>{{customer.CustomerAddress.Text}}</md-table-cell>
        <md-table-cell>
          <ul>
            <li v-for="contact in customer.Contacts" :key="contact.Name">
              {{contact.Name}} ({{contact.Position}}) {{contact.Phone}}
            </li>
          </ul>
        </md-table-cell>
        <md-table-cell>
          <md-button @click="handleDeleteCustomer(customer.CustomerID)">delete</md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import MyS3Image from '../components/MyS3Image'

export default {
  name: 'CustomerList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image},
  data () {
    return {
      customerItems: []
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    handleDeleteCustomer: function (customerID) {
      let self = this
      API.del('ezietruckapi', `/api/customers/${customerID}`).then(() => {
        self.customerItems = []
        self.fetchCustomer()
      })
    }
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    }
  },
  created () {
    this.fetchCustomer()
  }
}
</script>
