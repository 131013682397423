<template>
  <div>
    <md-tabs @md-changed="tabChanged">
      <template slot="md-tab" slot-scope="{ tab }">
        {{ tab.label }} <i class="badge" v-if="tab.data.badge">{{ tab.data.badge }}</i>
      </template>
      <md-tab id="tab-driver" md-label="Drivers" exact>
        <md-toolbar>
          <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
              <div class="md-toolbar-row" >
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-switch v-model="showAll">Show all</md-switch>
                  </div>
                  <md-field class="md-layout-item" v-show="!showAll">
                    <label for="movie">Field</label>
                    <md-select v-model="driverFilterForm.field">
                      <md-option value="LastGPSUpdatedAt">Last Localtion Updated</md-option>
                      <md-option value="LastDiaryUpdatedAt">Last Diary Sent</md-option>
                    </md-select>
                  </md-field>
                  <md-field class="md-layout-item" v-show="!showAll">
                    <label for="movie">Condition</label>
                    <md-select v-model="driverFilterForm.condition">
                      <md-option value="lt">Older than</md-option>
                      <md-option value="gt">Newer than</md-option>
                      <md-option value="btw">Between</md-option>
                    </md-select>
                  </md-field>
                  <md-field class="md-layout-item" v-show="!showAll">
                    <label>Days ago</label>
                    <md-input v-model.number="driverFilterForm.since" v-show="['gt', 'btw'].includes(driverFilterForm.condition)"></md-input>
                    <md-input v-model.number="driverFilterForm.until" v-show="['lt', 'btw'].includes(driverFilterForm.condition)"></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-toolbar-section-end">
              <md-field>
                <label>Driver</label>
                <md-input v-model="searchTxt"></md-input>
              </md-field>
              <md-button  @click="handleDownloadCSV">
                Download
              </md-button>
            </div>
          </div>
        </md-toolbar>
          <div class="md-content md-table-content md-scrollbar md-theme-default">
            <table v-for="truck in drivers" :key="truck.VehicleTruckType" style="border-spacing: 0; border-collapse: collapse; border-color: gray">
              <thead>
                <th>
                  <h3 class="md-title">{{truck.VehicleTruckType}}</h3>
                </th>
              </thead>
              <tbody>
                <tr>
                  <th class="md-table-head">
                    <div class="md-table-head-container">ID</div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container">Name</div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container">License Plate</div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container">Address</div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container">Phone Number</div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container">Last Loctaion</div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container">Last Diary</div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container">Approved By</div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container">Operation</div>
                  </th>
                </tr>
                <tr v-for="driver in truck.items" :key="driver.SK" class="md-table-row">
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <router-link :to="'/Drivers/' + driver.DriverID">{{driver.DriverID}}</router-link>
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <img src="../assets/LINE_Brand_icon.png" v-show="driver.LineUserID" width="16" />
                      {{driver.DriverName}}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{driver.Vehicle ? driver.Vehicle.VehicleLicenseNumber : ''}} {{driver.Vehicle ? driver.Vehicle.VehicleLicenseProvince : ''}}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{driver.DriverAddress}}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{driver.DriverPhoneNumber}}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <a @click="mapFocusAt(driver)">{{driver.LastLocation ? driver.LastLocation.Address : ''}}</a>
                      ({{dateTimeFormatter(driver.LastGPSUpdatedAt)}}) <md-icon v-show="driver.LastFCMTokenUpdatedAt">phone_android</md-icon>
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{dateTimeFormatter(driver.LastDiaryUpdatedAt)}}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{driver.ApprovedBy}}
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <div class="md-layout">
                        <div class="md-layout-item">
                          <md-button @click="handleBlockDriver(driver.DriverID)">
                            <md-icon>block</md-icon>
                          </md-button>
                        </div>
                        <div class="md-layout-item">
                          <md-button @click="handleDeleteDriver(driver.DriverID)">
                            <md-icon>delete</md-icon>
                          </md-button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </md-tab>
      <md-tab id="tab-new-driver" md-label="New Drivers" :md-template-data="{ badge: newDrivers.length }">
        <md-table>
          <md-table-row>
            <md-table-head>Photo</md-table-head>
            <md-table-head>ID</md-table-head>
            <md-table-head>Name</md-table-head>
            <md-table-head>Phone Number</md-table-head>
            <md-table-head>Begin Date</md-table-head>
            <md-table-head>Recruited By</md-table-head>
            <md-table-head>Approved By</md-table-head>
            <md-table-head>Status</md-table-head>
            <md-table-head>Operation</md-table-head>
          </md-table-row>
          <md-table-row v-for="(driver, i) in newDrivers" :key="driver.DriverID + '.' + i">
            <md-table-cell>
              <md-avatar class="md-large">
                <amplify-s3-image :imagePath="driver.DriverPhoto"></amplify-s3-image>
              </md-avatar>
            </md-table-cell>
            <md-table-cell>{{driver.DriverID}}</md-table-cell>
            <md-table-cell>{{driver.DriverName}}</md-table-cell>
            <md-table-cell>{{driver.DriverPhoneNumber}}</md-table-cell>
            <md-table-cell>
              <md-datepicker :md-model-type="String" md-immediately @input="inputDriverForm($event, driver.SK, 'BeginDate')" :value="(newDriverForm[driver.SK] || {}).BeginDate">
              </md-datepicker>
            </md-table-cell>
            <md-table-cell>
              <md-field>
                <label>Recruited By</label>
                <md-select @input="inputDriverForm($event, driver.SK, 'RecruitedBy')" :value="(newDriverForm[driver.SK] || {}).RecruitedBy">
                  <md-option v-for="user in userOptions" :value="user" :key="user">{{user}}</md-option>
                </md-select>
              </md-field>
            </md-table-cell>
            <md-table-cell>
              <md-field>
                <label>Approved By</label>
                <md-select @input="inputDriverForm($event, driver.SK, 'ApprovedBy')" :value="(newDriverForm[driver.SK] || {}).ApprovedBy">
                  <md-option v-for="user in userOptions" :value="user" :key="user">{{user}}</md-option>
                </md-select>
              </md-field>
            </md-table-cell>
            <md-table-cell>
              <md-field>
                <md-select @input="inputDriverForm($event, driver.SK, 'Status')" :value="(newDriverForm[driver.SK] || {}).Status">
                  <md-option value="Active">Active</md-option>
                  <md-option value="Inactive">Inactive</md-option>
                </md-select>
              </md-field>
            </md-table-cell>
            <md-table-cell>
              <div class="md-layout">
                <div class="md-layout-item">
                  <md-button @click="handleApproveNewDriver(driver.SK, driver.DriverPhoneNumber, driver.DriverID)">
                    <md-icon>thumb_up</md-icon>
                  </md-button>
                </div>
                <div class="md-layout-item">
                  <md-button @click="handleBlockNewDriver(driver.SK, driver.DriverPhoneNumber)">
                    <md-icon>block</md-icon>
                  </md-button>
                </div>
                <div class="md-layout-item">
                  <md-button @click="handleDeleteNewDriver(driver.SK, driver.DriverPhoneNumber)">
                    <md-icon>delete</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-tab>
      <md-tab id="tab-block-driver" md-label="Block Drivers">
        <md-table>
          <md-table-row>
            <md-table-head>Photo</md-table-head>
            <md-table-head>ID</md-table-head>
            <md-table-head>Name</md-table-head>
            <md-table-head>Phone Number</md-table-head>
            <md-table-head>Operation</md-table-head>
          </md-table-row>
          <md-table-row v-for="(driver, i) in blockDrivers" :key="driver.DriverID + '.' + i">
            <md-table-cell>
              <md-avatar class="md-large">
                <amplify-s3-image :imagePath="driver.DriverPhoto"></amplify-s3-image>
              </md-avatar>
            </md-table-cell>
            <md-table-cell>{{driver.DriverID}}</md-table-cell>
            <md-table-cell>{{driver.DriverName}}</md-table-cell>
            <md-table-cell>{{driver.DriverPhoneNumber}}</md-table-cell>
            <md-table-cell>
              <div class="md-layout">
                <div class="md-layout-item">
                  <md-button @click="handleDeleteBlockDriver(driver.DriverID)">
                    <md-icon>delete</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-tab>
      <md-tab id="tab-add-new-driver" md-label="Add New Driver" to="/NewDriver"></md-tab>
      <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
        <span>{{snackBarMsg}}</span>
      </md-snackbar>
    </md-tabs>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Location</md-dialog-title>
        <LastLocationMap :truck-lat-lng="mapOption.markerLatLng" :style="{'height': ($vssHeight - 20) + 'px', 'width': ($vssWidth *0.3) + 'px'}"/>
    </md-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
// import MyS3Image from '../components/MyS3Image'
import LastLocationMap from '../components/LastLocationMap'
import { getFirestore, getDocs, collection } from "firebase/firestore";

export default {
  name: 'DriverList',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {LastLocationMap},
  data () {
    return {
      driverItems: [],
      newDriverItems: [],
      blockDrivers: [],
      newDriverForm: [],
      userItems: [],
      LastEvaluatedKey: null,
      showDialog: false,
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      interval: null,
      currentDriver: null,
      loading: false,
      showAll: false,
      mapOption: {
        markerLatLng: null,
      },
      driverFilterForm: {
        field: "LastGPSUpdatedAt",
        condition: "gt",
        since: 30,
        until: 30
      },
      driverFilter: {
        field: "LastGPSUpdatedAt",
        condition: "gt",
        since: 30,
        until: 30
      },
      searchTxt: ''
    }
  },
  methods: {
    fetchDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.driverItems = []
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          self.driverItems = [...self.driverItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.LastEvaluatedKey = json.LastEvaluatedKey
          self.fetchDriver(json.LastEvaluatedKey)
        } else {
          self.loading = false
          self.LastEvaluatedKey = null
        }
      })
    },
    fetchUser: function () {
      let self = this
      API.get('ezietruckapi', '/api/users').then((json) => {
        self.userItems = json['Users']
      })
    },
    tabChanged: function (activeTab) {
      if (activeTab == 'tab-driver') {
        this.fetchNewDriver()
      }
    },
    fetchNewDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey && lastEvaluatedKey.PK) {
        q = `?LastDriver=${lastEvaluatedKey.PK.slice(10)}`
      } else {
        self.newDriverItems = []
      }
      API.get('ezietruckapi', '/api/newDrivers' + q).then((json) => {
        if (json['Items']) {
          self.newDriverItems = [...self.newDriverItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          // self.LastEvaluatedKey = json.LastEvaluatedKey
          self.fetchNewDriver(json.LastEvaluatedKey)
        }
      })
    },
    fetchBlockNewDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}&DriverBacklist=true&NEWDRIVER=true`
      } else {
        q = `?DriverBacklist=true&NEWDRIVER=true`
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          self.blockDrivers = [...self.blockDrivers, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchBlockNewDriver(json.LastEvaluatedKey)
        }
      })
    },
    fetchBlockDriver: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}&DriverBacklist=true`
      } else {
        q = `?DriverBacklist=true`
      }
      API.get('ezietruckapi', '/api/drivers' + q).then((json) => {
        if (json['Items']) {
          self.blockDrivers = [...self.blockDrivers, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchBlockDriver(json.LastEvaluatedKey)
        }
      })
    },
    handleDeleteDriver: function (driverID) {
      let self = this
      API.del('ezietruckapi', `/api/drivers/${driverID}`).then(() => {
        let idx = self.driverItems.findIndex((d) => d.DriverID === driverID)
        if (idx > -1) {
          self.driverItems = [...self.driverItems.slice(0, idx), ...self.driverItems.slice(idx + 1)]
        }
      })
    },
    handleBlockDriver: function (driverID) {
      let self = this
      API.post('ezietruckapi', `/api/drivers/${driverID}/blacklist`).then(() => {
        let idx = self.driverItems.findIndex((d) => d.DriverID === driverID)
        if (idx > -1) {
          self.driverItems = [...self.driverItems.slice(0, idx), ...self.driverItems.slice(idx + 1)]
        }
        self.blockDrivers = []
        self.fetchBlockDriver()
        self.fetchBlockNewDriver()
      })
    },
    handleDeleteBlockDriver: function (driverID) {
      let self = this
      API.del('ezietruckapi', `/api/drivers/${driverID}/blacklist`).then(() => {
        let idx = self.driverItems.findIndex((d) => d.DriverID === driverID)
        if (idx > -1) {
          self.driverItems = [...self.driverItems.slice(0, idx), ...self.driverItems.slice(idx + 1)]
        }
        self.blockDrivers = []
        self.fetchBlockDriver()
        self.fetchBlockNewDriver()
      })
    },
    handleBlockNewDriver: function (driverPhoneNumber) {
      let self = this
      API.post('ezietruckapi', `/api/drivers/${driverPhoneNumber}/blacklist`).then(() => {
        let idx = self.newDriverItems.findIndex((d) => d.DriverPhoneNumber === driverPhoneNumber)
        if (idx > -1) {
          self.newDriverItems = [...self.newDriverItems.slice(0, idx), ...self.newDriverItems.slice(idx + 1)]
        }
        self.fetchBlockDriver()
      })
    },
    handleDeleteNewDriver: function (driverID) {
      let self = this
      API.del('ezietruckapi', `/api/newDrivers/${driverID}`).then(() => {
        let idx = self.newDriverItems.findIndex((d) => d.DriverPhoneNumber === driverID)
        self.showSnackbar = true
        self.snackBarMsg = 'Successfully saved'
        if (idx > -1) {
          self.newDriverItems = [...self.newDriverItems.slice(0, idx), ...self.newDriverItems.slice(idx + 1)]
        }
      }).catch((e) => {
        self.showSnackbar = true
        self.snackBarMsg = e.message
      })
    },
    handleApproveNewDriver: function (sk, driverPhoneNumber, driverID) {
      let self = this
      API.put('ezietruckapi', `/api/newDrivers/${driverPhoneNumber}`, {body: {
        BeginDate: this.newDriverForm[sk].BeginDate,
        RecruitedBy: this.newDriverForm[sk].RecruitedBy,
        ApprovedBy: this.newDriverForm[sk].ApprovedBy,
        Status: this.newDriverForm[sk].Status
      }}).then(() => {
        let idx = self.newDriverItems.findIndex((d) => d.SK === sk)
        self.showSnackbar = true
        self.snackBarMsg = 'Successfully saved'
        self.$router.push(`/Drivers/${driverID}`)
        if (idx > -1) {
          self.newDriverForm = []
          self.driverItems = [...self.driverItems, self.newDriverItems[idx]]
          self.newDriverItems = [...self.newDriverItems.slice(0, idx), ...self.newDriverItems.slice(idx + 1)]
        }
      }).catch((e) => {
        self.showSnackbar = true
        self.snackBarMsg = e.response.data.message || e.message 
      })
    },
    dateTimeFormatter: function (str) {
      if (str) {
        return moment(str).format("lll")
      } else {
        return ''
      }
    },
    mapFocusAt: function (driver) {
      this.showDialog = true
      this.currentDriver = driver
      this.mapOption = Object.assign({}, this.mapOption, {
        markerLatLng: {lat: driver.LastLocation.GPS.Lat, lng: driver.LastLocation.GPS.Lon},
      })
    },
    fetchLocation: function (lastEvaluatedKey) {
      let self = this
      let q = {LastEvaluatedKey: lastEvaluatedKey} || {}
      API.post('ezietruckapi', '/api/vehicles/locations', {body: q}).then((json) => {
        for (let j of json.Items) {
          let idx = self.driverItems.findIndex((d) => d.DriverID === j.SK.split('#')[1])
          if (idx > -1) {
            self.driverItems = [...self.driverItems.slice(0, idx), Object.assign({}, self.driverItems[idx], {
              LastLocation: {
                Address: j.Address,
                GPS: j.GPS
              },
              LastGPSUpdatedAt: j.UpdatedAt
            }), ...self.driverItems.slice(idx + 1)]
          }
          if (self.showDialog && self.currentDriver && self.currentDriver.DriverID === j.SK.split('#')[1]) {
            self.mapOption = Object.assign({}, self.mapOption, {
              markerLatLng: {lat: j.GPS.Lat, lon: j.GPS.Lon},
            })
          }
        }
        if (json && json.LastEvaluatedKey) {
          self.fetchLocation(json.LastEvaluatedKey)
        }
      })
    },
    handleUpdateFilter () {
      this.driverFilter = Object.assign({}, this.driverFilterForm)
    },
    handleDownloadCSV () {
      let csv = "PK,SK,DriverBankAccountName,DriverBankAccountNumber,DriverBankBranchCode,DriverBankName,DriverCitizenID,DriverID,DriverLicenseID,DriverName,DriverPhoneNumber,LastGPSUpdatedAt,LastDiaryUpdatedAt,LastLocation,VehicleContainerType,VehicleLicenseNumber,VehicleLicenseProvince,VehicleTruckType\n"
      const clean = function (s) {
        return s ? s.replace(",", " ") : ''
      }
      for (let truckType of this.drivers) {
        for (let driver of truckType.items) {
          csv += `${clean(driver.PK)},${clean(driver.SK)},${clean(driver.DriverBankAccountName)},${clean(driver.DriverBankAccountNumber)},${clean(driver.DriverBankBranchCode)},${clean(driver.DriverBankName)},${clean(driver.DriverCitizenID)},${clean(driver.DriverID)},${clean(driver.DriverLicenseID)},${clean(driver.DriverName)},${clean(driver.DriverPhoneNumber)},${clean(driver.LastGPSUpdatedAt)},${clean(driver.LastDiaryUpdatedAt)},${clean((driver.LastLocation || {}).Address)},${clean((driver.Vehicle || {}).VehicleContainerType)},${clean((driver.Vehicle || {}).VehicleLicenseNumber)},${clean((driver.Vehicle || {}).VehicleLicenseProvince)},${clean((driver.Vehicle || {}).VehicleTruckType)}\n`
        }
      }
      let hiddenElement = document.createElement('a')
      const blob = new Blob(["\ufeff", csv], {type : 'text/csv;charset=utf-8'})
      hiddenElement.href = URL.createObjectURL(blob)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'driver.csv'
      hiddenElement.click()
    },
    inputDriverForm (event, sk, field) {
      let idx = this.newDriverItems.findIndex((d) => d.SK === sk);
      if (idx > -1) {
        this.newDriverForm[sk] = Object.assign({}, this.newDriverItems[idx], Object.assign({}, this.newDriverForm[sk] || {}, {
          [field]: event
        }))
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    drivers: function () {
      let driver = {}
      let truckType = {}
      let allDriver = []
      let hasVehicle = []
      let out = []
      let isShow = {}
      const s = moment().subtract(this.driverFilter.since, 'days').startOf('day').utc().format()
      const u = moment().subtract(this.driverFilter.until, 'days').endOf('day').utc().format()
      if (!this.LastEvaluatedKey) {
        for (let o of this.driverItems) {
          if (o.SK.startsWith('#METADATA#')) {
            driver[o.PK] = Object.assign({}, driver[o.PK] || {}, o)
            allDriver.push(o.PK)
            isShow[o.PK] = false
            if (this.searchTxt.length > 3) {
              isShow[o.PK] = o.DriverName.includes(this.searchTxt)
            } else {
              if (o[this.driverFilter.field]) {
                if (this.driverFilter.condition === 'gt') {
                  isShow[o.PK] = o[this.driverFilter.field] > s
                } else if (this.driverFilter.condition === 'lt') {
                  isShow[o.PK] = o[this.driverFilter.field] < u
                } else if (this.driverFilter.condition === 'btw') {
                  isShow[o.PK] = o[this.driverFilter.field] > s && o[this.driverFilter.field] < u
                }
              }
            }
            isShow[o.PK] = this.showAll || isShow[o.PK]
          } else if (o.SK.startsWith('VEHICLE#')) {
            driver[o.PK] = Object.assign({}, driver[o.PK] || {}, {Vehicle: o})
            if (truckType[o.VehicleTruckType]) {
              truckType[o.VehicleTruckType].push(o.PK)
            } else {
              truckType[o.VehicleTruckType] = [o.PK]
            }
            hasVehicle.push(o.PK)
          }
        }
        for (let k in truckType) {
          out.push({VehicleTruckType: k, items: Array.from(new Set(truckType[k])).filter((pk) => isShow[pk]).map((pk) => driver[pk]).sort((a,b) => (a.DriverName && b.DriverName) ?  a.DriverName.localeCompare(b.DriverName) : -1)})
        }
        let noVehicle = {VehicleTruckType: 'No Vehicle', items: []}
        for (let pk of allDriver) {
          if (!hasVehicle.includes(pk) && isShow[pk]) {
            noVehicle.items.push(driver[pk])
          }
        }
        noVehicle.items.sort((a,b) => (a.DriverName && b.DriverName) ?  a.DriverName.localeCompare(b.DriverName) : -1)
        out.push(noVehicle)
      }
      return out
    },
    newDrivers: function () {
      let blockDriverIDs = this.blockDrivers.map((d) => d.DriverID)
      return this.newDriverItems.filter((i) => i.SK.startsWith('#METADATA#') && !blockDriverIDs.includes(i.DriverID))
    },
    userOptions: function () {
      return this.userItems.map((u) => {
        let idx = u.Attributes.findIndex((a) => a.Name === 'email')
        if (idx > -1) {
          return u.Attributes[idx].Value
        }
      })
    }
  },
  created () {
    this.loading = true
    this.fetchDriver()
    this.fetchUser()
    this.fetchBlockDriver()
    this.fetchBlockNewDriver()
    // const db = getFirestore();
    // getDocs(collection(db, "locations")).then((snapshot) => {
    //   snapshot.forEach((change) => {
    //     if (change.type === "modified") {
    //       let newLocationData = change.doc.data()
    //       let idx = this.driverItems.findIndex((d) => d.SK.startsWith('#METADATA#') && d.DriverID === newLocationData.SK.split('#')[1])
    //       if (idx > -1) {
    //         this.driverItems = [
    //           ...this.driverItems.slice(0, idx),
    //           Object.assign({}, this.driverItems[idx], {
    //             LastLocation: {
    //               Address: newLocationData.Address,
    //               GPS: newLocationData.GPS
    //             },
    //             LastGPSUpdatedAt: (new Date(newLocationData.UpdatedAt.seconds * 1000)).toISOString()
    //           }),
    //           ...this.driverItems.slice(idx + 1)
    //         ]
    //       }
    //     }
    //   });
    // });
  }
}
</script>
<style lang="scss" scoped>
  .badge {
    width: 19px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
    font-family: 'Roboto Mono', monospace;
  }
</style>