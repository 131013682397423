<template>
  <div>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <h3 class="md-title">Margin Dashboard</h3>
          <div style="margin-left: 15px">
            <md-chip class="md-primary" md-deletable v-show="selectedBar" @md-delete="selectedBar=null">{{selectedBar}}</md-chip>
            <md-chip class="md-primary" md-deletable v-show="selectedOwner" @md-delete="selectedOwner=null">{{selectedOwner}}</md-chip>
            <md-chip class="md-primary" md-deletable v-show="selectedCus" @md-delete="selectedCus=null">{{selectedCus}}</md-chip>
          </div>
        </div>
        <div class="md-toolbar-section-end">
          <md-datepicker v-model="startDate" @md-closed="handleYearInput"><label>Start date</label></md-datepicker>
          <md-datepicker v-model="endDate" @md-closed="handleYearInput"><label>End date</label></md-datepicker>
          <md-field>
            <label for="movie">Group By</label>
            <md-select v-model="groupBy" name="groupBy" id="groupBy">
              <md-option value="MONTHLY">Monthly</md-option>
              <md-option value="WEEKLY">Weekly</md-option>
            </md-select>
          </md-field>
          <md-autocomplete v-model="jobType" :md-options="jobTypeOptions" >
            <label>Job Type</label>
          </md-autocomplete>
          <md-field>
            <label>Customer</label>
            <md-select v-model="selectedCustomers" multiple>
              <md-option v-for="cus in customerOpions" :value="cus" :key="cus">{{cus}}</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
    </md-toolbar>
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <horizontal-bar-chart :chart-data="chartData" :options="mainChartOptions" :styles="{position: 'relative', height: $vssHeight-220 + 'px'}"></horizontal-bar-chart>
      </div>
      <div class="md-layout-item md-size-50">
        <horizontal-bar-chart :chart-data="byCustomerData" :options="byCustomerChartOptions" :styles="{position: 'relative', height: $vssHeight-220 + 'px'}"></horizontal-bar-chart>
      </div>
      <div class="md-layout-item md-size-50">
        <bar-chart :chart-data="byUserData" :options="byUserChartOptions" :styles="{position: 'relative', height: ($vssHeight/2)-120 + 'px'}"></bar-chart>
      </div>
      <div class="md-layout-item md-size-25"  :style="{'overflow-y': 'auto', height: ($vssHeight/2)-120 + 'px'}">
        <md-table md-card>
          <md-table-toolbar>
            <h1 class="md-title">Owner</h1>
          </md-table-toolbar>
          <md-table-row>
            <md-table-head>Name</md-table-head>
            <md-table-head>Percent</md-table-head>
          </md-table-row>
          <md-table-row v-for="row in userTable" :key="row[0]">
            <md-table-cell md-label="Name">{{row[0]}}</md-table-cell>
            <md-table-cell md-label="%">{{$currencyFormater(row[1]) }}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <div class="md-layout-item md-size-25"  :style="{'overflow-y': 'auto', height: ($vssHeight/2)-120 + 'px'}">
          <md-table md-card>
            <md-table-toolbar>
              <h1 class="md-title">Customer</h1>
            </md-table-toolbar>
            <md-table-row>
              <md-table-head>ID</md-table-head>
              <md-table-head>Percent</md-table-head>
            </md-table-row>
            <md-table-row v-for="(row, cusID) in cusTable" :key="cusID">
              <md-table-cell md-label="Name">
                <a @click="showCustomerJobType = showCustomerJobType === cusID ? null : cusID">{{cusID}}</a>
                <ul v-show="showCustomerJobType === cusID">
                  <li v-for="(v, j) in row.jobType" :key="j">{{j}} ({{$currencyFormater(v.percent)}}%)</li>
                </ul>
              </md-table-cell>
              <md-table-cell md-label="%">
                {{$currencyFormater(row.percent) }}
              </md-table-cell>
            </md-table-row>
          </md-table>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalBarChart from '../components/HorizontalBarChart.js'
import BarChart from '../components/BarChart.js'
import VueScreenSize from 'vue-screen-size'
import moment from 'moment'
import { API } from 'aws-amplify'

export default {
  name: 'MarginDashboard',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {
    'horizontal-bar-chart': HorizontalBarChart,
    'bar-chart': BarChart,
  },
  data () {
    let vm = this
    return {
      currentYear: (new Date()).getFullYear(),
      groupBy: 'MONTHLY',
      jobType: null,
      showCustomerJobType: null,
      startDate: moment().startOf('year').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      summaryData: [],
      summaryAllData: {},
      summaryCustomerData: {},
      summaryUserData: {},
      selectedCustomers: [],
      selectedBar: null,
      selectedOwner: null,
      selectedCus: null,
      loading: false,
      mainChartOptions: {
        maintainAspectRatio: false,
        title: {
            display: true,
            text: 'Margin summary'
        },
        legend: {
          display: true
        },
        onClick: this.clicked,
        scales: {
            xAxes: [{
                ticks: {
                    callback: this.$currencyFormater,
                    beginAtZero: true,
                    maxTicksLimit: 6,
                },
                stacked: true
            }],
            yAxes: [{
              stacked: true
            }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: 4,
            formatter: function(value, context) {
              if (context.datasetIndex === 1) {
                return vm.$currencyFormater(parseFloat(context.chart.data.datasets.reduce((accumulator, currentValue) => accumulator + (currentValue.data[context.dataIndex] || 0), 0)));
              } else {
                return ''
              }
            }
          }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return vm.$currencyFormater(parseFloat(tooltipItem.value));
            }
          }
        }
      },
      byUserChartOptions: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'By Owner chart'
        },
        legend: {
          display: false
        },
        scales: {
            xAxes: [{
              stacked: true,
            }],
            yAxes: [{
              stacked: true
            }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: 4,
            formatter: function(value, context) {
              if (context.datasetIndex === 1) {
                return vm.$currencyFormater(parseFloat(context.chart.data.datasets.reduce((accumulator, currentValue) => accumulator + (currentValue.data[context.dataIndex] || 0), 0)));
              } else {
                return ''
              }
            }
          }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return vm.$currencyFormater(parseFloat(tooltipItem.value));
            }
          }
        },
        onClick: this.ownerClicked,
      },
      byCustomerChartOptions: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'By Customer chart'
        },
        legend: {
          display: false
        },
        scales: {
            xAxes: [{
                stacked: true,
                ticks: {
                    callback: this.$currencyFormater,
                    beginAtZero: true
                }
            }],
            yAxes: [{
              stacked: true
            }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: 4,
            formatter: function(value, context) {
              if (context.datasetIndex === 1) {
                return vm.$currencyFormater(parseFloat(context.chart.data.datasets.reduce((accumulator, currentValue) => accumulator + (currentValue.data[context.dataIndex] || 0), 0)));
              } else {
                return ''
              }
            }
          }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return vm.$currencyFormater(parseFloat(tooltipItem.value));
            }
          }
        },
        onClick: this.cusClicked,
      }
    }
  },
  methods: {
    fetchData: function () {
      let vm = this
      this.loading = true
      let sDate = moment(this.startDate)
      let promises = []
      while (sDate.format('YYYY-MM') <= moment(this.endDate).format('YYYY-MM')) {
        promises.push(API.get('ezietruckapi', `/api/summary/sales/${sDate.format('YYYY-MM')}`))
        sDate.add(1, 'months')
      }
      Promise.all(promises).then((results) => {
        vm.loading = false
        vm.summaryData = results.flat().filter((r) => {
          let formatter = 'YYYYMMDD'
          if (r.SK.split('#')[0] === 'MONTHLY') {
            formatter = 'YYYYMM'
          } else if (r.SK.split('#')[0] === 'WEEKLY') {
            formatter = 'YYYYWW'
          }
          return r.SK.split('#')[1] >= moment(vm.startDate).format(formatter) && r.SK.split('#')[1] <= moment(vm.endDate).format(formatter)
        })
      })
    },
    clicked: function (event, array) {
      if (array[0]) {
        this.selectedBar = this.chartData.labels[array[0]._index]
        this.selectedOwner = null
        this.selectedCus = null
      }
    },
    ownerClicked: function (event, array) {
      if (array[0]) {
        this.selectedOwner = this.byUserData.labels[array[0]._index]
        this.selectedBar = null
        this.selectedCus = null
      }
    },
    cusClicked: function (event, array) {
      if (array[0]) {
        this.selectedCus = this.byCustomerData.labels[array[0]._index]
        this.selectedBar = null
        this.selectedOwner = null
      }
    },
    randomColorGenerator: function () { 
      return '#' + (Math.random().toString(16) + '0000000').slice(2, 8); 
    },
    handleYearInput: function () {
      this.$nextTick(function () {
        this.fetchData()
      })
    }
  },
  computed: {
    jobTypeOptions: function () {
      let jt = new Set()
      for (let item of (this.summaryData ||  [])) {
        if (/^\w+#\d+#JOBTYPE#\w+/.test(item.SK)) {
          jt.add(item.SK.split('#')[3])
        }
      }
      return [...jt]
    },
    customerOpions: function () {
      let cus = new Set()
      for (let item of (this.summaryData ||  [])) {
        if (/^\w+#\d+#CUSTOMER#\w+/.test(item.SK)) {
          cus.add(item.SK.split('#')[3])
        }
      }
      return [...cus]
    },
    chartData: function () {
      let chart = {
        labels: [],
        datasets: [
          {label: 'INVOICE', data: [], backgroundColor: "rgba(0, 177, 31,0.2)"},
          {label: 'RECIEPT', data: [], backgroundColor: "rgba(0, 177, 31,0.8)"}
        ]
      }
      let pattern = '^' + this.groupBy + '#\\d+'
      if (this.jobType) {
        pattern += '#JOBTYPE#' + this.jobType
      }
      if (this.selectedOwner) {
        if (this.selectedCus) {
          pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?' + '#CUSTOMER#(' + this.selectedCustomers.join('|') + ')'
        } else {
          pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?' + '#CUSTOMER#\\w+'
        }
      } else {
        if (this.selectedCus) {
          pattern += '#CUSTOMER#' + this.selectedCus
        } else if (this.selectedCustomers.length > 0) {
          pattern += '#CUSTOMER#(' + this.selectedCustomers.join('|') + ')'
        }
      }
      let patternReg = new RegExp(pattern+'$')
      let data = {}
      for (let item of (this.summaryData ||  [])) {
        if (patternReg.test(item.SK)) {
          if (!data[item.SK.split('#')[1]]) {
            data[item.SK.split('#')[1]] = {TotalSaleMarginAmount: 0, TotalReceiptMarginAmount: 0}
          }
          data[item.SK.split('#')[1]].TotalSaleMarginAmount += item.TotalSaleMarginAmount
          data[item.SK.split('#')[1]].TotalReceiptMarginAmount += item.TotalReceiptMarginAmount
        }
      }
      chart.labels = [...Object.keys(data)].sort((a,b) => a.localeCompare(b))
      for (let label of chart.labels) {
        chart.datasets[0].data.push(data[label].TotalSaleMarginAmount)
        chart.datasets[1].data.push(data[label].TotalReceiptMarginAmount)
      }
      return chart
    },
    byCustomerData: function () {
      let chart = {
        labels: [],
        datasets: [
          {label: 'INVOICE', data: [], backgroundColor: "rgba(0, 177, 31,0.2)"},
          {label: 'RECIEPT', data: [], backgroundColor: "rgba(0, 177, 31,0.8)"}
        ]
      }
      let pattern = '^' + this.groupBy + '#'
      if (this.selectedBar) {
        pattern += this.selectedBar
      } else {
        pattern += '\\d+'
      }
      if (this.jobType) {
        pattern += '#JOBTYPE#' + this.jobType
      }
      if (this.selectedOwner) {
        pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?'
      }
      if (this.selectedCus) {
        pattern += '#CUSTOMER#' + this.selectedCus 
      } else {
        if (this.selectedCustomers.length > 0) {
          pattern += '#CUSTOMER#(' + this.selectedCustomers.join('|') + ')'
        } else {
          pattern += '#CUSTOMER#\\w+'
        }
      }
      let patternReg = new RegExp(pattern+'$')
      for (let item of (this.summaryData ||  [])) {
        if (patternReg.test(item.SK)) {
          let lidx = chart.labels.findIndex((i) => i === item.SK.split('#')[item.SK.split('#').length  - 1])
          if (lidx < 0) {
            chart.labels.push(item.SK.split('#')[item.SK.split('#').length  - 1])
            lidx = chart.labels.length - 1
            chart.datasets[0].data[lidx] = 0
            chart.datasets[1].data[lidx] = 0
          }
          chart.datasets[0].data[lidx] += (item.TotalSaleMarginAmount || 0)
          chart.datasets[1].data[lidx] += (item.TotalReceiptMarginAmount || 0)
        }
      }
      return chart
    },
    byUserData: function () {
      let chart = {
        labels: [],
        datasets: [
          {label: 'Unpaid', data: [], backgroundColor: "rgba(0, 177, 31,0.2)"},
          {label: 'Paid', data: [], backgroundColor: "rgba(0, 177, 31,0.8)"}
        ]
      }
      let pattern = '^' + this.groupBy + '#'
      if (this.selectedBar) {
        pattern += this.selectedBar
      } else {
        pattern += '\\d+'
      }
      if (this.jobType) {
        pattern += '#JOBTYPE#' + this.jobType
      }
      if (this.selectedOwner) {
        if (this.selectedCus) {
          pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?' + '#CUSTOMER#' + this.selectedCus
        } else {
          pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?'
          if (this.selectedCustomers.length > 0) {
            pattern += '#CUSTOMER#(' + this.selectedCustomers.join('|') + ')'
          }
        }
      } else {
        if (this.selectedCus) {
          pattern += '#USER#[\\.\\@\\w]+#CUSTOMER#' + this.selectedCus 
        } else {
          pattern += '#USER#[\\.\\@\\w]+'
          if (this.selectedCustomers.length > 0) {
            pattern += '#CUSTOMER#(' + this.selectedCustomers.join('|') + ')'
          }
        }
      }
      let patternReg = new RegExp(pattern+'$')
      for (let item of (this.summaryData ||  [])) {
        if (patternReg.test(item.SK)) {
          let label = item.SK.split('#')[item.SK.split('#').length  - 1].split('@')[0]
          if (item.SK.split('#').length === 8 || (item.SK.split('#').length === 6)) {
            label = item.SK.split('#')[item.SK.split('#').length  - 3].split('@')[0]
          }
          let lidx = chart.labels.findIndex((i) => i === label)
          if (lidx < 0) {
            chart.labels.push(label)
            lidx = chart.labels.length - 1
            chart.datasets[0].data[lidx] = 0
            chart.datasets[1].data[lidx] = 0
          }
          chart.datasets[0].data[lidx] += (item.TotalSaleMarginAmount || 0)
          chart.datasets[1].data[lidx] += (item.TotalReceiptMarginAmount || 0)
        }
      }
      return chart
    },
    byUserSaleData: function () {
      let chart = {
        labels: [],
        datasets: [
          {label: 'Unpaid', data: [], backgroundColor: "rgba(0, 177, 31,0.2)"},
          {label: 'Paid', data: [], backgroundColor: "rgba(0, 177, 31,0.8)"}
        ]
      }
      let pattern = '^' + this.groupBy + '#'
      if (this.selectedBar) {
        pattern += this.selectedBar
      } else {
        pattern += '\\d+'
      }
      if (this.jobType) {
        pattern += '#JOBTYPE#' + this.jobType
      }
      if (this.selectedOwner) {
        if (this.selectedCus) {
          pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?' + '#CUSTOMER#' + this.selectedCus
        } else {
          pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?'
          if (this.selectedCustomers.length > 0) {
            pattern += '#CUSTOMER#(' + this.selectedCustomers.join('|') + ')'
          }
        }
      } else {
        if (this.selectedCus) {
          pattern += '#USER#[\\.\\@\\w]+#CUSTOMER#' + this.selectedCus 
        } else {
          pattern += '#USER#[\\.\\@\\w]+'
          if (this.selectedCustomers.length > 0) {
            pattern += '#CUSTOMER#(' + this.selectedCustomers.join('|') + ')'
          }
        }
      }
      let patternReg = new RegExp(pattern+'$')
      for (let item of (this.summaryData ||  [])) {
        if (patternReg.test(item.SK)) {
          let label = item.SK.split('#')[item.SK.split('#').length  - 1].split('@')[0]
          if (item.SK.split('#').length === 8 || (item.SK.split('#').length === 6)) {
            label = item.SK.split('#')[item.SK.split('#').length  - 3].split('@')[0]
          }
          let lidx = chart.labels.findIndex((i) => i === label)
          if (lidx < 0) {
            chart.labels.push(label)
            lidx = chart.labels.length - 1
            chart.datasets[0].data[lidx] = 0
            chart.datasets[1].data[lidx] = 0
          }
          chart.datasets[0].data[lidx] += (item.TotalSaleAmount || 0)
          chart.datasets[1].data[lidx] += (item.TotalCostAmount || 0)
        }
      }
      return chart
    },
    userTable: function () {
      let rows = []
      for (let i = 0; i < this.byUserData.labels.length; i++) {
        if (this.byUserData.labels[i] === this.byUserSaleData.labels[i]) {
          rows.push([this.byUserData.labels[i],  100.0 * this.byUserData.datasets[0].data[i] / this.byUserSaleData.datasets[0].data[i]])
        }
      }
      return rows
    },
    cusTable: function () {
      let pattern = '^' + this.groupBy + '#'
      if (this.selectedBar) {
        pattern += this.selectedBar
      } else {
        pattern += '\\d+'
      }
      if (this.jobType) {
        pattern += '#JOBTYPE#' + this.jobType
      } else {
        pattern += '#JOBTYPE#(\\w| )+'
      }
      if (this.selectedOwner) {
        pattern += '#USER#' + this.selectedOwner + '(@eziecorp\\.com)?'
      }
      if (this.selectedCus) {
        pattern += '#CUSTOMER#' + this.selectedCus 
      } else {
        if (this.selectedCustomers.length > 0) {
          pattern += '#CUSTOMER#(' + this.selectedCustomers.join('|') + ')'
        } else {
          pattern += '#CUSTOMER#\\w+'
        }
      }
      let customers = {}
      let patternReg = new RegExp(pattern+'$')
      for (let item of (this.summaryData ||  [])) {
        if (patternReg.test(item.SK)) {
          let label = item.SK.split('#')[item.SK.split('#').length  - 1].split('@')[0]
          let jobType = item.SK.split('#')[3]
          if (!customers[label]) {
            customers[label] = {
              jobType: {},
              marginSum: 0,
              saleSum: 0,
              percent: 0
            }
          }
          customers[label].marginSum += (item.TotalSaleMarginAmount || 0) + (item.TotalReceiptMarginAmount || 0)
          customers[label].saleSum += item.TotalSaleAmount
          if (!customers[label].jobType[jobType]) {
            customers[label].jobType[jobType] = {
              marginSum: 0,
              saleSum: 0,
              percent: 0
            }
          }
          customers[label].jobType[jobType].marginSum += (item.TotalSaleMarginAmount || 0) + (item.TotalReceiptMarginAmount || 0)
          customers[label].jobType[jobType].saleSum += item.TotalSaleAmount
        }
      }
      for (let c in customers) {
        customers[c].percent = customers[c].marginSum / customers[c].saleSum * 100.0
        for (let j in customers[c].jobType) {
          customers[c].jobType[j].percent = customers[c].jobType[j].marginSum  / customers[c].jobType[j].saleSum * 100.0
        }
      }
      return customers
    }
  },
  created () {
    this.fetchData()
  }
}
</script>