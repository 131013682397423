<template>
  <div>
    <md-toolbar>
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>CustomerID</label>
                <md-select v-model="formData.CustomerID" @md-selected="handleCustomerChanged">
                  <md-option value="---">---</md-option>
                  <md-option v-for="customer in customers" :key="customer.SK" :value="customer.CustomerID">
                    {{customer.CustomerID}} / {{customer.CustomerName}}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label>Job Type</label>
                <md-select v-model="formData.JobType">
                  <md-option value="---">---</md-option>
                  <md-option v-for="jobType in customerJobTypes" :key="jobType.Name" :value="jobType.Name">{{jobType.Name}}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label>Job Subtype</label>
                <md-select v-model="formData.JobSubtype">
                  <md-option value="---">---</md-option>
                  <md-option v-for="subJobType in currentSubJobTypes" :key="subJobType" :value="subJobType">{{ subJobType }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-raised" @click="fetchData()">Search</md-button>
          <md-button class="md-raised" @click="$router.push('RatePlanForm')">New Rate Plan</md-button>
        </div>
      </div>
    </md-toolbar>
    <md-table>
      <md-table-row>
        <md-table-head>Name</md-table-head>
        <md-table-head>Detail</md-table-head>
      </md-table-row>
      <md-table-row v-for="plan in ratePlans" :key="plan.Name">
        <md-table-cell>
          {{plan.Name}}
        </md-table-cell>
        <md-table-cell>
          <md-table>
            <md-table-row>
              <md-table-cell><strong>CustomerID:</strong> {{plan.CustomerID}}</md-table-cell>
              <md-table-cell><strong>JobType:</strong> {{plan.JobType}}</md-table-cell>
              <md-table-cell><strong>IsMultipleDiary:</strong> {{plan.IsMultipleDiary}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell colspan="4">
                Custom Fields:
              </md-table-cell>
            </md-table-row>
            <md-table-row v-for="field in plan.CustomFields" :key="field.Field">
              <md-table-cell><strong>Name:</strong> {{field.Field}}</md-table-cell>
              <md-table-cell><strong>ShowInDiary:</strong> {{field.ShowInDiary}}</md-table-cell>
              <md-table-cell><strong>Validation:</strong><span v-show="field.Validation.isRequired">Required</span></md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell colspan="4">
                Computed Fields:
              </md-table-cell>
            </md-table-row>
            <md-table-row v-for="field in plan.ComputedFields" :key="field.Name">
              <md-table-cell><strong>Name:</strong> {{field.Name}}</md-table-cell>
              <md-table-cell><strong>Function:</strong> {{field.Function}}</md-table-cell>
              <md-table-cell><strong>Expression:</strong> {{field.Expression}}</md-table-cell>
              <md-table-cell><strong>Lookup:</strong> {{field.Lookup}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell colspan="4">
                Rates:
              </md-table-cell>
            </md-table-row>
            <md-table-row v-for="rate in plan.ServiceRatePlans" :key="rate.Name">
              <md-table-cell><strong>Name:</strong> {{rate.Name}}</md-table-cell>
              <md-table-cell><strong>Function:</strong> {{rate.Function}}</md-table-cell>
              <md-table-cell><strong>Expression:</strong> {{rate.Expression}}</md-table-cell>
              <md-table-cell><strong>Lookup:</strong> {{rate.Lookup}}</md-table-cell>
            </md-table-row>
            <md-table-row v-show="plan.AggregateOrderPlans.length > 0">
              <md-table-cell colspan="4">
                Aggregate:
              </md-table-cell>
            </md-table-row>
            <md-table-row v-for="rate in plan.AggregateOrderPlans" :key="rate.Name">
              <md-table-cell><strong>Name:</strong> {{rate.Name}}</md-table-cell>
              <md-table-cell><strong>Function:</strong> {{rate.Function}}</md-table-cell>
              <md-table-cell><strong>Expression:</strong> {{rate.Expression}}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'

export default {
  name: 'RatePlanList',
  data () {
    return {
      customerItems: [],
      customerJobTypes: [],
      jobTypes: [],
      ratePlans: [],
      formData: {
        CustomerID: '---',
        JobType: '---',
        JobSubtype: '---'
      },
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items'].filter((i) => i.SK.startsWith('#METADATA#'))]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchFavJobTypes: function (customerID) {
      let vm = this
      API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`).then((json) => {
        vm.customerJobTypes = json
      })
    },
    fetchJobTypes: function () {
      let vm = this
      API.get('ezietruckapi', `/api/options/JobType`).then((json) => {
        vm.jobTypes = json
      })
    },    
    fetchData: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', `/api/ratePlans/${this.formData.CustomerID}/${this.formData.JobType}/${this.formData.JobSubtype}`).then((json) => {
        self.ratePlans = [...self.ratePlans, ...json['Items']]
        if (json.LastEvaluatedKey) {
          self.fetchData(json.LastEvaluatedKey)
        }
      })
    },
    handleCustomerChanged: function (customerID) {
      this.customerJobTypes = []
      this.fetchFavJobTypes(customerID)
    },
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    allJobTypes: function () {
      return this.customerJobTypes.length > 0 ? this.customerJobTypes : this.jobTypes
    },
    currentSubJobTypes: function () {
      if (this.formData.JobType) {
        const idx = this.customerJobTypes.findIndex((j) => j.Name === this.formData.JobType)
        return idx > -1 ? this.customerJobTypes[idx].SubJobType : []
      }
      return []
    },
  },
  watch: {
    curentOption: function () {
      this.fetchData()
    }
  },
  created () {
    this.fetchData()
    this.fetchCustomer()
    this.fetchJobTypes()
  }
}
</script>