import moment from 'moment'

function install (Vue) {
  Vue.prototype.$checkNested = function (obj) {
    let args = Array.prototype.slice.call(arguments, 1)
    for (var i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
        return false
      }
      obj = obj[args[i]]
    }
    return true
  }
  Vue.prototype.$currencyFormater = function (number) {
    if (typeof number === 'number') {
      return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
     } else {
       return ''
     }
  },
  Vue.prototype.$dateTimeFormater = function (str) {
    if (str) {
      return moment(str).format("DD MMM YYYY HH:MM")
    } else {
      return ''
    }
  }

}

export default install
