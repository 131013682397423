<template>
  <div>
    <md-toolbar>
      <form @submit.prevent="handleSearchAvailable" class="md-toolbar-row md-layout" >
        <div class="md-layout-item">
          <md-datepicker v-model="formData.StartDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
            <label>Start Date</label>
            <span class="md-error" v-if="errors['Date']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item">
          <md-datepicker v-model="formData.EndDate" :md-model-type="String" :class="{'md-invalid': errors['Date']}" md-immediately>
            <label>End Date</label>
            <span class="md-error" v-if="errors['Date']">Invalid field input</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item">
          <md-button :disabled="loading" type="submit" class="md-raised md-primary">
            <span>Search</span>
          </md-button>
          <md-button :disabled="loading" @click="handleDownloadAllCSV">
            Download All
          </md-button>
        </div>
      </form>
    </md-toolbar>
    <div class="md-content md-table">
      <table>
        <thead>
          <tr class="md-table-row">
            <th class="md-table-head" rowspan="2">Driver</th>
            <th class="md-table-head" v-for="d in dates" :key="d" colspan="4">{{d}}</th>
          </tr>
          <tr class="md-table-row">
            <th class="md-table-head" v-for="t in times" :key="t">{{t.split('T')[1]}}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="md-table-row" v-for="(a, driverID) in availables" :key="driverID">
            <td>{{(drivers[driverID] || {})['DriverName']}}</td>
            <td v-for="t in datetimes" :key="t" class="md-table-cell" style="width: 120px">
              <span v-show="(a[t.split('T')[0]] || {})[t.split('T')[1]]">
                {{(a[t.split('T')[0]] || {})['zone'] || ''}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <progress max="100" style="width: 100%" v-show="loading">60%</progress>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment'

export default {
  name: 'DriverAvailableReport',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data () {
    return {
      availableItems: [],
      drivers: {},
      formData: {
        StartDate: moment().format('YYYY-MM-DD'),
        EndDate: moment().add(7, 'days').format('YYYY-MM-DD')
      },
      errors: {
        Date: null
      },
      loading: false
    }
  },
  methods: {
    handleSearchAvailable: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.availableItems = []
      }
      this.loading = true
      API.get('ezietruckapi', `/api/availables/${this.formData.StartDate}/${this.formData.EndDate}${q}`).then((json) => {
        if (json['Items']) {
          self.availableItems = [...self.availableItems, ...json['Items']]
          for (let d of json['Items']) {
            if (!self.drivers[d.PK.split('#')[1]]) {
              self.fetchDriver(d.PK.split('#')[1])
            }
          }
        }
        if (json.LastEvaluatedKey) {
          self.handleSearchAvailable(json.LastEvaluatedKey)
        } else {
          self.loading = false
        }
      })
    },
    handleDownloadAllCSV: function () {
      let csvHeader = `Driver ID,Driver Name,${this.datetimes.join(',')}\n`;
      let csv = ''
      let customHeaders = []
      let customValues = []
      let hidx = -1
      let dt;

      for (let d in this.availables) {
        csv += `${d},${this.drivers[d]['DriverName']},${this.datetimes.map((dt) => (this.availables[d][dt.split('T')[0]]|| {})[dt.split('T')[1]] ? this.availables[d][dt.split('T')[0]]['zone'] : '').join(',')}\n`
      }
      let hiddenElement = document.createElement('a')
      const blob = new Blob(["\ufeff", csvHeader + csv], {type : 'text/csv;charset=utf-8'})
      hiddenElement.href = URL.createObjectURL(blob)
      hiddenElement.target = '_blank'
      hiddenElement.download = 'available.csv'
      hiddenElement.click()
    },
    datetimeFormater: function (str) {
      let m = moment(str)
      m.local() 
      return m.format('DD MMM YYYY HH:mm:ss')
    },
    fetchDriver (driverID) {
      API.get('ezietruckapi', `/api/drivers/${driverID}`).then((json) => {
        for (let j of json) {
          if (j.SK.startsWith('#METADATA#')) {
            this.drivers = Object.assign({}, this.drivers, {
              [j.DriverID]: j
            })
          }
        }
      })
    }
  },
  computed: {
    ...mapGetters(['user']),
    availables () {
      let out = {}
      for (let d of this.availableItems) {
        if (!out[d.PK.split('#')[1]]) {
          out[d.PK.split('#')[1]] = {}
        }
        out[d.PK.split('#')[1]][d.date] = d
      }
      return out
    },
    dates () {
      let d = this.formData.StartDate
      let out = []
      while (d <= this.formData.EndDate) {
        d = moment(d).add(1, 'days').format('YYYY-MM-DD')
        out.push(d)
      }
      return out
    },
    times () {
      let d = this.formData.StartDate
      let out = []
      while (d <= this.formData.EndDate) {
        d = moment(d).add(1, 'days').format('YYYY-MM-DD')
        out = [...out, ...['0am', '6am', '12pm', '16pm'].map((t) => d+'T'+t)] 
      }
      return out
    },
    datetimes () {
      let d = this.formData.StartDate
      let out = []
      while (d <= this.formData.EndDate) {
        d = moment(d).add(1, 'days').format('YYYY-MM-DD')
        out = [...out, ...['00:00-06:00', '06:00-12:00', '12:00-18:00', '18:00-24:00'].map((t) => d+'T'+t)] 
      }
      return out
    }
  },
  created () {
    this.handleSearchAvailable()
  }
}
</script>