<template>
  <div class="md-layout">
    <div class="md-layout-item md-large-size-85 md-xlarge-size-85 md-medium-size-85 md-small-size-100 md-xsmall-size-100 md-content">
      <form @submit.prevent="handleCreateOrder">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100 md-layout">
            <div class="md-layout-item md-size-10" v-if="formData.Customer.CustomerLogo">
              <md-avatar class="md-large">
                <my-s3-image :imagePath="formData.Customer.CustomerLogo"></my-s3-image>
              </md-avatar>
            </div>
            <div class="md-layout-item">
              <md-autocomplete @input="handleCustomerSelect" :value="formData.Customer.CustomerName" :md-options="customers"
                :class="{'md-invalid': errors['instance.Customer']}" :disabled="!!$route.params.orderID">
                <label>Customer</label>
                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                  <md-avatar>
                    <amplify-s3-image :imagePath="item.CustomerLogo" class="md-avatar md-large"></amplify-s3-image>
                  </md-avatar>
                  <md-highlight-text :md-term="term">{{ item.CustomerName }}</md-highlight-text>
                </template>

                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  No customer matching "{{ term }}" were found.
                </template>
                <span class="md-error" v-if="errors['instance.Customer']">Invalid field input</span>
              </md-autocomplete>
            </div>
          </div>
          <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
            <md-autocomplete v-model="formData.JobType" :md-options="jobTypeAllOptions" :class="{'md-invalid': errors['instance.JobType']}">
              <label>Job Type</label>
              <template slot="md-autocomplete-empty" slot-scope="{ term }">
                <a @click="handleSaveCustomerJobType(term)">Create a new</a> Job Type!
              </template>
              <span class="md-error" v-if="errors['instance.JobType']">Invalid field input</span>
            </md-autocomplete>
          </div>
          <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
            <md-field :class="{'md-invalid': errors['instance.ProductType']}">
              <label>Product Type</label>
              <md-input v-model="formData.ProductType"></md-input>
              <span class="md-error" v-if="errors['instance.ProductType']">Invalid field input</span>
            </md-field>
          </div>
          <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
            <md-autocomplete v-model="formData.TruckType" :md-options="truckTypeOptions" :class="{'md-invalid': errors['instance.TruckType']}">
              <label>Truck Type</label>
              <template slot="md-autocomplete-empty" slot-scope="{ term }">
                <a @click="handleSaveOption('TruckType', term)">Create a new</a>Truck Type!
              </template>
              <span class="md-error" v-if="errors['instance.TruckType']">Invalid field input</span>
            </md-autocomplete>
          </div>
          <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-50 md-xsmall-size-50">
            <md-autocomplete v-model="formData.ContainerType" :md-options="containerTypeOptions" :class="{'md-invalid': errors['instance.ContainerType']}">
              <label>Container Type</label>
              <template slot="md-autocomplete-empty" slot-scope="{ term }">
                <a @click="handleSaveOption('ContainerType', term)">Create a new</a>Container Type!
              </template>
              <span class="md-error" v-if="errors['instance.ContainerType']">Invalid field input</span>
            </md-autocomplete>
          </div>
          <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-100 md-xsmall-size-100">
            <md-field :class="{'md-invalid': errors['instance.LoadPerTruck']}">
              <label>Load Per Truck (Ton)</label>
              <md-input v-model.number="formData.LoadPerTruck" type="number"></md-input>
              <span class="md-error" v-if="errors['instance.LoadPerTruck']">Invalid field input</span>
            </md-field>
          </div>
          <div class="md-layout-item md-large-size-25 md-xlarge-size-25 md-medium-size-25 md-small-size-100 md-xsmall-size-100">
            <md-field :class="{'md-invalid': errors['instance.PickerPerTruck']}">
              <label>Picker Per Truck</label>
              <md-input v-model.number="formData.PickerPerTruck" type="number"></md-input>
              <span class="md-error" v-if="errors['instance.PickerPerTruck']">Invalid field input</span>
            </md-field>
          </div>
          <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
            <div class="md-layout">
              <div :class="['md-layout-item', 'md-large-size-100', 'md-xlarge-size-100', 'md-medium-size-100', 'md-small-size-100', 'md-xsmall-size-100', {'highlight-field': favoriteForm.From.Description == formData.From.Description}]">
                <div style="display: flex;">
                  <md-autocomplete @input="handleLocationFromSelect" :value="formData.From.Description" :md-options="customerFavoriteLocations" :class="{'md-invalid': errors['instance.From.Description']}">
                    <label>From</label>
                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                      <md-highlight-text :md-term="term">{{ item.Description }}</md-highlight-text>
                    </template>
                    
                    <span class="md-error" v-if="errors['instance.From.Description']">Invalid field input</span>
                  </md-autocomplete>
                  <md-button @click="handleSaveFavLocation(formData.From)" class="md-icon-button">
                    <md-icon>save</md-icon>
                  </md-button>
                </div>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
                <md-field :class="{'md-invalid': errors['instance.From.GPS.Lat']}">
                  <label>Lat</label>
                  <md-input v-model.number="formData.From.GPS.Lat"></md-input>
                  <span class="md-error" v-if="errors['instance.From.GPS.Lat']">Invalid field input</span>
                </md-field>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
                <md-field :class="{'md-invalid': errors['instance.From.GPS.Lat']}">
                  <label>Lon</label>
                  <md-input v-model.number="formData.From.GPS.Lon"></md-input>
                  <span class="md-error" v-if="errors['instance.From.GPS.Lon']">Invalid field input</span>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
            <div class="md-layout">
              <div :class="['md-layout-item', 'md-large-size-100', 'md-xlarge-size-100', 'md-medium-size-100', 'md-small-size-100', 'md-xsmall-size-100', {'highlight-field': favoriteForm.To.Description == formData.To.Description}]">
                <div style="display: flex;">
                  <md-autocomplete @input="handleLocationToSelect" :value="formData.To.Description" :md-options="customerFavoriteLocations" :class="{'md-invalid': errors['instance.To.Description']}">
                    <label>To</label>
                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                      <md-highlight-text :md-term="term">{{ item.Description }}</md-highlight-text>
                    </template>
                    <span class="md-error" v-if="errors['instance.To.Description']">Invalid field input</span>
                  </md-autocomplete>
                  <md-button @click="handleSaveFavLocation(formData.To)" class="md-icon-button">
                    <md-icon>save</md-icon>
                  </md-button>
                </div>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
                <md-field :class="{'md-invalid': errors['instance.To.GPS.Lat']}">
                  <label>Lat</label>
                  <md-input v-model.number="formData.To.GPS.Lat"></md-input>
                  <span class="md-error" v-if="errors['instance.To.GPS.Lat']">Invalid field input</span>
                </md-field>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
                <md-field :class="{'md-invalid': errors['instance.To.GPS.Lat']}">
                  <label>Lon</label>
                  <md-input v-model.number="formData.To.GPS.Lon"></md-input>
                  <span class="md-error" v-if="errors['instance.To.GPS.Lon']">Invalid field input</span>
                </md-field>
              </div>
            </div>
          </div>
          <div :class="['md-layout-item', 'md-layout', 'md-large-size-50', 'md-xlarge-size-50', 'md-medium-size-50', 'md-small-size-100', 'md-xsmall-size-100', {'highlight-field': favoriteForm.StartDate == formData.StartDate}]">
            <div class="md-layout-item">
              <md-datepicker :value="formData.StartDate" :md-model-type="String" :class="{'md-invalid': errors['instance.StartDate']}" md-immediately @input="onStartDateClose">
                <label>Start Date</label>
                <span class="md-error" v-if="errors['instance.StartDate']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item">
              <md-field :class="{'md-invalid': errors['instance.StartTime']}">
                <md-input v-model="formData.StartTime" type="time"></md-input>
                 <span class="md-error" v-if="errors['instance.StartTime']">Invalid field input</span>
              </md-field>
            </div>
          </div>
          <div :class="['md-layout-item', 'md-layout', 'md-large-size-50', 'md-xlarge-size-50', 'md-medium-size-50', 'md-small-size-100', 'md-xsmall-size-100', {'highlight-field': favoriteForm.StartDate == formData.StartDate}]">
            <div class="md-layout-item">
              <md-datepicker v-model="formData.EndDate" :md-model-type="String" :class="{'md-invalid': errors['instance.EndDate']}" md-immediately>
                <label>End Date</label>
                <span class="md-error" v-if="errors['instance.EndDate']">Invalid field input</span>
              </md-datepicker>
            </div>
            <div class="md-layout-item">
              <md-field :class="{'md-invalid': errors['instance.EndTime']}">
                <md-input v-model="formData.EndTime" type="time"></md-input>
                 <span class="md-error" v-if="errors['instance.EndTime']">Invalid field input</span>
              </md-field>
            </div>
          </div>
          <div :class="['md-layout-item', 'md-large-size-50', 'md-xlarge-size-50', 'md-medium-size-50', 'md-small-size-100', 'md-xsmall-size-100', {'highlight-field': favoriteForm.CustomerPaidAmount == formData.CustomerPaidAmount}]">
            <md-autocomplete @input="handleCustomerPaidInput" :value="CustomerPaidAmount" :md-options="paidAmounts" :class="{'md-invalid': errors['instance.CustomerPaidAmount']}">
              <label>Customer Paid Amount</label>                
              <span class="md-error" v-if="errors['instance.CustomerPaidAmount']">Invalid field input</span>
              <template slot="md-autocomplete-empty" slot-scope="{ term }">
                <a @click="handleSavePaidOption(term)">Create a new</a> Amount!
              </template>
              <span class="md-error" v-if="errors['instance.CustomerPaidAmount']">Invalid field input</span>
            </md-autocomplete>
          </div>
          <div :class="['md-layout-item', 'md-large-size-50', 'md-xlarge-size-50', 'md-medium-size-50', 'md-small-size-100', 'md-xsmall-size-100', {'highlight-field': favoriteForm.CustomerBillDate == formData.CustomerBillDate}]">
            <md-datepicker v-model="formData.CustomerBillDate" :md-model-type="String" :class="{'md-invalid': errors['instance.CustomerBillDate']}" md-immediately>
              <label>Customer Bill Date</label>
              <span class="md-error" v-if="errors['instance.CustomerBillDate']">Invalid field input</span>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
            <div class="md-layout">
            <div :class="['md-layout-item', 'md-large-size-100', 'md-xlarge-size-100', 'md-medium-size-100', 'md-small-size-100', 'md-xsmall-size-100', 'md-layout', {'highlight-field': favoriteForm.Trip.Driver.DriverName == formData.Trip.Driver.DriverName}]">
              <div class="md-layout-item md-size-10" v-if="formData.Trip.Driver.DriverPhoto">
                <md-avatar class="md-large">
                  <my-s3-image :imagePath="formData.Trip.Driver.DriverPhoto"></my-s3-image>
                </md-avatar>
              </div>
              <div class="md-layout-item">
                <md-autocomplete @input="handleDriverSelect" :value="formData.Trip.Driver.DriverName" :md-options="drivers" :class="{'md-invalid': errors['instance.Driver.DriverName']}">
                  <label>Driver</label>
                  <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                    <md-highlight-text :md-term="term">{{ item.DriverName }}</md-highlight-text>
                  </template>

                  <template slot="md-autocomplete-empty" slot-scope="{ term }">
                    No driver matching "{{ term }}" were found. <a @click="noop()">Create a new</a> one!
                  </template>
                  <span class="md-error" v-if="errors['instance.Trip.Driver.DriverName']">Invalid field input</span>
                </md-autocomplete>
              </div>
            </div>
            <div class="md-layout-item md-large-size-100 md-xlarge-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                <md-field :class="{'md-invalid': errors['instance.Trip.TruckSupporter']}">
                  <label>Truck Supporter</label>
                  <md-input v-model.number="formData.Trip.TruckSupporter"></md-input>
                  <span class="md-error" v-if="errors['instance.Trip.TruckSupporter']">Invalid field input</span>
                </md-field>
              </div>
              <div :class="['md-layout-item', 'md-large-size-50', 'md-xlarge-size-50', 'md-medium-size-50', 'md-small-size-100', 'md-xsmall-size-100', {'highlight-field': favoriteForm.Trip.DeliveryCost == formData.Trip.DeliveryCost}]">
                <md-autocomplete @input="handleDeliveryCostInput" :value="DeliveryCost" :md-options="paidAmounts" :class="{'md-invalid': errors['instance.Trip.DeliveryCost']}">
                  <label>Delivery Cost</label>                
                  <span class="md-error" v-if="errors['instance.Trip.DeliveryCost']">Invalid field input</span>
                  <template slot="md-autocomplete-empty" slot-scope="{ term }">
                    <a @click="handleSavePaidOption(term)">Create a new</a> Amount!
                  </template>
                  <span class="md-error" v-if="errors['instance.Trip.DeliveryCost']">Invalid field input</span>
                </md-autocomplete>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
                <md-field :class="{'md-invalid': errors['instance.Trip.DailyPickerCost']}">
                  <label>Daily Picker Cost</label>
                  <md-input v-model.number="formData.Trip.DailyPickerCost" type="number"></md-input>
                  <span class="md-error" v-if="errors['instance.DailyPickerCost']">Invalid field input</span>
                </md-field>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
                <md-field :class="{'md-invalid': errors['instance.Trip.OtherCost']}">
                  <label>Other Cost</label>
                  <md-input v-model.number="formData.Trip.OtherCost" type="number"></md-input>
                  <span class="md-error" v-if="errors['instance.OtherCost']">Invalid field input</span>
                </md-field>
              </div>
              <div :class="['md-layout-item', 'md-large-size-50', 'md-xlarge-size-50', 'md-medium-size-50', 'md-small-size-100', 'md-xsmall-size-100', {'highlight-field': favoriteForm.Trip.SalaryPerTrip == formData.Trip.SalaryPerTrip}]">
                <md-field :class="{'md-invalid': errors['instance.Trip.SalaryPerTrip']}">
                  <label>Salary Per Trip</label>
                  <md-input v-model.number="formData.Trip.SalaryPerTrip" type="number"></md-input>
                  <span class="md-error" v-if="errors['instance.SalaryPerTrip']">Invalid field input</span>
                </md-field>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
                <md-field :class="{'md-invalid': errors['instance.Trip.FuelAllowance']}">
                  <label>Fuel Allowance</label>
                  <md-input v-model.number="formData.Trip.FuelAllowance" type="number"></md-input>
                  <span class="md-error" v-if="errors['instance.Trip.FuelAllowance']">Invalid field input</span>
                </md-field>
              </div>
              <div :class="['md-layout-item', 'md-large-size-50', 'md-xlarge-size-50', 'md-medium-size-50', 'md-small-size-100', 'md-xsmall-size-100', {'highlight-field': favoriteForm.Trip.DriverPaidDueDate == formData.Trip.DriverPaidDueDate}]">
                <md-datepicker v-model="formData.Trip.DriverPaidDueDate" :md-model-type="String" :class="{'md-invalid': errors['instance.Trip.DriverPaidDueDate']}" md-immediately>
                  <label>Driver Paid Due Date</label>
                  <span class="md-error" v-if="errors['instance.Trip.DriverPaidDueDate']">{{errors['instance.Trip.DriverPaidDueDate']}}</span>
                </md-datepicker>
              </div>
              <div :class="['md-layout-item', 'md-large-size-100', 'md-xlarge-size-100', 'md-medium-size-100', 'md-small-size-100', 'md-xsmall-size-100']">
                <md-field :class="{'md-invalid': errors['instance.CreatedBy']}">
                  <label>Owner</label>
                  <md-select v-model="formData.CreatedBy">
                    <md-option v-for="user in userOptions" :value="user" :key="user">{{user}}</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
                <md-field :class="{'md-invalid': errors['instance.Trip.Driver.DriverID']}">
                  <label>Driver ID</label>
                  <md-input v-model="formData.Trip.Driver.DriverID" disabled></md-input>
                  <span class="md-error" v-if="errors['instance.Trip.Driver.DriverID']">Invalid field input</span>
                </md-field>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
                <md-field :class="{'md-invalid': errors['instance.Trip.Driver.DriverID']}">
                  <label>License Plate</label>
                  <md-input v-model="formData.Trip.LicensePlate" disabled></md-input>
                  <span class="md-error" v-if="errors['instance.Trip.Driver.DriverID']">Invalid field input</span>
                </md-field>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
                <md-field :class="{'md-invalid': errors['instance.Trip.Driver.DriverID']}">
                  <label>Truck Type</label>
                  <md-input v-model="formData.Trip.TruckType" disabled></md-input>
                  <span class="md-error" v-if="errors['instance.Trip.Driver.DriverID']">Invalid field input</span>
                </md-field>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
                <md-field :class="{'md-invalid': errors['instance.Trip.Driver.DriverID']}">
                  <label>Container Type</label>
                  <md-input v-model="formData.Trip.ContainerType" disabled></md-input>
                  <span class="md-error" v-if="errors['instance.Trip.Driver.DriverID']">Invalid field input</span>
                </md-field>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
                <md-field :class="{'md-invalid': errors['instance.Trip.Driver.DriverID']}">
                  <label>Total deliver cost</label>
                  <md-input :value="(formData.Trip.DeliveryCost*1000 + formData.Trip.DailyPickerCost*1000 + formData.Trip.OtherCost*1000 + formData.Trip.SalaryPerTrip*1000 + formData.Trip.FuelAllowance*1000)/1000" disabled></md-input>
                  <span class="md-error" v-if="errors['instance.Trip.Driver.DriverID']">Invalid field input</span>
                </md-field>
              </div>
              <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-50">
                <md-field :class="{'md-invalid': errors['instance.Trip.Margin']}">
                  <label>Margin</label>
                  <md-input :value="(formData.CustomerPaidAmount*1000 - (formData.Trip.DeliveryCost*1000 + formData.Trip.DailyPickerCost*1000 + formData.Trip.OtherCost*1000 + formData.Trip.SalaryPerTrip*1000 + formData.Trip.FuelAllowance*1000))/1000" disabled></md-input>
                  <span class="md-error" v-if="errors['instance.Trip.Margin']">Invalid field input</span>
                </md-field>
              </div>
              <div class="md-layout-item md-large-size-100 md-xlarge-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                <md-field :class="{'md-invalid': errors['instance.Remark']}">
                  <label>Remark</label>
                  <md-textarea v-model="formData.Remark"></md-textarea>
                  <span class="md-error" v-if="errors['instance.Remark']">Invalid field input</span>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-large-size-50 md-xlarge-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
            <md-checkbox v-model="favoriteOrder">Save as favorite.</md-checkbox>
          </div>
        </div>
        <md-card class="md-primary" v-show="Object.keys(warnings).length > 0">
          <md-card-header>
            <div class="md-subtitle">Warning</div>
          </md-card-header>
          <md-card-content>
            <ul>
              <li v-for="(warning, k) in warnings" :key="k">{{warning}}</li>
            </ul>
          </md-card-content>
        </md-card>
        <md-button type="submit" class="md-raised md-primary" v-show="!hideSubmit">
          <span v-show="!$route.params.orderID">Create an order</span>
          <span v-show="!!$route.params.orderID">Update an order</span>
        </md-button>
        <md-button class="md-raised" @click="$router.go(-1)">Back</md-button>
        <md-snackbar :md-position="'center'" :md-duration="snackBarDuration" :md-active.sync="showSnackbar" md-persistent>
          <span>{{snackBarMsg}}</span>
        </md-snackbar>
      </form>
    </div>
    <div class="md-layout-item md-large-size-15 md-xlarge-size-15 md-medium-size-15 md-small-hide md-xsmall-hide md-elevation-1">
      <div :style="{'position': 'fixed', 'width': $vssWidth*0.15 + 'px', 'max-height': $vssHeight-190 + 'px', 'overflow': 'auto'}">
        <md-list class="md-triple-line">
          <md-list-item v-for="order in sortedOrders" :key="order.OrderID" :to="`/Orders/${order.OrderID}`" @click="fetchData">
            <div class="md-list-item-text">
              <span v-if="formData.OrderID === '-' && order.OrderID === submitedForm[0].OrderID" style="font-style: italic;">**NEW**</span>
              <span v-else>{{order.OrderID}}</span>
              <span v-if="order.From && order.To">{{order.From.Description}} - {{order.To.Description}} </span>
              <p v-if="trip[order.PK]">{{trip[order.PK].DriverName}}</p>
            </div>
          </md-list-item>
        </md-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueScreenSize from 'vue-screen-size'
import {validate} from 'jsonschema'
import moment from 'moment'
import { API } from 'aws-amplify'
import schema from '../schema'
import MyS3Image from '../components/MyS3Image'

export default {
  name: 'OrderForm',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image},
  data () {
    let timeChoices = []
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 15) {
        let s = ''
        if (i < 10) {
          s += '0'
        }
        s += i.toString() + ':'
        if (j < 10) {
          s += '0'
        }
        s += j.toString()
        timeChoices.push(s)
      }
    }
    return {
      customerItems: [],
      userItems: [],
      jobTypeOptions: [],
      jobTypeCustomerOptions: [],
      priceOptions: [],
      truckTypeOptions: [],
      containerTypeOptions: [],
      customerFavoriteLocations: [],
      paidAmounts: [],
      hideSubmit: false,
      FieldBatchSize: {},
      showSnackbar: false,
      snackBarMsg: null,
      snackBarDuration: 4000,
      oldStatus: null,
      drivers: [],
      orders: [],
      trip: {},
      CustomerPaidAmount: null,
      DeliveryCost: null,
      favoriteOrder: false,
      loading: true,
      favoriteForm: {
        From: {Description: null},
        To: {Description: null},
        CustomerPaidAmount: null,
        Trip: {
          Driver: {
            DriverName: null
          },
          DeliveryCost: null,
          SalaryPerTrip: null
        }
      },
      formData: {
        PK: '-',
        SK: '-',
        Customer: {},
        Date: moment().format('YYYY-MM-DD'),
        OrderID: '-',
        JobType: null,
        ProductType: 'Normal goods',
        TruckType: null,
        ContainerType: null,
        LoadPerTruck: 0,
        PickerPerTruck: 0,
        From: {
          Description: null,
          GPS: {
            Lat: null,
            Lon: null
          }
        },
        To: {
          Description: null,
          GPS: {
            Lat: null,
            Lon: null
          }
        },
        TruckRequest: 1,
        StopNumber: 1,
        TripRequest: 1,
        StartDate: moment().format('YYYY-MM-DD'),
        StartTime: null,
        EndDate: moment().format('YYYY-MM-DD'),
        EndTime: null,
        CustomerPaidAmount: 0,
        CustomerBillDate: moment().endOf('month').format('YYYY-MM-DD'),
        OrderStatus: '1:Preparing',
        Trip: {
          PK: '-',
          SK: '-',
          Driver: {
            DriverName: null,
            DriverID: null,
            DriverPhoto: null,
          },
          TripID: '-',
          OrderID: '-',
          DeliveryCost: 0,
          DailyPickerCost: 0,
          OtherCost: 0,
          SalaryPerTrip: 0,
          LicensePlate: null,
          TruckType: null,
          ContainerType: null,
          FuelAllowance: 0,
          DriverPaidDueDate: null,
          TripStatus: '1:Waiting'
        },
        CreatedBy: null,
        Remark: null
      },
      errors: {},
      warnings: {},
      timeChoices: timeChoices
    }
  },
  methods: {
    handleCustomerSelect (value) {
      if (!this.loading) {
        if (typeof value === 'object') {
          this.formData.Customer = value
          let self = this
          API.get('ezietruckapi', `/api/customers/${value.CustomerID}/locations`).then((json) => {
            self.customerFavoriteLocations = json
          })
          this.orders = []
          this.trip = {}
          this.loadCustomerOrders(value.CustomerID, value.CustomerID + moment().format('YYYYMMDD'), '1:Preparing', null)
          this.loadCustomerOrders(value.CustomerID, value.CustomerID + moment().format('YYYYMMDD'), '2:Waiting', null)
          this.loadCustomerJobType(value.CustomerID)
        } else {
          if (!value) {
            this.formData.Customer = {CustomerID: '', CustomerName: '', CustomerLogo: ''}
            this.formData = Object.assign({}, this.formData)
            console.log('c' + this.formData.CustomerBillDate)
            this.customerFavoriteLocations = []
            this.orders = []
            this.trip = {}
          }
        }
      }
    },
    handleLocationFromSelect (value) {
      if (!this.loading) {
        if (typeof value === 'object') {
          this.formData.From.Description = value.Description
          if (value.GPS) {
            this.formData.From.GPS = value.GPS
          }
        } else {
          this.formData.From.Description = value
        }
      }
    },
    handleLocationToSelect (value) {
      if (!this.loading) {
        if (typeof value === 'object') {
          this.formData.To.Description = value.Description
          if (value.GPS) {
            this.formData.To.GPS = value.GPS
          }
        } else {
          this.formData.To.Description = value
        }
      }
    },
    loadCustomerOrders (customerID, beginsWith, status, lastEvaluatedKey) {
      let self = this
      let body = {'q': {'beginsWith': beginsWith, 'status': status}, 'opt': {'IncludeTrips': true}}
      if (lastEvaluatedKey) {
        body['opt']['LastEvaluatedKey'] = lastEvaluatedKey
      }
      API.post('ezietruckapi', `/api/customers/${customerID}/orders`, {body: body}).then((json) => {
        self.orders = [...self.orders, ...json['Items'].filter((i) => i.SK.startsWith('#METADATA#'))]
        json['Items'].filter((i) => i.SK.startsWith('TRIP#')).forEach((i) => self.trip[i.PK] = i)
        self.trip = Object.assign({}, self.trip)
        if (json.LastEvaluatedKey) {
          self.loadCustomerOrders(customerID, beginsWith, status, json.LastEvaluatedKey)
        }
      })
    },
    loadCustomerJobType (customerID) {
      API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`).then((json) => {
        this.jobTypeCustomerOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/customers/${customerID}/favorite-prices`).then((json) => {
        this.paidAmounts = json.map((j) => j.Name)
      })
    },
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items']]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchUser: function () {
      let self = this
      API.get('ezietruckapi', '/api/users').then((json) => {
        self.userItems = json['Users']
      })
    },
    fetchOptions: function () {
      let self = this
      this.fetchCustomer()
      API.get('ezietruckapi', `/api/options/JobType`).then((json) => {
        self.jobTypeOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/TruckType`).then((json) => {
        self.truckTypeOptions = json.map((j) => j.Name)
      })
      API.get('ezietruckapi', `/api/options/ContainerType`).then((json) => {
        self.containerTypeOptions = json.map((j) => j.Name)
      })
    },
    fetchData: function () {
      let self = this
      this.fetchOptions()
      if (this.$route.params.orderID) {
        API.get('ezietruckapi',`/api/orders/${this.$route.params.orderID}`).then((json) => {
          self.oldStatus = json.OrderStatus
          let formData = {
            PK: json.PK,
            SK: json.SK,
            Customer: {
              CustomerID: json.CustomerID,
              CustomerName: json.CustomerName,
              CustomerLogo: json.CustomerLogo || ''
            },
            Date: json.Date,
            OrderID: json.OrderID,
            JobType: json.JobType,
            ProductType: json.ProductType,
            TruckType: json.TruckType,
            ContainerType: json.ContainerType,
            LoadPerTruck: json.LoadPerTruck || 0,
            PickerPerTruck: json.PickerPerTruck,
            From: {
              Description: json.From.Description,
              GPS: {
                Lat: json.From.GPS ? json.From.GPS.Lat : null,
                Lon: json.From.GPS ? json.From.GPS.Lon : null
              }
            },
            To: {
              Description: json.To.Description,
              GPS: {
                Lat: json.To.GPS ? json.To.GPS.Lat : null,
                Lon: json.To.GPS ? json.To.GPS.Lat : null
              }
            },
            TruckRequest: json.TruckRequest,
            StopNumber: json.StopNumber,
            TripRequest: json.TruckRequest,
            StartDate: moment(json.StartDateTime).format('YYYY-MM-DD'),
            StartTime: moment(json.StartDateTime).format('HH:mm'),
            EndDate: moment(json.EndDateTime).format('YYYY-MM-DD'),
            EndTime: moment(json.EndDateTime).format('HH:mm'),
            CustomerPaidAmount: json.CustomerPaidAmount,
            CustomerBillDate: moment(json.CustomerBillDate).format('YYYY-MM-DD'),
            OrderStatus: json.OrderStatus,
            OrderSheetRef: json.OrderSheetRef || null,
            Remark: json.Remark || null,
            CreatedBy: json.CreatedBy,
            CreatedAt: json.CreatedAt || (new Date()).toISOString()
          }
          self.formData = Object.assign({}, self.formData, formData)
          self.CustomerPaidAmount = json.CustomerPaidAmount.toString()
          API.get('ezietruckapi',`/api/customers/${json.CustomerID}/locations`).then((json) => {
            self.customerFavoriteLocations = json
          })
          self.orders = []
          self.errors = {}
          self.warnings = {}
          self.loadCustomerOrders(json.CustomerID, json.OrderID.slice(0, 11), json.OrderStatus, null)
          API.get('ezietruckapi',`/api/orders/${self.$route.params.orderID}/trips`).then((tjson) => {
            let trip = {}
            for (let j of tjson) {
              trip = {
                PK: j.PK,
                SK: j.SK,
                TripID: j.TripID,
                OrderID: j.OrderID,
                Driver: {
                  DriverName: j.DriverName,
                  DriverID: j.DriverID,
                  DriverPhoto: j.DriverPhoto
                },
                LicensePlate: j.LicensePlate,
                TruckType: j.TruckType,
                ContainerType: j.ContainerType,
                DeliveryCost: j.DeliveryCost,
                DailyPickerCost: j.DailyPickerCost,
                OtherCost: j.OtherCost,
                SalaryPerTrip: j.SalaryPerTrip,
                FuelAllowance: j.FuelAllowance,
                DriverPaidDueDate: j.DriverPaidDueDate,
                TripStatus: j.TripStatus,
                OrderSheetRef: j.OrderSheetRef || null,
                AssignSheetRef: j.AssignSheetRef || null,
                CreatedBy: j.CreatedBy || null,
                CreatedAt: j.CreatedAt || (new Date()).toISOString()
              }
              self.DeliveryCost = j.DeliveryCost.toString()
            }
            if (tjson.length > 0) {
              self.formData = Object.assign({}, self.formData, {Trip: trip})
            }
            self.loading = false
          })
        })
      }  else if (this.$route.params.customer && this.$route.params.name) {
        this.orders = []
        this.trip = {}
        this.loadCustomerOrders(this.$route.params.customer, this.$route.params.customer + moment().format('YYYYMMDD'), '1:Preparing', null)
        this.loadCustomerOrders(this.$route.params.customer, this.$route.params.customer + moment().format('YYYYMMDD'), '2:Waiting', null)
        API.get('ezietruckapi', `/api/customers/${this.$route.params.customer}/favorite-orders/${this.$route.params.name}`).then((json) => {
          self.favoriteForm = JSON.parse(JSON.stringify(json.OrderTemplate))
          self.formData = Object.assign({}, self.formData, json.OrderTemplate)
          self.formData.PK = '-'
          self.formData.SK = '-'
          self.formData.Date = moment().format('YYYY-MM-DD')
          self.formData.CustomerBillDate = moment(self.formData.StartDate + ' ' + self.formData.StartTime).endOf('month').format('YYYY-MM-DD')
          self.formData.CreatedBy = self.user.signInUserSession.idToken.payload.email
          self.formData.OrderID = '-'
          self.CustomerPaidAmount = self.formData.CustomerPaidAmount.toString()
          self.formData.CreatedAt = (new Date()).toISOString()
          self.formData.OrderSheetRef = Math.random().toString(36).substring(2)
          if (!self.formData.From.GPS) {
            self.formData.From.GPS = {Lat: null, Lon: null}
          }
          if (!self.formData.To.GPS) {
            self.formData.To.GPS = {Lat: null, Lon: null}
          }
          if (json.OrderTemplate.Trip.Driver.DriverName) {
            self.formData.Trip.PK = '-'
            self.formData.Trip.SK = '-'
            self.formData.Trip.TripID = '-'
            self.formData.Trip.OrderID = '-'
            self.DeliveryCost = json.OrderTemplate.Trip.DeliveryCost.toString()
            self.formData.Trip.CreatedBy = self.user.signInUserSession.idToken.payload.email
            self.formData.Trip.CreatedAt = (new Date()).toISOString()
            self.formData.Trip.OrderSheetRef = self.formData.OrderSheetRef
            self.formData.Trip.AssignSheetRef =  Math.random().toString(36).substring(2)
          }
          self.loading = false
        })
        API.get('ezietruckapi', `/api/customers/${this.$route.params.customer}/locations`).then((json) => {
          self.customerFavoriteLocations = json
        })
      } else {
        this.loading = false
      }
    },
    handleSaveOption (field, value) {
      let self = this
      API.post('ezietruckapi', `/api/options/${field}`, {body: {Name: value}}).then((json) => {
        if (json) {
          self.showSnackbar = true
          self.snackBarMsg = 'Successfully saved'
          self.fetchOptions()
        } else {
          self.showSnackbar = true
          self.snackBarMsg = 'Something went wrong'
        }
      }).catch((e) => {
        self.showSnackbar = true
        self.snackBarMsg = e.message
      })
    },
    handleSaveCustomerJobType (value) {
      let self = this
      API.post('ezietruckapi', `/api/customers/${this.formData.Customer.CustomerID}/favorite-job-types`, {body: {Name: value}}).then((json) => {
        if (json) {
          self.showSnackbar = true
          self.snackBarMsg = 'Successfully saved'
          self.fetchOptions()
        } else {
          self.showSnackbar = true
          self.snackBarMsg = 'Something went wrong'
        }
      })
    },
    handleDriverSelect (value) {
      if (!this.loading) {
        if (typeof value === 'object') {
          this.formData.Trip.Driver = value
          if (value.vehicles.length > 0) {
            this.formData.Trip.LicensePlate = value.vehicles[0].VehicleLicenseNumber + ' ' + value.vehicles[0].VehicleLicenseProvince
            this.formData.Trip.TruckType = value.vehicles[0].VehicleTruckType
            this.formData.Trip.ContainerType = value.vehicles[0].VehicleContainerType
          }
          this.formData = Object.assign({}, this.formData)
          this.formData.Trip.DriverPaidDueDate = moment(this.submitedForm.StartDateTime).add(7, 'days').format('YYYY-MM-DD')
        } else {
          if (!value) {
            this.formData.Trip.Driver = {
              DriverName: '',
              DriverID: '',
              DriverPhoto: '',
            }
            this.formData.Trip.LicensePlate = null
            this.formData.Trip.TruckType = null
            this.formData.Trip.ContainerType = null
            this.formData = Object.assign({}, this.formData)
          }
        }
      }
    },
    formValidate: function () {
      let form = Object.assign({}, this.formData)
      if (form.From.GPS.Lat == null || form.From.GPS.Lon == null) {
        form.From = Object.assign({}, {Description: form.From.Description})
      }
      if (form.To.GPS.Lat == null || form.To.GPS.Lon == null) {
        form.To = Object.assign({}, {Description: form.To.Description})
      }
      if (this.formData.Remark == null) {
        delete form.Remark
      }
      if (!form.CreatedAt) {
        form.CreatedAt = (new Date()).toISOString()
      }
      if (!form.Trip.CreatedAt) {
        form.Trip.CreatedAt = (new Date()).toISOString()
      }
      let p = validate(form, schema.OrderFormSchema)
      this.errors = {}
      this.warnings = {}
      for (let err of p.errors) {
        this.errors[err.property] = err.message
      }
      if (!this.formData.Trip.Driver.DriverID) {
        this.errors['instance.Trip.Driver.DriverID'] = 'Driver is required.'
      }
      let startDate = moment(form.StartDate + ' ' + form.StartTime, 'YYYY-MM-DD HH:mm')
      let endDate = moment(form.EndDate + ' ' + form.EndTime, 'YYYY-MM-DD HH:mm')
      if (endDate < startDate) {
        this.errors['instance.EndDate'] = 'End date not after Start date'
        this.errors['instance.EndTime'] = 'End date not after Start date'
      }
      if (moment(form.CustomerBillDate, 'YYYY-MM-DD') < startDate.startOf('day')) {
        this.errors['instance.CustomerBillDate'] = 'Customer bill date not after Start date'
      }
      if (form.Trip) {
        if (form.Trip.DriverPaidDueDate && moment(form.Trip.DriverPaidDueDate, 'YYYY-MM-DD') < startDate.startOf('day')) {
          this.errors['instance.Trip.DriverPaidDueDate'] = 'Driver paid due date is before Start date'
        }
        // if ((this.formData.Trip.DeliveryCost*1000 + this.formData.Trip.DailyPickerCost*1000 + this.formData.Trip.OtherCost*1000 + this.formData.Trip.SalaryPerTrip*1000 + this.formData.Trip.FuelAllowance*1000) <= 0) {
        //   this.errors['instance.Trip.DeliveryCost'] = 'Total Delivery Cost should more than zero'
        //   this.errors['instance.Trip.DailyPickerCost'] = 'Total Delivery Cost should more than zero'
        //   this.errors['instance.Trip.OtherCost'] = 'Total Delivery Cost should more than zero'
        //   this.errors['instance.Trip.SalaryPerTrip'] = 'Total Delivery Cost should more than zero'
        //   this.errors['instance.Trip.FuelAllowance'] = 'Total Delivery Cost should more than zero'
        // }
        // if (this.formData.CustomerPaidAmount*1000 < (this.formData.Trip.DeliveryCost*1000 + this.formData.Trip.DailyPickerCost*1000 + this.formData.Trip.OtherCost*1000 + this.formData.Trip.SalaryPerTrip*1000 + this.formData.Trip.FuelAllowance*1000)) {
        //   this.warnings['instance.Trip.Margin'] = 'Total delivery cost less then customer paid.'
        // }
      }
      if (startDate < moment().subtract(3, 'months')) {
        this.warnings['instance.startDate'] = 'Start date older than 3 months'
      }
      if (endDate < moment().subtract(3, 'months')) {
        this.warnings['instance.endDate'] = 'End date older than 3 months'
      }
      this.warnings = Object.assign({}, this.warnings)
      this.errors = Object.assign({}, this.errors)
    },
    handleCreateOrder: function () {
      this.formValidate()
      if (Object.keys(this.errors).length == 0) {
        this.hideSubmit = true
        let self = this
        if (this.$route.params.orderID) {
          let order = [...this.submitedForm]
          if (order[1] && order[1].PK === '-') {
            order[1].PK = 'ORDER#' + order[0].OrderID
            order[1].SK = 'TRIP#' + order[0].OrderID + '.1'
            order[1].OrderID = order[0].OrderID
            order[1].TripID = order[0].OrderID + '.1'
            order[1].OrderSheetRef = order[0].OrderSheetRef
            order[1].AssignSheetRef = Math.random().toString(36).substring(2)
            order[1].CreatedAt = (new Date()).toISOString()
          }
          API.patch('ezietruckapi', `/api/orders?oldStatus=${this.oldStatus}`, {body: order}).then((json) => {
            self.hideSubmit = false
            if (json) {
              self.showSnackbar = true
              self.snackBarMsg = 'Successfully saved'
              self.orders = []
              self.trip = {}
              self.loadCustomerOrders(order[0].CustomerID, order[0].OrderID.slice(0, 11), order[0].OrderStatus, null)
            } else {
              self.showSnackbar = true
              self.snackBarMsg = 'Something went wrong'
            }
          }).catch((e) => {
            self.showSnackbar = true
            self.snackBarMsg = e.message
            self.hideSubmit = false
          })
        } else {
          if (this.submitedForm[0].PK === '-' && this.submitedForm[0].CustomerID) {
            let orderID = `${this.submitedForm[0].CustomerID}${moment().format('YYYYMMDD')}_`
            let order = [...this.submitedForm]
            API.get('ezietruckapi', `/api/customers/${this.submitedForm[0].CustomerID}`).then((customer) => {
              if (customer.LastOrderID && customer.LastOrderID.slice(3, 11) === moment().format('YYYYMMDD')) {
                let s = `${parseInt(customer.LastOrderID.slice(12)) + 1}`
                while (s.length < 3) s = "0" + s
                orderID += s
              } else {
                orderID += '001'
              }
              order[0].PK = 'ORDER#' + orderID
              order[0].SK = '#METADATA#' + orderID
              order[0].Date = moment().format('YYYY-MM-DD')
              order[0].OrderID = orderID
              order[0].OrderSheetRef = Math.random().toString(36).substring(2)
              order[0].CreatedAt = (new Date()).toISOString()
              if (order[1]) {
                order[1].PK = 'ORDER#' + orderID
                order[1].SK = 'TRIP#' + orderID + '.1'
                order[1].OrderID = orderID
                order[1].TripID = orderID + '.1'
                order[1].OrderSheetRef = order[0].OrderSheetRef
                order[1].AssignSheetRef = Math.random().toString(36).substring(2)
                if (!order[1].CreatedAt) {
                  order[1].CreatedAt = (new Date()).toISOString()
                }
              }
              API.post('ezietruckapi', `/api/orders`, {body: order}).then((json) => {
                self.hideSubmit = false
                if (json) {
                  self.showSnackbar = true
                  self.snackBarMsg = 'Successfully saved'
                  self.orders = []
                  self.trip = {}
                  self.loadCustomerOrders(order[0].CustomerID, order[0].OrderID.slice(0, 11), order[0].OrderStatus, null)
                  self.formData.Trip.CreatedAt = null
                  self.formData = Object.assign({}, self.formData, {
                    PK: '-',
                    SK: '-',
                    OrderID: '-',
                    CreatedAt: null
                  })
                } else {
                  self.showSnackbar = true
                  self.snackBarMsg = 'Something went wrong'
                }
              }).catch((e) => {
                self.showSnackbar = true
                self.snackBarMsg = e.message
                self.hideSubmit = false
              })
            })
          }
        }
        if (this.favoriteOrder) {
          this.favoriteOrder = false
          let favOrder = Object.assign({}, this.formData, {
            PK: '-', SK: '-', OrderID: '-', FavoriteOrderName: Math.random().toString(36).substring(2),
            Trip: Object.assign({}, this.formData.Trip)
          })
          favOrder.Customer = {
            CustomerID: favOrder.Customer.CustomerID,
            CustomerName: favOrder.Customer.CustomerName,
            CustomerLogo: favOrder.Customer.CustomerLogo
          }
          if (!favOrder.From.GPS.Lat || !favOrder.From.GPS.Lon) {
            favOrder.From = {Description: favOrder.From.Description}
          }
          if (!favOrder.To.GPS.Lat || !favOrder.To.GPS.Lon) {
            favOrder.To = {Description: favOrder.To.Description}
          }
          if (!favOrder.Remark) {
            delete favOrder.Remark
          }
          delete favOrder.CreatedAt
          delete favOrder.CreatedBy
          delete favOrder.OrderSheetRef
          if (favOrder.Trip.Driver.DriverID) {
            favOrder.OrderStatus = '2:Waiting'
            favOrder.Trip.Driver = Object.assign({}, favOrder.Trip.Driver)
            delete favOrder.Trip.CreatedAt
            delete favOrder.Trip.CreatedBy
            delete favOrder.Trip.OrderSheetRef
            delete favOrder.Trip.AssignSheetRef
          } else {
            delete favOrder.Trip
          }
          let p = validate(favOrder, schema.FavoriteOrderSchema)
          if (p.errors.length === 0) {
            API.post('ezietruckapi', `/api/customers/${this.submitedForm[0].CustomerID}/favorite-orders`, {body: favOrder})
          } else {
            self.showSnackbar = true
            self.snackBarMsg = p.errors
            self.hideSubmit = false
          }
        }
        if (this.$route.params.customer && this.$route.params.name) {
          let favOrder = Object.assign({}, this.formData, {
            FavoriteOrderName: this.$route.params.name,
            PK: '-', SK: '-', OrderID: '-', Trip: Object.assign({}, this.formData.Trip)
          })
          favOrder.Customer = {
            CustomerID: favOrder.Customer.CustomerID,
            CustomerName: favOrder.Customer.CustomerName,
            CustomerLogo: favOrder.Customer.CustomerLogo
          }
          if (!favOrder.From.GPS.Lat || !favOrder.From.GPS.Lon) {
            favOrder.From = {Description: favOrder.From.Description}
          }
          if (!favOrder.To.GPS.Lat || !favOrder.To.GPS.Lon) {
            favOrder.To = {Description: favOrder.To.Description}
          }
          delete favOrder.CreatedAt
          delete favOrder.CreatedBy
          delete favOrder.OrderSheetRef
          if (favOrder.Trip.Driver.DriverID) {
            favOrder.OrderStatus = '2:Waiting'
            favOrder.Trip.Driver = Object.assign({}, favOrder.Trip.Driver)
            delete favOrder.Trip.CreatedAt
            delete favOrder.Trip.CreatedBy
            delete favOrder.Trip.OrderSheetRef
            delete favOrder.Trip.AssignSheetRef
          } else {
            delete favOrder.Trip
          }
          let p = validate(favOrder, schema.FavoriteOrderSchema)
          if (p.errors.length === 0) {
            API.post('ezietruckapi', `/api/customers/${this.submitedForm[0].CustomerID}/favorite-orders`, {body: favOrder})
          } else {
            console.log(p.errors)
            console.log(favOrder)
          }
        }
      } else {
        this.showSnackbar = true
        this.snackBarMsg = 'Invalid form input'
        this.hideSubmit = false
      }
    },
    fetchDriver: function (truckType, containerType, lastEvaluatedKey) {
      let body = {body: {truckType: truckType, containerType: containerType, opt: {}}}
      if (lastEvaluatedKey) {
        body.body.opt['LastEvaluatedKey'] = lastEvaluatedKey
      }
      API.post('ezietruckapi', `/api/vehicles/q`, body).then((json) => {
        if (!lastEvaluatedKey) {
          this.drivers = []
          if (this.formData.Trip.TruckType !== truckType || this.formData.Trip.ContainerType !== containerType) {
            this.formData.Trip.Driver = {
              DriverName: '',
              DriverID: '',
              DriverPhoto: '',
            }
            this.formData.Trip.LicensePlate = null
            this.formData.Trip.TruckType = null
            this.formData.Trip.ContainerType = null
          }
          this.formData = Object.assign({}, this.formData)
        }
        if (json && json['Items']) {
          for (let driver of json['Items']) {
            let driverIndex = this.drivers.findIndex((d) => d['PK'] === driver['PK'])
            if (driverIndex < 0) {
              if (driver['SK'].startsWith('#METADATA#')) {
                this.drivers.push(Object.assign(driver, {'vehicles': []}))
              } else {
                this.drivers.push({'PK': driver['PK'], 'vehicles': [driver]})
              }
            } else {
              if (driver['SK'].startsWith('#METADATA#')) {
                this.drivers[driverIndex] = Object.assign(this.drivers[driverIndex], driver)
              } else {
                this.drivers[driverIndex]['vehicles'].push(driver)
              }
            }
          }
          this.drivers = [...this.drivers]
          if (json.LastEvaluatedKey) {
            this.fetchDriver(truckType, containerType, json.LastEvaluatedKey)
          }
        }
      }).catch(() => {})
    },
    onStartDateClose: function (event) {
      if (!this.loading) {
        this.formData.StartDate = event
        this.formData.EndDate = this.formData.StartDate
        this.formData.CustomerBillDate = moment(event).endOf('month').format('YYYY-MM-DD')
        if (this.formData.Trip.Driver.DriverID) {
          this.formData.Trip.DriverPaidDueDate = moment(event).add(7, 'days').format('YYYY-MM-DD')
        }
      }
    },
    handleSaveFavLocation: function (location) {
      if (this.formData.Customer.CustomerID && location.Description) {
        let body = {PK: `FAVORITE#LOCATION#${this.formData.Customer.CustomerID}`}
        body.SK = `${this.formData.Customer.CustomerID}#${location.Description}`
        body.Description = location.Description
        if (location.GPS.Lat && location.GPS.Lon) {
          body.GPS = {
            Lat: location.GPS.Lat,
            Lon: location.GPS.Lon
          }
        }
        let self = this
        API.post('ezietruckapi', `/api/customers/${this.formData.Customer.CustomerID}/locations`, {body: body}).then((json) => {
          if (json) {
            self.showSnackbar = true
            self.snackBarMsg = 'Successfully saved'
            API.get('ezietruckapi', `/api/customers/${self.formData.Customer.CustomerID}/locations`).then((json) => {
              self.customerFavoriteLocations = json
            })
          } else {
            self.showSnackbar = true
            self.snackBarMsg = 'Something went wrong'
          }
        }).catch((e) => {
          self.showSnackbar = true
          self.snackBarMsg = e.message
        })
      }
    },
    handleCustomerPaidInput: function (e) {
      this.CustomerPaidAmount = e
      this.formData.CustomerPaidAmount = parseFloat(e)
    },
    handleDeliveryCostInput: function (e) {
      this.DeliveryCost = e
      this.formData.Trip.DeliveryCost = parseFloat(e)
    },
    handleSavePaidOption: function (amount) {
      let self = this
      API.post('ezietruckapi', `/api/customers/${this.formData.Customer.CustomerID}/favorite-prices`, {body: {Name: amount}}).then((json) => {
        if (json) {
          self.showSnackbar = true
          self.snackBarMsg = 'Successfully saved'
          self.fetchOptions()
        } else {
          self.showSnackbar = true
          self.snackBarMsg = 'Something went wrong'
        }
      })
    }
  },
  watch: {
    'formData.TruckType': function (newTruckType) {
      this.fetchDriver(newTruckType, this.formData.ContainerType, null)
    },
    'formData.ContainerType': function (newContainerType) {
      this.fetchDriver(this.formData.TruckType, newContainerType, null)
    }
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    submitedForm: function () {
      let out = []
      this.formValidate()
      let base = {
        PK: this.formData.PK,
        SK: this.formData.SK,
        OrderID: this.formData.OrderID,
        CustomerID: this.formData.Customer.CustomerID,
        CustomerName: this.formData.Customer.CustomerName,
        CustomerLogo: this.formData.Customer.CustomerLogo,
        Date: this.formData.Date,
        ProductType: this.formData.ProductType,
        JobType: this.formData.JobType,
        TruckType: this.formData.TruckType,
        ContainerType: this.formData.ContainerType,
        LoadPerTruck: this.formData.LoadPerTruck,
        TruckRequest: this.formData.TruckRequest,
        StopNumber: this.formData.StopNumber,
        TripRequest: this.formData.TripRequest,
        PickerPerTruck: this.formData.PickerPerTruck,
        From: {
          Description: this.formData.From.Description
        },
        To: {
          Description: this.formData.To.Description
        },
        StartDateTime: moment(this.formData.StartDate + ' ' + this.formData.StartTime, 'YYYY-MM-DD HH:mm').format(),
        EndDateTime: moment(this.formData.EndDate + ' ' + this.formData.EndTime, 'YYYY-MM-DD HH:mm').format(),
        CustomerPaidAmount: this.formData.CustomerPaidAmount,
        CustomerBillDate: this.formData.CustomerBillDate,
        OrderStatus: this.formData.OrderStatus,
        OrderSheetRef: this.formData.OrderSheetRef || Math.random().toString(36).substring(2),
        CreatedBy: this.formData.CreatedBy || this.user.signInUserSession.idToken.payload.email,
        CreatedAt: this.formData.CreatedAt || (new Date()).toISOString()
      }
      if (this.formData.From.GPS.Lat && this.formData.From.GPS.Lon) {
        base.From.GPS = Object.assign({}, this.formData.From.GPS)
      }
      if (this.formData.To.GPS.Lat && this.formData.To.GPS.Lon) {
        base.To.GPS = Object.assign({}, this.formData.To.GPS)
      }
      if (this.formData.Remark) {
        base.Remark = this.formData.Remark
      }
      out = [base]
      if (this.formData.Trip.Driver.DriverID) {
        if (out[0].OrderStatus === '1:Preparing') {
          out[0].OrderStatus = '2:Waiting'
        }
        let trip = {
          PK: this.formData.Trip.PK || 'ORDER#' + out[0].OrderID,
          SK: this.formData.Trip.SK || 'TRIP#' + out[0].OrderID + '.1',
          OrderID: this.formData.Trip.OrderID || out[0].OrderID,
          TripID: this.formData.Trip.TripID || out[0].OrderID + '.1',
          DriverName: this.formData.Trip.Driver.DriverName,
          DriverID: this.formData.Trip.Driver.DriverID,
          DriverPhoto: this.formData.Trip.Driver.DriverPhoto,
          LicensePlate: this.formData.Trip.LicensePlate,
          TruckType: this.formData.Trip.TruckType,
          ContainerType: this.formData.Trip.ContainerType,
          DriverPaidDueDate: this.formData.Trip.DriverPaidDueDate,
          DeliveryCost: this.formData.Trip.DeliveryCost,
          DailyPickerCost: this.formData.Trip.DailyPickerCost,
          OtherCost: this.formData.Trip.OtherCost,
          SalaryPerTrip: this.formData.Trip.SalaryPerTrip,
          FuelAllowance: this.formData.Trip.FuelAllowance,
          TripStatus: this.formData.Trip.TripStatus || '1:Waiting',
          OrderSheetRef: base.OrderSheetRef,
          AssignSheetRef: this.formData.Trip.AssignSheetRef || Math.random().toString(36).substring(2),
          CreatedBy: this.formData.CreatedBy || this.user.signInUserSession.idToken.payload.email,
          CreatedAt: this.formData.CreatedAt || (new Date()).toISOString()

        }
        if (this.formData.Trip.TruckSupporter) {
          trip.TruckSupporter = this.formData.Trip.TruckSupporter
        }
        out.push(trip)
      } else {
        out[0].OrderStatus = '1:Preparing'
      }
      return out
    },
    sortedOrders: function () {
      let out = this.formData.PK === '-' ? [this.submitedForm[0]] : [] 
      return out.concat([...this.orders].sort((a, b) => -1 * a.PK.localeCompare(b.PK)))
    },
    jobTypeAllOptions: function () {
      return [...this.jobTypeOptions, ...this.jobTypeCustomerOptions]
    },
    userOptions: function () {
      return this.userItems.map((u) => {
        let idx = u.Attributes.findIndex((a) => a.Name === 'email')
        if (idx > -1) {
          return u.Attributes[idx].Value
        }
      })
    }
  },
  created () {
    this.fetchData()
    this.fetchUser()
  }
}
</script>
<style type="text/css" scoped="">
  .highlight-field {
    background: #ffde03
  }
  .white-text span a {
    color: wheat
  }
  .white-text span a:hover {
    color: black
  }
</style>