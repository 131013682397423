<template>
  <div>
    <md-dialog :md-active.sync="showPhotoDialog">
      <md-dialog-title>Photo</md-dialog-title>
      <div style="overflow: auto">
        <md-content v-for="photo in currentPhoto" :key="photo">
          <my-s3-image :imagePath="photo"></my-s3-image>
        </md-content>
      </div>
    </md-dialog>
    <md-dialog :md-active.sync="showPhotoDialog">
      <md-dialog-title>Photo</md-dialog-title>
      <div style="overflow: auto">
        <md-content v-for="photo in currentPhoto" :key="photo">
          <my-s3-image :imagePath="photo"></my-s3-image>
        </md-content>
      </div>
    </md-dialog>
    <div class="md-layout">
      <div class="md-layout-item">
        <TripDiaryMap :markers="markers" :style="{'height': ($vssHeight - 160) + 'px', 'width': ($vssWidth * 0.7) + 'px'}" v-if="loaded" />
      </div>
      <div class="md-layout-item">
        <md-table>
          <md-table-row>
            <md-table-head>OrderID</md-table-head>
            <md-table-cell><router-link :to="'/Orders/' + order.OrderID" v-show="order.PK !== '-'">{{order.OrderID}}</router-link></md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-head>Driver Assign</md-table-head>
            <md-table-cell>{{order.DriverName}}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-head>Truck Type</md-table-head>
            <md-table-cell>{{order.TruckType}} - {{order.ContainerType}}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-head>Start at</md-table-head>
            <md-table-cell>{{datetimeFormater(order.StartDateTime)}}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-head>From</md-table-head>
            <md-table-cell v-if="order.From">{{order.From.Description}}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-head>To</md-table-head>
            <md-table-cell v-if="order.To">{{order.To.Description}}</md-table-cell>
          </md-table-row>
        </md-table>
        <h3 v-show="diaryItems.length">Diaries</h3>
        <md-list>
          <md-list-item v-for="(diary, i) in diaryItems" :key="diary.SK" :to="'/DiaryDetail/' + encodeURIComponent(diary.PK) + '/' + encodeURIComponent(diary.SK)" target="_blank">Diary #{{i + 1}}</md-list-item>
        </md-list>
      </div>
    </div>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import MyS3Image from '../components/MyS3Image'
import TripDiaryMap from '../components/TripDiaryMap'
import moment from 'moment'

export default {
  name: 'TripForm',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image, TripDiaryMap},
  data () {
    return {
      diaryItems: [],
      currentPhoto: [],
      showPhotoDialog: false,
      loaded: false,
      order: {}
    }
  },
  methods: {
    handleDriverSelect (value) {
      if (typeof value === 'object') {
        this.formData.Driver = value
      }
    },
    fetchData: function () {
      let vm = this
      API.get('ezietruckapi', `/api/orders/${this.$route.params.orderID}/diary`).then((json) => {
        vm.diaryItems = [...vm.diaryItems, ...(json.Items || [])]
        vm.loaded = true
      })
      API.get('ezietruckapi',`/api/orders/${this.$route.params.orderID}`).then((json) => {
        vm.order = Object.assign({}, vm.order, json)
      })
      API.get('ezietruckapi',`/api/orders/${this.$route.params.orderID}/trips`).then((json) => {
        vm.order = Object.assign({}, vm.order, json[0])
      })
    },
    datetimeFormater: function (str) {
      let m = moment(str)
      m.local() 
      return m.format('DD MMM YYYY HH:mm:ss')
    },
    openPhotoDialog: function (photo) {
      this.showPhotoDialog = true
      this.currentPhoto = photo
    }
  },
  computed: {
    ...mapGetters(['user']),
    markers: function () {
      return [...this.diaryItems.map((d) => {
        return {
          SK: d.SK,
          LatLng: {lat: d.Location.Lat, lng: d.Location.Lon},
          Diary: d
        }
      })].sort((a,b) =>  a.Diary.CreatedAt.localeCompare(b.Diary.CreatedAt))
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
