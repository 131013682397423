<template>
  <div>
    <h2>Diary</h2>
    <md-table>
      <md-table-row>
        <md-table-head>Name</md-table-head>
        <md-table-head>Customer</md-table-head>
        <md-table-head>Job Type</md-table-head>
        <md-table-head>Fields</md-table-head>
        <md-table-head>Operation</md-table-head>
      </md-table-row>
      <md-table-row v-for="form in lineDiaryFormTemplates" :key="form.Name">
        <md-table-cell>
          {{form.Name}}
        </md-table-cell>
        <md-table-cell>
          <div v-show="editName !== form.Name">
            {{form.CustomerID}}
          </div>
          <md-field v-show="editName === form.Name">
            <md-select v-model="editForm.CustomerID">
              <md-option value="---">---</md-option>
              <md-option v-for="customer in customerItems" :key="customer.SK" :value="customer.CustomerID">{{customer.CustomerName}}</md-option>
            </md-select>
          </md-field>
        </md-table-cell>
        <md-table-cell>
          <div v-show="editName !== form.Name">
            {{form.JobType}}
          </div>
          <md-field v-show="editName === form.Name">
            <md-select v-model="editForm.JobType">
              <md-option value="---">---</md-option>
              <md-option v-for="jobType in allJobTypes" :key="jobType.Name" :value="jobType.Name">{{jobType.Name}}</md-option>
            </md-select>
          </md-field>
        </md-table-cell>
        <md-table-cell>
          <ul v-show="editName !== form.Name">
            <li v-for="f in form.Fields" :key="f.Name">{{f}}</li>
          </ul>
          <md-field v-for="(field, idx) in editFieldStrs" :key="idx" v-show="editName === form.Name">
            <md-input :value="field" @input="handleUpdateEditField(idx, $event)"></md-input>
            <md-button @click="handleAddEditField(idx)">
              <md-icon>add</md-icon>
            </md-button>
            <md-button @click="handleRemoveEditField(idx)" v-show="idx > 0">
              <md-icon>remove</md-icon>
            </md-button>
          </md-field>
        </md-table-cell>
        <md-table-cell>
          <md-button @click="handleDeleteForm(form.Name)" v-show="form.Name !== 'Default'">delete</md-button>
          <md-button @click="handleEditForm(form)" v-show="editName !== form.Name">edit</md-button>
          <md-button @click="handleSaveEditForm(form)" v-show="editName === form.Name">save</md-button>
        </md-table-cell>
      </md-table-row>
      <md-table-row>
        <md-table-cell>
          <md-field>
            <label>Name</label>
            <md-input v-model="newForm.Name"></md-input>
          </md-field>
        </md-table-cell>
        <md-table-cell>
          <md-field>
            <label>CustomerID</label>
            <md-select v-model="newForm.CustomerID">
              <md-option value="---">---</md-option>
              <md-option v-for="customer in customerItems" :key="customer.SK" :value="customer.CustomerID">{{customer.CustomerName}}</md-option>
            </md-select>
          </md-field>
        </md-table-cell>
        <md-table-cell>
          <md-field>
            <label>JobType</label>
            <md-select v-model="newForm.JobType">
              <md-option value="---">---</md-option>
              <md-option v-for="jobType in allJobTypes" :key="jobType.Name" :value="jobType.Name">{{jobType.Name}}</md-option>
            </md-select>
          </md-field>
        </md-table-cell>
        <md-table-cell>
          <md-field v-for="(field, idx) in fieldStrs" :key="idx">
            <md-input :value="field" @input="handleUpdateField(idx, $event)"></md-input>
            <md-button @click="handleAddField(idx)">
              <md-icon>add</md-icon>
            </md-button>
            <md-button @click="handleRemoveField(idx)" v-show="idx > 0">
              <md-icon>remove</md-icon>
            </md-button>
          </md-field>
        </md-table-cell>
        <md-table-cell>
          <md-button @click="handleAddMoreForm">Add</md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import MyS3Image from '../components/MyS3Image'

export default {
  name: 'LineDiaryFormTemplate',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {MyS3Image},
  data () {
    return {
      customerItems: [],
      jobTypes: [],
      customerJobTypes: [],
      lineDiaryFormTemplates: [],
      lineCheckIOFormTemplates: [],
      newForm: {
        Name: null,
        CustomerID: null,
        JobType: null,
        Fields: [
          {
            Name: null,
            Type: null,
            Option: ""
          }
        ]
      },
      editForm: {
        Name: null,
        CustomerID: null,
        JobType: null,
        Fields: [
          {
            Name: null,
            Type: null,
            Option: ""
          }
        ]
      },
      fieldStrs: [JSON.stringify({
        Name: null,
        Type: null,
        Option: ""
      })],
      editName: null,
      editFieldStrs: []
    }
  },
  methods: {
    fetchCustomer: function (lastEvaluatedKey) {
      let self = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?ExclusiveStartKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      }
      API.get('ezietruckapi', '/api/customers' + q).then((json) => {
        if (json['Items']) {
          self.customerItems = [...self.customerItems, ...json['Items'].filter((i) => i.SK.startsWith('#METADATA#'))]
        }
        if (json.LastEvaluatedKey) {
          self.fetchCustomer(json.LastEvaluatedKey)
        }
      })
    },
    fetchFavJobTypes: function (customerID) {
      let vm = this
      API.get('ezietruckapi', `/api/customers/${customerID}/favorite-job-types`).then((json) => {
        vm.customerJobTypes = json
      })
    },
    fetchJobTypes: function () {
      let vm = this
      API.get('ezietruckapi', `/api/options/JobType`).then((json) => {
        vm.jobTypes = json
      })
    },
    fetchLineDiaryFormTemplates: function () {
      let vm = this
      API.get('ezietruckapi', `/api/options/LineDiaryFormTemplate`).then((json) => {
        vm.lineDiaryFormTemplates = json
      })
    },
    handleAddField:function (idx) {
      this.fieldStrs = [
        ...this.fieldStrs.slice(0, idx + 1),
        JSON.stringify({
          Name: null,
          Type: null,
          Option: ""
        }),
        ...this.fieldStrs.slice(idx + 1),
      ]
    },
    handleRemoveField: function (idx) {
      this.fieldStrs = [
        ...this.fieldStrs.slice(0, idx),
        ...this.fieldStrs.slice(idx + 1),
      ]
    },
    handleAddEditField:function (idx) {
      this.editFieldStrs = [
        ...this.editFieldStrs.slice(0, idx + 1),
        JSON.stringify({
          Name: null,
          Type: null,
          Option: ""
        }),
        ...this.editFieldStrs.slice(idx + 1),
      ]
    },
    handleRemoveEditField: function (idx) {
      this.editFieldStrs = [
        ...this.editFieldStrs.slice(0, idx),
        ...this.editFieldStrs.slice(idx + 1),
      ]
    },
    handleAddMoreForm: function () {
      if (this.newForm.Name) {
        let newForm = {
          Name: this.newForm.Name,
          CustomerID: this.newForm.CustomerID || '---',
          JobType: this.newForm.JobType || '---',
          Fields: this.fieldStrs.map((f) => JSON.parse(f))
        }
        let vm = this
        API.post('ezietruckapi', `/api/options/LineDiaryFormTemplate/body`, {body: newForm}).then((_) => {
          vm.fetchLineDiaryFormTemplates()
          vm.newForm = {
            Name: null,
            CustomerID: null,
            JobType: null,
            Fields: [
              {
                Name: null,
                Type: null,
                Option: ""
              }
            ]
          }
        })
      }
    },
    handleDeleteForm: function (name) {
      let self = this
      API.del('ezietruckapi', `/api/options/LineDiaryFormTemplate/${name}`).then(() => {
        self.fetchLineDiaryFormTemplates()
      })
    },
    handleEditForm: function (item) {
      this.editForm = {
        Name: item.Name,
        CustomerID: item.CustomerID,
        JobType: item.JobType,
        Fields: item.Fields
      }
      this.editFieldStrs = item.Fields.map((f) => JSON.stringify(f))
      this.editName = item.Name
    },
    handleUpdateField: function (idx, value) {
      this.fieldStrs =[
        ...this.fieldStrs.slice(0, idx),
        value,
        ...this.fieldStrs.slice(idx + 1)
      ]
    },
    handleUpdateEditField: function (idx, value) {
      this.editFieldStrs =[
        ...this.editFieldStrs.slice(0, idx),
        value,
        ...this.editFieldStrs.slice(idx + 1)
      ]
    },
    handleSaveEditForm: function (item) {
      if (this.editForm.Name) {
        let newForm = {
          Name: this.editForm.Name,
          CustomerID: this.editForm.CustomerID || '---',
          JobType: this.editForm.JobType || '---',
          Fields: this.editFieldStrs.map((f) => JSON.parse(f))
        }
        let vm = this
        API.post('ezietruckapi', `/api/options/LineDiaryFormTemplate/body`, {body: newForm}).then((_) => {
          vm.fetchLineDiaryFormTemplates()
          vm.editName = null
          vm.editForm = {
            Name: null,
            CustomerID: null,
            JobType: null,
            Fields: [
              {
                Name: null,
                Type: null,
                Option: ""
              }
            ]
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    customers: function () {
      let out = []
      for (let o of this.customerItems) {
        if (o.SK.slice(0, 10) === '#METADATA#') {
          out.push(o)
        }
      }
      return out
    },
    allJobTypes: function () {
      return this.customerJobTypes.length > 0 ? this.customerJobTypes : this.jobTypes
    }
  },
  created () {
    this.fetchCustomer()
    this.fetchJobTypes()
    this.fetchLineDiaryFormTemplates()
  }
}
</script>
